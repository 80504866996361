import { ThemeOptions } from '@mui/material/styles'
import tailWindConfig from 'tailwind-workspace-preset'

const {
  theme: {
    colors: { primary, shades, orange, red, white },
    fontFamily: { sans },
    fontSize: { base, lg, xl },
  },
} = tailWindConfig

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xssm: true
    smmd: true
    xxsm: true
    xxs: true
  }
}

export const muiTheme: ThemeOptions = {
  palette: {
    // mui theme has a scale of 10 tints vs our 5, leaving this out of config
    // until we have a better idea how we want to configure these
    // see: https://mui.com/customization/default-theme/
    primary: {
      50: primary[50],
      200: primary[200],
      400: primary[400],
      600: primary[600],
      800: primary[800],
      main: primary.DEFAULT,
    },
    grey: {
      50: shades[50],
      200: shades[200],
      400: shades[400],
      600: shades[600],
      800: shades[800],
    },
    error: {
      main: red.DEFAULT,
      50: red[50],
      200: red[200],
      400: red[400],
      600: red[600],
      800: red[800],
    },
    warning: {
      main: orange.DEFAULT,
      50: orange[50],
      200: orange[200],
      400: orange[400],
      600: orange[600],
      800: orange[800],
    },
  },
  typography: {
    h1: {
      fontWeight: 'bold',
      fontSize: xl,
    },
    h2: {
      fontWeight: 'bold',
      fontSize: lg,
    },
    h3: {
      fontWeight: 'bold',
      fontSize: base,
    },
    fontFamily: sans.join(', '),
  },
  transitions: {
    // Disabling the funky animations globally, we can add per component as needed
    create: () => 'none',
  },
  components: {
    // Name of the component
    MuiPaper: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          paddingLeft: '8px',
          paddingRight: '8px',
          marginTop: '5px',
          borderWidth: '1px',
          borderColor: shades[200],
          fontSize: '14px',
          boxShadow: '0px 20px 20px rgba(0, 0, 0, 0.05)',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          borderRadius: 4,
          paddingLeft: '8px',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 5,
          backgroundColor: primary[400],
          color: 'white',
          border: 0,
        },
        deleteIcon: {
          color: 'white',
          fontSize: '15px',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          paddingTop: '2px',
          paddingBottom: '3px',
        },
        option: {
          fontSize: '14px',
          color: shades[600],
        },
        tag: {
          margin: 0,
          marginRight: '9px',
          marginBottom: '4px',
          marginLeft: '0px',
          marginTop: '4px',
          height: '22px',
          fontSize: '10px',
        },
        input: {
          paddingTop: '0px',
          paddingBottom: '0px',
        },
        popper: {
          zIndex: '10004',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          paddingTop: '8px',
          paddingBottom: '9px',
          backgroundColor: white.DEFAULT,
        },
      },
    },

    MuiInput: {
      styleOverrides: {
        root: {
          borderWidth: '0px',
        },
        underline: {
          backgroundColor: white.DEFAULT,
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          zIndex: 10003,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: white.DEFAULT,
        },
        notchedOutline: {
          borderColor: shades[200],
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      xxs: 300,
      xxsm: 365,
      xssm: 390,
      sm: 600,
      smmd: 660,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
}
