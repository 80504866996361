// -------------------------------------------------------------------------------------------------------------------
//
//  Known bot list from Datadog:
//  https://docs.datadoghq.com/real_user_monitoring/guide/identify-bots-in-the-ui/#filter-out-bot-sessions-on-intake
//
// -------------------------------------------------------------------------------------------------------------------
// '(googlebot/|bot|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebCrawler|FAST Enterprise Crawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerCrawler|webmon |httrack|webcrawler|grub.org|UsineNouvelleCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|findthatfile|europarchive.org|NerdByNature.Bot|sistrix crawler|ahrefsbot|Aboundex|domaincrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketcrawler|drupact|webcompanycrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkcrawler|coccoc|integromedb|content crawler spider|toplistbot|seokicks-robot|it2media-domain-crawler|ip-web-crawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotCrawler|urlappendbot|brainobot|fr-crawler|binlar|SimpleCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|crawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis)'

const bots: string[] = []

/**
 * Device Type  : Bot
 * Browser Name : Bytespider
 * Country      : 🇸🇬 Singapore / 🇨🇳 China
 * Background:
 *   https://stackoverflow.com/questions/57908900/what-is-the-bytespider-user-agent
 *   https://www.reddit.com/r/flask/comments/161fqml/what_to_do_about_bytespider/
 *   https://wordpress.org/support/topic/bytedance-crawler-friend-or-foe/
 *
 * Example:
 *   https://app.datadoghq.com/rum/sessions?query=%40type%3Aerror%20%40application.id%3A96cb20f8-1110-4a9e-897f-191b1acd87ea%20env%3Aprod%20%40device.type%3ABot&cols=&event=AgAAAYvSW7hhRe9eRQAAAAAAAAAYAAAAAEFZdlNXOFV5QUFDcnVxbTRZQjQwbEFBQgAAACQAAAAAMDE4YmQyYzQtMGZlMS00MGQ0LWI0Y2YtODY3ZDk2MzFiY2Zh&p_tab=attributes&from_ts=1699980349548&to_ts=1700066749548&live=true
 *
 */
bots.push('Bytespider')

/**
 * Device Type  : Bot
 * Browser Name : bingbot
 * Country      : 🇺🇸 United States
 *
 * Example:
 * https://app.datadoghq.com/rum/sessions?query=%40type%3Aerror%20%40application.id%3A96cb20f8-1110-4a9e-897f-191b1acd87ea%20env%3Aprod%20%40device.type%3ABot&cols=&event=AgAAAYvTy2EtIEFAegAAAAAAAAAYAAAAAEFZdlR5MkU2QUFEVWZSWGVjYm16WmdBVwAAACQAAAAAMDE4YmQzZDktYjcxMy00OGM0LTlkN2UtN2I4MjNmODRiMWI1&p_tab=attributes&from_ts=1699980649766&to_ts=1700067049766&live=true
 *
 */
bots.push('bingbot')

export const IGNORED_BOTS = `(${bots.join('|')})`
