import { RumEvent } from '@datadog/browser-rum'
import { FILTERED_ERRORS } from './filtered-errors'

/**
 * Function used to filter out Datadog errors that we don't want to send to Datadog.
 * // https://docs.datadoghq.com/real_user_monitoring/guide/enrich-and-control-rum-data/?tab=event
 * @param event The event to filter.
 * @returns True if the event should be sent to Datadog, false if it should be filtered out.
 */
export function filterEvent(event: RumEvent) {
  if (event.type === 'error') {
    for (const error of FILTERED_ERRORS) {
      if (event.error.message.includes(error)) {
        // Don't send the event.
        return false
      }
    }
  }

  // OK to send!
  return true
}
