const startCase = require('lodash/startCase')

const commonListingTranslations = ['listings', 'ui', 'geolocation']

module.exports = {
  locales: ['en'],
  defaultLocale: 'en',
  pages: {
    '*': ['header', 'footer', 'base', 'ui', 'users'],
    '/learn-more/career-opportunities': ['listings', 'inner-pages'],
    '/learn-more/about-us': ['listings', 'inner-pages'],
    '/learn-more/contact-us': ['listings', 'inner-pages'],
    '/learn-more/selling-simplified': ['listings', 'inner-pages', 'geolocation'],
    '/learn-more/selling-simplified-new': ['inner-pages'],
    '/learn-more/cashback-details': ['listings', 'inner-pages'],
    '/learn-more/buying-simplified': ['inner-pages'],
    '/legal/terms-of-use': ['listings', 'inner-pages'],
    '/real-estate/liked': [...commonListingTranslations],
    '/real-estate/inquired': [...commonListingTranslations],
    '/real-estate/[listing]': [...commonListingTranslations],
    '/real-estate/listing/[id]/[tab]': ['listings', 'ui', 'buildings', 'real-estate', 'furniture'],
    '/real-estate/listing/[id]/book-a-showing': ['listings', 'ui'],
    '/real-estate/listing/[id]/ask-a-question': ['listings', 'ui'],
    '/register-or-sign-in': ['users'],
    '/sign-in': ['users'],
    '/forgot-password': ['users'],
    '/users/verify/[token]': ['users', 'ui', 'geolocation', 'data-form-instance'],
    '/users/accept-invite/[token]': ['users'],
    '/users/reset-password/[token]': ['users'],
    '/users/profile': ['users', 'data-form-instance', 'ui', 'geolocation'],
    '/users/edit-profile': ['data-form-instance', 'geolocation'],
    '/attend-open-house/[id]': ['listings', 'users'],
    '/home': [...commonListingTranslations, 'home'],
    '/form-wizard-preview': ['data-form-instance', 'geolocation'],
    '/my-account/my-listings': ['my-account', 'listings', 'ui'],
    '/my-account/my-listings/[id]': ['my-account', 'listings', 'ui'],
    '/my-account/my-listings/[id]/[tab]': ['my-account', 'listings', 'ui', 'activities'],
    '/property-listing-opportunity-inquiry': ['listings', 'data-form-instance', 'inner-pages', 'geolocation', 'forms'],
    '/my-account/my-listings/[id]/property-form/[listingIterationId]': [
      'ui',
      'data-form-instance',
      'listings',
      'geolocation',
      'users',
    ],
  },

  loadLocaleFrom: (lang, ns) =>
    import(`../../locales/${lang}/${ns}.json`)
      .then((m) => m.default)
      .catch(() => import(`./locales/${lang}/${ns}.json`).then((m) => m.default)),

  interpolation: {
    format: (value, format) => {
      return format === 'titleCase' ? startCase(value.toLowerCase()) : value
    },
  },
}
