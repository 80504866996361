import { datadogRum } from '@datadog/browser-rum'
import { CONFIG, TEST_CONFIG } from './configs'

export function initDatadogRum({ forTesting = false }) {
  const config = forTesting ? TEST_CONFIG : CONFIG

  if (forTesting) {
    console.log(`dataDog.init() !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!`)
    console.log(config)
  }

  datadogRum.init(config)
  datadogRum.startSessionReplayRecording()
}
