import { RumInitConfiguration } from '@datadog/browser-rum'
import { filterEvent } from './filter-event'
import { IGNORED_BOTS } from './bots'
import { HAVE_WINDOW } from '../window'

// ---------------------------------------------------------------------------------------------------------------
// Normal config to use when not specifically debugging.
//
// TODO: Certain params are deprecated in V5 (keep this in mind when upgrading from V4).
// https://docs.datadoghq.com/real_user_monitoring/guide/browser-sdk-upgrade/#sdk-initialization-parameters
//
// ---------------------------------------------------------------------------------------------------------------
export const CONFIG: RumInitConfiguration = {
  applicationId: process.env.NEXT_PUBLIC_DD_APPLICATION_ID as string,
  clientToken: process.env.NEXT_PUBLIC_DD_CLIENT_TOKEN as string,
  site: process.env.NEXT_PUBLIC_DD_SITE,
  service: process.env.NEXT_PUBLIC_DD_SERVICE,
  env: process.env.NEXT_PUBLIC_DD_ENV,
  version: process.env.NEXT_PUBLIC_APP_VERSION,
  sessionReplaySampleRate: parseFloat(process.env.NEXT_PUBLIC_DD_SESSION_REPLAY_SAMPLE_RATE as string),
  trackInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
  allowedTracingOrigins: [new RegExp(process.env.NEXT_PUBLIC_DD_ALLOWED_TRACING_ORIGIN as string)],
  tracingSampleRate: parseFloat(process.env.NEXT_PUBLIC_DD_TRACING_SAMPLE_RATE as string),

  // --------------------------------------------------------------------------------------------------------------
  // Turn sampling off for the bots we want to ignore.
  //    - https://docs.datadoghq.com/real_user_monitoring/guide/sampling-browser-plans/#setup
  //    - https://docs.datadoghq.com/real_user_monitoring/guide/browser-sdk-upgrade/#sdk-initialization-parameters
  //    - https://docs.datadoghq.com/real_user_monitoring/guide/identify-bots-in-the-ui/#filter-out-bot-sessions-on-intake
  // --------------------------------------------------------------------------------------------------------------
  sampleRate:
    HAVE_WINDOW && new RegExp(IGNORED_BOTS, 'i').test(navigator.userAgent)
      ? 0
      : parseFloat(process.env.NEXT_PUBLIC_DD_SAMPLE_RATE as string),

  // Filter our events that we don't want.
  // https://docs.datadoghq.com/real_user_monitoring/guide/enrich-and-control-rum-data/?tab=event
  beforeSend: filterEvent,
}

// ---------------------------------------------------------------------------------------------------------------
// Use this config for debugging and sending errors to the unreserved-datadog-testing project:
//
// https://app.datadoghq.com/rum/sessions?query=%40application.id%3A11a9c736-e37b-4c2c-ae00-6347803fa696%20%40type%3Aerror&cols=&replay_tab=most_interacted_with&viz=stream&from_ts=1698351682938&to_ts=1698438082938&live=true
//
// ---------------------------------------------------------------------------------------------------------------
export const TEST_CONFIG: RumInitConfiguration = {
  applicationId: '11a9c736-e37b-4c2c-ae00-6347803fa696',
  clientToken: 'pub5131bbfbea36a00d8e903da5362b51cc',
  site: 'datadoghq.com',
  service: 'unreserved-datadog-testing',
  env: 'dev',

  // Specify a version number to identify the deployed version of your application in Datadog
  version: 'testing.1.2.3',

  // NOTE: Log all events and replays for all sessions so use this only for debugging!
  sampleRate: 100,
  sessionReplaySampleRate: 100,
  trackResources: true,

  // https://docs.datadoghq.com/real_user_monitoring/guide/enrich-and-control-rum-data/?tab=event
  beforeSend: filterEvent,
}
