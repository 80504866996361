// -------------------------------------------------------------------------------------------------------------
//
// The list of error strings to check when an error event comes in from Datadog.
// If any of the strings below are in event.error.message, the event will be filtered out and not sent to Datadog.
//
// !!! IMPORTANT !!! - Make sure to only add error messages that we are absolutely sure we want to filter out!
//
// Check this link for potential errors to filter:
// https://app.datadoghq.com/rum/explorer?query=%40type%3Aerror%20%40application.id%3A96cb20f8-1110-4a9e-897f-191b1acd87ea%20env%3Aprod&cols=
//
// -------------------------------------------------------------------------------------------------------------
export const FILTERED_ERRORS = [
  // Source(s): mapblibre, Lightgallery
  'Ignored attempt to cancel a touchmove event with cancelable=false',

  // Source(s): maplibre
  "null is not an object (evaluating 'e.getParameter(e.VERSION).startsWith')",
]
