/* eslint-disable */

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigDecimal: any;
  Date: any;
  DateTime: any;
  JSON: any;
  Locale: any;
  Long: any;
  Void: any;
};

export type AbstractTaskInstance = {
  activityFeed?: Maybe<ActivityFeed>;
  assignedBy?: Maybe<AdminUser | AgentUser | ConsumerUser | ContactUser>;
  assignedUser?: Maybe<AdminUser | AgentUser | ConsumerUser | ContactUser>;
  assignedUserStatus: TaskAssigneeStatus;
  assignedUserStatusName: Scalars['String'];
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  definition: ApproveSubmissionToMlsTaskDefinition | AttachFileStorageTaskDefinition | BasicTaskDefinition | FileUploadTaskDefinition | FormAssignTaskDefinition | FormFillTaskDefinition | FormReviewTaskDefinition | SubmitListingToMlsTaskDefinition | UploadToFileStorageTaskDefinition;
  description?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  following: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
  objectType: ActivityObjectType;
  project: ProjectInstance;
  requestedChanges?: Maybe<Scalars['String']>;
  reviewer?: Maybe<AdminUser | AgentUser | ConsumerUser | ContactUser>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status: TaskInstanceStatus;
  statusName: Scalars['String'];
  type: TaskType;
  typeName: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type AbstractTaskInstanceActivityFeedArgs = {
  containingActivityId?: InputMaybe<Scalars['ID']>;
  first?: Scalars['Int'];
  offset?: Scalars['Int'];
};

export type AcceptProjectCollaboratorInviteNewUserInput = {
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  /** Required */
  password?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  token: Scalars['ID'];
};

export type AcceptProjectCollaboratorInviteResponse = {
  __typename?: 'AcceptProjectCollaboratorInviteResponse';
  authToken: Scalars['String'];
  projectInstance: ProjectInstance;
  user: AdminUser | AgentUser | ConsumerUser | ContactUser;
};

export type AcceptTaskAssignNewUserResponse = {
  __typename?: 'AcceptTaskAssignNewUserResponse';
  authToken: Scalars['String'];
  taskInstance: ApproveSubmissionToMlsTaskInstance | BasicTaskInstance | FileUploadTaskInstance | FormFillTaskInstance | SubmitListingToMlsTaskInstance;
  user: AdminUser | AgentUser | ConsumerUser | ContactUser;
};

export type AcceptUserInviteInput = {
  addressId?: InputMaybe<Scalars['ID']>;
  birthDate?: InputMaybe<Scalars['Date']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  locale: Scalars['Locale'];
  password: Scalars['String'];
  primaryPhone: Scalars['String'];
  screenName?: InputMaybe<Scalars['String']>;
  token: Scalars['String'];
};

export const enum AccessibilityFeature {
  /** <https://ddwiki.reso.org/display/DDW17/AccessibilityFeatures+Lookups> */
  AccessibleApproachWithRamp = 'ACCESSIBLE_APPROACH_WITH_RAMP',
  AccessibleBedroom = 'ACCESSIBLE_BEDROOM',
  AccessibleCentralLivingArea = 'ACCESSIBLE_CENTRAL_LIVING_AREA',
  AccessibleClosets = 'ACCESSIBLE_CLOSETS',
  AccessibleCommonArea = 'ACCESSIBLE_COMMON_AREA',
  AccessibleDoors = 'ACCESSIBLE_DOORS',
  AccessibleElectricalAndEnvironmentalControls = 'ACCESSIBLE_ELECTRICAL_AND_ENVIRONMENTAL_CONTROLS',
  AccessibleElevatorInstalled = 'ACCESSIBLE_ELEVATOR_INSTALLED',
  AccessibleEntrance = 'ACCESSIBLE_ENTRANCE',
  AccessibleForHearingImpairment = 'ACCESSIBLE_FOR_HEARING_IMPAIRMENT',
  AccessibleFullBath = 'ACCESSIBLE_FULL_BATH',
  AccessibleHallway = 'ACCESSIBLE_HALLWAY',
  AccessibleKitchen = 'ACCESSIBLE_KITCHEN',
  AccessibleKitchenAppliances = 'ACCESSIBLE_KITCHEN_APPLIANCES',
  AccessibleStairway = 'ACCESSIBLE_STAIRWAY',
  AccessibleWasherOrDryer = 'ACCESSIBLE_WASHER_OR_DRYER',
  AdaptableBathroomWalls = 'ADAPTABLE_BATHROOM_WALLS',
  AdaptableForElevator = 'ADAPTABLE_FOR_ELEVATOR',
  CeilingTrack = 'CEILING_TRACK',
  CentralLivingArea = 'CENTRAL_LIVING_AREA',
  CommonArea = 'COMMON_AREA',
  CustomizedWheelchairAccessible = 'CUSTOMIZED_WHEELCHAIR_ACCESSIBLE',
  ElectronicEnvironmentalControls = 'ELECTRONIC_ENVIRONMENTAL_CONTROLS',
  EnhancedAccessible = 'ENHANCED_ACCESSIBLE',
  ExteriorWheelchairLift = 'EXTERIOR_WHEELCHAIR_LIFT',
  GripAccessibleFeatures = 'GRIP_ACCESSIBLE_FEATURES',
  ReinforcedFloors = 'REINFORCED_FLOORS',
  SafeEmergencyEgressFromHome = 'SAFE_EMERGENCY_EGRESS_FROM_HOME',
  SmartTechnology = 'SMART_TECHNOLOGY',
  StairLift = 'STAIR_LIFT',
  StandbyGenerator = 'STANDBY_GENERATOR',
  TherapeuticWhirlpool = 'THERAPEUTIC_WHIRLPOOL',
  Visitable = 'VISITABLE',
  VisitorBathroom = 'VISITOR_BATHROOM',
  WalkerAccessibleStairs = 'WALKER_ACCESSIBLE_STAIRS'
};

export type ActionDefinition = {
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Icon>;
  id: Scalars['ID'];
  kind: ActionKind;
  logicalName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  sequence?: Maybe<Scalars['Int']>;
};

export const enum ActionKind {
  Custom = 'CUSTOM',
  Framework = 'FRAMEWORK'
};

export type Activity = {
  actor?: Maybe<AdminUser | AgentUser | ConsumerUser | ContactUser>;
  comments?: Maybe<Array<ActivityComment>>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** Whether the thread id fully read or there are any unread comments */
  isRead: Scalars['Boolean'];
  /** Whether the thread is resolved */
  isResolved?: Maybe<Scalars['Boolean']>;
  media?: Maybe<Array<File | Image | Model3D>>;
  message?: Maybe<Scalars['String']>;
  object?: Maybe<AdminUser | AgentUser | ApproveSubmissionToMlsTaskInstance | BasicTaskInstance | ConsumerUser | ContactUser | FileUploadTaskInstance | FormFillTaskInstance | ProjectInstance | PropertyListing | PropertyListingOpportunity | SubmitListingToMlsTaskInstance>;
  /** Timestamp of the last time the thread was read by the current user. False if the current user is not a thread participant */
  readAt?: Maybe<Scalars['DateTime']>;
  relatedEntity?: Maybe<Address | AdminUser | AgentUser | ConsumerUser | ContactUser | DataFormInstance | DataFormStepInstance | Image | PropertyComparativeMarketAnalysis | PropertyListing | PropertyListingIteration | ResidentialPropertyRoom>;
  status: ActivityStatus;
  subType?: Maybe<ActivitySubType>;
  subTypeName?: Maybe<Scalars['String']>;
  type: ActivityType;
  typeName: Scalars['String'];
  verb: Scalars['String'];
  visibility: ActivityVisibility;
  visibilityName: Scalars['String'];
};


export type ActivityCommentsArgs = {
  latest?: InputMaybe<Scalars['Int']>;
  statuses?: InputMaybe<Array<ActivityCommentStatus>>;
};


export type ActivityMediaArgs = {
  first?: InputMaybe<Scalars['Int']>;
  statuses?: InputMaybe<Array<MediaStatus>>;
};

export type ActivityComment = {
  __typename?: 'ActivityComment';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  media?: Maybe<Array<File | Image | Model3D>>;
  message: Scalars['String'];
  status: ActivityCommentStatus;
  statusName: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: AdminUser | AgentUser | ConsumerUser | ContactUser;
  visibility: ActivityVisibility;
  visibilityName: Scalars['String'];
};


export type ActivityCommentMediaArgs = {
  first?: InputMaybe<Scalars['Int']>;
  statuses?: InputMaybe<Array<MediaStatus>>;
};

export const enum ActivityCommentStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED'
};

export type ActivityFeed = {
  __typename?: 'ActivityFeed';
  activities: Array<InquiryCreatedActivity | NoteActivity | ProjectStageEventActivity | PropertyListingLikeActivity | PropertyListingViewActivity | RootProjectEventActivity | SubProjectEventActivity | TaskAssignActivity | TaskEventActivity | UserAssignedAgentAddedActivity | UserAssignedAgentRemovedActivity | UserReactivatedActivity | UserSuspendedActivity>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
};

export type ActivityMetadata = {
  __typename?: 'ActivityMetadata';
  subType?: Maybe<Array<Maybe<EnumValueMetadata>>>;
  type: Array<EnumValueMetadata>;
};


export type ActivityMetadataSubTypeArgs = {
  type: ActivityType;
};

export type ActivityObject = {
  activityFeed?: Maybe<ActivityFeed>;
  following: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
  objectType: ActivityObjectType;
};


export type ActivityObjectActivityFeedArgs = {
  containingActivityId?: InputMaybe<Scalars['ID']>;
  first?: Scalars['Int'];
  offset?: Scalars['Int'];
};

export const enum ActivityObjectType {
  Opportunity = 'OPPORTUNITY',
  ProjectInstance = 'PROJECT_INSTANCE',
  PropertyListing = 'PROPERTY_LISTING',
  TaskInstance = 'TASK_INSTANCE',
  User = 'USER'
};

export const enum ActivityStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED'
};

export const enum ActivitySubType {
  AskQuestion = 'ASK_QUESTION',
  Note = 'NOTE',
  PostUpdate = 'POST_UPDATE',
  ProjectStageCompleted = 'PROJECT_STAGE_COMPLETED',
  ProjectStageStarted = 'PROJECT_STAGE_STARTED',
  RootProjectCancelled = 'ROOT_PROJECT_CANCELLED',
  RootProjectCreated = 'ROOT_PROJECT_CREATED',
  RootProjectFinished = 'ROOT_PROJECT_FINISHED',
  RootProjectStarted = 'ROOT_PROJECT_STARTED',
  SubProjectCancelled = 'SUB_PROJECT_CANCELLED',
  SubProjectCreated = 'SUB_PROJECT_CREATED',
  SubProjectFinished = 'SUB_PROJECT_FINISHED',
  SubProjectStarted = 'SUB_PROJECT_STARTED',
  TaskApproved = 'TASK_APPROVED',
  TaskAssigned = 'TASK_ASSIGNED',
  TaskChangesRequested = 'TASK_CHANGES_REQUESTED',
  TaskCompleted = 'TASK_COMPLETED',
  TaskCreated = 'TASK_CREATED',
  TaskReassigned = 'TASK_REASSIGNED',
  TaskReopened = 'TASK_REOPENED',
  TaskReopenedChangesRequested = 'TASK_REOPENED_CHANGES_REQUESTED',
  TaskStarted = 'TASK_STARTED',
  TaskSubmittedForReview = 'TASK_SUBMITTED_FOR_REVIEW',
  TaskUnassigned = 'TASK_UNASSIGNED'
};

export const enum ActivityType {
  InquiryCreated = 'INQUIRY_CREATED',
  ListingLiked = 'LISTING_LIKED',
  ListingViewed = 'LISTING_VIEWED',
  Note = 'NOTE',
  ProjectStageEvent = 'PROJECT_STAGE_EVENT',
  RootProjectEvent = 'ROOT_PROJECT_EVENT',
  SubProjectEvent = 'SUB_PROJECT_EVENT',
  TaskAssign = 'TASK_ASSIGN',
  TaskEvent = 'TASK_EVENT',
  UserAssignedAgentAdded = 'USER_ASSIGNED_AGENT_ADDED',
  UserAssignedAgentRemoved = 'USER_ASSIGNED_AGENT_REMOVED',
  UserReactivated = 'USER_REACTIVATED',
  UserSuspended = 'USER_SUSPENDED'
};

export const enum ActivityVisibility {
  Private = 'PRIVATE',
  Public = 'PUBLIC'
};

export type AddDistributionMediaInput = {
  contentType: Scalars['String'];
  distributionId: Scalars['ID'];
  originalFileName?: InputMaybe<Scalars['String']>;
  sizeBytes: Scalars['Long'];
};

export type AddEntityTagInput = {
  entity: TagEntityInput;
  tagId: Scalars['ID'];
};

export type AddImageInput = {
  contentType: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  entityId: Scalars['ID'];
  entityType: MediaOwner;
  originalFileName?: InputMaybe<Scalars['String']>;
  sequence: Scalars['Int'];
  sizeBytes: Scalars['Long'];
  /** Allow only 'image/jpeg' or 'image/png' */
  title?: InputMaybe<Scalars['String']>;
};

export type AddMediaInput = {
  contentType: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  entityId?: InputMaybe<Scalars['ID']>;
  entityType?: InputMaybe<MediaOwner>;
  originalFileName?: InputMaybe<Scalars['String']>;
  sequence: Scalars['Int'];
  sizeBytes: Scalars['Long'];
  title?: InputMaybe<Scalars['String']>;
};

export type AddOpportunityCustomersInput = {
  customerUserIds: Array<Scalars['ID']>;
  opportunityId: Scalars['ID'];
};

export type AddProductModel3DInput = {
  contentType: Scalars['String'];
  originalFileName?: InputMaybe<Scalars['String']>;
  productId: Scalars['ID'];
  sizeBytes: Scalars['Long'];
};

export type AddProjectCollaboratorInput = {
  message?: InputMaybe<Scalars['String']>;
  projectInstanceId: Scalars['ID'];
  roleId?: InputMaybe<Scalars['ID']>;
  userId: Scalars['ID'];
};

export type AddProjectCollaboratorsInput = {
  collaborators: Array<ProjectCollaboratorInput>;
  projectInstanceId: Scalars['ID'];
};

export type AddPropertyListingIterationSellersInput = {
  listingIterationId: Scalars['ID'];
  sellerIds: Array<Scalars['ID']>;
};

export type AddSpaceMaterialMapInput = {
  contentType: Scalars['String'];
  originalFileName?: InputMaybe<Scalars['String']>;
  sizeBytes: Scalars['Long'];
  spaceMaterialId: Scalars['ID'];
};

export type AddUserAssignedAgentsInput = {
  agentUserIds: Array<Scalars['ID']>;
  sequence?: InputMaybe<Scalars['Int']>;
  userId: Scalars['ID'];
};

export type AddUserProfileImageInput = {
  contentType: Scalars['String'];
  originalFileName?: InputMaybe<Scalars['String']>;
  sizeBytes: Scalars['Long'];
};

export type Address = Entity & {
  __typename?: 'Address';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  addressNormalized: Scalars['Boolean'];
  /** @deprecated No longer supported */
  city: Place;
  cityId: Scalars['ID'];
  cityNameShort: Scalars['String'];
  citySlug: Scalars['String'];
  /** @deprecated No longer supported */
  country: Place;
  countryId: Scalars['ID'];
  countryNameShort: Scalars['String'];
  countrySlug: Scalars['String'];
  id: Scalars['ID'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  /** @deprecated No longer supported */
  macroNeighbourhood?: Maybe<Place>;
  macroNeighbourhoodId?: Maybe<Scalars['ID']>;
  macroNeighbourhoodNameShort?: Maybe<Scalars['String']>;
  macroNeighbourhoodSlug?: Maybe<Scalars['String']>;
  metroArea?: Maybe<Place>;
  metroAreaId?: Maybe<Scalars['ID']>;
  metroAreaNameShort?: Maybe<Scalars['String']>;
  metroAreaSlug?: Maybe<Scalars['String']>;
  /** @deprecated No longer supported */
  neighbourhood?: Maybe<Place>;
  neighbourhoodId?: Maybe<Scalars['ID']>;
  neighbourhoodNameShort?: Maybe<Scalars['String']>;
  neighbourhoodSlug?: Maybe<Scalars['String']>;
  /** @deprecated No longer supported */
  region: Place;
  regionId: Scalars['ID'];
  regionNameAbbreviated: Scalars['String'];
  regionNameShort: Scalars['String'];
  regionSlug: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  /** @deprecated No longer supported */
  street?: Maybe<Place>;
  streetId?: Maybe<Scalars['ID']>;
  streetNameShort?: Maybe<Scalars['String']>;
  /** @deprecated Please use addressNormalized instead */
  streetNormalized: Scalars['Boolean'];
  streetNumber?: Maybe<Scalars['String']>;
  streetSlug?: Maybe<Scalars['String']>;
  /** @deprecated No longer supported */
  subNeighbourhood?: Maybe<Place>;
  subNeighbourhoodId?: Maybe<Scalars['ID']>;
  subNeighbourhoodNameShort?: Maybe<Scalars['String']>;
  subNeighbourhoodSlug?: Maybe<Scalars['String']>;
  unitNumber?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type AddressAutocompleteInput = {
  query: Scalars['String'];
  sessionToken: Scalars['String'];
};

export type AddressAutocompleteResultInput = {
  placeId: Scalars['ID'];
  sessionToken: Scalars['String'];
};

export type AddressDataField = DataField & {
  __typename?: 'AddressDataField';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  key: Scalars['String'];
  logicalName?: Maybe<DataFieldLogicalName>;
  mlsField?: Maybe<MlsField>;
  name: Scalars['String'];
  processed?: Maybe<Scalars['Boolean']>;
  type: DataFieldType;
  typeName: Scalars['String'];
};

export type AddressDataFormInputInstance = DataFormInputInstance & {
  __typename?: 'AddressDataFormInputInstance';
  address?: Maybe<Address>;
  currentValue?: Maybe<Scalars['String']>;
  definition: DataFormInputDefinition;
  editable: Scalars['Boolean'];
  id: Scalars['ID'];
};

export type AddressDataViewFieldInstance = DataViewFieldInstance & {
  __typename?: 'AddressDataViewFieldInstance';
  address?: Maybe<Address>;
  definition: DataViewFieldDefinition;
  value?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  cityId?: InputMaybe<Scalars['ID']>;
  countryId?: InputMaybe<Scalars['ID']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  macroNeighbourhoodId?: InputMaybe<Scalars['ID']>;
  metroAreaId?: InputMaybe<Scalars['ID']>;
  neighbourhoodId?: InputMaybe<Scalars['ID']>;
  regionId?: InputMaybe<Scalars['ID']>;
  streetId?: InputMaybe<Scalars['ID']>;
  streetNumber?: InputMaybe<Scalars['String']>;
  subNeighbourhoodId?: InputMaybe<Scalars['ID']>;
  unitNumber?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type AddressSuggestion = {
  __typename?: 'AddressSuggestion';
  mainText: Scalars['String'];
  placeId: Scalars['ID'];
  secondaryText?: Maybe<Scalars['String']>;
};

export type AdminUser = ActivityObject & Entity & User & {
  __typename?: 'AdminUser';
  activityFeed?: Maybe<ActivityFeed>;
  address?: Maybe<Address>;
  /** Number of people where the user is assigned as agent */
  assignedUserCount?: Maybe<Scalars['Int']>;
  birthDate?: Maybe<Scalars['Date']>;
  brokerage?: Maybe<Brokerage>;
  chimeId?: Maybe<Scalars['Long']>;
  chimeLead?: Maybe<LeadInfo>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  following: Scalars['Boolean'];
  fubId?: Maybe<Scalars['Int']>;
  fubLead?: Maybe<LeadInfo>;
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastActiveAt?: Maybe<Scalars['DateTime']>;
  lastName?: Maybe<Scalars['String']>;
  listingInquiredCount?: Maybe<Scalars['Int']>;
  listingLikedCount?: Maybe<Scalars['Int']>;
  /** Number of opportunities where the user is assigned as agent */
  listingOpportunityCount?: Maybe<Scalars['Int']>;
  listingOpportunityStats?: Maybe<ListingOpportunityStats>;
  locale?: Maybe<Scalars['Locale']>;
  name: Scalars['String'];
  objectType: ActivityObjectType;
  personalFeed?: Maybe<ActivityFeed>;
  primaryPhone?: Maybe<Scalars['String']>;
  profileImage?: Maybe<Image>;
  receiveMarketingEmails?: Maybe<Scalars['Boolean']>;
  screenName?: Maybe<Scalars['String']>;
  status?: Maybe<UserStatus>;
  statusName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userType?: Maybe<UserType>;
  userTypeName?: Maybe<Scalars['String']>;
  workspaceMemberships?: Maybe<Array<WorkspaceMember>>;
};


export type AdminUserActivityFeedArgs = {
  containingActivityId?: InputMaybe<Scalars['ID']>;
  first?: Scalars['Int'];
  offset?: Scalars['Int'];
};


export type AdminUserPersonalFeedArgs = {
  containingActivityId?: InputMaybe<Scalars['ID']>;
  first?: Scalars['Int'];
  offset?: Scalars['Int'];
};

export type AgentUser = ActivityObject & Entity & User & {
  __typename?: 'AgentUser';
  activityFeed?: Maybe<ActivityFeed>;
  address?: Maybe<Address>;
  /** Number of people where the user is assigned as agent */
  assignedUserCount?: Maybe<Scalars['Int']>;
  birthDate?: Maybe<Scalars['Date']>;
  brokerage?: Maybe<Brokerage>;
  chimeId?: Maybe<Scalars['Long']>;
  chimeLead?: Maybe<LeadInfo>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  following: Scalars['Boolean'];
  fubId?: Maybe<Scalars['Int']>;
  fubLead?: Maybe<LeadInfo>;
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastActiveAt?: Maybe<Scalars['DateTime']>;
  lastName?: Maybe<Scalars['String']>;
  listingInquiredCount?: Maybe<Scalars['Int']>;
  listingLikedCount?: Maybe<Scalars['Int']>;
  /** Number of opportunities where the user is assigned as agent */
  listingOpportunityCount?: Maybe<Scalars['Int']>;
  listingOpportunityStats?: Maybe<ListingOpportunityStats>;
  locale?: Maybe<Scalars['Locale']>;
  name: Scalars['String'];
  objectType: ActivityObjectType;
  personalFeed?: Maybe<ActivityFeed>;
  primaryPhone?: Maybe<Scalars['String']>;
  profileImage?: Maybe<Image>;
  receiveMarketingEmails?: Maybe<Scalars['Boolean']>;
  screenName?: Maybe<Scalars['String']>;
  status?: Maybe<UserStatus>;
  statusName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userType?: Maybe<UserType>;
  userTypeName?: Maybe<Scalars['String']>;
  workspaceMemberships?: Maybe<Array<WorkspaceMember>>;
};


export type AgentUserActivityFeedArgs = {
  containingActivityId?: InputMaybe<Scalars['ID']>;
  first?: Scalars['Int'];
  offset?: Scalars['Int'];
};


export type AgentUserPersonalFeedArgs = {
  containingActivityId?: InputMaybe<Scalars['ID']>;
  first?: Scalars['Int'];
  offset?: Scalars['Int'];
};

export type AggregatedFacetFilter = ConnectionFilter & {
  __typename?: 'AggregatedFacetFilter';
  allowKeywordSearch?: Maybe<Scalars['Boolean']>;
  allowMultiSelect?: Maybe<Scalars['Boolean']>;
  currentValue?: Maybe<Array<FacetCurrentValue>>;
  facets?: Maybe<Array<BasicCountFacet | ResidentialListingTagFacet>>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<ConnectionFilterType>;
  typeName?: Maybe<Scalars['String']>;
};

export type AggregatedFacetKeywordSearchInput = {
  filterId: Scalars['String'];
  keyword: Scalars['String'];
};

export type Aggregation = {
  __typename?: 'Aggregation';
  count?: Maybe<Scalars['Long']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['BigDecimal']>;
};

export const enum AggregationInterval {
  Day = 'DAY',
  Month = 'MONTH',
  Year = 'YEAR'
};

export const enum AggregationValueMode {
  Average = 'AVERAGE',
  Median = 'MEDIAN'
};

export const enum ApartmentStyle {
  Loft = 'LOFT',
  PentHouse = 'PENT_HOUSE',
  Studio = 'STUDIO'
};

export const enum ApplianceType {
  Compactor = 'COMPACTOR',
  Cooktop = 'COOKTOP',
  Dishwasher = 'DISHWASHER',
  Dryer = 'DRYER',
  Freezer = 'FREEZER',
  Microwave = 'MICROWAVE',
  Oven = 'OVEN',
  Refrigerator = 'REFRIGERATOR',
  Stove = 'STOVE',
  Washer = 'WASHER',
  WineFridge = 'WINE_FRIDGE'
};

export const enum ApplicablePropertyType {
  BusinessOpportunity = 'BUSINESS_OPPORTUNITY',
  CommercialLease = 'COMMERCIAL_LEASE',
  CommercialSale = 'COMMERCIAL_SALE',
  Farm = 'FARM',
  Land = 'LAND',
  Mobile = 'MOBILE',
  Residential = 'RESIDENTIAL',
  ResidentialIncome = 'RESIDENTIAL_INCOME',
  ResidentialLease = 'RESIDENTIAL_LEASE'
};

export type Appointment = {
  __typename?: 'Appointment';
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  endTime: Scalars['DateTime'];
  id: Scalars['ID'];
  location?: Maybe<Scalars['String']>;
  outcome?: Maybe<Scalars['String']>;
  owner: PropertyListingOpportunity;
  participants?: Maybe<Array<AppointmentParticipant>>;
  startTime: Scalars['DateTime'];
  status: AppointmentStatus;
  statusName: Scalars['String'];
  title: Scalars['String'];
  type: AppointmentType;
  typeName: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type AppointmentOwner = {
  appointments?: Maybe<Array<Appointment>>;
  id: Scalars['ID'];
};


export type AppointmentOwnerAppointmentsArgs = {
  first?: Scalars['Int'];
};

export const enum AppointmentOwnerType {
  Opportunity = 'OPPORTUNITY'
};

export type AppointmentParticipant = {
  __typename?: 'AppointmentParticipant';
  appointment: Appointment;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  user: AdminUser | AgentUser | ConsumerUser | ContactUser;
};

export const enum AppointmentStatus {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  RequiresReschedule = 'REQUIRES_RESCHEDULE',
  Scheduled = 'SCHEDULED'
};

export const enum AppointmentType {
  Capture = 'CAPTURE',
  ListingAppointment = 'LISTING_APPOINTMENT'
};

export type ApproveSubmissionToMlsTaskDefinition = TaskDefinition & {
  __typename?: 'ApproveSubmissionToMlsTaskDefinition';
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Icon>;
  id: Scalars['ID'];
  logicalName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  projectDefinition: ProjectDefinition;
  scriptOnComplete?: Maybe<Scalars['String']>;
  scriptOnCreate?: Maybe<Scalars['String']>;
  scriptOnStart?: Maybe<Scalars['String']>;
  scriptValidateBeforeComplete?: Maybe<Scalars['String']>;
  scriptValidateBeforeCreate?: Maybe<Scalars['String']>;
  scriptValidateBeforeStart?: Maybe<Scalars['String']>;
  type: TaskType;
  typeName: Scalars['String'];
  uiOnComplete?: Maybe<Array<Scalars['String']>>;
  uiOnCreate?: Maybe<Array<Scalars['String']>>;
  uiOnStart?: Maybe<Array<Scalars['String']>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ApproveSubmissionToMlsTaskInstance = AbstractTaskInstance & ActivityObject & {
  __typename?: 'ApproveSubmissionToMlsTaskInstance';
  activityFeed?: Maybe<ActivityFeed>;
  assignedBy?: Maybe<AdminUser | AgentUser | ConsumerUser | ContactUser>;
  assignedUser?: Maybe<AdminUser | AgentUser | ConsumerUser | ContactUser>;
  assignedUserStatus: TaskAssigneeStatus;
  assignedUserStatusName: Scalars['String'];
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  definition: ApproveSubmissionToMlsTaskDefinition | AttachFileStorageTaskDefinition | BasicTaskDefinition | FileUploadTaskDefinition | FormAssignTaskDefinition | FormFillTaskDefinition | FormReviewTaskDefinition | SubmitListingToMlsTaskDefinition | UploadToFileStorageTaskDefinition;
  description?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  following: Scalars['Boolean'];
  id: Scalars['ID'];
  listing?: Maybe<PropertyListing>;
  name: Scalars['String'];
  objectType: ActivityObjectType;
  project: ProjectInstance;
  requestedChanges?: Maybe<Scalars['String']>;
  reviewer?: Maybe<AdminUser | AgentUser | ConsumerUser | ContactUser>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status: TaskInstanceStatus;
  statusName: Scalars['String'];
  type: TaskType;
  typeName: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type ApproveSubmissionToMlsTaskInstanceActivityFeedArgs = {
  containingActivityId?: InputMaybe<Scalars['ID']>;
  first?: Scalars['Int'];
  offset?: Scalars['Int'];
};

export type AssignOpportunityAgentsInput = {
  agentUserIds: Array<Scalars['ID']>;
  opportunityId: Scalars['ID'];
  sequence?: InputMaybe<Scalars['Int']>;
};

export type AssignPropertyListingIterationAgentsInput = {
  agentUserIds: Array<Scalars['ID']>;
  listingIterationId: Scalars['ID'];
  sequence?: InputMaybe<Scalars['Int']>;
};

export type AssignTaskInput = {
  /** Whether to also add the user to the project collaborators */
  addToProject: Scalars['Boolean'];
  /** Required if added to the project */
  projectRoleId?: InputMaybe<Scalars['ID']>;
  taskInstanceId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type AssociationFee = {
  __typename?: 'AssociationFee';
  feeInclusions?: Maybe<Array<FeeInclusion>>;
  feeInclusionsName?: Maybe<Array<Scalars['String']>>;
  monthlyFee?: Maybe<MonetaryAmount>;
};

export type AttachFileStorageTaskDefinition = TaskDefinition & {
  __typename?: 'AttachFileStorageTaskDefinition';
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Icon>;
  id: Scalars['ID'];
  logicalName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  projectDefinition: ProjectDefinition;
  scriptOnComplete?: Maybe<Scalars['String']>;
  scriptOnCreate?: Maybe<Scalars['String']>;
  scriptOnStart?: Maybe<Scalars['String']>;
  scriptValidateBeforeComplete?: Maybe<Scalars['String']>;
  scriptValidateBeforeCreate?: Maybe<Scalars['String']>;
  scriptValidateBeforeStart?: Maybe<Scalars['String']>;
  type: TaskType;
  typeName: Scalars['String'];
  uiOnComplete?: Maybe<Array<Scalars['String']>>;
  uiOnCreate?: Maybe<Array<Scalars['String']>>;
  uiOnStart?: Maybe<Array<Scalars['String']>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AuthenticatedUser = {
  __typename?: 'AuthenticatedUser';
  token: Scalars['String'];
  user: AdminUser | AgentUser | ConsumerUser | ContactUser;
};

export const enum BalconyType {
  Enclosed = 'ENCLOSED',
  Juliette = 'JULIETTE',
  None = 'NONE',
  Open = 'OPEN',
  Terrace = 'TERRACE'
};

export const enum BasementFeature {
  /** <https://ddwiki.reso.org/display/DDW17/Basement+Lookups> */
  Apartment = 'APARTMENT',
  BathStubbed = 'BATH_STUBBED',
  Block = 'BLOCK',
  Concrete = 'CONCRETE',
  CrawlSpace = 'CRAWL_SPACE',
  DirtFloor = 'DIRT_FLOOR',
  Entry = 'ENTRY',
  Exterior = 'EXTERIOR',
  FrenchDrain = 'FRENCH_DRAIN',
  Full = 'FULL',
  InteriorEntry = 'INTERIOR_ENTRY',
  None = 'NONE',
  Partial = 'PARTIAL',
  StorageSpace = 'STORAGE_SPACE',
  SumpPump = 'SUMP_PUMP',
  WalkOutAccess = 'WALK_OUT_ACCESS',
  WalkUpAccess = 'WALK_UP_ACCESS'
};

export const enum BasementFinish {
  Finished = 'FINISHED',
  NotApplicable = 'NOT_APPLICABLE',
  PartiallyFinished = 'PARTIALLY_FINISHED',
  Unfinished = 'UNFINISHED'
};

export const enum BasementType {
  Finished = 'FINISHED',
  NotApplicable = 'NOT_APPLICABLE',
  PartiallyFinished = 'PARTIALLY_FINISHED',
  Unfinished = 'UNFINISHED'
};

export type BasicCountFacet = Facet & {
  __typename?: 'BasicCountFacet';
  count: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  selected: Scalars['Boolean'];
};

export type BasicTaskDefinition = TaskDefinition & {
  __typename?: 'BasicTaskDefinition';
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Icon>;
  id: Scalars['ID'];
  logicalName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  projectDefinition: ProjectDefinition;
  scriptOnComplete?: Maybe<Scalars['String']>;
  scriptOnCreate?: Maybe<Scalars['String']>;
  scriptOnStart?: Maybe<Scalars['String']>;
  scriptValidateBeforeComplete?: Maybe<Scalars['String']>;
  scriptValidateBeforeCreate?: Maybe<Scalars['String']>;
  scriptValidateBeforeStart?: Maybe<Scalars['String']>;
  type: TaskType;
  typeName: Scalars['String'];
  uiOnComplete?: Maybe<Array<Scalars['String']>>;
  uiOnCreate?: Maybe<Array<Scalars['String']>>;
  uiOnStart?: Maybe<Array<Scalars['String']>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BasicTaskInstance = AbstractTaskInstance & ActivityObject & {
  __typename?: 'BasicTaskInstance';
  activityFeed?: Maybe<ActivityFeed>;
  assignedBy?: Maybe<AdminUser | AgentUser | ConsumerUser | ContactUser>;
  assignedUser?: Maybe<AdminUser | AgentUser | ConsumerUser | ContactUser>;
  assignedUserStatus: TaskAssigneeStatus;
  assignedUserStatusName: Scalars['String'];
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  definition: ApproveSubmissionToMlsTaskDefinition | AttachFileStorageTaskDefinition | BasicTaskDefinition | FileUploadTaskDefinition | FormAssignTaskDefinition | FormFillTaskDefinition | FormReviewTaskDefinition | SubmitListingToMlsTaskDefinition | UploadToFileStorageTaskDefinition;
  description?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  following: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
  objectType: ActivityObjectType;
  project: ProjectInstance;
  requestedChanges?: Maybe<Scalars['String']>;
  reviewer?: Maybe<AdminUser | AgentUser | ConsumerUser | ContactUser>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status: TaskInstanceStatus;
  statusName: Scalars['String'];
  type: TaskType;
  typeName: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type BasicTaskInstanceActivityFeedArgs = {
  containingActivityId?: InputMaybe<Scalars['ID']>;
  first?: Scalars['Int'];
  offset?: Scalars['Int'];
};

/** Residential Property Details Types */
export type BathroomCount = {
  __typename?: 'BathroomCount';
  ensuiteCount?: Maybe<Scalars['Int']>;
  fullCount?: Maybe<Scalars['Int']>;
  halfCount?: Maybe<Scalars['Int']>;
  total: Scalars['Float'];
};

export type BooleanDataField = DataField & {
  __typename?: 'BooleanDataField';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  key: Scalars['String'];
  logicalName?: Maybe<DataFieldLogicalName>;
  mlsField?: Maybe<MlsField>;
  name: Scalars['String'];
  processed?: Maybe<Scalars['Boolean']>;
  type: DataFieldType;
  typeName: Scalars['String'];
};

export type BooleanDataFormInputInstance = DataFormInputInstance & {
  __typename?: 'BooleanDataFormInputInstance';
  currentValue?: Maybe<Scalars['String']>;
  definition: DataFormInputDefinition;
  editable: Scalars['Boolean'];
  id: Scalars['ID'];
};

export type BooleanDataViewFieldInstance = DataViewFieldInstance & {
  __typename?: 'BooleanDataViewFieldInstance';
  definition: DataViewFieldDefinition;
  value?: Maybe<Scalars['String']>;
};

export type BooleanFilter = ConnectionFilter & {
  __typename?: 'BooleanFilter';
  currentValue?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isToggle?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<ConnectionFilterType>;
  typeName?: Maybe<Scalars['String']>;
};

export type BoxRoomConfiguration = SpaceConfiguration & {
  __typename?: 'BoxRoomConfiguration';
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  floorMaterial: SpaceMaterial;
  id: Scalars['ID'];
  items: Array<SpaceConfigurationItem>;
  itemsModel?: Maybe<Model3D>;
  model?: Maybe<Model3D>;
  name: Scalars['String'];
  roomHeight: Scalars['Float'];
  roomLength: Scalars['Float'];
  roomWidth: Scalars['Float'];
  user?: Maybe<AdminUser | AgentUser | ConsumerUser | ContactUser>;
  wallsMaterial: SpaceMaterial;
};

export type Brokerage = {
  __typename?: 'Brokerage';
  address?: Maybe<Address>;
  createdAt: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  status: BrokerageStatus;
  statusName: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type BrokerageConnection = {
  __typename?: 'BrokerageConnection';
  edges?: Maybe<Array<Maybe<BrokerageConnectionEdge>>>;
  facetGroups?: Maybe<Array<Maybe<FacetGroup>>>;
  filters?: Maybe<Array<Maybe<AggregatedFacetFilter | BooleanFilter | DateRangeFilter | FloatRangeFilter | IntegerRangeFilter | SimpleFacetFilter | TagCollectionFilter>>>;
  histogramGroups?: Maybe<Array<Maybe<HistogramGroup>>>;
  metricGroups?: Maybe<Array<Maybe<MetricGroup>>>;
  pageInfo?: Maybe<PageInfo>;
  savedSearches?: Maybe<Array<Maybe<SavedSearch>>>;
  sortOptions?: Maybe<Array<Maybe<SortOption>>>;
};


export type BrokerageConnectionFacetGroupsArgs = {
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type BrokerageConnectionFiltersArgs = {
  aggregatedFacetKeywordSearch?: InputMaybe<AggregatedFacetKeywordSearchInput>;
  fetchAggregatedFacetsFilterIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  includeTagFilters?: InputMaybe<Scalars['Boolean']>;
  selected?: InputMaybe<Scalars['Boolean']>;
};


export type BrokerageConnectionHistogramGroupsArgs = {
  histogramGroupInput?: InputMaybe<HistogramGroupInput>;
};


export type BrokerageConnectionMetricGroupsArgs = {
  metricInput?: InputMaybe<Array<InputMaybe<MetricGroupInput>>>;
};

export type BrokerageConnectionEdge = {
  __typename?: 'BrokerageConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Brokerage>;
};

export type BrokerageConnectionInput = {
  first: Scalars['Int'];
  keyword?: InputMaybe<Scalars['String']>;
  offset: Scalars['Int'];
  sortKey?: InputMaybe<BrokerageSortKey>;
  sortOptionId?: InputMaybe<Scalars['ID']>;
  sortOrder?: InputMaybe<SortOrder>;
  statuses?: InputMaybe<Array<BrokerageStatus>>;
  tagIds?: InputMaybe<Array<Scalars['ID']>>;
};

export const enum BrokerageSortKey {
  CreatedAt = 'CREATED_AT'
};

export const enum BrokerageStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  Suspended = 'SUSPENDED'
};

export type Building = {
  __typename?: 'Building';
  address: Address;
  amenities: Array<BuildingAmenity>;
  amenitiesNames: Array<Scalars['String']>;
  buildingName: Scalars['String'];
  condoPetPolicy?: Maybe<CondoPetPolicy>;
  construction?: Maybe<BuildingConstruction>;
  corporationNumber: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  internalNotes?: Maybe<Scalars['String']>;
  listings?: Maybe<Array<ResidentialPropertyForSaleListing>>;
  maintenanceFeeIncludes?: Maybe<Array<MaintenanceFeeIncludes>>;
  maintenanceFeeIncludesName?: Maybe<Array<Scalars['String']>>;
  media?: Maybe<Array<File | Image | Model3D>>;
  propertyManagementName: Scalars['String'];
  shortTermRentalPolicy?: Maybe<ShortTermRentalPolicy>;
  shortTermRentalPolicyName?: Maybe<Scalars['String']>;
  status: BuildingStatus;
  statusName: Scalars['String'];
  storiesTotal: Scalars['Int'];
  unitsTotal: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  zoning?: Maybe<Array<Zoning>>;
  zoningName?: Maybe<Array<Scalars['String']>>;
};


export type BuildingMediaArgs = {
  first?: InputMaybe<Scalars['Int']>;
  statuses?: InputMaybe<Array<MediaStatus>>;
};

export const enum BuildingAmenity {
  AirportRunway = 'AIRPORT_RUNWAY',
  Barbecue = 'BARBECUE',
  BasketballCourt = 'BASKETBALL_COURT',
  BeachAccess = 'BEACH_ACCESS',
  BeachRights = 'BEACH_RIGHTS',
  BicycleStorage = 'BICYCLE_STORAGE',
  BilliardRoom = 'BILLIARD_ROOM',
  Boating = 'BOATING',
  BoatDock = 'BOAT_DOCK',
  BoatSlip = 'BOAT_SLIP',
  BusinessCenter = 'BUSINESS_CENTER',
  Cabana = 'CABANA',
  CableTv = 'CABLE_TV',
  CarWash = 'CAR_WASH',
  CarWashArea = 'CAR_WASH_AREA',
  Clubhouse = 'CLUBHOUSE',
  CoinLaundry = 'COIN_LAUNDRY',
  CommunityRoom = 'COMMUNITY_ROOM',
  Concierge = 'CONCIERGE',
  Courts = 'COURTS',
  Daycare = 'DAYCARE',
  DayCare = 'DAY_CARE',
  DiningRoom = 'DINING_ROOM',
  DogPark = 'DOG_PARK',
  DryCleaning = 'DRY_CLEANING',
  DryDock = 'DRY_DOCK',
  Electricity = 'ELECTRICITY',
  Elevator = 'ELEVATOR',
  Elevators = 'ELEVATORS',
  ExerciseCourse = 'EXERCISE_COURSE',
  FitnessCenter = 'FITNESS_CENTER',
  GameCourtExterior = 'GAME_COURT_EXTERIOR',
  GameCourtInterior = 'GAME_COURT_INTERIOR',
  GameRoom = 'GAME_ROOM',
  Garden = 'GARDEN',
  Gas = 'GAS',
  Gated = 'GATED',
  GolfCourse = 'GOLF_COURSE',
  GuestSuite = 'GUEST_SUITE',
  HotWater = 'HOT_WATER',
  IndoorPool = 'INDOOR_POOL',
  Insurance = 'INSURANCE',
  JoggingPath = 'JOGGING_PATH',
  Landscaping = 'LANDSCAPING',
  Laundry = 'LAUNDRY',
  LaundryFacilities = 'LAUNDRY_FACILITIES',
  MaidService = 'MAID_SERVICE',
  Maintenance = 'MAINTENANCE',
  MaintenanceGrounds = 'MAINTENANCE_GROUNDS',
  MaintenanceStructure = 'MAINTENANCE_STRUCTURE',
  Management = 'MANAGEMENT',
  Marina = 'MARINA',
  MeetingRoom = 'MEETING_ROOM',
  None = 'NONE',
  OnSiteMaintenance = 'ON_SITE_MAINTENANCE',
  Other = 'OTHER',
  Park = 'PARK',
  Parking = 'PARKING',
  PartyRoom = 'PARTY_ROOM',
  PicnicArea = 'PICNIC_AREA',
  Playground = 'PLAYGROUND',
  PondSeasonal = 'POND_SEASONAL',
  PondYearRound = 'POND_YEAR_ROUND',
  Pool = 'POOL',
  PoweredBoatsAllowed = 'POWERED_BOATS_ALLOWED',
  Racquetball = 'RACQUETBALL',
  RecreationFacilities = 'RECREATION_FACILITIES',
  RecreationRoom = 'RECREATION_ROOM',
  RooftopDeck = 'ROOFTOP_DECK',
  RooftopTerrace = 'ROOFTOP_TERRACE',
  RvBoatStorage = 'RV_BOAT_STORAGE',
  RvParking = 'RV_PARKING',
  Sauna = 'SAUNA',
  Security = 'SECURITY',
  SecuritySystems = 'SECURITY_SYSTEMS',
  ServiceElevator = 'SERVICE_ELEVATOR',
  ShuffleboardCourt = 'SHUFFLEBOARD_COURT',
  SkiAccessible = 'SKI_ACCESSIBLE',
  SnowRemoval = 'SNOW_REMOVAL',
  SpaHotTub = 'SPA_HOT_TUB',
  SportCourt = 'SPORT_COURT',
  Stable = 'STABLE',
  Storage = 'STORAGE',
  StreamSeasonal = 'STREAM_SEASONAL',
  StreamYearRound = 'STREAM_YEAR_ROUND',
  SwimmingPool = 'SWIMMING_POOL',
  Taxes = 'TAXES',
  TennisCourt = 'TENNIS_COURT',
  Theatre = 'THEATRE',
  Trail = 'TRAIL',
  Trash = 'TRASH',
  Water = 'WATER',
  WorkshopArea = 'WORKSHOP_AREA'
};

export type BuildingConnection = {
  __typename?: 'BuildingConnection';
  edges?: Maybe<Array<Maybe<BuildingConnectionEdge>>>;
  facetGroups?: Maybe<Array<Maybe<FacetGroup>>>;
  filters?: Maybe<Array<Maybe<AggregatedFacetFilter | BooleanFilter | DateRangeFilter | FloatRangeFilter | IntegerRangeFilter | SimpleFacetFilter | TagCollectionFilter>>>;
  histogramGroups?: Maybe<Array<Maybe<HistogramGroup>>>;
  metricGroups?: Maybe<Array<Maybe<MetricGroup>>>;
  pageInfo?: Maybe<PageInfo>;
  savedSearches?: Maybe<Array<Maybe<SavedSearch>>>;
  sortOptions?: Maybe<Array<Maybe<SortOption>>>;
};


export type BuildingConnectionFacetGroupsArgs = {
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type BuildingConnectionFiltersArgs = {
  aggregatedFacetKeywordSearch?: InputMaybe<AggregatedFacetKeywordSearchInput>;
  fetchAggregatedFacetsFilterIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  includeTagFilters?: InputMaybe<Scalars['Boolean']>;
  selected?: InputMaybe<Scalars['Boolean']>;
};


export type BuildingConnectionHistogramGroupsArgs = {
  histogramGroupInput?: InputMaybe<HistogramGroupInput>;
};


export type BuildingConnectionMetricGroupsArgs = {
  metricInput?: InputMaybe<Array<InputMaybe<MetricGroupInput>>>;
};

export type BuildingConnectionEdge = {
  __typename?: 'BuildingConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Building>;
};

export type BuildingConnectionInput = {
  ageGTE?: InputMaybe<Scalars['Int']>;
  ageLTE?: InputMaybe<Scalars['Int']>;
  amenities?: InputMaybe<Array<BuildingAmenity>>;
  filters?: InputMaybe<Array<FilterInput>>;
  first: Scalars['Int'];
  keyword?: InputMaybe<Scalars['String']>;
  offset: Scalars['Int'];
  placeIds?: InputMaybe<Array<Scalars['ID']>>;
  placeSlugs?: InputMaybe<Array<Scalars['String']>>;
  savedSearchId?: InputMaybe<Scalars['ID']>;
  sortKey?: InputMaybe<BuildingSortKey>;
  sortOptionId?: InputMaybe<Scalars['ID']>;
  sortOrder?: InputMaybe<SortOrder>;
  status?: InputMaybe<BuildingStatus>;
  tagIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type BuildingConstruction = {
  __typename?: 'BuildingConstruction';
  buildYearMax?: Maybe<Scalars['Int']>;
  buildYearMin?: Maybe<Scalars['Int']>;
  builderName?: Maybe<Scalars['String']>;
  buildingAge?: Maybe<Scalars['String']>;
};

export type BuildingConstructionInput = {
  buildYearMax?: InputMaybe<Scalars['Int']>;
  buildYearMin?: InputMaybe<Scalars['Int']>;
  builderName?: InputMaybe<Scalars['String']>;
};

export const enum BuildingSortKey {
  Address = 'ADDRESS',
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT'
};

export const enum BuildingStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED'
};

export const enum ClientType {
  Desktop = 'DESKTOP',
  Mobile = 'MOBILE'
};

export const enum CommissionType {
  Flat = 'FLAT',
  Percentage = 'PERCENTAGE'
};

export type Company = {
  createdAt: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  locations?: Maybe<Array<Maybe<CompanyLocation>>>;
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  type: CompanyType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  website?: Maybe<Scalars['String']>;
};

export type CompanyConnection = {
  __typename?: 'CompanyConnection';
  edges?: Maybe<Array<Maybe<CompanyConnectionEdge>>>;
  facetGroups?: Maybe<Array<Maybe<FacetGroup>>>;
  filters?: Maybe<Array<Maybe<AggregatedFacetFilter | BooleanFilter | DateRangeFilter | FloatRangeFilter | IntegerRangeFilter | SimpleFacetFilter | TagCollectionFilter>>>;
  histogramGroups?: Maybe<Array<Maybe<HistogramGroup>>>;
  metricGroups?: Maybe<Array<Maybe<MetricGroup>>>;
  pageInfo?: Maybe<PageInfo>;
  savedSearches?: Maybe<Array<Maybe<SavedSearch>>>;
  sortOptions?: Maybe<Array<Maybe<SortOption>>>;
};


export type CompanyConnectionFacetGroupsArgs = {
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type CompanyConnectionFiltersArgs = {
  aggregatedFacetKeywordSearch?: InputMaybe<AggregatedFacetKeywordSearchInput>;
  fetchAggregatedFacetsFilterIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  includeTagFilters?: InputMaybe<Scalars['Boolean']>;
  selected?: InputMaybe<Scalars['Boolean']>;
};


export type CompanyConnectionHistogramGroupsArgs = {
  histogramGroupInput?: InputMaybe<HistogramGroupInput>;
};


export type CompanyConnectionMetricGroupsArgs = {
  metricInput?: InputMaybe<Array<InputMaybe<MetricGroupInput>>>;
};

export type CompanyConnectionEdge = {
  __typename?: 'CompanyConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<FurnitureSupplierCompany | FurnitureVendorCompany>;
};

export type CompanyConnectionInput = {
  filters?: InputMaybe<Array<FilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  keyword?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  savedSearchId?: InputMaybe<Scalars['ID']>;
  sortKey?: InputMaybe<CompanySortKey>;
  sortOptionId?: InputMaybe<Scalars['ID']>;
  sortOrder?: InputMaybe<SortOrder>;
  tagIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type CompanyLocation = {
  __typename?: 'CompanyLocation';
  address: Address;
  createdAt: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  type: CompanyLocationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  website?: Maybe<Scalars['String']>;
};

export const enum CompanyLocationType {
  Corporate = 'CORPORATE',
  Showroom = 'SHOWROOM',
  Warehouse = 'WAREHOUSE'
};

export const enum CompanySortKey {
  CreatedAt = 'CREATED_AT',
  Name = 'NAME',
  UpdatedAt = 'UPDATED_AT'
};

export const enum CompanyType {
  FurnitureSupplier = 'FURNITURE_SUPPLIER',
  FurnitureVendor = 'FURNITURE_VENDOR'
};

export type CompletePropertyListingOpportunityMilestoneInput = {
  milestone: PropertyListingOpportunityMilestone;
  notes?: InputMaybe<Scalars['String']>;
  opportunityId: Scalars['ID'];
};

export type CompleteTaskInstanceInput = {
  id: Scalars['ID'];
  outcomeId?: InputMaybe<Scalars['ID']>;
  payload?: InputMaybe<Scalars['JSON']>;
};

/**
 * input AssignUserRoleInput {
 *     userId: ID! @hashed
 *     role: UserRole!
 * }
 */
export type CompleteUserSignUpInput = {
  addressId?: InputMaybe<Scalars['ID']>;
  birthDate?: InputMaybe<Scalars['Date']>;
  firstName: Scalars['String'];
  /** Token from URL in sign up email */
  isBuyer: Scalars['Boolean'];
  isSeller: Scalars['Boolean'];
  lastName: Scalars['String'];
  locale?: InputMaybe<Scalars['Locale']>;
  password: Scalars['String'];
  primaryPhone?: InputMaybe<Scalars['String']>;
  receiveMarketingEmails: Scalars['Boolean'];
  token: Scalars['String'];
};

/** Ownership Details Types */
export type CondoAdministration = {
  __typename?: 'CondoAdministration';
  condoCorporationNumber?: Maybe<Scalars['String']>;
  condoRegistryOffice?: Maybe<Scalars['String']>;
  propertyManagementCompany?: Maybe<Scalars['String']>;
};

export type CondoMaintenanceFee = {
  __typename?: 'CondoMaintenanceFee';
  feeInclusions?: Maybe<Array<FeeInclusion>>;
  feeInclusionsName?: Maybe<Array<Scalars['String']>>;
  feeInclusionsRaw?: Maybe<Scalars['String']>;
  monthlyFee?: Maybe<MonetaryAmount>;
};

export type CondoPetPolicy = {
  __typename?: 'CondoPetPolicy';
  allowedPetTypes?: Maybe<Array<PetType>>;
  allowedPetTypesName?: Maybe<Array<Scalars['String']>>;
  numberLimit?: Maybe<Scalars['Int']>;
};

export type CondoPetPolicyInput = {
  allowedPetTypes?: InputMaybe<Array<PetType>>;
  numberLimit?: InputMaybe<Scalars['Int']>;
};

export type ConnectionFilter = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<ConnectionFilterType>;
  typeName?: Maybe<Scalars['String']>;
};

export const enum ConnectionFilterType {
  /**
   * Aggregated facet filter uses Elasticsearch to get available facets with counts. These filters are expensive in terms of `facets` entry value resolving, thus ids of the filters requiring `facet` resolving shall be provided via filters argument `fetchAggregatedFacetsFilterIds`
   * Valid format  is `{"id": <filter_id>, "value": "[<csv>]"}` e.g : filter for openHouseDates will be `{ "id": "openHouseDates", "value": "[2023-06-19,2023-06-20]" }`
   */
  AggregatedFacetFilter = 'AGGREGATED_FACET_FILTER',
  /** Can be `toggle`(can only be true or false), or non-toggle(true/false/null). Valid format is `{"id": <filter_id>, "value": "<value>"}` e.g : filter for hasVirtualTour will be `{ "id": "hasVirtualTour", "value": "true" }` */
  BooleanFilter = 'BOOLEAN_FILTER',
  /** Valid format  is `{"id": <filter_id>, "value": "[GTE=<gteValue>,LTE=<lteValue>]"}` e.g : filter for totalParking will be `{ "id": "dateListed", "value": "[GTE=2023-06-01,LTE=2023-06-15]" }`; GTE and LTE are case insensitive and filter can also have only GTE or LTE */
  DateRangeFilter = 'DATE_RANGE_FILTER',
  /** Valid format  is `{"id": <filter_id>, "value": "[GTE=<gteValue>,LTE=<lteValue>]"}` e.g : filter for totalBathrooms will be `{ "id": "totalBathrooms", "value": "[GTE=1,LTE=10.5]" }`; GTE and LTE are case insensitive and filter can also have only GTE or LTE */
  FloatRangeFilter = 'FLOAT_RANGE_FILTER',
  /** Valid format  is `{"id": <filter_id>, "value": "[GTE=<gteValue>,LTE=<lteValue>]"}` e.g : filter for totalParking will be `{ "id": "totalParking", "value": "[GTE=1,LTE=10]" }`; GTE and LTE are case insensitive and filter can also have only GTE or LTE */
  IntegerRangeFilter = 'INTEGER_RANGE_FILTER',
  /** Simple facet filter exposing static available facet values(mostly enum values). Valid format  is `{"id": <filter_id>, "value": "[<csv>]"}` e.g : filter for parkingTypes will be `{ "id": "parkingTypes", "value": "[GARAGE,INDOOR]" }` */
  SimpleFacetFilter = 'SIMPLE_FACET_FILTER',
  /**
   * Tag collection filters are similar AGGREGATED_FACET_FILTER, except around tags where tag collections aggregations are the facet groups, and tag aggregations are the facets
   * Will only be generated with if filter argument `includeTagFilters` is true.
   */
  TagCollectionFilter = 'TAG_COLLECTION_FILTER'
};

export type ConsumerUser = ActivityObject & Entity & User & {
  __typename?: 'ConsumerUser';
  activityFeed?: Maybe<ActivityFeed>;
  address?: Maybe<Address>;
  assignedAgents?: Maybe<Array<UserAssignedAgent>>;
  birthDate?: Maybe<Scalars['Date']>;
  chimeId?: Maybe<Scalars['Long']>;
  chimeLead?: Maybe<LeadInfo>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  emailVerified: Scalars['Boolean'];
  firstName?: Maybe<Scalars['String']>;
  following: Scalars['Boolean'];
  fubId?: Maybe<Scalars['Int']>;
  fubLead?: Maybe<LeadInfo>;
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isBuyer?: Maybe<Scalars['Boolean']>;
  isSeller?: Maybe<Scalars['Boolean']>;
  lastActiveAt?: Maybe<Scalars['DateTime']>;
  lastName?: Maybe<Scalars['String']>;
  leadSource?: Maybe<LeadSource>;
  leadSourceName?: Maybe<Scalars['String']>;
  listingInquiredCount?: Maybe<Scalars['Int']>;
  listingLikedCount?: Maybe<Scalars['Int']>;
  listingOpportunityCount?: Maybe<Scalars['Int']>;
  locale?: Maybe<Scalars['Locale']>;
  name: Scalars['String'];
  objectType: ActivityObjectType;
  /** Opportunities where this user is one of the customers */
  opportunities?: Maybe<Array<PropertyListingOpportunity>>;
  personalFeed?: Maybe<ActivityFeed>;
  primaryPhone?: Maybe<Scalars['String']>;
  profileImage?: Maybe<Image>;
  receiveMarketingEmails?: Maybe<Scalars['Boolean']>;
  screenName?: Maybe<Scalars['String']>;
  status?: Maybe<UserStatus>;
  statusName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userType?: Maybe<UserType>;
  userTypeName?: Maybe<Scalars['String']>;
  workspaceMemberships?: Maybe<Array<WorkspaceMember>>;
};


export type ConsumerUserActivityFeedArgs = {
  containingActivityId?: InputMaybe<Scalars['ID']>;
  first?: Scalars['Int'];
  offset?: Scalars['Int'];
};


export type ConsumerUserPersonalFeedArgs = {
  containingActivityId?: InputMaybe<Scalars['ID']>;
  first?: Scalars['Int'];
  offset?: Scalars['Int'];
};

export type Contact = {
  __typename?: 'Contact';
  address?: Maybe<Address>;
  birthDay?: Maybe<Scalars['Date']>;
  createdAt: Scalars['DateTime'];
  emails?: Maybe<Array<ContactEmail>>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<Array<ContactPhoneNumber>>;
  updatedAt: Scalars['DateTime'];
  user?: Maybe<AdminUser | AgentUser | ConsumerUser | ContactUser>;
  workspace: Workspace;
};

export type ContactEmail = {
  __typename?: 'ContactEmail';
  contact: Contact;
  email: Scalars['String'];
  id: Scalars['ID'];
  type?: Maybe<ContactEmailType>;
  typeName?: Maybe<Scalars['String']>;
};

export const enum ContactEmailType {
  Home = 'HOME',
  Work = 'WORK'
};

export type ContactPhoneNumber = {
  __typename?: 'ContactPhoneNumber';
  contact: Contact;
  id: Scalars['ID'];
  phoneNumber: Scalars['String'];
  type?: Maybe<ContactPhoneNumberType>;
  typeName?: Maybe<Scalars['String']>;
};

export const enum ContactPhoneNumberType {
  Mobile = 'MOBILE',
  Work = 'WORK'
};

export type ContactUser = ActivityObject & Entity & User & {
  __typename?: 'ContactUser';
  activityFeed?: Maybe<ActivityFeed>;
  address?: Maybe<Address>;
  assignedAgents?: Maybe<Array<UserAssignedAgent>>;
  birthDate?: Maybe<Scalars['Date']>;
  chimeId?: Maybe<Scalars['Long']>;
  chimeLead?: Maybe<LeadInfo>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  emailVerified: Scalars['Boolean'];
  firstName?: Maybe<Scalars['String']>;
  following: Scalars['Boolean'];
  fubId?: Maybe<Scalars['Int']>;
  fubLead?: Maybe<LeadInfo>;
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastActiveAt?: Maybe<Scalars['DateTime']>;
  lastName?: Maybe<Scalars['String']>;
  leadSource?: Maybe<LeadSource>;
  leadSourceName?: Maybe<Scalars['String']>;
  listingInquiredCount?: Maybe<Scalars['Int']>;
  listingLikedCount?: Maybe<Scalars['Int']>;
  listingOpportunityCount?: Maybe<Scalars['Int']>;
  locale?: Maybe<Scalars['Locale']>;
  name: Scalars['String'];
  objectType: ActivityObjectType;
  /** Opportunities where this user is one of the customers */
  opportunities?: Maybe<Array<PropertyListingOpportunity>>;
  personalFeed?: Maybe<ActivityFeed>;
  primaryPhone?: Maybe<Scalars['String']>;
  profileImage?: Maybe<Image>;
  receiveMarketingEmails?: Maybe<Scalars['Boolean']>;
  screenName?: Maybe<Scalars['String']>;
  status?: Maybe<UserStatus>;
  statusName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userType?: Maybe<UserType>;
  userTypeName?: Maybe<Scalars['String']>;
  workspaceMemberships?: Maybe<Array<WorkspaceMember>>;
};


export type ContactUserActivityFeedArgs = {
  containingActivityId?: InputMaybe<Scalars['ID']>;
  first?: Scalars['Int'];
  offset?: Scalars['Int'];
};


export type ContactUserPersonalFeedArgs = {
  containingActivityId?: InputMaybe<Scalars['ID']>;
  first?: Scalars['Int'];
  offset?: Scalars['Int'];
};

export const enum CoolingType {
  /** <https://ddwiki.reso.org/display/DDW17/Cooling+Lookups> */
  AtticFan = 'ATTIC_FAN',
  CeilingFan = 'CEILING_FAN',
  CentralAir = 'CENTRAL_AIR',
  CentralAirConditioning = 'CENTRAL_AIR_CONDITIONING',
  Dual = 'DUAL',
  Ductless = 'DUCTLESS',
  Electric = 'ELECTRIC',
  EnergyStarQualifiedEquipment = 'ENERGY_STAR_QUALIFIED_EQUIPMENT',
  EvaporativeCooling = 'EVAPORATIVE_COOLING',
  ExhaustFan = 'EXHAUST_FAN',
  Gas = 'GAS',
  Geothermal = 'GEOTHERMAL',
  HeatPump = 'HEAT_PUMP',
  HumidityControl = 'HUMIDITY_CONTROL',
  MultiUnits = 'MULTI_UNITS',
  None = 'NONE',
  Other = 'OTHER',
  WallUnit = 'WALL_UNIT',
  WindowUnit = 'WINDOW_UNIT',
  Zoned = 'ZONED'
};

export type Coordinate = {
  __typename?: 'Coordinate';
  lat: Scalars['Float'];
  lon: Scalars['Float'];
};

export type CoordinateInput = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type CreateActivityCommentInput = {
  activityId: Scalars['ID'];
  mediaIds?: InputMaybe<Array<Scalars['ID']>>;
  message: Scalars['String'];
  visibility?: InputMaybe<ActivityVisibility>;
};

export type CreateAddressDataFieldInput = {
  common: CreateDataFieldCommonInput;
};

export type CreateAppointmentInput = {
  description?: InputMaybe<Scalars['String']>;
  endTime: Scalars['DateTime'];
  location?: InputMaybe<Scalars['String']>;
  ownerId: Scalars['ID'];
  ownerType: AppointmentOwnerType;
  participantUserIds?: InputMaybe<Array<Scalars['ID']>>;
  startTime: Scalars['DateTime'];
  title: Scalars['String'];
  type: AppointmentType;
};

export type CreateBooleanDataFieldInput = {
  common: CreateDataFieldCommonInput;
};

export type CreateBoxRoomConfigurationInput = {
  floorMaterialId: Scalars['ID'];
  items: Array<SpaceConfigurationItemInput>;
  name: Scalars['String'];
  roomHeight: Scalars['Float'];
  roomLength: Scalars['Float'];
  roomWidth: Scalars['Float'];
  wallsMaterialId: Scalars['ID'];
};

export type CreateBrokerageInput = {
  addressId?: InputMaybe<Scalars['ID']>;
  email?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type CreateBuildingInput = {
  address: AddressInput;
  amenities: Array<BuildingAmenity>;
  buildingName: Scalars['String'];
  condoPetPolicy?: InputMaybe<CondoPetPolicyInput>;
  construction?: InputMaybe<BuildingConstructionInput>;
  corporationNumber: Scalars['String'];
  internalNotes?: InputMaybe<Scalars['String']>;
  listings?: InputMaybe<Array<Scalars['ID']>>;
  maintenanceFeeIncludes?: InputMaybe<Array<MaintenanceFeeIncludes>>;
  propertyManagementName: Scalars['String'];
  shortTermRentalPolicy?: InputMaybe<ShortTermRentalPolicy>;
  status?: InputMaybe<BuildingStatus>;
  storiesTotal: Scalars['Int'];
  unitsTotal: Scalars['Int'];
  zoning?: InputMaybe<Array<Zoning>>;
};

export type CreateCompanyInput = {
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  type: CompanyType;
  website?: InputMaybe<Scalars['String']>;
};

export type CreateCompanyLocationInput = {
  addressId: Scalars['ID'];
  companyId: Scalars['ID'];
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<CompanyLocationType>;
  website?: InputMaybe<Scalars['String']>;
};

export type CreateContactUserInput = {
  /** Address id provided by the address picker */
  addressId?: InputMaybe<Scalars['ID']>;
  birthDate?: InputMaybe<Scalars['Date']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  leadSource?: InputMaybe<LeadSource>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type CreateDataFieldCommonInput = {
  dataConfigId: Scalars['ID'];
  description?: InputMaybe<Scalars['String']>;
  logicalName?: InputMaybe<DataFieldLogicalName>;
  mlsFieldId?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};

export type CreateDataFormInputInput = {
  accessLevel?: InputMaybe<DataFormInputAccessLevel>;
  dataFieldId?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Icon>;
  name?: InputMaybe<Scalars['String']>;
  /** Used for ordering. The reference to a peer after which it should be added. If it should be added as the first entry, set value as NULL */
  previousInputId?: InputMaybe<Scalars['ID']>;
  required?: InputMaybe<Scalars['Boolean']>;
  styleDesktop?: InputMaybe<DataFormInputStyle>;
  styleMobile?: InputMaybe<DataFormInputStyle>;
  subsectionId?: InputMaybe<Scalars['ID']>;
  widthPercentDesktop?: InputMaybe<Scalars['Int']>;
  widthPercentMobile?: InputMaybe<Scalars['Int']>;
};

export type CreateDataFormSectionInput = {
  description?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Icon>;
  name?: InputMaybe<Scalars['String']>;
  /** Used for ordering. The reference to a peer after which it should be added. If it should be added as the first entry, set value as NULL */
  previousSectionId?: InputMaybe<Scalars['ID']>;
  stepId?: InputMaybe<Scalars['ID']>;
  supportsHistoricalData?: InputMaybe<Scalars['Boolean']>;
};

export type CreateDataFormStepInput = {
  dataFormId?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Icon>;
  name?: InputMaybe<Scalars['String']>;
  /** Used for ordering. The reference to a peer after which it should be added. If it should be added as the first entry, set value as NULL */
  previousStepId?: InputMaybe<Scalars['ID']>;
};

export type CreateDataFormSubsectionInput = {
  description?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Icon>;
  name?: InputMaybe<Scalars['String']>;
  /** Used for ordering. The reference to a peer after which it should be added. If it should be added as the first entry, set value as NULL */
  previousSubsectionId?: InputMaybe<Scalars['ID']>;
  sectionId?: InputMaybe<Scalars['ID']>;
};

export type CreateDataViewFieldInput = {
  dataFieldId?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Icon>;
  mlsSubmissionWebFormInputId?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  /** Used for ordering. The reference to a peer after which it should be added. If it should be added as the first entry, set value as NULL */
  previousFieldId?: InputMaybe<Scalars['ID']>;
  subsectionId?: InputMaybe<Scalars['ID']>;
  widthPercentDesktop?: InputMaybe<Scalars['Int']>;
  widthPercentMobile?: InputMaybe<Scalars['Int']>;
};

export type CreateDataViewSectionInput = {
  description?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Icon>;
  name?: InputMaybe<Scalars['String']>;
  /** Used for ordering. The reference to a peer after which it should be added. If it should be added as the first entry, set value as NULL */
  previousSectionId?: InputMaybe<Scalars['ID']>;
  tableId?: InputMaybe<Scalars['ID']>;
};

export type CreateDataViewSubsectionInput = {
  description?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Icon>;
  name?: InputMaybe<Scalars['String']>;
  /** Used for ordering. The reference to a peer after which it should be added. If it should be added as the first entry, set value as NULL */
  previousSubsectionId?: InputMaybe<Scalars['ID']>;
  sectionId?: InputMaybe<Scalars['ID']>;
};

export type CreateDataViewTableInput = {
  dataViewId?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Icon>;
  name?: InputMaybe<Scalars['String']>;
  /** Used for ordering. The reference to a peer after which it should be added. If it should be added as the first entry, set value as NULL */
  previousTableId?: InputMaybe<Scalars['ID']>;
};

export type CreateDateDataFieldInput = {
  common: CreateDataFieldCommonInput;
  /** Minimal absolute date that can be selected */
  from?: InputMaybe<Scalars['Date']>;
  multiSelect?: InputMaybe<Scalars['Boolean']>;
  /** Maximum absolute date that can be selected */
  to?: InputMaybe<Scalars['Date']>;
};

export type CreateDistributionInput = {
  companyId: Scalars['ID'];
  primaryColor?: InputMaybe<Scalars['String']>;
};

export type CreateDropdownDataFieldInput = {
  common: CreateDataFieldCommonInput;
  maxAllowed?: InputMaybe<Scalars['Int']>;
  multiSelect?: InputMaybe<Scalars['Boolean']>;
  /** Option can only be set if the field is not tied to MLS Metadata. If it is, options would be used from mls metadata */
  options?: InputMaybe<Array<DropdownFieldOptionInput>>;
};

export type CreateEmailDataFieldInput = {
  common: CreateDataFieldCommonInput;
};

export type CreateFileDataFieldInput = {
  common: CreateDataFieldCommonInput;
  useCase?: InputMaybe<SetMediaUseCase>;
};

export type CreateFurnitureGeneralInquiryInput = {
  company: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  message?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  newsletter: Scalars['Boolean'];
  phoneNumber: Scalars['String'];
  website: Scalars['String'];
};

export type CreateFurnitureProductInput = {
  category?: InputMaybe<FurnitureCategory>;
  description?: InputMaybe<Scalars['String']>;
  ecommerceUrl?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<DimensionInput>;
  length?: InputMaybe<DimensionInput>;
  positioning?: InputMaybe<ProductPositioning>;
  price?: InputMaybe<MonetaryAmountInput>;
  sku?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ProductStatus>;
  /** Deprecated in favour of 'vendorCompany' */
  storeName?: InputMaybe<Scalars['String']>;
  supplierCompanyId?: InputMaybe<Scalars['ID']>;
  title: Scalars['String'];
  useCubemap?: InputMaybe<Scalars['Boolean']>;
  vendorCompanyId?: InputMaybe<Scalars['ID']>;
  width?: InputMaybe<DimensionInput>;
};

export type CreateGeneralInquiryInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  message?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
};

export type CreateImageDataFieldInput = {
  common: CreateDataFieldCommonInput;
  minimumImagesRequired?: InputMaybe<Scalars['Int']>;
  multiSelect?: InputMaybe<Scalars['Boolean']>;
  useCase?: InputMaybe<SetMediaUseCase>;
};

export type CreateNoteActivityInput = {
  mediaIds?: InputMaybe<Array<Scalars['ID']>>;
  message: Scalars['String'];
  objectId: Scalars['ID'];
  objectType: ActivityObjectType;
  relatedEntity?: InputMaybe<EntityInput>;
  subType?: InputMaybe<ActivitySubType>;
  visibility?: InputMaybe<ActivityVisibility>;
};

export type CreateNumericDataFieldInput = {
  common: CreateDataFieldCommonInput;
  decimalPlaces?: InputMaybe<Scalars['Int']>;
  increment?: InputMaybe<Scalars['Float']>;
  maxValue?: InputMaybe<Scalars['Float']>;
  minValue?: InputMaybe<Scalars['Float']>;
};

export type CreateOpenHouseInput = {
  arrivalInstructions?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  eventEndTime?: InputMaybe<Scalars['DateTime']>;
  eventStartTime?: InputMaybe<Scalars['DateTime']>;
  format?: InputMaybe<OpenHouseFormat>;
  hostingTeamIds?: InputMaybe<Array<Scalars['ID']>>;
  listingId: Scalars['ID'];
  primaryHostId: Scalars['ID'];
  status?: InputMaybe<OpenHouseStatus>;
  targetAudience?: InputMaybe<TargetAudience>;
};

export type CreatePhoneDataFieldInput = {
  common: CreateDataFieldCommonInput;
};

export type CreatePlaceInput = {
  cityId?: InputMaybe<Scalars['ID']>;
  countryId?: InputMaybe<Scalars['ID']>;
  metroAreaId?: InputMaybe<Scalars['ID']>;
  nameAbbreviated?: InputMaybe<Scalars['String']>;
  nameLong: Scalars['String'];
  nameShort: Scalars['String'];
  regionId?: InputMaybe<Scalars['ID']>;
  sourceGeometryPlaceIds?: InputMaybe<Array<Scalars['ID']>>;
  type: PlaceType;
};

export type CreateProductInventoryInput = {
  price?: InputMaybe<MonetaryAmountInput>;
  productId: Scalars['ID'];
  quantity?: InputMaybe<Scalars['Int']>;
  status: ProductInventoryStatus;
  vendorLocationId: Scalars['ID'];
};

export type CreateProjectActionDefinition = ActionDefinition & {
  __typename?: 'CreateProjectActionDefinition';
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Icon>;
  id: Scalars['ID'];
  kind: ActionKind;
  logicalName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  sequence?: Maybe<Scalars['Int']>;
  targetProjectDefinition: ProjectDefinition;
};

export type CreateProjectDefinitionInput = {
  description?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Icon>;
  logicalName?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  parentProjectDefinitionId?: InputMaybe<Scalars['ID']>;
  scriptOnCancelled?: InputMaybe<Scalars['String']>;
  scriptOnCreate?: InputMaybe<Scalars['String']>;
  scriptOnFinished?: InputMaybe<Scalars['String']>;
  scriptOnStart?: InputMaybe<Scalars['String']>;
  scriptValidateBeforeCancelled?: InputMaybe<Scalars['String']>;
  scriptValidateBeforeCreate?: InputMaybe<Scalars['String']>;
  scriptValidateBeforeFinished?: InputMaybe<Scalars['String']>;
  scriptValidateBeforeStart?: InputMaybe<Scalars['String']>;
  type: ProjectType;
};

export type CreateProjectInstanceInput = {
  assignments?: InputMaybe<Array<WorkflowAssignmentInput>>;
  collaborators?: InputMaybe<Array<ProjectCollaboratorInput>>;
  parentProjectInstanceId?: InputMaybe<Scalars['ID']>;
  payload?: InputMaybe<Scalars['JSON']>;
  projectDefinitionId: Scalars['ID'];
  projectLeadId?: InputMaybe<Scalars['ID']>;
};

export type CreatePropertyFloorPlanConfigurationInput = {
  description?: InputMaybe<Scalars['String']>;
  floorPlanId: Scalars['ID'];
  levels: Array<PropertyFloorPlanLevelConfigurationInput>;
  name: Scalars['String'];
  /** Whether the configuration is public available for everyone, or private available via share link only */
  publiclyAvailable: Scalars['Boolean'];
  /** Whether configuration should be created as draft or published immediately. Relevant for `public` configurations only */
  published: Scalars['Boolean'];
};

export type CreatePropertyFloorPlanInput = {
  addressId?: InputMaybe<Scalars['ID']>;
  title: Scalars['String'];
};

export type CreatePropertyFloorPlanLevelInput = {
  /** Whether the level shall be set as default for the floor plan */
  defaultLevel: Scalars['Boolean'];
  level?: InputMaybe<Level>;
  propertyFloorPlanId: Scalars['ID'];
  squareFootage?: InputMaybe<Scalars['Int']>;
  title: Scalars['String'];
};

export type CreatePropertyInput = {
  address: AddressInput;
};

export type CreatePropertyListingDataConfigInput = {
  description?: InputMaybe<Scalars['String']>;
  logicalName?: InputMaybe<DataConfigLogicalName>;
  mlsBoardId: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};

export type CreatePropertyListingInput = {
  opportunityId: Scalars['ID'];
  supportedPropertyListingTypeId: Scalars['ID'];
};

export type CreatePropertyListingIterationInput = {
  copyDynamicData?: InputMaybe<Scalars['Boolean']>;
  copyMedia?: InputMaybe<Scalars['Boolean']>;
  copyOpenHouses?: InputMaybe<Scalars['Boolean']>;
  copyRooms?: InputMaybe<Scalars['Boolean']>;
  propertyListingId: Scalars['ID'];
};

export type CreatePropertyListingOpportunityFromDataInput = {
  /** Required to create opportunity */
  addressId?: InputMaybe<Scalars['ID']>;
  /** Required to create opportunity */
  commission?: InputMaybe<Scalars['BigDecimal']>;
  /** Required to create opportunity */
  commissionType?: InputMaybe<CommissionType>;
  completedMilestones?: InputMaybe<Array<PropertyListingOpportunityMilestone>>;
  /** Required to create opportunity */
  currentStage?: InputMaybe<PropertyListingOpportunityStage>;
  userId: Scalars['ID'];
};

export type CreatePropertyListingOpportunityInquiryInput = {
  /** Required. ID of the address provided by the address picker component */
  addressId?: InputMaybe<Scalars['ID']>;
  /** Required. Whether the customer is in the market to buy a new home */
  buyingHome?: InputMaybe<YesNoNotSure>;
  /** Required. Customer email. Must be a valid email */
  email: Scalars['String'];
  /** Required. Customer first name */
  firstName: Scalars['String'];
  /** Required. Whether there is an agent currently representing the customer */
  haveAgent?: InputMaybe<Scalars['Boolean']>;
  /** Required. Customer last name */
  lastName: Scalars['String'];
  /** Optional for now. Source from which lead came */
  leadSource?: InputMaybe<LeadSource>;
  /** Required. Planned time to list customer's home for sale */
  listingTime?: InputMaybe<PropertyListingOpportunityInquiryListingTime>;
  message?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  /** Required. Customer phone number. Must be a valid phone number */
  phoneNumber: Scalars['String'];
  /** Required. Type of the property for listing */
  propertyType?: InputMaybe<PropertyListingOpportunityInquiryPropertyType>;
  /** Required. Type of the transaction. The only accepted values are: FOR_SALE | FOR_LEASE */
  transactionType?: InputMaybe<ListingType>;
};

export type CreatePropertyListingTagRuleInput = {
  dataFieldId: Scalars['ID'];
  /** Values which would trigger adding the tag to the listing. Work as OR(if at least one value match it triggers tag adding). In case data field is dropdown, values shall be ids of the options: `DropdownDataField.options.id` */
  dataFieldValues: Array<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  tagId: Scalars['ID'];
};

export type CreateResidentialAppraisalInquiryInput = {
  address: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  message?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
};

export type CreateResidentialListingGeneralInquiryInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  listingId: Scalars['ID'];
  message?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
};

export type CreateResidentialListingOpenHouseInquiryInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  message?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  openHouseId: Scalars['ID'];
  phoneNumber: Scalars['String'];
};

export type CreateResidentialListingPrivateShowingInquiryInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  listingId: Scalars['ID'];
  message?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  requestedShowingDate: Scalars['Date'];
  requestedShowingTime: DayTime;
};

export type CreateResidentialPropertyRoomInput = {
  description?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<DimensionInput>;
  isHighlight?: InputMaybe<Scalars['Boolean']>;
  length?: InputMaybe<DimensionInput>;
  level?: InputMaybe<Level>;
  listingId?: InputMaybe<Scalars['ID']>;
  propertyListingIterationId?: InputMaybe<Scalars['ID']>;
  roomName?: InputMaybe<Scalars['String']>;
  roomType?: InputMaybe<RoomType>;
  width?: InputMaybe<DimensionInput>;
};

export type CreateRoomDataFieldInput = {
  common: CreateDataFieldCommonInput;
  minimumEntriesRequired?: InputMaybe<Scalars['Int']>;
  multiSelect?: InputMaybe<Scalars['Boolean']>;
};

export type CreateSavedSearchInput = {
  connection: SavedSearchSupportedConnection;
  description?: InputMaybe<Scalars['String']>;
  filters: Array<FilterInput>;
  name: Scalars['String'];
  sortOptionId?: InputMaybe<Scalars['ID']>;
};

export type CreateStringDataFieldInput = {
  common: CreateDataFieldCommonInput;
  maxLength?: InputMaybe<Scalars['Int']>;
  minLength?: InputMaybe<Scalars['Int']>;
};

export type CreateTagCollectionInput = {
  dependencyTagIds?: InputMaybe<Array<Scalars['ID']>>;
  description?: InputMaybe<Scalars['String']>;
  entityType: TagEntityType;
  name: Scalars['String'];
  ownerCompanyId?: InputMaybe<Scalars['ID']>;
  weight: Scalars['Int'];
};

export type CreateTagInput = {
  collectionId: Scalars['ID'];
  dependencyTagIds?: InputMaybe<Array<Scalars['ID']>>;
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type CreateTaskActionDefinition = ActionDefinition & {
  __typename?: 'CreateTaskActionDefinition';
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Icon>;
  id: Scalars['ID'];
  kind: ActionKind;
  logicalName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  sequence?: Maybe<Scalars['Int']>;
  targetTaskDefinition: ApproveSubmissionToMlsTaskDefinition | AttachFileStorageTaskDefinition | BasicTaskDefinition | FileUploadTaskDefinition | FormAssignTaskDefinition | FormFillTaskDefinition | FormReviewTaskDefinition | SubmitListingToMlsTaskDefinition | UploadToFileStorageTaskDefinition;
};

export type CreateTaskDefinitionInput = {
  description?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Icon>;
  logicalName?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  projectDefinitionId: Scalars['ID'];
  scriptOnComplete?: InputMaybe<Scalars['String']>;
  scriptOnCreate?: InputMaybe<Scalars['String']>;
  scriptOnStart?: InputMaybe<Scalars['String']>;
  type: TaskType;
};

export type CreateTaskInstanceInput = {
  assignedUserId?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  dueDate?: InputMaybe<Scalars['Date']>;
  name?: InputMaybe<Scalars['String']>;
  payload?: InputMaybe<Scalars['JSON']>;
  projectInstanceId: Scalars['ID'];
  reviewerUserId?: InputMaybe<Scalars['ID']>;
  taskDefinitionId: Scalars['ID'];
};

export type CreateTimeDataFieldInput = {
  common: CreateDataFieldCommonInput;
  /** Minimal absolute time that can be selected */
  from?: InputMaybe<Scalars['DateTime']>;
  multiSelect?: InputMaybe<Scalars['Boolean']>;
  /** Maximum absolute time that can be selected */
  to?: InputMaybe<Scalars['DateTime']>;
};

export type CreateUserDataFieldInput = {
  common: CreateDataFieldCommonInput;
  minimumEntriesRequired?: InputMaybe<Scalars['Int']>;
  multiSelect?: InputMaybe<Scalars['Boolean']>;
};

export const enum CurrencyCode {
  Cad = 'CAD',
  Usd = 'USD'
};

export type CustomActionDefinition = ActionDefinition & {
  __typename?: 'CustomActionDefinition';
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Icon>;
  id: Scalars['ID'];
  kind: ActionKind;
  logicalName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  scriptOnPerform?: Maybe<Scalars['String']>;
  scriptValidateBeforePerform?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Int']>;
  uiOnPerform?: Maybe<Array<Scalars['String']>>;
};

export type DataConfig = {
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  fields: Array<AddressDataField | BooleanDataField | DateDataField | DropdownDataField | EmailDataField | FileDataField | ImageDataField | NumericDataField | PhoneDataField | PrimaryImageSelectDataField | RoomDataField | StringDataField | TimeDataField | UserDataField>;
  forms: Array<PropertyListingDataFormDefinition>;
  id: Scalars['ID'];
  logicalName?: Maybe<DataConfigLogicalName>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  views: Array<PropertyListingDataViewDefinition>;
};

export type DataConfigConnection = {
  __typename?: 'DataConfigConnection';
  edges?: Maybe<Array<Maybe<DataConfigConnectionEdge>>>;
  facetGroups?: Maybe<Array<Maybe<FacetGroup>>>;
  filters?: Maybe<Array<Maybe<AggregatedFacetFilter | BooleanFilter | DateRangeFilter | FloatRangeFilter | IntegerRangeFilter | SimpleFacetFilter | TagCollectionFilter>>>;
  histogramGroups?: Maybe<Array<Maybe<HistogramGroup>>>;
  metricGroups?: Maybe<Array<Maybe<MetricGroup>>>;
  pageInfo?: Maybe<PageInfo>;
  savedSearches?: Maybe<Array<Maybe<SavedSearch>>>;
  sortOptions?: Maybe<Array<Maybe<SortOption>>>;
};


export type DataConfigConnectionFacetGroupsArgs = {
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type DataConfigConnectionFiltersArgs = {
  aggregatedFacetKeywordSearch?: InputMaybe<AggregatedFacetKeywordSearchInput>;
  fetchAggregatedFacetsFilterIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  includeTagFilters?: InputMaybe<Scalars['Boolean']>;
  selected?: InputMaybe<Scalars['Boolean']>;
};


export type DataConfigConnectionHistogramGroupsArgs = {
  histogramGroupInput?: InputMaybe<HistogramGroupInput>;
};


export type DataConfigConnectionMetricGroupsArgs = {
  metricInput?: InputMaybe<Array<InputMaybe<MetricGroupInput>>>;
};

export type DataConfigConnectionEdge = {
  __typename?: 'DataConfigConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<PlainEntityDataConfig | PropertyListingDataConfig>;
};

export type DataConfigConnectionInput = {
  first: Scalars['Int'];
  keyword?: InputMaybe<Scalars['String']>;
  offset: Scalars['Int'];
  sortKey?: InputMaybe<DataConfigSortKey>;
  sortOptionId?: InputMaybe<Scalars['ID']>;
  sortOrder?: InputMaybe<SortOrder>;
  tagIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type DataConfigFormDescriptor = {
  __typename?: 'DataConfigFormDescriptor';
  form: PropertyListingDataFormDefinition;
  label: Scalars['String'];
};

export const enum DataConfigLogicalName {
  FurnitureSupplierCompany = 'FURNITURE_SUPPLIER_COMPANY',
  FurnitureVendorCompany = 'FURNITURE_VENDOR_COMPANY'
};

export const enum DataConfigSortKey {
  CreatedAt = 'CREATED_AT',
  Name = 'NAME',
  UpdatedAt = 'UPDATED_AT'
};

export type DataConfigViewDescriptor = {
  __typename?: 'DataConfigViewDescriptor';
  label: Scalars['String'];
  view: PropertyListingDataViewDefinition;
};

export type DataField = {
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  key: Scalars['String'];
  logicalName?: Maybe<DataFieldLogicalName>;
  mlsField?: Maybe<MlsField>;
  name: Scalars['String'];
  processed?: Maybe<Scalars['Boolean']>;
  type: DataFieldType;
  typeName: Scalars['String'];
};

export type DataFieldConnection = {
  __typename?: 'DataFieldConnection';
  edges?: Maybe<Array<Maybe<DataFieldConnectionEdge>>>;
  facetGroups?: Maybe<Array<Maybe<FacetGroup>>>;
  filters?: Maybe<Array<Maybe<AggregatedFacetFilter | BooleanFilter | DateRangeFilter | FloatRangeFilter | IntegerRangeFilter | SimpleFacetFilter | TagCollectionFilter>>>;
  histogramGroups?: Maybe<Array<Maybe<HistogramGroup>>>;
  metricGroups?: Maybe<Array<Maybe<MetricGroup>>>;
  pageInfo?: Maybe<PageInfo>;
  savedSearches?: Maybe<Array<Maybe<SavedSearch>>>;
  sortOptions?: Maybe<Array<Maybe<SortOption>>>;
};


export type DataFieldConnectionFacetGroupsArgs = {
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type DataFieldConnectionFiltersArgs = {
  aggregatedFacetKeywordSearch?: InputMaybe<AggregatedFacetKeywordSearchInput>;
  fetchAggregatedFacetsFilterIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  includeTagFilters?: InputMaybe<Scalars['Boolean']>;
  selected?: InputMaybe<Scalars['Boolean']>;
};


export type DataFieldConnectionHistogramGroupsArgs = {
  histogramGroupInput?: InputMaybe<HistogramGroupInput>;
};


export type DataFieldConnectionMetricGroupsArgs = {
  metricInput?: InputMaybe<Array<InputMaybe<MetricGroupInput>>>;
};

export type DataFieldConnectionEdge = {
  __typename?: 'DataFieldConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<AddressDataField | BooleanDataField | DateDataField | DropdownDataField | EmailDataField | FileDataField | ImageDataField | NumericDataField | PhoneDataField | PrimaryImageSelectDataField | RoomDataField | StringDataField | TimeDataField | UserDataField>;
};

export type DataFieldConnectionInput = {
  dataConfigIds?: InputMaybe<Array<Scalars['ID']>>;
  first: Scalars['Int'];
  keyword?: InputMaybe<Scalars['String']>;
  lookupForDataFormDefinitionId?: InputMaybe<Scalars['ID']>;
  lookupForDataViewDefinitionId?: InputMaybe<Scalars['ID']>;
  offset: Scalars['Int'];
  sortOptionId?: InputMaybe<Scalars['ID']>;
};

export const enum DataFieldLogicalName {
  Acreage = 'ACREAGE',
  Address = 'ADDRESS',
  AnnualPropertyTax = 'ANNUAL_PROPERTY_TAX',
  Baths = 'BATHS',
  Beds = 'BEDS',
  BedsAboveGrade = 'BEDS_ABOVE_GRADE',
  BedsBelowGrade = 'BEDS_BELOW_GRADE',
  CondoFee = 'CONDO_FEE',
  CondoFeeFrequency = 'CONDO_FEE_FREQUENCY',
  DateListed = 'DATE_LISTED',
  DateOfPossession = 'DATE_OF_POSSESSION',
  DateSold = 'DATE_SOLD',
  Dens = 'DENS',
  Description = 'DESCRIPTION',
  LeasedPrice = 'LEASED_PRICE',
  LeasePrice = 'LEASE_PRICE',
  LeaseTerm = 'LEASE_TERM',
  ListPrice = 'LIST_PRICE',
  LotSize = 'LOT_SIZE',
  OrebStyleOfDwelling = 'OREB_STYLE_OF_DWELLING',
  OrebTypeOfDwelling = 'OREB_TYPE_OF_DWELLING',
  ParkingSpots = 'PARKING_SPOTS',
  PropertyListingAgents = 'PROPERTY_LISTING_AGENTS',
  PropertyListingPhotos = 'PROPERTY_LISTING_PHOTOS',
  PropertyListingRooms = 'PROPERTY_LISTING_ROOMS',
  PropertyListingSellers = 'PROPERTY_LISTING_SELLERS',
  PropertyStyle = 'PROPERTY_STYLE',
  PropertyType = 'PROPERTY_TYPE',
  SalePrice = 'SALE_PRICE',
  Sqft = 'SQFT',
  SqftMax = 'SQFT_MAX',
  SqftMin = 'SQFT_MIN',
  Stories = 'STORIES',
  YearBuilt = 'YEAR_BUILT',
  YearBuiltMax = 'YEAR_BUILT_MAX',
  YearBuiltMin = 'YEAR_BUILT_MIN'
};

export type DataFieldMetadata = {
  __typename?: 'DataFieldMetadata';
  logicalName: Array<EnumValueMetadata>;
  setMediaUseCase: Array<EnumValueMetadata>;
  type: Array<EnumValueMetadata>;
};

export const enum DataFieldType {
  Address = 'ADDRESS',
  Boolean = 'BOOLEAN',
  Custom = 'CUSTOM',
  Date = 'DATE',
  Dropdown = 'DROPDOWN',
  Email = 'EMAIL',
  File = 'FILE',
  Image = 'IMAGE',
  Numeric = 'NUMERIC',
  Phone = 'PHONE',
  PrimaryImageSelect = 'PRIMARY_IMAGE_SELECT',
  Room = 'ROOM',
  String = 'STRING',
  Time = 'TIME',
  User = 'USER'
};

export type DataFormDefinition = {
  dataConfig: PlainEntityDataConfig | PropertyListingDataConfig;
  description?: Maybe<Scalars['String']>;
  hasReviewStep: Scalars['Boolean'];
  icon?: Maybe<Icon>;
  id: Scalars['ID'];
  instancePreview: DataFormInstance;
  isFinalizable: Scalars['Boolean'];
  linear: Scalars['Boolean'];
  logicalName?: Maybe<DataFormLogicalName>;
  name: Scalars['String'];
  status: DataFormStatus;
  steps: Array<DataFormStepDefinition>;
};

export type DataFormDefinitionMetadata = {
  __typename?: 'DataFormDefinitionMetadata';
  status: Array<EnumValueMetadata>;
};

export const enum DataFormFilteringMode {
  All = 'ALL',
  Incomplete = 'INCOMPLETE',
  Optional = 'OPTIONAL',
  Required = 'REQUIRED'
};

export const enum DataFormInputAccessLevel {
  CustomerEditable = 'CUSTOMER_EDITABLE',
  CustomerReadOnly = 'CUSTOMER_READ_ONLY',
  Internal = 'INTERNAL'
};

export type DataFormInputDefinition = {
  __typename?: 'DataFormInputDefinition';
  accessLevel: DataFormInputAccessLevel;
  accessLevelName: Scalars['String'];
  dataField: AddressDataField | BooleanDataField | DateDataField | DropdownDataField | EmailDataField | FileDataField | ImageDataField | NumericDataField | PhoneDataField | PrimaryImageSelectDataField | RoomDataField | StringDataField | TimeDataField | UserDataField;
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Icon>;
  id: Scalars['ID'];
  logicalName?: Maybe<DataFormInputLogicalName>;
  name?: Maybe<Scalars['String']>;
  required: Scalars['Boolean'];
  sequence: Scalars['Int'];
  style?: Maybe<DataFormInputStyle>;
  styleName?: Maybe<Scalars['String']>;
  widthPercent?: Maybe<Scalars['Int']>;
};


export type DataFormInputDefinitionStyleArgs = {
  clientType: ClientType;
};


export type DataFormInputDefinitionStyleNameArgs = {
  clientType: ClientType;
};


export type DataFormInputDefinitionWidthPercentArgs = {
  clientType: ClientType;
};

export type DataFormInputDefinitionMetadata = {
  __typename?: 'DataFormInputDefinitionMetadata';
  accessLevel: Array<EnumValueMetadata>;
  status: Array<EnumValueMetadata>;
  style?: Maybe<Array<Maybe<EnumValueMetadata>>>;
};


export type DataFormInputDefinitionMetadataStyleArgs = {
  dataFieldType?: InputMaybe<DataFieldType>;
};

export type DataFormInputInstance = {
  currentValue?: Maybe<Scalars['String']>;
  definition: DataFormInputDefinition;
  editable: Scalars['Boolean'];
  /** ID of the data form input. Should be used for FormWizardSaveFormInput.id */
  id: Scalars['ID'];
};

export const enum DataFormInputLogicalName {
  PropertyListingAgents = 'PROPERTY_LISTING_AGENTS',
  /** Property listing common. Used by clients for custom inputs handling */
  PropertyListingPhotos = 'PROPERTY_LISTING_PHOTOS',
  PropertyListingRooms = 'PROPERTY_LISTING_ROOMS',
  PropertyListingSellers = 'PROPERTY_LISTING_SELLERS'
};

export const enum DataFormInputStyle {
  ButtonHorizontalGroupSelect = 'BUTTON_HORIZONTAL_GROUP_SELECT',
  ButtonVerticalGroupSelect = 'BUTTON_VERTICAL_GROUP_SELECT',
  CalendarSelect = 'CALENDAR_SELECT',
  MultiLineTextInput = 'MULTI_LINE_TEXT_INPUT',
  TileGroupSelect = 'TILE_GROUP_SELECT'
};

export type DataFormInputValueInput = {
  /** ID of the `DataFormInputInstance` which value should be set */
  id: Scalars['ID'];
  value?: InputMaybe<Scalars['String']>;
};

export type DataFormInstance = Entity & {
  __typename?: 'DataFormInstance';
  complete: Scalars['Boolean'];
  currentSectionId?: Maybe<Scalars['ID']>;
  currentStepId?: Maybe<Scalars['ID']>;
  currentSubsectionId?: Maybe<Scalars['ID']>;
  currentValues?: Maybe<Array<KeyValuePair>>;
  definition: PropertyListingDataFormDefinition;
  derivedView: DataViewInstance;
  finalized: Scalars['Boolean'];
  id: Scalars['ID'];
  incompleteRequiredInputs: Scalars['Int'];
  progressPercent: Scalars['Int'];
  steps: Array<DataFormStepInstance>;
};


export type DataFormInstanceStepsArgs = {
  filter?: InputMaybe<DataFormStepInstanceFilterInput>;
};

export type DataFormInstanceFieldInput = {
  /** Input ID to navigate to. This would affect the current step and section returned by the form, it would be set to section & subsection containing the input with provided ID */
  currentInputId?: InputMaybe<Scalars['ID']>;
  /** Keyword search on form input data, such as name, description, and current value. If provided, only inputs matching the search are returned */
  keyword?: InputMaybe<Scalars['String']>;
  /** Mode to filter only required or optional fields */
  mode?: InputMaybe<DataFormFilteringMode>;
};

export const enum DataFormLogicalName {
  CompanyLocation = 'COMPANY_LOCATION',
  FurnitureProduct = 'FURNITURE_PRODUCT',
  FurnitureSupplierCompany = 'FURNITURE_SUPPLIER_COMPANY',
  FurnitureVendorCompany = 'FURNITURE_VENDOR_COMPANY',
  OrebFarmForLeaseAssessment = 'OREB_FARM_FOR_LEASE_ASSESSMENT',
  OrebFarmForLeaseListing = 'OREB_FARM_FOR_LEASE_LISTING',
  OrebFarmForSaleAssessment = 'OREB_FARM_FOR_SALE_ASSESSMENT',
  OrebFarmForSaleListing = 'OREB_FARM_FOR_SALE_LISTING',
  OrebLandForLeaseAssessment = 'OREB_LAND_FOR_LEASE_ASSESSMENT',
  OrebLandForLeaseListing = 'OREB_LAND_FOR_LEASE_LISTING',
  OrebLandForSaleAssessment = 'OREB_LAND_FOR_SALE_ASSESSMENT',
  OrebLandForSaleListing = 'OREB_LAND_FOR_SALE_LISTING',
  OrebOtherForLeaseAssessment = 'OREB_OTHER_FOR_LEASE_ASSESSMENT',
  OrebOtherForLeaseListing = 'OREB_OTHER_FOR_LEASE_LISTING',
  OrebOtherForSaleAssessment = 'OREB_OTHER_FOR_SALE_ASSESSMENT',
  OrebOtherForSaleListing = 'OREB_OTHER_FOR_SALE_LISTING',
  OrebParkingForLeaseAssessment = 'OREB_PARKING_FOR_LEASE_ASSESSMENT',
  OrebParkingForLeaseListing = 'OREB_PARKING_FOR_LEASE_LISTING',
  OrebParkingForSaleAssessment = 'OREB_PARKING_FOR_SALE_ASSESSMENT',
  OrebParkingForSaleListing = 'OREB_PARKING_FOR_SALE_LISTING',
  OrebRecreationalForLeaseAssessment = 'OREB_RECREATIONAL_FOR_LEASE_ASSESSMENT',
  OrebRecreationalForLeaseListing = 'OREB_RECREATIONAL_FOR_LEASE_LISTING',
  OrebRecreationalForSaleAssessment = 'OREB_RECREATIONAL_FOR_SALE_ASSESSMENT',
  OrebRecreationalForSaleListing = 'OREB_RECREATIONAL_FOR_SALE_LISTING',
  OrebResidentialCondoForLeaseAssessment = 'OREB_RESIDENTIAL_CONDO_FOR_LEASE_ASSESSMENT',
  OrebResidentialCondoForLeaseListing = 'OREB_RESIDENTIAL_CONDO_FOR_LEASE_LISTING',
  OrebResidentialCondoForSale = 'OREB_RESIDENTIAL_CONDO_FOR_SALE',
  OrebResidentialCondoForSaleAssessment = 'OREB_RESIDENTIAL_CONDO_FOR_SALE_ASSESSMENT',
  OrebResidentialFreeholdForSale = 'OREB_RESIDENTIAL_FREEHOLD_FOR_SALE',
  OrebResidentialHouseForLeaseAssessment = 'OREB_RESIDENTIAL_HOUSE_FOR_LEASE_ASSESSMENT',
  OrebResidentialHouseForLeaseListing = 'OREB_RESIDENTIAL_HOUSE_FOR_LEASE_LISTING',
  OrebResidentialHouseForSaleAssessment = 'OREB_RESIDENTIAL_HOUSE_FOR_SALE_ASSESSMENT',
  OrebResidentialMultiFamilyForLeaseAssessment = 'OREB_RESIDENTIAL_MULTI_FAMILY_FOR_LEASE_ASSESSMENT',
  OrebResidentialMultiFamilyForLeaseListing = 'OREB_RESIDENTIAL_MULTI_FAMILY_FOR_LEASE_LISTING',
  OrebResidentialMultiFamilyForSaleAssessment = 'OREB_RESIDENTIAL_MULTI_FAMILY_FOR_SALE_ASSESSMENT',
  OrebResidentialMultiFamilyForSaleListing = 'OREB_RESIDENTIAL_MULTI_FAMILY_FOR_SALE_LISTING',
  SpaceMaterial = 'SPACE_MATERIAL',
  TrrebFarmForLeaseAssessment = 'TRREB_FARM_FOR_LEASE_ASSESSMENT',
  TrrebFarmForLeaseListing = 'TRREB_FARM_FOR_LEASE_LISTING',
  TrrebFarmForSaleAssessment = 'TRREB_FARM_FOR_SALE_ASSESSMENT',
  TrrebFarmForSaleListing = 'TRREB_FARM_FOR_SALE_LISTING',
  TrrebLandForLeaseAssessment = 'TRREB_LAND_FOR_LEASE_ASSESSMENT',
  TrrebLandForLeaseListing = 'TRREB_LAND_FOR_LEASE_LISTING',
  TrrebLandForSaleAssessment = 'TRREB_LAND_FOR_SALE_ASSESSMENT',
  TrrebLandForSaleListing = 'TRREB_LAND_FOR_SALE_LISTING',
  TrrebOtherForLeaseAssessment = 'TRREB_OTHER_FOR_LEASE_ASSESSMENT',
  TrrebOtherForLeaseListing = 'TRREB_OTHER_FOR_LEASE_LISTING',
  TrrebOtherForSaleAssessment = 'TRREB_OTHER_FOR_SALE_ASSESSMENT',
  TrrebOtherForSaleListing = 'TRREB_OTHER_FOR_SALE_LISTING',
  TrrebParkingForLeaseAssessment = 'TRREB_PARKING_FOR_LEASE_ASSESSMENT',
  TrrebParkingForLeaseListing = 'TRREB_PARKING_FOR_LEASE_LISTING',
  TrrebParkingForSaleAssessment = 'TRREB_PARKING_FOR_SALE_ASSESSMENT',
  TrrebParkingForSaleListing = 'TRREB_PARKING_FOR_SALE_LISTING',
  TrrebRecreationalForLeaseAssessment = 'TRREB_RECREATIONAL_FOR_LEASE_ASSESSMENT',
  TrrebRecreationalForLeaseListing = 'TRREB_RECREATIONAL_FOR_LEASE_LISTING',
  TrrebRecreationalForSaleAssessment = 'TRREB_RECREATIONAL_FOR_SALE_ASSESSMENT',
  TrrebRecreationalForSaleListing = 'TRREB_RECREATIONAL_FOR_SALE_LISTING',
  TrrebResidentialCondoForLeaseAssessment = 'TRREB_RESIDENTIAL_CONDO_FOR_LEASE_ASSESSMENT',
  TrrebResidentialCondoForLeaseListing = 'TRREB_RESIDENTIAL_CONDO_FOR_LEASE_LISTING',
  TrrebResidentialCondoForSale = 'TRREB_RESIDENTIAL_CONDO_FOR_SALE',
  TrrebResidentialCondoForSaleAssessment = 'TRREB_RESIDENTIAL_CONDO_FOR_SALE_ASSESSMENT',
  TrrebResidentialHouseForLeaseAssessment = 'TRREB_RESIDENTIAL_HOUSE_FOR_LEASE_ASSESSMENT',
  TrrebResidentialHouseForLeaseListing = 'TRREB_RESIDENTIAL_HOUSE_FOR_LEASE_LISTING',
  TrrebResidentialHouseForSale = 'TRREB_RESIDENTIAL_HOUSE_FOR_SALE',
  TrrebResidentialHouseForSaleAssessment = 'TRREB_RESIDENTIAL_HOUSE_FOR_SALE_ASSESSMENT',
  TrrebResidentialMultiFamilyForLeaseAssessment = 'TRREB_RESIDENTIAL_MULTI_FAMILY_FOR_LEASE_ASSESSMENT',
  TrrebResidentialMultiFamilyForLeaseListing = 'TRREB_RESIDENTIAL_MULTI_FAMILY_FOR_LEASE_LISTING',
  TrrebResidentialMultiFamilyForSaleAssessment = 'TRREB_RESIDENTIAL_MULTI_FAMILY_FOR_SALE_ASSESSMENT',
  TrrebResidentialMultiFamilyForSaleListing = 'TRREB_RESIDENTIAL_MULTI_FAMILY_FOR_SALE_LISTING'
};

export type DataFormSaveInput = {
  /** ID of the `DataFormInputInstance` which value should be set */
  id: Scalars['ID'];
  value?: InputMaybe<Scalars['String']>;
};

export type DataFormSectionDefinition = {
  __typename?: 'DataFormSectionDefinition';
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Icon>;
  id: Scalars['ID'];
  logicalName?: Maybe<DataFormSectionLogicalName>;
  name?: Maybe<Scalars['String']>;
  sequence: Scalars['Int'];
  subsections?: Maybe<Array<DataFormSubsectionDefinition>>;
  supportsHistoricalData: Scalars['Boolean'];
};

export type DataFormSectionInstance = {
  __typename?: 'DataFormSectionInstance';
  available: Scalars['Boolean'];
  complete: Scalars['Boolean'];
  definition: DataFormSectionDefinition;
  /** ID of the data form section definition */
  id: Scalars['ID'];
  incompleteRequiredInputs: Scalars['Int'];
  required: Scalars['Boolean'];
  subsections?: Maybe<Array<DataFormSubsectionInstance>>;
};

export const enum DataFormSectionLogicalName {
  PropertyListingAgents = 'PROPERTY_LISTING_AGENTS',
  PropertyListingMedia = 'PROPERTY_LISTING_MEDIA',
  PropertyListingRooms = 'PROPERTY_LISTING_ROOMS',
  PropertyListingSellers = 'PROPERTY_LISTING_SELLERS'
};

export const enum DataFormStatus {
  Deleted = 'DELETED',
  Draft = 'DRAFT',
  Outdated = 'OUTDATED',
  Published = 'PUBLISHED'
};

export type DataFormStepDefinition = {
  __typename?: 'DataFormStepDefinition';
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Icon>;
  id: Scalars['ID'];
  logicalName?: Maybe<DataFormStepLogicalName>;
  name: Scalars['String'];
  sections: Array<DataFormSectionDefinition>;
  sequence: Scalars['Int'];
};

export type DataFormStepInstance = Entity & {
  __typename?: 'DataFormStepInstance';
  assignedUser?: Maybe<AdminUser | AgentUser | ConsumerUser | ContactUser>;
  available: Scalars['Boolean'];
  complete: Scalars['Boolean'];
  definition: DataFormStepDefinition;
  /** ID of the data form step definition */
  id: Scalars['ID'];
  incompleteRequiredInputs: Scalars['Int'];
  progressPercent: Scalars['Int'];
  required: Scalars['Boolean'];
  sections: Array<DataFormSectionInstance>;
  thread?: Maybe<InquiryCreatedActivity | NoteActivity | ProjectStageEventActivity | PropertyListingLikeActivity | PropertyListingViewActivity | RootProjectEventActivity | SubProjectEventActivity | TaskAssignActivity | TaskEventActivity | UserAssignedAgentAddedActivity | UserAssignedAgentRemovedActivity | UserReactivatedActivity | UserSuspendedActivity>;
};

export type DataFormStepInstanceFilterInput = {
  /** Keyword search on form input data, such as name, description, and current value. If provided, only inputs matching the search are returned */
  keyword?: InputMaybe<Scalars['String']>;
  /** Mode to filter only required or optional fields */
  mode?: InputMaybe<DataFormFilteringMode>;
  /** Id of the step for which the filter should be applied */
  stepInstanceId: Scalars['ID'];
};

export const enum DataFormStepLogicalName {
  Value1 = 'VALUE1'
};

export type DataFormSubsectionDefinition = {
  __typename?: 'DataFormSubsectionDefinition';
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Icon>;
  id: Scalars['ID'];
  inputs?: Maybe<Array<DataFormInputDefinition>>;
  logicalName?: Maybe<DataFormSubsectionLogicalName>;
  name?: Maybe<Scalars['String']>;
  sequence: Scalars['Int'];
};

export type DataFormSubsectionInstance = {
  __typename?: 'DataFormSubsectionInstance';
  definition: DataFormSubsectionDefinition;
  /** ID of the data form subsection definition */
  id: Scalars['ID'];
  incompleteRequiredInputs: Scalars['Int'];
  inputs?: Maybe<Array<AddressDataFormInputInstance | BooleanDataFormInputInstance | DateDataFormInputInstance | DropdownDataFormInputInstance | EmailDataFormInputInstance | FileDataFormInputInstance | ImageDataFormInputInstance | NumericDataFormInputInstance | PhoneDataFormInputInstance | PrimaryImageDataFormInputInstance | RoomDataFormInputInstance | StringDataFormInputInstance | TimeDataFormInputInstance | UserDataFormInputInstance>>;
};

export const enum DataFormSubsectionLogicalName {
  Value1 = 'VALUE1'
};

export const enum DataSource {
  CreaMls = 'CREA_MLS',
  OrebMls = 'OREB_MLS',
  TrrebMls = 'TRREB_MLS',
  Unreserved = 'UNRESERVED'
};

export type DataViewDefinition = {
  dataConfig: PlainEntityDataConfig | PropertyListingDataConfig;
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Icon>;
  id: Scalars['ID'];
  instancePreview: DataViewInstance;
  logicalName?: Maybe<DataViewLogicalName>;
  name: Scalars['String'];
  status: DataViewStatus;
  tables?: Maybe<Array<DataViewTableDefinition>>;
};

export type DataViewDefinitionMetadata = {
  __typename?: 'DataViewDefinitionMetadata';
  logicalName: Array<EnumValueMetadata>;
  status: Array<EnumValueMetadata>;
};

export type DataViewFieldDefinition = {
  __typename?: 'DataViewFieldDefinition';
  dataField: AddressDataField | BooleanDataField | DateDataField | DropdownDataField | EmailDataField | FileDataField | ImageDataField | NumericDataField | PhoneDataField | PrimaryImageSelectDataField | RoomDataField | StringDataField | TimeDataField | UserDataField;
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Icon>;
  id: Scalars['ID'];
  logicalName?: Maybe<DataViewFieldLogicalName>;
  mlsSubmissionWebFormInputId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  sequence: Scalars['Int'];
  widthPercent?: Maybe<Scalars['Int']>;
};


export type DataViewFieldDefinitionWidthPercentArgs = {
  clientType: ClientType;
};

export type DataViewFieldInstance = {
  definition: DataViewFieldDefinition;
  value?: Maybe<Scalars['String']>;
};

export const enum DataViewFieldLogicalName {
  Value1 = 'VALUE1'
};

export type DataViewInstance = {
  __typename?: 'DataViewInstance';
  definition: PropertyListingDataViewDefinition;
  tables?: Maybe<Array<DataViewTableInstance>>;
};

export const enum DataViewLogicalName {
  OrebFarmForLeaseAssessment = 'OREB_FARM_FOR_LEASE_ASSESSMENT',
  OrebFarmForLeaseInternal = 'OREB_FARM_FOR_LEASE_INTERNAL',
  OrebFarmForLeaseMlsSubmission = 'OREB_FARM_FOR_LEASE_MLS_SUBMISSION',
  OrebFarmForLeasePublic = 'OREB_FARM_FOR_LEASE_PUBLIC',
  OrebFarmForSaleAssessment = 'OREB_FARM_FOR_SALE_ASSESSMENT',
  OrebFarmForSaleInternal = 'OREB_FARM_FOR_SALE_INTERNAL',
  OrebFarmForSaleMlsSubmission = 'OREB_FARM_FOR_SALE_MLS_SUBMISSION',
  OrebFarmForSalePublic = 'OREB_FARM_FOR_SALE_PUBLIC',
  OrebLandForLeaseAssessment = 'OREB_LAND_FOR_LEASE_ASSESSMENT',
  OrebLandForLeaseInternal = 'OREB_LAND_FOR_LEASE_INTERNAL',
  OrebLandForLeaseMlsSubmission = 'OREB_LAND_FOR_LEASE_MLS_SUBMISSION',
  OrebLandForLeasePublic = 'OREB_LAND_FOR_LEASE_PUBLIC',
  OrebLandForSaleAssessment = 'OREB_LAND_FOR_SALE_ASSESSMENT',
  OrebLandForSaleInternal = 'OREB_LAND_FOR_SALE_INTERNAL',
  OrebLandForSaleMlsSubmission = 'OREB_LAND_FOR_SALE_MLS_SUBMISSION',
  OrebLandForSalePublic = 'OREB_LAND_FOR_SALE_PUBLIC',
  OrebOtherForLeaseAssessment = 'OREB_OTHER_FOR_LEASE_ASSESSMENT',
  OrebOtherForLeaseInternal = 'OREB_OTHER_FOR_LEASE_INTERNAL',
  OrebOtherForLeaseMlsSubmission = 'OREB_OTHER_FOR_LEASE_MLS_SUBMISSION',
  OrebOtherForLeasePublic = 'OREB_OTHER_FOR_LEASE_PUBLIC',
  OrebOtherForSaleAssessment = 'OREB_OTHER_FOR_SALE_ASSESSMENT',
  OrebOtherForSaleInternal = 'OREB_OTHER_FOR_SALE_INTERNAL',
  OrebOtherForSaleMlsSubmission = 'OREB_OTHER_FOR_SALE_MLS_SUBMISSION',
  OrebOtherForSalePublic = 'OREB_OTHER_FOR_SALE_PUBLIC',
  OrebParkingForLeaseAssessment = 'OREB_PARKING_FOR_LEASE_ASSESSMENT',
  OrebParkingForLeaseInternal = 'OREB_PARKING_FOR_LEASE_INTERNAL',
  OrebParkingForLeaseMlsSubmission = 'OREB_PARKING_FOR_LEASE_MLS_SUBMISSION',
  OrebParkingForLeasePublic = 'OREB_PARKING_FOR_LEASE_PUBLIC',
  OrebParkingForSaleAssessment = 'OREB_PARKING_FOR_SALE_ASSESSMENT',
  OrebParkingForSaleInternal = 'OREB_PARKING_FOR_SALE_INTERNAL',
  OrebParkingForSaleMlsSubmission = 'OREB_PARKING_FOR_SALE_MLS_SUBMISSION',
  OrebParkingForSalePublic = 'OREB_PARKING_FOR_SALE_PUBLIC',
  OrebRecreationalForLeaseAssessment = 'OREB_RECREATIONAL_FOR_LEASE_ASSESSMENT',
  OrebRecreationalForLeaseInternal = 'OREB_RECREATIONAL_FOR_LEASE_INTERNAL',
  OrebRecreationalForLeaseMlsSubmission = 'OREB_RECREATIONAL_FOR_LEASE_MLS_SUBMISSION',
  OrebRecreationalForLeasePublic = 'OREB_RECREATIONAL_FOR_LEASE_PUBLIC',
  OrebRecreationalForSaleAssessment = 'OREB_RECREATIONAL_FOR_SALE_ASSESSMENT',
  OrebRecreationalForSaleInternal = 'OREB_RECREATIONAL_FOR_SALE_INTERNAL',
  OrebRecreationalForSaleMlsSubmission = 'OREB_RECREATIONAL_FOR_SALE_MLS_SUBMISSION',
  OrebRecreationalForSalePublic = 'OREB_RECREATIONAL_FOR_SALE_PUBLIC',
  OrebResidentialCondoForLeaseAssessment = 'OREB_RESIDENTIAL_CONDO_FOR_LEASE_ASSESSMENT',
  OrebResidentialCondoForLeaseInternal = 'OREB_RESIDENTIAL_CONDO_FOR_LEASE_INTERNAL',
  OrebResidentialCondoForLeaseMlsSubmission = 'OREB_RESIDENTIAL_CONDO_FOR_LEASE_MLS_SUBMISSION',
  OrebResidentialCondoForLeasePublic = 'OREB_RESIDENTIAL_CONDO_FOR_LEASE_PUBLIC',
  OrebResidentialCondoForSaleAssessment = 'OREB_RESIDENTIAL_CONDO_FOR_SALE_ASSESSMENT',
  OrebResidentialCondoForSaleInternal = 'OREB_RESIDENTIAL_CONDO_FOR_SALE_INTERNAL',
  OrebResidentialCondoForSaleListing = 'OREB_RESIDENTIAL_CONDO_FOR_SALE_LISTING',
  OrebResidentialCondoForSaleMlsSubmission = 'OREB_RESIDENTIAL_CONDO_FOR_SALE_MLS_SUBMISSION',
  OrebResidentialCondoForSalePublic = 'OREB_RESIDENTIAL_CONDO_FOR_SALE_PUBLIC',
  OrebResidentialFreeholdForSaleListing = 'OREB_RESIDENTIAL_FREEHOLD_FOR_SALE_LISTING',
  OrebResidentialFreeholdForSaleMlsSubmission = 'OREB_RESIDENTIAL_FREEHOLD_FOR_SALE_MLS_SUBMISSION',
  OrebResidentialHouseForLeaseAssessment = 'OREB_RESIDENTIAL_HOUSE_FOR_LEASE_ASSESSMENT',
  OrebResidentialHouseForLeaseInternal = 'OREB_RESIDENTIAL_HOUSE_FOR_LEASE_INTERNAL',
  OrebResidentialHouseForLeaseMlsSubmission = 'OREB_RESIDENTIAL_HOUSE_FOR_LEASE_MLS_SUBMISSION',
  OrebResidentialHouseForLeasePublic = 'OREB_RESIDENTIAL_HOUSE_FOR_LEASE_PUBLIC',
  OrebResidentialHouseForSaleAssessment = 'OREB_RESIDENTIAL_HOUSE_FOR_SALE_ASSESSMENT',
  OrebResidentialHouseForSaleInternal = 'OREB_RESIDENTIAL_HOUSE_FOR_SALE_INTERNAL',
  OrebResidentialHouseForSalePublic = 'OREB_RESIDENTIAL_HOUSE_FOR_SALE_PUBLIC',
  OrebResidentialMultiFamilyForLeaseAssessment = 'OREB_RESIDENTIAL_MULTI_FAMILY_FOR_LEASE_ASSESSMENT',
  OrebResidentialMultiFamilyForLeaseInternal = 'OREB_RESIDENTIAL_MULTI_FAMILY_FOR_LEASE_INTERNAL',
  OrebResidentialMultiFamilyForLeaseMlsSubmission = 'OREB_RESIDENTIAL_MULTI_FAMILY_FOR_LEASE_MLS_SUBMISSION',
  OrebResidentialMultiFamilyForLeasePublic = 'OREB_RESIDENTIAL_MULTI_FAMILY_FOR_LEASE_PUBLIC',
  OrebResidentialMultiFamilyForSaleAssessment = 'OREB_RESIDENTIAL_MULTI_FAMILY_FOR_SALE_ASSESSMENT',
  OrebResidentialMultiFamilyForSaleInternal = 'OREB_RESIDENTIAL_MULTI_FAMILY_FOR_SALE_INTERNAL',
  OrebResidentialMultiFamilyForSaleMlsSubmission = 'OREB_RESIDENTIAL_MULTI_FAMILY_FOR_SALE_MLS_SUBMISSION',
  OrebResidentialMultiFamilyForSalePublic = 'OREB_RESIDENTIAL_MULTI_FAMILY_FOR_SALE_PUBLIC',
  TrrebFarmForLeaseAssessment = 'TRREB_FARM_FOR_LEASE_ASSESSMENT',
  TrrebFarmForLeaseInternal = 'TRREB_FARM_FOR_LEASE_INTERNAL',
  TrrebFarmForLeaseMlsSubmission = 'TRREB_FARM_FOR_LEASE_MLS_SUBMISSION',
  TrrebFarmForLeasePublic = 'TRREB_FARM_FOR_LEASE_PUBLIC',
  TrrebFarmForSaleAssessment = 'TRREB_FARM_FOR_SALE_ASSESSMENT',
  TrrebFarmForSaleInternal = 'TRREB_FARM_FOR_SALE_INTERNAL',
  TrrebFarmForSaleMlsSubmission = 'TRREB_FARM_FOR_SALE_MLS_SUBMISSION',
  TrrebFarmForSalePublic = 'TRREB_FARM_FOR_SALE_PUBLIC',
  TrrebLandForLeaseAssessment = 'TRREB_LAND_FOR_LEASE_ASSESSMENT',
  TrrebLandForLeaseInternal = 'TRREB_LAND_FOR_LEASE_INTERNAL',
  TrrebLandForLeaseMlsSubmission = 'TRREB_LAND_FOR_LEASE_MLS_SUBMISSION',
  TrrebLandForLeasePublic = 'TRREB_LAND_FOR_LEASE_PUBLIC',
  TrrebLandForSaleAssessment = 'TRREB_LAND_FOR_SALE_ASSESSMENT',
  TrrebLandForSaleInternal = 'TRREB_LAND_FOR_SALE_INTERNAL',
  TrrebLandForSaleMlsSubmission = 'TRREB_LAND_FOR_SALE_MLS_SUBMISSION',
  TrrebLandForSalePublic = 'TRREB_LAND_FOR_SALE_PUBLIC',
  TrrebOtherForLeaseAssessment = 'TRREB_OTHER_FOR_LEASE_ASSESSMENT',
  TrrebOtherForLeaseInternal = 'TRREB_OTHER_FOR_LEASE_INTERNAL',
  TrrebOtherForLeaseMlsSubmission = 'TRREB_OTHER_FOR_LEASE_MLS_SUBMISSION',
  TrrebOtherForLeasePublic = 'TRREB_OTHER_FOR_LEASE_PUBLIC',
  TrrebOtherForSaleAssessment = 'TRREB_OTHER_FOR_SALE_ASSESSMENT',
  TrrebOtherForSaleInternal = 'TRREB_OTHER_FOR_SALE_INTERNAL',
  TrrebOtherForSaleMlsSubmission = 'TRREB_OTHER_FOR_SALE_MLS_SUBMISSION',
  TrrebOtherForSalePublic = 'TRREB_OTHER_FOR_SALE_PUBLIC',
  TrrebParkingForLeaseAssessment = 'TRREB_PARKING_FOR_LEASE_ASSESSMENT',
  TrrebParkingForLeaseInternal = 'TRREB_PARKING_FOR_LEASE_INTERNAL',
  TrrebParkingForLeaseMlsSubmission = 'TRREB_PARKING_FOR_LEASE_MLS_SUBMISSION',
  TrrebParkingForLeasePublic = 'TRREB_PARKING_FOR_LEASE_PUBLIC',
  TrrebParkingForSaleAssessment = 'TRREB_PARKING_FOR_SALE_ASSESSMENT',
  TrrebParkingForSaleInternal = 'TRREB_PARKING_FOR_SALE_INTERNAL',
  TrrebParkingForSaleMlsSubmission = 'TRREB_PARKING_FOR_SALE_MLS_SUBMISSION',
  TrrebParkingForSalePublic = 'TRREB_PARKING_FOR_SALE_PUBLIC',
  TrrebRecreationalForLeaseAssessment = 'TRREB_RECREATIONAL_FOR_LEASE_ASSESSMENT',
  TrrebRecreationalForLeaseInternal = 'TRREB_RECREATIONAL_FOR_LEASE_INTERNAL',
  TrrebRecreationalForLeaseMlsSubmission = 'TRREB_RECREATIONAL_FOR_LEASE_MLS_SUBMISSION',
  TrrebRecreationalForLeasePublic = 'TRREB_RECREATIONAL_FOR_LEASE_PUBLIC',
  TrrebRecreationalForSaleAssessment = 'TRREB_RECREATIONAL_FOR_SALE_ASSESSMENT',
  TrrebRecreationalForSaleInternal = 'TRREB_RECREATIONAL_FOR_SALE_INTERNAL',
  TrrebRecreationalForSaleMlsSubmission = 'TRREB_RECREATIONAL_FOR_SALE_MLS_SUBMISSION',
  TrrebRecreationalForSalePublic = 'TRREB_RECREATIONAL_FOR_SALE_PUBLIC',
  TrrebResidentialCondoForLeaseAssessment = 'TRREB_RESIDENTIAL_CONDO_FOR_LEASE_ASSESSMENT',
  TrrebResidentialCondoForLeaseInternal = 'TRREB_RESIDENTIAL_CONDO_FOR_LEASE_INTERNAL',
  TrrebResidentialCondoForLeaseMlsSubmission = 'TRREB_RESIDENTIAL_CONDO_FOR_LEASE_MLS_SUBMISSION',
  TrrebResidentialCondoForLeasePublic = 'TRREB_RESIDENTIAL_CONDO_FOR_LEASE_PUBLIC',
  TrrebResidentialCondoForSaleAssessment = 'TRREB_RESIDENTIAL_CONDO_FOR_SALE_ASSESSMENT',
  TrrebResidentialCondoForSaleInternal = 'TRREB_RESIDENTIAL_CONDO_FOR_SALE_INTERNAL',
  TrrebResidentialCondoForSaleListing = 'TRREB_RESIDENTIAL_CONDO_FOR_SALE_LISTING',
  TrrebResidentialCondoForSaleMlsSubmission = 'TRREB_RESIDENTIAL_CONDO_FOR_SALE_MLS_SUBMISSION',
  TrrebResidentialCondoForSalePublic = 'TRREB_RESIDENTIAL_CONDO_FOR_SALE_PUBLIC',
  TrrebResidentialHouseForLeaseAssessment = 'TRREB_RESIDENTIAL_HOUSE_FOR_LEASE_ASSESSMENT',
  TrrebResidentialHouseForLeaseInternal = 'TRREB_RESIDENTIAL_HOUSE_FOR_LEASE_INTERNAL',
  TrrebResidentialHouseForLeaseMlsSubmission = 'TRREB_RESIDENTIAL_HOUSE_FOR_LEASE_MLS_SUBMISSION',
  TrrebResidentialHouseForLeasePublic = 'TRREB_RESIDENTIAL_HOUSE_FOR_LEASE_PUBLIC',
  TrrebResidentialHouseForSaleAssessment = 'TRREB_RESIDENTIAL_HOUSE_FOR_SALE_ASSESSMENT',
  TrrebResidentialHouseForSaleInternal = 'TRREB_RESIDENTIAL_HOUSE_FOR_SALE_INTERNAL',
  TrrebResidentialHouseForSaleListing = 'TRREB_RESIDENTIAL_HOUSE_FOR_SALE_LISTING',
  TrrebResidentialHouseForSaleMlsSubmission = 'TRREB_RESIDENTIAL_HOUSE_FOR_SALE_MLS_SUBMISSION',
  TrrebResidentialHouseForSalePublic = 'TRREB_RESIDENTIAL_HOUSE_FOR_SALE_PUBLIC',
  TrrebResidentialMultiFamilyForLeaseAssessment = 'TRREB_RESIDENTIAL_MULTI_FAMILY_FOR_LEASE_ASSESSMENT',
  TrrebResidentialMultiFamilyForLeaseInternal = 'TRREB_RESIDENTIAL_MULTI_FAMILY_FOR_LEASE_INTERNAL',
  TrrebResidentialMultiFamilyForLeaseMlsSubmission = 'TRREB_RESIDENTIAL_MULTI_FAMILY_FOR_LEASE_MLS_SUBMISSION',
  TrrebResidentialMultiFamilyForLeasePublic = 'TRREB_RESIDENTIAL_MULTI_FAMILY_FOR_LEASE_PUBLIC',
  TrrebResidentialMultiFamilyForSaleAssessment = 'TRREB_RESIDENTIAL_MULTI_FAMILY_FOR_SALE_ASSESSMENT',
  TrrebResidentialMultiFamilyForSaleInternal = 'TRREB_RESIDENTIAL_MULTI_FAMILY_FOR_SALE_INTERNAL',
  TrrebResidentialMultiFamilyForSaleMlsSubmission = 'TRREB_RESIDENTIAL_MULTI_FAMILY_FOR_SALE_MLS_SUBMISSION',
  TrrebResidentialMultiFamilyForSalePublic = 'TRREB_RESIDENTIAL_MULTI_FAMILY_FOR_SALE_PUBLIC'
};

export type DataViewSectionDefinition = {
  __typename?: 'DataViewSectionDefinition';
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Icon>;
  id: Scalars['ID'];
  logicalName?: Maybe<DataViewSectionLogicalName>;
  name: Scalars['String'];
  sequence: Scalars['Int'];
  subsections?: Maybe<Array<DataViewSubsectionDefinition>>;
};

export type DataViewSectionInstance = {
  __typename?: 'DataViewSectionInstance';
  definition: DataViewSectionDefinition;
  subsections?: Maybe<Array<DataViewSubsectionInstance>>;
};

export const enum DataViewSectionLogicalName {
  PropertyListingAgents = 'PROPERTY_LISTING_AGENTS',
  PropertyListingMedia = 'PROPERTY_LISTING_MEDIA',
  PropertyListingRooms = 'PROPERTY_LISTING_ROOMS',
  PropertyListingSellers = 'PROPERTY_LISTING_SELLERS'
};

export const enum DataViewStatus {
  Deleted = 'DELETED',
  Draft = 'DRAFT',
  Outdated = 'OUTDATED',
  Published = 'PUBLISHED'
};

export type DataViewSubsectionDefinition = {
  __typename?: 'DataViewSubsectionDefinition';
  description?: Maybe<Scalars['String']>;
  fields?: Maybe<Array<DataViewFieldDefinition>>;
  icon?: Maybe<Icon>;
  id: Scalars['ID'];
  logicalName?: Maybe<DataViewSubsectionLogicalName>;
  name?: Maybe<Scalars['String']>;
  sequence: Scalars['Int'];
};

export type DataViewSubsectionInstance = {
  __typename?: 'DataViewSubsectionInstance';
  definition: DataViewSubsectionDefinition;
  fields?: Maybe<Array<AddressDataViewFieldInstance | BooleanDataViewFieldInstance | DateDataViewFieldInstance | DropdownDataViewFieldInstance | EmailDataViewFieldInstance | FileDataViewFieldInstance | ImageDataViewFieldInstance | NumericDataViewFieldInstance | PhoneDataViewFieldInstance | PrimaryImageDataViewFieldInstance | RoomDataViewFieldInstance | StringDataViewFieldInstance | TimeDataViewFieldInstance | UserDataViewFieldInstance>>;
};

export const enum DataViewSubsectionLogicalName {
  Value1 = 'VALUE1'
};

export type DataViewTableDefinition = {
  __typename?: 'DataViewTableDefinition';
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Icon>;
  id: Scalars['ID'];
  logicalName?: Maybe<DataViewTableLogicalName>;
  name: Scalars['String'];
  sections?: Maybe<Array<DataViewSectionDefinition>>;
  sequence: Scalars['Int'];
};

export type DataViewTableInstance = {
  __typename?: 'DataViewTableInstance';
  definition: DataViewTableDefinition;
  sections?: Maybe<Array<DataViewSectionInstance>>;
};

export const enum DataViewTableLogicalName {
  PropertyListingAgents = 'PROPERTY_LISTING_AGENTS',
  PropertyListingMedia = 'PROPERTY_LISTING_MEDIA',
  PropertyListingRooms = 'PROPERTY_LISTING_ROOMS',
  PropertyListingSellers = 'PROPERTY_LISTING_SELLERS'
};

export type DateDataField = DataField & {
  __typename?: 'DateDataField';
  description?: Maybe<Scalars['String']>;
  /** Must be absolute from date */
  from?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  key: Scalars['String'];
  logicalName?: Maybe<DataFieldLogicalName>;
  mlsField?: Maybe<MlsField>;
  multiSelect: Scalars['Boolean'];
  name: Scalars['String'];
  processed?: Maybe<Scalars['Boolean']>;
  /** Must be absolute to date */
  to?: Maybe<Scalars['Date']>;
  type: DataFieldType;
  typeName: Scalars['String'];
};

export type DateDataFormInputInstance = DataFormInputInstance & {
  __typename?: 'DateDataFormInputInstance';
  currentValue?: Maybe<Scalars['String']>;
  definition: DataFormInputDefinition;
  editable: Scalars['Boolean'];
  id: Scalars['ID'];
};

export type DateDataViewFieldInstance = DataViewFieldInstance & {
  __typename?: 'DateDataViewFieldInstance';
  definition: DataViewFieldDefinition;
  value?: Maybe<Scalars['String']>;
};

export type DateRange = {
  __typename?: 'DateRange';
  gte?: Maybe<Scalars['Date']>;
  lte?: Maybe<Scalars['Date']>;
};

export type DateRangeFilter = ConnectionFilter & {
  __typename?: 'DateRangeFilter';
  availableRange?: Maybe<DateRange>;
  currentValue?: Maybe<DateRange>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  options?: Maybe<Array<DateRangeOption>>;
  type?: Maybe<ConnectionFilterType>;
  typeName?: Maybe<Scalars['String']>;
};

export type DateRangeOption = {
  __typename?: 'DateRangeOption';
  count?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  selected: Scalars['Boolean'];
  value: DateRange;
};

export const enum DayTime {
  Afternoon = 'AFTERNOON',
  Day = 'DAY',
  Evening = 'EVENING',
  Morning = 'MORNING'
};

export const enum DerivedSquareFootageSource {
  Description = 'DESCRIPTION',
  Mpac = 'MPAC',
  RoomDimensions = 'ROOM_DIMENSIONS'
};

export type Dimension = {
  __typename?: 'Dimension';
  formattedDimension?: Maybe<Scalars['String']>;
  unit?: Maybe<DimensionUnit>;
  unitName?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};


export type DimensionFormattedDimensionArgs = {
  unit?: InputMaybe<DimensionUnit>;
};


export type DimensionValueArgs = {
  unit?: InputMaybe<DimensionUnit>;
};

export type DimensionInput = {
  unit: DimensionUnit;
  value: Scalars['Float'];
};

export const enum DimensionUnit {
  Cm = 'CM',
  Feet = 'FEET',
  Inches = 'INCHES',
  Meters = 'METERS'
};

export type Distance = {
  __typename?: 'Distance';
  formattedDistance: Scalars['String'];
  unit: DistanceUnit;
  unitName: Scalars['String'];
  value: Scalars['Float'];
};

export type DistanceInput = {
  unit: DistanceUnit;
  value: Scalars['Float'];
};

export const enum DistanceUnit {
  Kilometers = 'KILOMETERS',
  Miles = 'MILES'
};

export type Distribution = {
  __typename?: 'Distribution';
  headerLogo?: Maybe<File | Image | Model3D>;
  id: Scalars['ID'];
  loadingLogo?: Maybe<File | Image | Model3D>;
  primaryColor?: Maybe<Scalars['String']>;
  /** UUID */
  status: DistributionStatus;
};

export const enum DistributionStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED'
};

export type DropdownDataField = DataField & {
  __typename?: 'DropdownDataField';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  key: Scalars['String'];
  logicalName?: Maybe<DataFieldLogicalName>;
  maxAllowed?: Maybe<Scalars['Int']>;
  mlsField?: Maybe<MlsField>;
  multiSelect: Scalars['Boolean'];
  name: Scalars['String'];
  options: Array<DropdownFieldOption>;
  processed?: Maybe<Scalars['Boolean']>;
  type: DataFieldType;
  typeName: Scalars['String'];
};

export type DropdownDataFormInputInstance = DataFormInputInstance & {
  __typename?: 'DropdownDataFormInputInstance';
  currentValue?: Maybe<Scalars['String']>;
  definition: DataFormInputDefinition;
  editable: Scalars['Boolean'];
  id: Scalars['ID'];
  selectedOptions?: Maybe<Array<DropdownFieldOption>>;
};

export type DropdownDataViewFieldInstance = DataViewFieldInstance & {
  __typename?: 'DropdownDataViewFieldInstance';
  definition: DataViewFieldDefinition;
  selectedOptions?: Maybe<Array<DropdownFieldOption>>;
  value?: Maybe<Scalars['String']>;
};

export type DropdownFieldOption = {
  __typename?: 'DropdownFieldOption';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  key: Scalars['String'];
  name: Scalars['String'];
  status?: Maybe<DropdownFieldOptionStatus>;
};

export type DropdownFieldOptionInput = {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export const enum DropdownFieldOptionStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
};

export type EmailDataField = DataField & {
  __typename?: 'EmailDataField';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  key: Scalars['String'];
  logicalName?: Maybe<DataFieldLogicalName>;
  mlsField?: Maybe<MlsField>;
  name: Scalars['String'];
  processed?: Maybe<Scalars['Boolean']>;
  type: DataFieldType;
  typeName: Scalars['String'];
};

export type EmailDataFormInputInstance = DataFormInputInstance & {
  __typename?: 'EmailDataFormInputInstance';
  currentValue?: Maybe<Scalars['String']>;
  definition: DataFormInputDefinition;
  editable: Scalars['Boolean'];
  id: Scalars['ID'];
};

export type EmailDataViewFieldInstance = DataViewFieldInstance & {
  __typename?: 'EmailDataViewFieldInstance';
  definition: DataViewFieldDefinition;
  value?: Maybe<Scalars['String']>;
};

export type EnsureAddressPlaceConsistencyInput = {
  cityIds?: InputMaybe<Array<Scalars['ID']>>;
  metroAreaIds?: InputMaybe<Array<Scalars['ID']>>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type EntitiesMetadata = {
  __typename?: 'EntitiesMetadata';
  activity: ActivityMetadata;
  dataField: DataFieldMetadata;
  dataFormDefinition: DataFormDefinitionMetadata;
  dataFormInputDefinition: DataFormInputDefinitionMetadata;
  dataViewDefinition: DataViewDefinitionMetadata;
  furnitureProduct: FurnitureProductMetadata;
  opportunityMilestone: OpportunityMilestoneMetadata;
  productInventory: ProductInventoryMetadata;
  propertyListingOpportunity: PropertyListingOpportunityMetadata;
  propertyListingOpportunityInquiry: PropertyListingOpportunityInquiryMetadata;
  residentialPropertyRoom: ResidentialPropertyRoomMetadata;
  supportedPropertyListingType: SupportedPropertyListingTypeMetadata;
  tag: TagMetadata;
  tagCollection: TagCollectionMetadata;
  taskInstance: TaskInstanceMetadata;
  user: UserMetadata;
};

export type Entity = {
  id: Scalars['ID'];
};

export type EntityCollectionPropertyListingIterationChange = PropertyListingIterationChange & {
  __typename?: 'EntityCollectionPropertyListingIterationChange';
  addedEntities?: Maybe<Array<Address | AdminUser | AgentUser | ConsumerUser | ContactUser | DataFormInstance | DataFormStepInstance | Image | PropertyComparativeMarketAnalysis | PropertyListing | PropertyListingIteration | ResidentialPropertyRoom>>;
  field: Scalars['String'];
  removedEntities?: Maybe<Array<Address | AdminUser | AgentUser | ConsumerUser | ContactUser | DataFormInstance | DataFormStepInstance | Image | PropertyComparativeMarketAnalysis | PropertyListing | PropertyListingIteration | ResidentialPropertyRoom>>;
};

export type EntityInput = {
  id: Scalars['ID'];
  type: EntityType;
};

export type EntityPropertyListingIterationChange = PropertyListingIterationChange & {
  __typename?: 'EntityPropertyListingIterationChange';
  addedEntity?: Maybe<Address | AdminUser | AgentUser | ConsumerUser | ContactUser | DataFormInstance | DataFormStepInstance | Image | PropertyComparativeMarketAnalysis | PropertyListing | PropertyListingIteration | ResidentialPropertyRoom>;
  field: Scalars['String'];
  removedEntity?: Maybe<Address | AdminUser | AgentUser | ConsumerUser | ContactUser | DataFormInstance | DataFormStepInstance | Image | PropertyComparativeMarketAnalysis | PropertyListing | PropertyListingIteration | ResidentialPropertyRoom>;
};

export const enum EntityType {
  CompanyLocation = 'COMPANY_LOCATION',
  Contact = 'CONTACT',
  DataFormStepInstance = 'DATA_FORM_STEP_INSTANCE',
  FurnitureProduct = 'FURNITURE_PRODUCT',
  FurnitureSupplierCompany = 'FURNITURE_SUPPLIER_COMPANY',
  FurnitureVendorCompany = 'FURNITURE_VENDOR_COMPANY',
  PropertyComparativeMarketAnalysis = 'PROPERTY_COMPARATIVE_MARKET_ANALYSIS',
  SpaceMaterial = 'SPACE_MATERIAL',
  User = 'USER'
};

export type EnumValueMetadata = {
  __typename?: 'EnumValueMetadata';
  id: Scalars['String'];
  name: Scalars['String'];
};

export const enum Exposure {
  East = 'EAST',
  North = 'NORTH',
  Northeast = 'NORTHEAST',
  Northwest = 'NORTHWEST',
  South = 'SOUTH',
  Southeast = 'SOUTHEAST',
  Southwest = 'SOUTHWEST',
  West = 'WEST'
};

export const enum ExteriorFeature {
  /** <https://ddwiki.reso.org/display/DDW17/ExteriorFeatures+Lookups> */
  Awning = 'AWNING',
  Balcony = 'BALCONY',
  Barbecue = 'BARBECUE',
  BasketballCourt = 'BASKETBALL_COURT',
  BoatSlip = 'BOAT_SLIP',
  BuiltInBarbecue = 'BUILT_IN_BARBECUE',
  Courtyard = 'COURTYARD',
  CoveredCourtyard = 'COVERED_COURTYARD',
  Dock = 'DOCK',
  DogRun = 'DOG_RUN',
  ElectricGrill = 'ELECTRIC_GRILL',
  FirePit = 'FIRE_PIT',
  Garden = 'GARDEN',
  GasGrill = 'GAS_GRILL',
  GrayWaterSystem = 'GRAY_WATER_SYSTEM',
  Kennel = 'KENNEL',
  Lighting = 'LIGHTING',
  MistingSystem = 'MISTING_SYSTEM',
  None = 'NONE',
  OutdoorGrill = 'OUTDOOR_GRILL',
  OutdoorKitchen = 'OUTDOOR_KITCHEN',
  OutdoorShower = 'OUTDOOR_SHOWER',
  PermeablePaving = 'PERMEABLE_PAVING',
  Playground = 'PLAYGROUND',
  PrivateEntrance = 'PRIVATE_ENTRANCE',
  PrivateYard = 'PRIVATE_YARD',
  RainBarrelOrCistern = 'RAIN_BARREL_OR_CISTERN',
  RainGutters = 'RAIN_GUTTERS',
  RvHookup = 'RV_HOOKUP',
  Storage = 'STORAGE',
  TennisCourt = 'TENNIS_COURT',
  UncoveredCourtyard = 'UNCOVERED_COURTYARD'
};

export const enum ExteriorFinish {
  AluminumSiding = 'ALUMINUM_SIDING',
  Brick = 'BRICK',
  BrickFront = 'BRICK_FRONT',
  Concrete = 'CONCRETE',
  Insulbrick = 'INSULBRICK',
  Log = 'LOG',
  MetalSteelSiding = 'METAL_STEEL_SIDING',
  Stone = 'STONE',
  Stucco = 'STUCCO',
  VinylSiding = 'VINYL_SIDING',
  Wood = 'WOOD'
};

export const enum ExteriorType {
  AluminumSiding = 'ALUMINUM_SIDING',
  Brick = 'BRICK',
  BrickFront = 'BRICK_FRONT',
  Composite = 'COMPOSITE',
  Concrete = 'CONCRETE',
  Insulbrick = 'INSULBRICK',
  Log = 'LOG',
  Metal = 'METAL',
  MetalSteel = 'METAL_STEEL',
  Other = 'OTHER',
  Stone = 'STONE',
  Stucco = 'STUCCO',
  Vinyl = 'VINYL',
  VinylSiding = 'VINYL_SIDING',
  Wood = 'WOOD'
};

export type Facet = {
  count: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  selected: Scalars['Boolean'];
};

export type FacetCurrentValue = {
  __typename?: 'FacetCurrentValue';
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type FacetGroup = {
  __typename?: 'FacetGroup';
  allowMultiple: Scalars['Boolean'];
  facets: Array<BasicCountFacet | ResidentialListingTagFacet>;
  name: Scalars['String'];
};

export const enum FeeInclusion {
  AirConditioning = 'AIR_CONDITIONING',
  Amenities = 'AMENITIES',
  BuildingInsurance = 'BUILDING_INSURANCE',
  CableTv = 'CABLE_TV',
  CommonElements = 'COMMON_ELEMENTS',
  CondoTax = 'CONDO_TAX',
  GarbageRemoval = 'GARBAGE_REMOVAL',
  Heat = 'HEAT',
  Hydro = 'HYDRO',
  Insurance = 'INSURANCE',
  Internet = 'INTERNET',
  Janitorial = 'JANITORIAL',
  Landscaping = 'LANDSCAPING',
  MaintenanceRepair = 'MAINTENANCE_REPAIR',
  ManagementFee = 'MANAGEMENT_FEE',
  Parking = 'PARKING',
  RecreationalFacilities = 'RECREATIONAL_FACILITIES',
  SnowRemoval = 'SNOW_REMOVAL',
  Storage = 'STORAGE',
  Water = 'WATER'
};

export type File = Media & {
  __typename?: 'File';
  contentType: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** @deprecated No longer supported */
  mediaAsset?: Maybe<MediaAsset>;
  originUrl: Scalars['String'];
  originalFileName?: Maybe<Scalars['String']>;
  sequence: Scalars['Int'];
  sizeBytes: Scalars['Long'];
  status: MediaStatus;
  statusName: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};

export type FileDataField = DataField & {
  __typename?: 'FileDataField';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  key: Scalars['String'];
  logicalName?: Maybe<DataFieldLogicalName>;
  mlsField?: Maybe<MlsField>;
  name: Scalars['String'];
  processed?: Maybe<Scalars['Boolean']>;
  type: DataFieldType;
  typeName: Scalars['String'];
  useCase?: Maybe<SetMediaUseCase>;
};

export type FileDataFormInputInstance = DataFormInputInstance & {
  __typename?: 'FileDataFormInputInstance';
  currentValue?: Maybe<Scalars['String']>;
  definition: DataFormInputDefinition;
  editable: Scalars['Boolean'];
  file?: Maybe<File | Image | Model3D>;
  id: Scalars['ID'];
};

export type FileDataViewFieldInstance = DataViewFieldInstance & {
  __typename?: 'FileDataViewFieldInstance';
  definition: DataViewFieldDefinition;
  file?: Maybe<File | Image | Model3D>;
  value?: Maybe<Scalars['String']>;
};

export const enum FileType {
  Jpeg = 'JPEG',
  Png = 'PNG'
};

export type FileUploadTaskDefinition = TaskDefinition & {
  __typename?: 'FileUploadTaskDefinition';
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Icon>;
  id: Scalars['ID'];
  logicalName?: Maybe<Scalars['String']>;
  maxNumberOfFiles?: Maybe<Scalars['Int']>;
  minNumberOfFiles?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  projectDefinition: ProjectDefinition;
  scriptOnComplete?: Maybe<Scalars['String']>;
  scriptOnCreate?: Maybe<Scalars['String']>;
  scriptOnStart?: Maybe<Scalars['String']>;
  scriptValidateBeforeComplete?: Maybe<Scalars['String']>;
  scriptValidateBeforeCreate?: Maybe<Scalars['String']>;
  scriptValidateBeforeStart?: Maybe<Scalars['String']>;
  supportedFileTypes?: Maybe<Array<FileType>>;
  type: TaskType;
  typeName: Scalars['String'];
  uiOnComplete?: Maybe<Array<Scalars['String']>>;
  uiOnCreate?: Maybe<Array<Scalars['String']>>;
  uiOnStart?: Maybe<Array<Scalars['String']>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FileUploadTaskInstance = AbstractTaskInstance & ActivityObject & {
  __typename?: 'FileUploadTaskInstance';
  activityFeed?: Maybe<ActivityFeed>;
  assignedBy?: Maybe<AdminUser | AgentUser | ConsumerUser | ContactUser>;
  assignedUser?: Maybe<AdminUser | AgentUser | ConsumerUser | ContactUser>;
  assignedUserStatus: TaskAssigneeStatus;
  assignedUserStatusName: Scalars['String'];
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  definition: ApproveSubmissionToMlsTaskDefinition | AttachFileStorageTaskDefinition | BasicTaskDefinition | FileUploadTaskDefinition | FormAssignTaskDefinition | FormFillTaskDefinition | FormReviewTaskDefinition | SubmitListingToMlsTaskDefinition | UploadToFileStorageTaskDefinition;
  description?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  following: Scalars['Boolean'];
  id: Scalars['ID'];
  media?: Maybe<Array<File | Image | Model3D>>;
  name: Scalars['String'];
  objectType: ActivityObjectType;
  project: ProjectInstance;
  requestedChanges?: Maybe<Scalars['String']>;
  reviewer?: Maybe<AdminUser | AgentUser | ConsumerUser | ContactUser>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status: TaskInstanceStatus;
  statusName: Scalars['String'];
  type: TaskType;
  typeName: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type FileUploadTaskInstanceActivityFeedArgs = {
  containingActivityId?: InputMaybe<Scalars['ID']>;
  first?: Scalars['Int'];
  offset?: Scalars['Int'];
};


export type FileUploadTaskInstanceMediaArgs = {
  first?: InputMaybe<Scalars['Int']>;
  statuses?: InputMaybe<Array<MediaStatus>>;
};

export type FillFormInput = {
  actionDefinitionId?: InputMaybe<Scalars['ID']>;
  inputs: Array<DataFormInputValueInput>;
  /** If executed via action, project instance and action definition is required */
  projectInstanceId?: InputMaybe<Scalars['ID']>;
  /** If executed via task instance, task instance is required */
  taskInstanceId?: InputMaybe<Scalars['ID']>;
};

export type FilterInput = {
  id: Scalars['ID'];
  value: Scalars['String'];
};

export const enum FireplaceType {
  Electric = 'ELECTRIC',
  Gas = 'GAS',
  Oil = 'OIL',
  Pellet = 'PELLET',
  Propane = 'PROPANE',
  Wood = 'WOOD'
};

export type Fireplaces = {
  __typename?: 'Fireplaces';
  aboveGrade?: Maybe<Scalars['Int']>;
  belowGrade?: Maybe<Scalars['Int']>;
  fireplaceTypes?: Maybe<Array<FireplaceType>>;
};

export type FloatRange = {
  __typename?: 'FloatRange';
  gte?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
};

export type FloatRangeFilter = ConnectionFilter & {
  __typename?: 'FloatRangeFilter';
  availableRange?: Maybe<FloatRange>;
  currentValue?: Maybe<FloatRange>;
  id?: Maybe<Scalars['String']>;
  increment?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<ConnectionFilterType>;
  typeName?: Maybe<Scalars['String']>;
};

export const enum FlooringType {
  Carpet = 'CARPET',
  Ceramic = 'CERAMIC',
  Concrete = 'CONCRETE',
  Cork = 'CORK',
  Granite = 'GRANITE',
  Hardwood = 'HARDWOOD',
  Laminate = 'LAMINATE',
  Linoleum = 'LINOLEUM',
  Marble = 'MARBLE',
  Mixed = 'MIXED',
  Other = 'OTHER',
  Parquet = 'PARQUET',
  Softwood = 'SOFTWOOD',
  Stone = 'STONE',
  Tile = 'TILE',
  Vinyl = 'VINYL',
  Wood = 'WOOD'
};

export type FormAssignTaskDefinition = TaskDefinition & {
  __typename?: 'FormAssignTaskDefinition';
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Icon>;
  id: Scalars['ID'];
  logicalName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  projectDefinition: ProjectDefinition;
  scriptOnComplete?: Maybe<Scalars['String']>;
  scriptOnCreate?: Maybe<Scalars['String']>;
  scriptOnStart?: Maybe<Scalars['String']>;
  scriptValidateBeforeComplete?: Maybe<Scalars['String']>;
  scriptValidateBeforeCreate?: Maybe<Scalars['String']>;
  scriptValidateBeforeStart?: Maybe<Scalars['String']>;
  type: TaskType;
  typeName: Scalars['String'];
  uiOnComplete?: Maybe<Array<Scalars['String']>>;
  uiOnCreate?: Maybe<Array<Scalars['String']>>;
  uiOnStart?: Maybe<Array<Scalars['String']>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FormFillTaskDefinition = TaskDefinition & {
  __typename?: 'FormFillTaskDefinition';
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Icon>;
  id: Scalars['ID'];
  logicalName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  projectDefinition: ProjectDefinition;
  scriptOnComplete?: Maybe<Scalars['String']>;
  scriptOnCreate?: Maybe<Scalars['String']>;
  scriptOnStart?: Maybe<Scalars['String']>;
  scriptValidateBeforeComplete?: Maybe<Scalars['String']>;
  scriptValidateBeforeCreate?: Maybe<Scalars['String']>;
  scriptValidateBeforeStart?: Maybe<Scalars['String']>;
  type: TaskType;
  typeName: Scalars['String'];
  uiOnComplete?: Maybe<Array<Scalars['String']>>;
  uiOnCreate?: Maybe<Array<Scalars['String']>>;
  uiOnStart?: Maybe<Array<Scalars['String']>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FormFillTaskInstance = AbstractTaskInstance & ActivityObject & {
  __typename?: 'FormFillTaskInstance';
  activityFeed?: Maybe<ActivityFeed>;
  assignedBy?: Maybe<AdminUser | AgentUser | ConsumerUser | ContactUser>;
  /** Filtered form containing only steps assigned on the user */
  assignedFormInstance: DataFormInstance;
  assignedUser?: Maybe<AdminUser | AgentUser | ConsumerUser | ContactUser>;
  assignedUserStatus: TaskAssigneeStatus;
  assignedUserStatusName: Scalars['String'];
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  definition: ApproveSubmissionToMlsTaskDefinition | AttachFileStorageTaskDefinition | BasicTaskDefinition | FileUploadTaskDefinition | FormAssignTaskDefinition | FormFillTaskDefinition | FormReviewTaskDefinition | SubmitListingToMlsTaskDefinition | UploadToFileStorageTaskDefinition;
  description?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  following: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
  objectType: ActivityObjectType;
  project: ProjectInstance;
  requestedChanges?: Maybe<Scalars['String']>;
  reviewer?: Maybe<AdminUser | AgentUser | ConsumerUser | ContactUser>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status: TaskInstanceStatus;
  statusName: Scalars['String'];
  type: TaskType;
  typeName: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type FormFillTaskInstanceActivityFeedArgs = {
  containingActivityId?: InputMaybe<Scalars['ID']>;
  first?: Scalars['Int'];
  offset?: Scalars['Int'];
};


export type FormFillTaskInstanceAssignedFormInstanceArgs = {
  input?: InputMaybe<DataFormInstanceFieldInput>;
};

export type FormReviewTaskDefinition = TaskDefinition & {
  __typename?: 'FormReviewTaskDefinition';
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Icon>;
  id: Scalars['ID'];
  logicalName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  projectDefinition: ProjectDefinition;
  scriptOnComplete?: Maybe<Scalars['String']>;
  scriptOnCreate?: Maybe<Scalars['String']>;
  scriptOnStart?: Maybe<Scalars['String']>;
  scriptValidateBeforeComplete?: Maybe<Scalars['String']>;
  scriptValidateBeforeCreate?: Maybe<Scalars['String']>;
  scriptValidateBeforeStart?: Maybe<Scalars['String']>;
  type: TaskType;
  typeName: Scalars['String'];
  uiOnComplete?: Maybe<Array<Scalars['String']>>;
  uiOnCreate?: Maybe<Array<Scalars['String']>>;
  uiOnStart?: Maybe<Array<Scalars['String']>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export const enum FoundationType {
  Block = 'BLOCK',
  Brick = 'BRICK',
  Concrete = 'CONCRETE',
  None = 'NONE',
  Other = 'OTHER',
  PouredConcrete = 'POURED_CONCRETE',
  Rubble = 'RUBBLE',
  Slab = 'SLAB',
  Stone = 'STONE',
  Wood = 'WOOD'
};

export const enum FurnitureCategory {
  Beds = 'BEDS',
  CoffeeTablesAndOttomans = 'COFFEE_TABLES_AND_OTTOMANS',
  DecorItems = 'DECOR_ITEMS',
  DiningTablesAndChairs = 'DINING_TABLES_AND_CHAIRS',
  Doors = 'DOORS',
  Dressers = 'DRESSERS',
  MediaCenters = 'MEDIA_CENTERS',
  Rugs = 'RUGS',
  SideEndTables = 'SIDE_END_TABLES',
  SofasAndChairs = 'SOFAS_AND_CHAIRS',
  Windows = 'WINDOWS'
};

export type FurnitureGeneralInquiry = Inquiry & {
  __typename?: 'FurnitureGeneralInquiry';
  chimeExportedAt?: Maybe<Scalars['DateTime']>;
  company: Scalars['String'];
  createdAt: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  fubExportedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  lastName: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  /** @deprecated Please use 'firstName' and 'lastName' */
  name?: Maybe<Scalars['String']>;
  newsletter: Scalars['Boolean'];
  phoneNumber?: Maybe<Scalars['String']>;
  status: InquiryStatus;
  statusName: Scalars['String'];
  type: InquiryType;
  typeName: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<AdminUser | AgentUser | ConsumerUser | ContactUser>;
  website: Scalars['String'];
};

export type FurnitureProduct = Product & TagsOwner & {
  __typename?: 'FurnitureProduct';
  category?: Maybe<FurnitureCategory>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  ecommerceUrl?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  inventories: Array<ProductInventory>;
  length?: Maybe<Scalars['Float']>;
  media?: Maybe<Array<File | Image | Model3D>>;
  model?: Maybe<Model3D>;
  modelARIos?: Maybe<Model3D>;
  modelLowRes?: Maybe<Model3D>;
  /** Ultra High Definition (UHD) 3D model */
  modelUhd?: Maybe<Model3D>;
  /** Unbaked UHD 3D Model */
  modelUhdUnbaked?: Maybe<Model3D>;
  positioning?: Maybe<ProductPositioning>;
  positioningName?: Maybe<Scalars['String']>;
  /**
   * Deprecated in favour of ProductInventory.price
   * @deprecated No longer supported
   */
  price?: Maybe<MonetaryAmount>;
  primaryImage?: Maybe<Image>;
  sku?: Maybe<Scalars['String']>;
  status: ProductStatus;
  statusName: Scalars['String'];
  /**
   * Deprecated in favour of 'vendorCompany'
   * @deprecated No longer supported
   */
  storeName?: Maybe<Scalars['String']>;
  supplier?: Maybe<FurnitureSupplierCompany | FurnitureVendorCompany>;
  tagEntityType: TagEntityType;
  tags: Array<Tag>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  useCubemap: Scalars['Boolean'];
  vendor?: Maybe<FurnitureSupplierCompany | FurnitureVendorCompany>;
  width?: Maybe<Scalars['Float']>;
};


export type FurnitureProductHeightArgs = {
  unit?: InputMaybe<DimensionUnit>;
};


export type FurnitureProductLengthArgs = {
  unit?: InputMaybe<DimensionUnit>;
};


export type FurnitureProductMediaArgs = {
  first?: InputMaybe<Scalars['Int']>;
  statuses?: InputMaybe<Array<MediaStatus>>;
};


export type FurnitureProductWidthArgs = {
  unit?: InputMaybe<DimensionUnit>;
};

export type FurnitureProductMetadata = {
  __typename?: 'FurnitureProductMetadata';
  positioning: Array<EnumValueMetadata>;
  status: Array<EnumValueMetadata>;
};

export type FurnitureSupplierCompany = Company & {
  __typename?: 'FurnitureSupplierCompany';
  createdAt: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  locations?: Maybe<Array<Maybe<CompanyLocation>>>;
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  type: CompanyType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  website?: Maybe<Scalars['String']>;
};

export type FurnitureVendorCompany = Company & {
  __typename?: 'FurnitureVendorCompany';
  createdAt: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  locations?: Maybe<Array<Maybe<CompanyLocation>>>;
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  type: CompanyType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  website?: Maybe<Scalars['String']>;
};

export type GeneralInquiry = Inquiry & {
  __typename?: 'GeneralInquiry';
  chimeExportedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  fubExportedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  lastName: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  /** @deprecated Please use 'firstName' and 'lastName' */
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  status: InquiryStatus;
  statusName: Scalars['String'];
  type: InquiryType;
  typeName: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<AdminUser | AgentUser | ConsumerUser | ContactUser>;
};

export type GeoBounds = {
  __typename?: 'GeoBounds';
  bottomRight: Coordinate;
  topLeft: Coordinate;
};

/** Filters data by a distance to a geo coordinate. Geo coordinate can be provided explicitly via `latitude` + `longitude`, or via address autocomplete result `addressAutocompleteResult`. */
export type GeoInput = {
  /** Should be left null if `latitude` + `longitude` is provided */
  addressAutocompleteResult?: InputMaybe<AddressAutocompleteResultInput>;
  distance: DistanceInput;
  /** Should be left null if `addressAutocompleteResult` is provided */
  latitude?: InputMaybe<Scalars['Float']>;
  /** Should be left null if `addressAutocompleteResult` is provided */
  longitude?: InputMaybe<Scalars['Float']>;
};

export type GeoSearchInput = {
  addressAutocompleteResult?: InputMaybe<AddressAutocompleteResultInput>;
  /** Full street address recommended */
  first?: InputMaybe<Scalars['Int']>;
  keyword?: InputMaybe<Scalars['String']>;
};

export type GeoShape = {
  coordinates: Array<CoordinateInput>;
  relation?: InputMaybe<GeoShapeRelation>;
};

export const enum GeoShapeRelation {
  Contains = 'CONTAINS',
  Disjoint = 'DISJOINT',
  Intersects = 'INTERSECTS',
  Within = 'WITHIN'
};

export type GeocodedAddress = {
  __typename?: 'GeocodedAddress';
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  country: Scalars['String'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  metroArea?: Maybe<Scalars['String']>;
  region: Scalars['String'];
  streetName: Scalars['String'];
  streetNumber: Scalars['String'];
  unitNumber?: Maybe<Scalars['String']>;
  zipCode: Scalars['String'];
};

export const enum GraphQlErrorType {
  EntityNotFound = 'ENTITY_NOT_FOUND',
  InvalidFieldValue = 'INVALID_FIELD_VALUE',
  UserUnauthorized = 'USER_UNAUTHORIZED'
};

export const enum HeatingType {
  /** <https://ddwiki.reso.org/display/DDW17/Heating+Lookups> */
  Baseboard = 'BASEBOARD',
  Boiler = 'BOILER',
  Ceiling = 'CEILING',
  Central = 'CENTRAL',
  Coal = 'COAL',
  Ductless = 'DUCTLESS',
  ElectricEnergyStarQualifiedEquipment = 'ELECTRIC_ENERGY_STAR_QUALIFIED_EQUIPMENT',
  ExhaustFan = 'EXHAUST_FAN',
  Fireplace = 'FIREPLACE',
  FireplaceInsert = 'FIREPLACE_INSERT',
  FloorFurnace = 'FLOOR_FURNACE',
  ForcedAir = 'FORCED_AIR',
  Furnace = 'FURNACE',
  Geothermal = 'GEOTHERMAL',
  HeatPump = 'HEAT_PUMP',
  HotWater = 'HOT_WATER',
  HumidityControl = 'HUMIDITY_CONTROL',
  NaturalGas = 'NATURAL_GAS',
  None = 'NONE',
  NoHeat = 'NO_HEAT',
  Oil = 'OIL',
  Other = 'OTHER',
  Propane = 'PROPANE',
  Radiant = 'RADIANT',
  Solar = 'SOLAR',
  SpaceHeater = 'SPACE_HEATER',
  VariesByUnit = 'VARIES_BY_UNIT',
  WallFurnace = 'WALL_FURNACE',
  WoodStove = 'WOOD_STOVE',
  Zoned = 'ZONED'
};

export type Histogram = {
  __typename?: 'Histogram';
  period?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Int']>;
};

export type HistogramGroup = {
  __typename?: 'HistogramGroup';
  entries?: Maybe<Array<Maybe<Histogram>>>;
  field: Scalars['String'];
  totalCount?: Maybe<Scalars['Int']>;
};

export type HistogramGroupInput = {
  aggregationInterval: AggregationInterval;
  fields: Array<Scalars['String']>;
  periodEnd: Scalars['DateTime'];
  periodStart: Scalars['DateTime'];
};

export type HouseLot = {
  __typename?: 'HouseLot';
  acres?: Maybe<Scalars['Float']>;
  depthDimension?: Maybe<Dimension>;
  frontExposure?: Maybe<Exposure>;
  frontExposureName?: Maybe<Scalars['String']>;
  frontageDimension?: Maybe<Dimension>;
  irregularLot?: Maybe<Scalars['Boolean']>;
};

export type HouseRoomCount = {
  __typename?: 'HouseRoomCount';
  aboveGrade?: Maybe<Scalars['Int']>;
  belowGrade?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
};

export const enum HouseStyle {
  Bungaloft = 'BUNGALOFT',
  Bungalow = 'BUNGALOW',
  Detached = 'DETACHED',
  Duplex = 'DUPLEX',
  EndUnit = 'END_UNIT',
  Fourplex = 'FOURPLEX',
  HiRanch = 'HI_RANCH',
  Link = 'LINK',
  Mobile = 'MOBILE',
  OneHalfStorey = 'ONE_HALF_STOREY',
  RowUnit = 'ROW_UNIT',
  SemiDetached = 'SEMI_DETACHED',
  SplitLevel = 'SPLIT_LEVEL',
  Stack = 'STACK',
  ThreeStorey = 'THREE_STOREY',
  Triplex = 'TRIPLEX',
  TwoHalfStorey = 'TWO_HALF_STOREY',
  TwoStorey = 'TWO_STOREY'
};

export const enum Icon {
  FaBadgePercent = 'FA_BADGE_PERCENT',
  FaBlockBrick = 'FA_BLOCK_BRICK',
  FaCalendarDay = 'FA_CALENDAR_DAY',
  FaCircleInfo = 'FA_CIRCLE_INFO',
  FaComment = 'FA_COMMENT',
  FaHouse = 'FA_HOUSE',
  FaHouseChimneyUser = 'FA_HOUSE_CHIMNEY_USER',
  FaImages = 'FA_IMAGES',
  FaLightbulb = 'FA_LIGHTBULB',
  FaLink = 'FA_LINK',
  FaLocationDot = 'FA_LOCATION_DOT',
  FaRulerCombined = 'FA_RULER_COMBINED',
  FaRulerTriangle = 'FA_RULER_TRIANGLE',
  FaSignHanging = 'FA_SIGN_HANGING',
  FaTag = 'FA_TAG',
  FaUser = 'FA_USER'
};

export type Image = Entity & Media & {
  __typename?: 'Image';
  contentType: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** @deprecated No longer supported */
  mediaAsset?: Maybe<MediaAsset>;
  originUrl: Scalars['String'];
  originalFileName?: Maybe<Scalars['String']>;
  sequence: Scalars['Int'];
  sizeBytes: Scalars['Long'];
  status: MediaStatus;
  statusName: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};

export type ImageDataField = DataField & {
  __typename?: 'ImageDataField';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  key: Scalars['String'];
  logicalName?: Maybe<DataFieldLogicalName>;
  minimumImagesRequired: Scalars['Int'];
  mlsField?: Maybe<MlsField>;
  multiSelect: Scalars['Boolean'];
  name: Scalars['String'];
  processed?: Maybe<Scalars['Boolean']>;
  type: DataFieldType;
  typeName: Scalars['String'];
  useCase?: Maybe<SetMediaUseCase>;
};

export type ImageDataFormInputInstance = DataFormInputInstance & {
  __typename?: 'ImageDataFormInputInstance';
  /** Not used for this type. please use `images` field */
  currentValue?: Maybe<Scalars['String']>;
  definition: DataFormInputDefinition;
  editable: Scalars['Boolean'];
  id: Scalars['ID'];
  images?: Maybe<Array<Image>>;
};

export type ImageDataViewFieldInstance = DataViewFieldInstance & {
  __typename?: 'ImageDataViewFieldInstance';
  definition: DataViewFieldDefinition;
  images?: Maybe<Array<Image>>;
  /** Not used for this type. Please use `images` field */
  value?: Maybe<Scalars['String']>;
};

export type Inquiry = {
  chimeExportedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  fubExportedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  lastName: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  /** @deprecated Please use 'firstName' and 'lastName' */
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  status: InquiryStatus;
  statusName: Scalars['String'];
  type: InquiryType;
  typeName: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<AdminUser | AgentUser | ConsumerUser | ContactUser>;
};

export type InquiryConnection = {
  __typename?: 'InquiryConnection';
  edges?: Maybe<Array<Maybe<InquiryConnectionEdge>>>;
  facetGroups?: Maybe<Array<Maybe<FacetGroup>>>;
  filters?: Maybe<Array<Maybe<AggregatedFacetFilter | BooleanFilter | DateRangeFilter | FloatRangeFilter | IntegerRangeFilter | SimpleFacetFilter | TagCollectionFilter>>>;
  histogramGroups?: Maybe<Array<Maybe<HistogramGroup>>>;
  metricGroups?: Maybe<Array<Maybe<MetricGroup>>>;
  pageInfo?: Maybe<PageInfo>;
  savedSearches?: Maybe<Array<Maybe<SavedSearch>>>;
  sortOptions?: Maybe<Array<Maybe<SortOption>>>;
};


export type InquiryConnectionFacetGroupsArgs = {
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type InquiryConnectionFiltersArgs = {
  aggregatedFacetKeywordSearch?: InputMaybe<AggregatedFacetKeywordSearchInput>;
  fetchAggregatedFacetsFilterIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  includeTagFilters?: InputMaybe<Scalars['Boolean']>;
  selected?: InputMaybe<Scalars['Boolean']>;
};


export type InquiryConnectionHistogramGroupsArgs = {
  histogramGroupInput?: InputMaybe<HistogramGroupInput>;
};


export type InquiryConnectionMetricGroupsArgs = {
  metricInput?: InputMaybe<Array<InputMaybe<MetricGroupInput>>>;
};

export type InquiryConnectionEdge = {
  __typename?: 'InquiryConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<FurnitureGeneralInquiry | GeneralInquiry | PropertyListingOpportunityInquiry | ResidentialAppraisalInquiry | ResidentialListingGeneralInquiry | ResidentialListingOpenHouseInquiry | ResidentialListingPrivateShowingInquiry>;
};

export type InquiryConnectionInput = {
  filters?: InputMaybe<Array<FilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  keyword?: InputMaybe<Scalars['String']>;
  listingIds?: InputMaybe<Array<Scalars['ID']>>;
  offset?: InputMaybe<Scalars['Int']>;
  savedSearchId?: InputMaybe<Scalars['ID']>;
  sortKey?: InputMaybe<InquirySortKey>;
  sortOptionId?: InputMaybe<Scalars['ID']>;
  sortOrder?: InputMaybe<SortOrder>;
  status?: InputMaybe<InquiryStatus>;
  tagIds?: InputMaybe<Array<Scalars['ID']>>;
  type?: InputMaybe<InquiryType>;
  userIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type InquiryCreatedActivity = Activity & {
  __typename?: 'InquiryCreatedActivity';
  actor?: Maybe<AdminUser | AgentUser | ConsumerUser | ContactUser>;
  comments?: Maybe<Array<ActivityComment>>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  inquiry: FurnitureGeneralInquiry | GeneralInquiry | PropertyListingOpportunityInquiry | ResidentialAppraisalInquiry | ResidentialListingGeneralInquiry | ResidentialListingOpenHouseInquiry | ResidentialListingPrivateShowingInquiry;
  /** Whether the thread id fully read or there are any unread comments */
  isRead: Scalars['Boolean'];
  /** Whether the thread is resolved */
  isResolved?: Maybe<Scalars['Boolean']>;
  media?: Maybe<Array<File | Image | Model3D>>;
  message?: Maybe<Scalars['String']>;
  object?: Maybe<AdminUser | AgentUser | ApproveSubmissionToMlsTaskInstance | BasicTaskInstance | ConsumerUser | ContactUser | FileUploadTaskInstance | FormFillTaskInstance | ProjectInstance | PropertyListing | PropertyListingOpportunity | SubmitListingToMlsTaskInstance>;
  /** Timestamp of the last time the thread was read by the current user. False if the current user is not a thread participant */
  readAt?: Maybe<Scalars['DateTime']>;
  relatedEntity?: Maybe<Address | AdminUser | AgentUser | ConsumerUser | ContactUser | DataFormInstance | DataFormStepInstance | Image | PropertyComparativeMarketAnalysis | PropertyListing | PropertyListingIteration | ResidentialPropertyRoom>;
  status: ActivityStatus;
  subType?: Maybe<ActivitySubType>;
  subTypeName?: Maybe<Scalars['String']>;
  type: ActivityType;
  typeName: Scalars['String'];
  verb: Scalars['String'];
  visibility: ActivityVisibility;
  visibilityName: Scalars['String'];
};


export type InquiryCreatedActivityCommentsArgs = {
  latest?: InputMaybe<Scalars['Int']>;
  statuses?: InputMaybe<Array<ActivityCommentStatus>>;
};


export type InquiryCreatedActivityMediaArgs = {
  first?: InputMaybe<Scalars['Int']>;
  statuses?: InputMaybe<Array<MediaStatus>>;
};

export const enum InquirySortKey {
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT'
};

export const enum InquiryStatus {
  Assigned = 'ASSIGNED',
  Junk = 'JUNK',
  Pending = 'PENDING'
};

export const enum InquiryType {
  FurnitureGeneralInquiry = 'FURNITURE_GENERAL_INQUIRY',
  GeneralInquiry = 'GENERAL_INQUIRY',
  PropertyListingOpportunityInquiry = 'PROPERTY_LISTING_OPPORTUNITY_INQUIRY',
  ResidentialAppraisalInquiry = 'RESIDENTIAL_APPRAISAL_INQUIRY',
  ResidentialListingGeneralInquiry = 'RESIDENTIAL_LISTING_GENERAL_INQUIRY',
  ResidentialListingOpenHouseInquiry = 'RESIDENTIAL_LISTING_OPEN_HOUSE_INQUIRY',
  ResidentialListingShowingInquiry = 'RESIDENTIAL_LISTING_SHOWING_INQUIRY'
};

export type IntegerRange = {
  __typename?: 'IntegerRange';
  gte?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
};

export type IntegerRangeFilter = ConnectionFilter & {
  __typename?: 'IntegerRangeFilter';
  availableRange?: Maybe<IntegerRange>;
  currentValue?: Maybe<IntegerRange>;
  id?: Maybe<Scalars['String']>;
  increment?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  options?: Maybe<Array<IntegerRangeOption>>;
  type?: Maybe<ConnectionFilterType>;
  typeName?: Maybe<Scalars['String']>;
};

export type IntegerRangeOption = {
  __typename?: 'IntegerRangeOption';
  count?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  selected: Scalars['Boolean'];
  value: IntegerRange;
};

export const enum InteriorFinish {
  Carpet = 'CARPET',
  CeramicTile = 'CERAMIC_TILE',
  Concrete = 'CONCRETE',
  Granite = 'GRANITE',
  Hardwood = 'HARDWOOD',
  Laminate = 'LAMINATE',
  Linoleum = 'LINOLEUM',
  Marble = 'MARBLE',
  Parquet = 'PARQUET',
  Stone = 'STONE',
  Tile = 'TILE',
  Vinyl = 'VINYL'
};

export const enum InteriorHouseFeature {
  AlarmSystem = 'ALARM_SYSTEM',
  CentralVacuum = 'CENTRAL_VACUUM',
  Elevator = 'ELEVATOR',
  Furnished = 'FURNISHED',
  HomeTheatre = 'HOME_THEATRE',
  InLawSuite = 'IN_LAW_SUITE',
  Sauna = 'SAUNA',
  WetBar = 'WET_BAR',
  WhirlpoolBath = 'WHIRLPOOL_BATH',
  WindowBlinds = 'WINDOW_BLINDS'
};

export type InviteOpportunityCustomerInput = {
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  opportunityId: Scalars['ID'];
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type InviteProjectCollaboratorInput = {
  /** Required */
  email?: InputMaybe<Scalars['String']>;
  /** Required */
  firstName?: InputMaybe<Scalars['String']>;
  /** Required */
  lastName?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  /** Required */
  phoneNumber?: InputMaybe<Scalars['String']>;
  projectInstanceId: Scalars['ID'];
  /** Required */
  roleId?: InputMaybe<Scalars['ID']>;
};

export type InviteTaskAssigneeInput = {
  /** Whether to also add the user to the project collaborators */
  addToProject: Scalars['Boolean'];
  /** Required */
  email?: InputMaybe<Scalars['String']>;
  /** Required */
  firstName?: InputMaybe<Scalars['String']>;
  /** Required */
  lastName?: InputMaybe<Scalars['String']>;
  /** Required */
  phoneNumber?: InputMaybe<Scalars['String']>;
  /** Required if added to the project */
  projectRoleId?: InputMaybe<Scalars['ID']>;
  taskInstanceId: Scalars['ID'];
};

export type InviteUserInput = {
  email: Scalars['String'];
  userType: UserType;
};

export type KeyValuePair = {
  __typename?: 'KeyValuePair';
  key: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type LeadInfo = {
  __typename?: 'LeadInfo';
  activitiesCount?: Maybe<Scalars['Int']>;
  assignedAgentName?: Maybe<Scalars['String']>;
  crmId: Scalars['String'];
  lastActiveAt?: Maybe<Scalars['DateTime']>;
  pondName?: Maybe<Scalars['String']>;
  stage?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export const enum LeadSource {
  DirectMail = 'DIRECT_MAIL',
  EmailMarketing = 'EMAIL_MARKETING',
  EventsSeminars = 'EVENTS_SEMINARS',
  NotSure = 'NOT_SURE',
  OutdoorAdvertising = 'OUTDOOR_ADVERTISING',
  PrintMedia = 'PRINT_MEDIA',
  SearchEngine = 'SEARCH_ENGINE',
  SocialMedia = 'SOCIAL_MEDIA',
  TvRadioAds = 'TV_RADIO_ADS',
  WordOfMouth = 'WORD_OF_MOUTH'
};

export const enum Level {
  Basement = 'BASEMENT',
  Flat = 'FLAT',
  Forth = 'FORTH',
  Ground = 'GROUND',
  /** Below this is for split levels */
  InBetween = 'IN_BETWEEN',
  LowerLevel = 'LOWER_LEVEL',
  Main = 'MAIN',
  Other = 'OTHER',
  Second = 'SECOND',
  SubBasement = 'SUB_BASEMENT',
  Third = 'THIRD',
  UpperLevel = 'UPPER_LEVEL'
};

export const enum ListingAggregationMode {
  Size = 'SIZE',
  Type = 'TYPE'
};

export type ListingOpportunityStats = {
  __typename?: 'ListingOpportunityStats';
  inProgress?: Maybe<Scalars['Int']>;
  lost?: Maybe<Scalars['Int']>;
  toQualify?: Maybe<Scalars['Int']>;
  transacted?: Maybe<Scalars['Int']>;
};

export const enum ListingType {
  Assignment = 'ASSIGNMENT',
  ForLease = 'FOR_LEASE',
  ForSale = 'FOR_SALE',
  PreConstruction = 'PRE_CONSTRUCTION'
};

export const enum ListingVisibility {
  AddressOnly = 'ADDRESS_ONLY',
  FullListingDetails = 'FULL_LISTING_DETAILS',
  PreviewRequireSignIn = 'PREVIEW_REQUIRE_SIGN_IN'
};

export const enum LotFeature {
  /** <https://ddwiki.reso.org/display/DDW17/LotFeatures+Lookups> */
  Agricultural = 'AGRICULTURAL',
  BackYard = 'BACK_YARD',
  Bluff = 'BLUFF',
  CityLot = 'CITY_LOT',
  Cleared = 'CLEARED',
  CloseToClubhouse = 'CLOSE_TO_CLUBHOUSE',
  CornersMarked = 'CORNERS_MARKED',
  CornerLot = 'CORNER_LOT',
  CulDeSac = 'CUL_DE_SAC',
  DesertBack = 'DESERT_BACK',
  DesertFront = 'DESERT_FRONT',
  Farm = 'FARM',
  FewTrees = 'FEW_TREES',
  FlagLot = 'FLAG_LOT',
  FrontYard = 'FRONT_YARD',
  Garden = 'GARDEN',
  GentleSloping = 'GENTLE_SLOPING',
  Greenbelt = 'GREENBELT',
  InteriorLot = 'INTERIOR_LOT',
  IrregularLot = 'IRREGULAR_LOT',
  Landscaped = 'LANDSCAPED',
  Level = 'LEVEL',
  ManyTrees = 'MANY_TREES',
  Meadow = 'MEADOW',
  NativePlants = 'NATIVE_PLANTS',
  NearGolfCourse = 'NEAR_GOLF_COURSE',
  NearPublicTransit = 'NEAR_PUBLIC_TRANSIT',
  OnGolfCourse = 'ON_GOLF_COURSE',
  OpenLot = 'OPEN_LOT',
  Orchard = 'ORCHARD',
  Pasture = 'PASTURE',
  Paved = 'PAVED',
  PieShapedLot = 'PIE_SHAPED_LOT',
  Private = 'PRIVATE',
  RectangularLot = 'RECTANGULAR_LOT',
  RockOutcropping = 'ROCK_OUTCROPPING',
  RollingSlope = 'ROLLING_SLOPE',
  Secluded = 'SECLUDED',
  SeeRemarks = 'SEE_REMARKS',
  Sloped = 'SLOPED',
  SlopedDown = 'SLOPED_DOWN',
  SlopedUp = 'SLOPED_UP',
  SplitPossible = 'SPLIT_POSSIBLE',
  SprinklersInFront = 'SPRINKLERS_IN_FRONT',
  SprinklersInRear = 'SPRINKLERS_IN_REAR',
  SteepSlope = 'STEEP_SLOPE',
  Subdivided = 'SUBDIVIDED',
  Views = 'VIEWS',
  Waterfall = 'WATERFALL',
  Waterfront = 'WATERFRONT',
  Wetlands = 'WETLANDS',
  Wooded = 'WOODED',
  ZeroLotLine = 'ZERO_LOT_LINE'
};

export const enum MaintenanceFeeIncludes {
  AllUtilities = 'ALL_UTILITIES',
  CableTv = 'CABLE_TV',
  Electricity = 'ELECTRICITY',
  Gardener = 'GARDENER',
  Gas = 'GAS',
  Internet = 'INTERNET',
  Management = 'MANAGEMENT',
  None = 'NONE',
  Other = 'OTHER',
  SeeRemarks = 'SEE_REMARKS',
  Sewer = 'SEWER',
  TrashCollection = 'TRASH_COLLECTION',
  Water = 'WATER'
};

export type MapCluster = {
  __typename?: 'MapCluster';
  coordinate: Coordinate;
  count?: Maybe<Scalars['Long']>;
  expandable?: Maybe<Scalars['Boolean']>;
  geoBounds: GeoBounds;
  id: Scalars['ID'];
  /** @deprecated No longer supported */
  latitude: Scalars['Float'];
  listingsAvgCoordinate: Coordinate;
  /** @deprecated No longer supported */
  listingsAvgLatitude: Scalars['Float'];
  /** @deprecated No longer supported */
  listingsAvgLongitude: Scalars['Float'];
  /** @deprecated No longer supported */
  longitude: Scalars['Float'];
  medianListingPrice?: Maybe<Scalars['BigDecimal']>;
  medianSalePrice?: Maybe<Scalars['BigDecimal']>;
};

export type Media = {
  contentType: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** @deprecated No longer supported */
  mediaAsset?: Maybe<MediaAsset>;
  originUrl: Scalars['String'];
  originalFileName?: Maybe<Scalars['String']>;
  sequence: Scalars['Int'];
  sizeBytes: Scalars['Long'];
  status: MediaStatus;
  statusName: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};

export type MediaAsset = {
  __typename?: 'MediaAsset';
  id: Scalars['ID'];
  originUrl: Scalars['String'];
};

export const enum MediaOwner {
  Activity = 'ACTIVITY',
  ActivityComment = 'ACTIVITY_COMMENT',
  Building = 'BUILDING',
  Product = 'PRODUCT',
  PropertyListingIteration = 'PROPERTY_LISTING_ITERATION',
  ResidentialPropertyListing = 'RESIDENTIAL_PROPERTY_LISTING',
  Room = 'ROOM',
  TaskInstance = 'TASK_INSTANCE'
};

export const enum MediaStatus {
  Deleted = 'DELETED',
  Init = 'INIT',
  Ready = 'READY',
  UploadExpired = 'UPLOAD_EXPIRED',
  UploadInProgress = 'UPLOAD_IN_PROGRESS'
};

export type MediaWithUploadUrl = {
  __typename?: 'MediaWithUploadUrl';
  media: File | Image | Model3D;
  uploadUrl: Scalars['String'];
};

export type MetadataResponse = {
  __typename?: 'MetadataResponse';
  entities: EntitiesMetadata;
};

export type Metric = {
  __typename?: 'Metric';
  type: MetricType;
  typeName: Scalars['String'];
  value: Scalars['Float'];
};

export type MetricGroup = {
  __typename?: 'MetricGroup';
  field: Scalars['String'];
  metrics?: Maybe<Array<Metric>>;
};

export type MetricGroupInput = {
  field: Scalars['String'];
  type: Array<MetricType>;
};

export const enum MetricType {
  Avg = 'AVG',
  Max = 'MAX',
  Median = 'MEDIAN',
  Min = 'MIN'
};

export type MlsBoard = {
  __typename?: 'MlsBoard';
  baseUrl?: Maybe<Scalars['String']>;
  businessKey: MlsBoardBusinessKey;
  businessKeyName: Scalars['String'];
  createdAt: Scalars['DateTime'];
  dataConfig?: Maybe<PropertyListingDataConfig>;
  fieldLookups?: Maybe<Array<MlsFieldLookup>>;
  id: Scalars['ID'];
  longName?: Maybe<Scalars['String']>;
  protocol?: Maybe<MlsProtocol>;
  protocolName?: Maybe<Scalars['String']>;
  protocolVersion?: Maybe<Scalars['String']>;
  resources?: Maybe<Array<MlsResource>>;
  shortName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  version: Scalars['Int'];
};

export const enum MlsBoardBusinessKey {
  CaCrea = 'CA_CREA',
  CaItso = 'CA_ITSO',
  CaOreb = 'CA_OREB',
  CaTrreb = 'CA_TRREB'
};

export type MlsBoardConnection = {
  __typename?: 'MlsBoardConnection';
  edges?: Maybe<Array<Maybe<MlsBoardConnectionEdge>>>;
  facetGroups?: Maybe<Array<Maybe<FacetGroup>>>;
  filters?: Maybe<Array<Maybe<AggregatedFacetFilter | BooleanFilter | DateRangeFilter | FloatRangeFilter | IntegerRangeFilter | SimpleFacetFilter | TagCollectionFilter>>>;
  histogramGroups?: Maybe<Array<Maybe<HistogramGroup>>>;
  metricGroups?: Maybe<Array<Maybe<MetricGroup>>>;
  pageInfo?: Maybe<PageInfo>;
  savedSearches?: Maybe<Array<Maybe<SavedSearch>>>;
  sortOptions?: Maybe<Array<Maybe<SortOption>>>;
};


export type MlsBoardConnectionFacetGroupsArgs = {
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MlsBoardConnectionFiltersArgs = {
  aggregatedFacetKeywordSearch?: InputMaybe<AggregatedFacetKeywordSearchInput>;
  fetchAggregatedFacetsFilterIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  includeTagFilters?: InputMaybe<Scalars['Boolean']>;
  selected?: InputMaybe<Scalars['Boolean']>;
};


export type MlsBoardConnectionHistogramGroupsArgs = {
  histogramGroupInput?: InputMaybe<HistogramGroupInput>;
};


export type MlsBoardConnectionMetricGroupsArgs = {
  metricInput?: InputMaybe<Array<InputMaybe<MetricGroupInput>>>;
};

export type MlsBoardConnectionEdge = {
  __typename?: 'MlsBoardConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<MlsBoard>;
};

export type MlsBoardConnectionInput = {
  filters?: InputMaybe<Array<FilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  keyword?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  savedSearchId?: InputMaybe<Scalars['ID']>;
  sortKey?: InputMaybe<MlsBoardConnectionSortKey>;
  sortOptionId?: InputMaybe<Scalars['ID']>;
  sortOrder?: InputMaybe<SortOrder>;
  tagIds?: InputMaybe<Array<Scalars['ID']>>;
};

export const enum MlsBoardConnectionSortKey {
  CreatedAt = 'CREATED_AT',
  ShortName = 'SHORT_NAME'
};

export type MlsField = {
  __typename?: 'MlsField';
  absolutePath?: Maybe<Scalars['String']>;
  applicableTypes?: Maybe<Array<ApplicablePropertyType>>;
  applicableTypesName?: Maybe<Array<Scalars['String']>>;
  createdAt: Scalars['DateTime'];
  dataField?: Maybe<AddressDataField | BooleanDataField | DateDataField | DropdownDataField | EmailDataField | FileDataField | ImageDataField | NumericDataField | PhoneDataField | PrimaryImageSelectDataField | RoomDataField | StringDataField | TimeDataField | UserDataField>;
  dataType?: Maybe<MlsFieldDataType>;
  dataTypeName?: Maybe<Scalars['String']>;
  definition?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  fieldLookup?: Maybe<MlsFieldLookup>;
  id: Scalars['ID'];
  maxLength?: Maybe<Scalars['Int']>;
  maxPrecision?: Maybe<Scalars['Int']>;
  mlsUniqueIdentifier?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MlsFieldConnection = {
  __typename?: 'MlsFieldConnection';
  edges?: Maybe<Array<Maybe<MlsFieldConnectionEdge>>>;
  facetGroups?: Maybe<Array<Maybe<FacetGroup>>>;
  filters?: Maybe<Array<Maybe<AggregatedFacetFilter | BooleanFilter | DateRangeFilter | FloatRangeFilter | IntegerRangeFilter | SimpleFacetFilter | TagCollectionFilter>>>;
  histogramGroups?: Maybe<Array<Maybe<HistogramGroup>>>;
  metricGroups?: Maybe<Array<Maybe<MetricGroup>>>;
  pageInfo?: Maybe<PageInfo>;
  savedSearches?: Maybe<Array<Maybe<SavedSearch>>>;
  sortOptions?: Maybe<Array<Maybe<SortOption>>>;
};


export type MlsFieldConnectionFacetGroupsArgs = {
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MlsFieldConnectionFiltersArgs = {
  aggregatedFacetKeywordSearch?: InputMaybe<AggregatedFacetKeywordSearchInput>;
  fetchAggregatedFacetsFilterIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  includeTagFilters?: InputMaybe<Scalars['Boolean']>;
  selected?: InputMaybe<Scalars['Boolean']>;
};


export type MlsFieldConnectionHistogramGroupsArgs = {
  histogramGroupInput?: InputMaybe<HistogramGroupInput>;
};


export type MlsFieldConnectionMetricGroupsArgs = {
  metricInput?: InputMaybe<Array<InputMaybe<MetricGroupInput>>>;
};

export type MlsFieldConnectionEdge = {
  __typename?: 'MlsFieldConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<MlsField>;
};

export type MlsFieldConnectionInput = {
  first: Scalars['Int'];
  keyword?: InputMaybe<Scalars['String']>;
  mlsBoardIds?: InputMaybe<Array<Scalars['ID']>>;
  offset: Scalars['Int'];
  sortOptionId?: InputMaybe<Scalars['ID']>;
};

export const enum MlsFieldDataType {
  Boolean = 'BOOLEAN',
  Date = 'DATE',
  LookupMultiSelect = 'LOOKUP_MULTI_SELECT',
  LookupSingleSelect = 'LOOKUP_SINGLE_SELECT',
  Number = 'NUMBER',
  Object = 'OBJECT',
  String = 'STRING',
  Timestamp = 'TIMESTAMP'
};

export type MlsFieldLookup = {
  __typename?: 'MlsFieldLookup';
  absolutePath?: Maybe<Scalars['String']>;
  definition?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lookupValues?: Maybe<Array<Maybe<MlsFieldLookupValue>>>;
  mlsUniqueIdentifier?: Maybe<Scalars['String']>;
};

export type MlsFieldLookupValue = {
  __typename?: 'MlsFieldLookupValue';
  definition?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  longName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
};

export const enum MlsProtocol {
  Reso = 'RESO',
  Rets = 'RETS'
};

export type MlsResource = {
  __typename?: 'MlsResource';
  displayName?: Maybe<Scalars['String']>;
  fields?: Maybe<Array<MlsField>>;
  id: Scalars['ID'];
  mlsUniqueIdentifier?: Maybe<Scalars['String']>;
};

export type Model3D = Media & {
  __typename?: 'Model3D';
  contentType: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** @deprecated No longer supported */
  mediaAsset?: Maybe<MediaAsset>;
  originUrl: Scalars['String'];
  originalFileName?: Maybe<Scalars['String']>;
  sequence: Scalars['Int'];
  sizeBytes: Scalars['Long'];
  status: MediaStatus;
  statusName: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};

export type ModifyActivityCommentInput = {
  id: Scalars['ID'];
  message?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ActivityCommentStatus>;
  visibility?: InputMaybe<ActivityVisibility>;
};

export type ModifyAddressDataFieldInput = {
  common: ModifyDataFieldCommonInput;
  id: Scalars['ID'];
};

export type ModifyAppointmentInput = {
  description?: InputMaybe<Scalars['String']>;
  endTime?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  location?: InputMaybe<Scalars['String']>;
  outcome?: InputMaybe<Scalars['String']>;
  participantUserIds?: InputMaybe<Array<Scalars['ID']>>;
  startTime?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<AppointmentStatus>;
  title?: InputMaybe<Scalars['String']>;
};

export type ModifyBooleanDataFieldInput = {
  common: ModifyDataFieldCommonInput;
  id: Scalars['ID'];
};

export type ModifyBrokerageInput = {
  addressId?: InputMaybe<Scalars['ID']>;
  email?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<BrokerageStatus>;
};

export type ModifyBuildingInput = {
  address?: InputMaybe<AddressInput>;
  amenities?: InputMaybe<Array<InputMaybe<BuildingAmenity>>>;
  buildingName?: InputMaybe<Scalars['String']>;
  condoPetPolicy?: InputMaybe<CondoPetPolicyInput>;
  construction?: InputMaybe<BuildingConstructionInput>;
  corporationNumber?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  internalNotes?: InputMaybe<Scalars['String']>;
  listings?: InputMaybe<Array<Scalars['ID']>>;
  /** this replaces existing listings with building to the passed listings */
  maintenanceFeeIncludes?: InputMaybe<Array<InputMaybe<MaintenanceFeeIncludes>>>;
  propertyManagementName?: InputMaybe<Scalars['String']>;
  shortTermRentalPolicy?: InputMaybe<ShortTermRentalPolicy>;
  status?: InputMaybe<BuildingStatus>;
  storiesTotal?: InputMaybe<Scalars['Int']>;
  unitsTotal?: InputMaybe<Scalars['Int']>;
  zoning?: InputMaybe<Array<InputMaybe<Zoning>>>;
};

export type ModifyCompanyInput = {
  email?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
};

export type ModifyCompanyLocationInput = {
  addressId?: InputMaybe<Scalars['ID']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<CompanyLocationType>;
  website?: InputMaybe<Scalars['String']>;
};

export type ModifyConsumerUserInput = {
  addressId?: InputMaybe<Scalars['ID']>;
  birthDate?: InputMaybe<Scalars['Date']>;
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  /** only for consumer user type */
  isBuyer?: InputMaybe<Scalars['Boolean']>;
  /** only for consumer user type */
  isSeller?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  leadSource?: InputMaybe<LeadSource>;
  locale?: InputMaybe<Scalars['Locale']>;
  newPassword?: InputMaybe<Scalars['String']>;
  newPasswordConfirmation?: InputMaybe<Scalars['String']>;
  oldPassword?: InputMaybe<Scalars['String']>;
  primaryPhone?: InputMaybe<Scalars['String']>;
  receiveMarketingEmails?: InputMaybe<Scalars['Boolean']>;
  screenName?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<UserStatus>;
};

export type ModifyDataFieldCommonInput = {
  description?: InputMaybe<Scalars['String']>;
  logicalName?: InputMaybe<DataFieldLogicalName>;
  mlsFieldId?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};

export type ModifyDataFormInput = {
  description?: InputMaybe<Scalars['String']>;
  hasReviewStep?: InputMaybe<Scalars['Boolean']>;
  icon?: InputMaybe<Icon>;
  id: Scalars['ID'];
  linear?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<DataFormStatus>;
};

export type ModifyDataFormInputInput = {
  accessLevel?: InputMaybe<DataFormInputAccessLevel>;
  description?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Icon>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  /** Used for ordering. The reference to a peer after which it should be moved. If it should be moved to the first entry, set value as NULL */
  previousInputId?: InputMaybe<Scalars['ID']>;
  required?: InputMaybe<Scalars['Boolean']>;
  styleDesktop?: InputMaybe<DataFormInputStyle>;
  styleMobile?: InputMaybe<DataFormInputStyle>;
  subsectionId?: InputMaybe<Scalars['ID']>;
  widthPercentDesktop?: InputMaybe<Scalars['Int']>;
  widthPercentMobile?: InputMaybe<Scalars['Int']>;
};

export type ModifyDataFormSectionInput = {
  description?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Icon>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  /** Used for ordering. The reference to a peer after which it should be moved. If it should be moved to the first entry, set value as NULL */
  previousSectionId?: InputMaybe<Scalars['ID']>;
  stepId?: InputMaybe<Scalars['ID']>;
  supportsHistoricalData?: InputMaybe<Scalars['Boolean']>;
};

export type ModifyDataFormStepInput = {
  description?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Icon>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  /** Used for ordering. The reference to a peer after which it should be moved. If it should be moved to the first entry, set value as NULL */
  previousStepId?: InputMaybe<Scalars['ID']>;
};

export type ModifyDataFormSubsectionInput = {
  description?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Icon>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  /** Used for ordering. The reference to a peer after which it should be moved. If it should be moved to the first entry, set value as NULL */
  previousSubsectionId?: InputMaybe<Scalars['ID']>;
  sectionId?: InputMaybe<Scalars['ID']>;
};

export type ModifyDataViewFieldInput = {
  description?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Icon>;
  id: Scalars['ID'];
  mlsSubmissionWebFormInputId?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  /** Used for ordering. The reference to a peer after which it should be moved. If it should be moved to the first entry, set value as NULL */
  previousFieldId?: InputMaybe<Scalars['ID']>;
  subsectionId?: InputMaybe<Scalars['ID']>;
  widthPercentDesktop?: InputMaybe<Scalars['Int']>;
  widthPercentMobile?: InputMaybe<Scalars['Int']>;
};

export type ModifyDataViewInput = {
  description?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Icon>;
  id: Scalars['ID'];
  logicalName?: InputMaybe<DataViewLogicalName>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<DataViewStatus>;
};

export type ModifyDataViewSectionInput = {
  description?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Icon>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  /** Used for ordering. The reference to a peer after which it should be moved. If it should be moved to the first entry, set value as NULL */
  previousSectionId?: InputMaybe<Scalars['ID']>;
  tableId?: InputMaybe<Scalars['ID']>;
};

export type ModifyDataViewSubsectionInput = {
  description?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Icon>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  /** Used for ordering. The reference to a peer after which it should be moved. If it should be moved to the first entry, set value as NULL */
  previousSubsectionId?: InputMaybe<Scalars['ID']>;
  sectionId?: InputMaybe<Scalars['ID']>;
};

export type ModifyDataViewTableInput = {
  description?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Icon>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  /** Used for ordering. The reference to a peer after which it should be moved. If it should be moved to the first entry, set value as NULL */
  previousTableId?: InputMaybe<Scalars['ID']>;
};

export type ModifyDateDataFieldInput = {
  common: ModifyDataFieldCommonInput;
  /** Minimal absolute date that can be selected */
  from?: InputMaybe<Scalars['Date']>;
  id: Scalars['ID'];
  multiSelect?: InputMaybe<Scalars['Boolean']>;
  /** Maximum absolute date that can be selected */
  to?: InputMaybe<Scalars['Date']>;
};

export type ModifyDistributionInput = {
  id: Scalars['ID'];
  primaryColor?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<DistributionStatus>;
};

export type ModifyDropdownDataFieldInput = {
  common: ModifyDataFieldCommonInput;
  id: Scalars['ID'];
  maxAllowed?: InputMaybe<Scalars['Int']>;
  multiSelect?: InputMaybe<Scalars['Boolean']>;
  /** Option can only be set if the field is not tied to MLS Metadata. If it is, options would be used from mls metadata */
  options?: InputMaybe<Array<DropdownFieldOptionInput>>;
};

export type ModifyEmailDataFieldInput = {
  common: ModifyDataFieldCommonInput;
  id: Scalars['ID'];
};

export type ModifyFileDataFieldInput = {
  common: ModifyDataFieldCommonInput;
  id: Scalars['ID'];
  useCase?: InputMaybe<SetMediaUseCase>;
};

export type ModifyFurnitureProductInput = {
  category?: InputMaybe<FurnitureCategory>;
  description?: InputMaybe<Scalars['String']>;
  ecommerceUrl?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<DimensionInput>;
  id: Scalars['ID'];
  length?: InputMaybe<DimensionInput>;
  positioning?: InputMaybe<ProductPositioning>;
  price?: InputMaybe<MonetaryAmountInput>;
  primaryImageId?: InputMaybe<Scalars['ID']>;
  sku?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ProductStatus>;
  /** Deprecated in favour of 'vendorCompany' */
  storeName?: InputMaybe<Scalars['String']>;
  supplierCompanyId?: InputMaybe<Scalars['ID']>;
  title?: InputMaybe<Scalars['String']>;
  useCubemap?: InputMaybe<Scalars['Boolean']>;
  vendorCompanyId?: InputMaybe<Scalars['ID']>;
  width?: InputMaybe<DimensionInput>;
};

export type ModifyImageDataFieldInput = {
  common: ModifyDataFieldCommonInput;
  id: Scalars['ID'];
  minimumImagesRequired?: InputMaybe<Scalars['Int']>;
  multiSelect?: InputMaybe<Scalars['Boolean']>;
  useCase?: InputMaybe<SetMediaUseCase>;
};

export type ModifyNoteActivityInput = {
  id: Scalars['ID'];
  message?: InputMaybe<Scalars['String']>;
  relatedEntity?: InputMaybe<EntityInput>;
  status?: InputMaybe<ActivityStatus>;
  visibility?: InputMaybe<ActivityVisibility>;
};

export type ModifyNumericDataFieldInput = {
  common: ModifyDataFieldCommonInput;
  decimalPlaces?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  increment?: InputMaybe<Scalars['Float']>;
  maxValue?: InputMaybe<Scalars['Float']>;
  minValue?: InputMaybe<Scalars['Float']>;
};

export type ModifyOpenHouseInput = {
  arrivalInstructions?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  eventEndTime?: InputMaybe<Scalars['DateTime']>;
  eventStartTime?: InputMaybe<Scalars['DateTime']>;
  format?: InputMaybe<OpenHouseFormat>;
  hostingTeamIds?: InputMaybe<Array<Scalars['ID']>>;
  id: Scalars['ID'];
  primaryHostId?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<OpenHouseStatus>;
  targetAudience?: InputMaybe<TargetAudience>;
};

export type ModifyPhoneDataFieldInput = {
  common: ModifyDataFieldCommonInput;
  id: Scalars['ID'];
};

export type ModifyPlaceInput = {
  id: Scalars['ID'];
  nameLong?: InputMaybe<Scalars['String']>;
  nameShort?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<PlaceType>;
};

export type ModifyProductInventoryInput = {
  id: Scalars['ID'];
  price?: InputMaybe<MonetaryAmountInput>;
  quantity?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<ProductInventoryStatus>;
};

export type ModifyProjectCollaboratorInput = {
  /** Project collaborator id */
  id: Scalars['ID'];
  roleId?: InputMaybe<Scalars['ID']>;
};

export type ModifyPropertyFloorPlanConfigurationInput = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  /** If provided, replaces the old level configurations */
  levels?: InputMaybe<Array<PropertyFloorPlanLevelConfigurationInput>>;
  name?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
};

export type ModifyPropertyFloorPlanInput = {
  addressId?: InputMaybe<Scalars['ID']>;
  defaultLevelId?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  title?: InputMaybe<Scalars['String']>;
};

export type ModifyPropertyFloorPlanLevelInput = {
  id: Scalars['ID'];
  level?: InputMaybe<Level>;
  squareFootage?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
};

export type ModifyPropertyInput = {
  address?: InputMaybe<AddressInput>;
  id: Scalars['ID'];
  status?: InputMaybe<PropertyStatus>;
};

export type ModifyPropertyListingInput = {
  id: Scalars['ID'];
  latestIterationStatus?: InputMaybe<PropertyListingIterationStatus>;
  listedAt?: InputMaybe<Scalars['DateTime']>;
  mlsModificationUrl?: InputMaybe<Scalars['String']>;
  mlsNumber?: InputMaybe<Scalars['String']>;
  propertyListingStatus?: InputMaybe<PropertyListingStatus>;
};

export type ModifyPropertyListingOpportunityInput = {
  addressId?: InputMaybe<Scalars['ID']>;
  commission?: InputMaybe<Scalars['BigDecimal']>;
  commissionType?: InputMaybe<CommissionType>;
  currentStage?: InputMaybe<PropertyListingOpportunityStage>;
  id: Scalars['ID'];
  outcome?: InputMaybe<PropertyListingOpportunityOutcome>;
};

export type ModifyPropertyListingOpportunityMilestoneInput = {
  id: Scalars['ID'];
  notes?: InputMaybe<Scalars['String']>;
};

export type ModifyPropertyListingTagRuleInput = {
  /** Values which would trigger adding the tag to the listing. Work as OR(if at least one value match it triggers tag adding). In case data field is dropdown, values shall be ids of the options: `DropdownDataField.options.id` */
  dataFieldValues?: InputMaybe<Array<Scalars['ID']>>;
  description?: InputMaybe<Scalars['String']>;
  /** ID of the rule to update */
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<PropertyListingTagRuleStatus>;
  tagId?: InputMaybe<Scalars['ID']>;
};

export type ModifyResidentialPropertyListingLikeInput = {
  like: Scalars['Boolean'];
  listingId: Scalars['ID'];
};

export type ModifyResidentialPropertyRoomInput = {
  description?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<DimensionInput>;
  id: Scalars['ID'];
  isHighlight?: InputMaybe<Scalars['Boolean']>;
  length?: InputMaybe<DimensionInput>;
  level?: InputMaybe<Level>;
  propertyListingIterationId?: InputMaybe<Scalars['ID']>;
  roomName?: InputMaybe<Scalars['String']>;
  roomType?: InputMaybe<RoomType>;
  sequence?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<ResidentialRoomStatus>;
  width?: InputMaybe<DimensionInput>;
};

export type ModifyRoomDataFieldInput = {
  common: ModifyDataFieldCommonInput;
  id: Scalars['ID'];
  minimumEntriesRequired?: InputMaybe<Scalars['Int']>;
  multiSelect?: InputMaybe<Scalars['Boolean']>;
};

export type ModifySavedSearchInput = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  sortOptionId?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<SavedSearchStatus>;
};

export type ModifyStaffUserInput = {
  addressId?: InputMaybe<Scalars['ID']>;
  birthDate?: InputMaybe<Scalars['Date']>;
  brokerageId?: InputMaybe<Scalars['ID']>;
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['Locale']>;
  /** for changing password */
  newPassword?: InputMaybe<Scalars['String']>;
  /** for changing password */
  newPasswordConfirmation?: InputMaybe<Scalars['String']>;
  oldPassword?: InputMaybe<Scalars['String']>;
  /** for changing password */
  primaryPhone?: InputMaybe<Scalars['String']>;
  /** for agent and consumer */
  receiveMarketingEmails?: InputMaybe<Scalars['Boolean']>;
  screenName?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<UserStatus>;
};

export type ModifyStringDataFieldInput = {
  common: ModifyDataFieldCommonInput;
  id: Scalars['ID'];
  maxLength?: InputMaybe<Scalars['Int']>;
  minLength?: InputMaybe<Scalars['Int']>;
};

export type ModifyTagCollectionInput = {
  dependencyTagIds?: InputMaybe<Array<Scalars['ID']>>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<TagCollectionStatus>;
  weight?: InputMaybe<Scalars['Int']>;
};

export type ModifyTagInput = {
  dependencyTagIds?: InputMaybe<Array<Scalars['ID']>>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<TagStatus>;
};

export type ModifyTaskInstanceInput = {
  assignedUserId?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  dueDate?: InputMaybe<Scalars['Date']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  reviewerUserId?: InputMaybe<Scalars['ID']>;
};

export type ModifyThreadInput = {
  id: Scalars['ID'];
  isResolved?: InputMaybe<Scalars['Boolean']>;
};

export type ModifyTimeDataFieldInput = {
  common: ModifyDataFieldCommonInput;
  /** Minimal absolute time that can be selected */
  from?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  multiSelect?: InputMaybe<Scalars['Boolean']>;
  /** Maximum absolute time that can be selected */
  to?: InputMaybe<Scalars['DateTime']>;
};

export type ModifyUserDataFieldInput = {
  common: ModifyDataFieldCommonInput;
  id: Scalars['ID'];
  minimumEntriesRequired?: InputMaybe<Scalars['Int']>;
  multiSelect?: InputMaybe<Scalars['Boolean']>;
};

export type ModifyUserInput = {
  addressId?: InputMaybe<Scalars['ID']>;
  birthDate?: InputMaybe<Scalars['Date']>;
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  isBuyer?: InputMaybe<Scalars['Boolean']>;
  /** only for consumer user type */
  isSeller?: InputMaybe<Scalars['Boolean']>;
  /** only for consumer user type */
  lastName?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['Locale']>;
  /** for changing password */
  newPassword?: InputMaybe<Scalars['String']>;
  /** for changing password */
  newPasswordConfirmation?: InputMaybe<Scalars['String']>;
  oldPassword?: InputMaybe<Scalars['String']>;
  /** for changing password */
  primaryPhone?: InputMaybe<Scalars['String']>;
  /** for agent and consumer */
  receiveMarketingEmails?: InputMaybe<Scalars['Boolean']>;
  screenName?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<UserStatus>;
};

export type MonetaryAmount = {
  __typename?: 'MonetaryAmount';
  amount?: Maybe<Scalars['BigDecimal']>;
  amountFullName?: Maybe<Scalars['String']>;
  amountShortName?: Maybe<Scalars['String']>;
  amountSymbol?: Maybe<Scalars['String']>;
  currency?: Maybe<CurrencyCode>;
  currencyName?: Maybe<Scalars['String']>;
};

export type MonetaryAmountInput = {
  amount: Scalars['BigDecimal'];
};

/** The Root Mutation for the application */
export type Mutation = {
  __typename?: 'Mutation';
  acceptProjectCollaboratorInviteExistingUser?: Maybe<ProjectInstance>;
  acceptProjectCollaboratorInviteNewUser?: Maybe<AcceptProjectCollaboratorInviteResponse>;
  acceptTaskAssignExistingUser?: Maybe<ApproveSubmissionToMlsTaskInstance | BasicTaskInstance | FileUploadTaskInstance | FormFillTaskInstance | SubmitListingToMlsTaskInstance>;
  acceptTaskAssignNewUser?: Maybe<AcceptTaskAssignNewUserResponse>;
  /** Can only be called with status on PENDING_PROFILE_COMPLETION */
  acceptUserInvite: AuthenticatedUser;
  addDistributionHeaderLogo?: Maybe<MediaWithUploadUrl>;
  addDistributionLoadingLogo?: Maybe<MediaWithUploadUrl>;
  addEntityTag?: Maybe<Scalars['Boolean']>;
  /** @deprecated Please use addMedia() instead */
  addImage: MediaWithUploadUrl;
  addMedia: MediaWithUploadUrl;
  addOpportunityCustomers?: Maybe<PropertyListingOpportunity>;
  addProductModel3D: MediaWithUploadUrl;
  /** Add existing user or contact to the project */
  addProjectCollaborator?: Maybe<ProjectInstance>;
  /** Add existing user or contact to the project */
  addProjectCollaborators?: Maybe<ProjectInstance>;
  addPropertyListingIterationSellers?: Maybe<PropertyListingIteration>;
  addSpaceMaterialAoMap: MediaWithUploadUrl;
  addSpaceMaterialDiffuseMap: MediaWithUploadUrl;
  addSpaceMaterialNormalMap: MediaWithUploadUrl;
  addUserProfileImage: MediaWithUploadUrl;
  approveMlsSubmission?: Maybe<PropertyListing>;
  assignOpportunityAgents?: Maybe<PropertyListingOpportunity>;
  assignPropertyListingIterationAgents?: Maybe<PropertyListingIteration>;
  /**
   * Review form for FORM_REVIEW type tasks
   *    reviewForm(input: ReviewFormInput!): DataFormInstance
   * Assign existing workspace user to the task
   */
  assignTask?: Maybe<ApproveSubmissionToMlsTaskInstance | BasicTaskInstance | FileUploadTaskInstance | FormFillTaskInstance | SubmitListingToMlsTaskInstance>;
  /** Assign a AGENT type users to a CONSUMER OR CONTACT type user. Available for staff only */
  assignUserAgents?: Maybe<AdminUser | AgentUser | ConsumerUser | ContactUser>;
  authenticateUser: AuthenticatedUser;
  completePropertyListingOpportunityMilestone?: Maybe<PropertyListingOpportunity>;
  /** Complete the task with one of the outcomes */
  completeTaskInstance?: Maybe<TaskEventResponse>;
  /**
   * Not authenticated mutation
   * Returns an authenticatedUser on successful user signup completion
   */
  completeUserSignUp: AuthenticatedUser;
  createActivityComment?: Maybe<ActivityComment>;
  createAddressDataField?: Maybe<AddressDataField>;
  createAppointment?: Maybe<Appointment>;
  createBooleanDataField?: Maybe<BooleanDataField>;
  createBoxRoomConfiguration?: Maybe<BoxRoomConfiguration>;
  createBrokerage?: Maybe<Brokerage>;
  createBuilding?: Maybe<Building>;
  /** Creates a Company. Available for ADMIN only */
  createCompany?: Maybe<FurnitureSupplierCompany | FurnitureVendorCompany>;
  /** Creates a Company Location. Available for ADMIN only */
  createCompanyLocation?: Maybe<CompanyLocation>;
  /** Creates a CONTACT type user. Available for staff only */
  createContactUser?: Maybe<ContactUser>;
  /** Creates a Data Form Input. Available to ADMIN only */
  createDataFormInput?: Maybe<DataFormInputDefinition>;
  /** Creates a Data Form Section. Available to ADMIN only */
  createDataFormSection?: Maybe<DataFormSectionDefinition>;
  /** Creates a Data Form Step. Available to ADMIN only */
  createDataFormStep?: Maybe<DataFormStepDefinition>;
  /** Creates a Data Form Subsection. Available to ADMIN only */
  createDataFormSubsection?: Maybe<DataFormSubsectionDefinition>;
  /** Creates a Data View Field. Available to ADMIN only */
  createDataViewField?: Maybe<DataViewFieldDefinition>;
  /** Creates a Data View Section. Available to ADMIN only */
  createDataViewSection?: Maybe<DataViewSectionDefinition>;
  /** Creates a Data View Subsection. Available to ADMIN only */
  createDataViewSubsection?: Maybe<DataViewSubsectionDefinition>;
  /** Creates a Data View Table. Available to ADMIN only */
  createDataViewTable?: Maybe<DataViewTableDefinition>;
  createDateDataField?: Maybe<DateDataField>;
  createDistribution?: Maybe<Distribution>;
  createDropdownDataField?: Maybe<DropdownDataField>;
  createEmailDataField?: Maybe<EmailDataField>;
  createFileDataField?: Maybe<FileDataField>;
  createFurnitureGeneralInquiry?: Maybe<FurnitureGeneralInquiry | GeneralInquiry | PropertyListingOpportunityInquiry | ResidentialAppraisalInquiry | ResidentialListingGeneralInquiry | ResidentialListingOpenHouseInquiry | ResidentialListingPrivateShowingInquiry>;
  createFurnitureProduct?: Maybe<FurnitureProduct>;
  createGeneralInquiry?: Maybe<FurnitureGeneralInquiry | GeneralInquiry | PropertyListingOpportunityInquiry | ResidentialAppraisalInquiry | ResidentialListingGeneralInquiry | ResidentialListingOpenHouseInquiry | ResidentialListingPrivateShowingInquiry>;
  createImageDataField?: Maybe<ImageDataField>;
  createNoteActivity?: Maybe<NoteActivity>;
  createNumericDataField?: Maybe<NumericDataField>;
  createOpenHouse?: Maybe<OpenHouse>;
  createPhoneDataField?: Maybe<PhoneDataField>;
  createPlace?: Maybe<Place>;
  createProductInventory?: Maybe<ProductInventory>;
  createProjectDefinition?: Maybe<ProjectDefinition>;
  createProjectInstance?: Maybe<ProjectEventResponse>;
  createProperty?: Maybe<Property>;
  createPropertyFloorPlan?: Maybe<PropertyFloorPlan>;
  /** Creates a Property Floor Plan Configuration. Available to everyone but only staff can set the configuration to be public */
  createPropertyFloorPlanConfiguration?: Maybe<PropertyFloorPlanConfiguration>;
  createPropertyFloorPlanLevel?: Maybe<PropertyFloorPlanLevel>;
  createPropertyListing?: Maybe<PropertyListing>;
  createPropertyListingDataConfig?: Maybe<PropertyListingDataConfig>;
  createPropertyListingIteration?: Maybe<PropertyListing>;
  createPropertyListingOpportunityFromData?: Maybe<PropertyListingOpportunity>;
  createPropertyListingOpportunityInquiry?: Maybe<PropertyListingOpportunityInquiry>;
  /** Create a new rule for adding tags to the listing iteration. Only admins can access the API */
  createPropertyListingTagRule?: Maybe<PropertyListingTagDropdownValueRule | PropertyListingTagPrimitiveValueRule>;
  createResidentialAppraisalInquiry?: Maybe<FurnitureGeneralInquiry | GeneralInquiry | PropertyListingOpportunityInquiry | ResidentialAppraisalInquiry | ResidentialListingGeneralInquiry | ResidentialListingOpenHouseInquiry | ResidentialListingPrivateShowingInquiry>;
  createResidentialListingGeneralInquiry?: Maybe<FurnitureGeneralInquiry | GeneralInquiry | PropertyListingOpportunityInquiry | ResidentialAppraisalInquiry | ResidentialListingGeneralInquiry | ResidentialListingOpenHouseInquiry | ResidentialListingPrivateShowingInquiry>;
  createResidentialListingOpenHouseInquiry?: Maybe<FurnitureGeneralInquiry | GeneralInquiry | PropertyListingOpportunityInquiry | ResidentialAppraisalInquiry | ResidentialListingGeneralInquiry | ResidentialListingOpenHouseInquiry | ResidentialListingPrivateShowingInquiry>;
  createResidentialListingPrivateShowingInquiry?: Maybe<FurnitureGeneralInquiry | GeneralInquiry | PropertyListingOpportunityInquiry | ResidentialAppraisalInquiry | ResidentialListingGeneralInquiry | ResidentialListingOpenHouseInquiry | ResidentialListingPrivateShowingInquiry>;
  createResidentialPropertyRoom?: Maybe<ResidentialPropertyRoom>;
  createRoomDataField?: Maybe<RoomDataField>;
  createSavedSearch?: Maybe<SavedSearch>;
  createStringDataField?: Maybe<StringDataField>;
  createTag?: Maybe<Tag>;
  createTagCollection?: Maybe<TagCollection>;
  createTaskDefinition?: Maybe<ApproveSubmissionToMlsTaskDefinition | AttachFileStorageTaskDefinition | BasicTaskDefinition | FileUploadTaskDefinition | FormAssignTaskDefinition | FormFillTaskDefinition | FormReviewTaskDefinition | SubmitListingToMlsTaskDefinition | UploadToFileStorageTaskDefinition>;
  /** Creates a new task within the project. Task is not started yet */
  createTaskInstance?: Maybe<TaskEventResponse>;
  createTimeDataField?: Maybe<TimeDataField>;
  createUserDataField?: Maybe<UserDataField>;
  deauthenticateUser?: Maybe<Scalars['Boolean']>;
  /** Only neighbourhood types can be modified for now */
  ensureAddressPlaceConsistency?: Maybe<Scalars['Void']>;
  /** Fill form for FORM_FILL type tasks or corresponding actions */
  fillForm?: Maybe<DataFormInstance>;
  generateMediaUploadUrl: Scalars['String'];
  /** Returns a Twilio token for the provided userId. Available to authenticated users only */
  generateRealTimeNotificationToken?: Maybe<Scalars['String']>;
  inviteOpportunityCustomer?: Maybe<PropertyListingOpportunity>;
  /** Invite a new user to the project. If the users exists in the system(e.g. in another workspace) by email it would invite them to the project, otherwise invite them to the system */
  inviteProjectCollaborator?: Maybe<ProjectInstance>;
  /** Invite an outside collaborator to complete the task */
  inviteTaskAssignee?: Maybe<ApproveSubmissionToMlsTaskInstance | BasicTaskInstance | FileUploadTaskInstance | FormFillTaskInstance | SubmitListingToMlsTaskInstance>;
  /** Sends email to invited user */
  inviteUser?: Maybe<Scalars['Boolean']>;
  junkInquiry?: Maybe<FurnitureGeneralInquiry | GeneralInquiry | PropertyListingOpportunityInquiry | ResidentialAppraisalInquiry | ResidentialListingGeneralInquiry | ResidentialListingOpenHouseInquiry | ResidentialListingPrivateShowingInquiry>;
  markThreadRead?: Maybe<InquiryCreatedActivity | NoteActivity | ProjectStageEventActivity | PropertyListingLikeActivity | PropertyListingViewActivity | RootProjectEventActivity | SubProjectEventActivity | TaskAssignActivity | TaskEventActivity | UserAssignedAgentAddedActivity | UserAssignedAgentRemovedActivity | UserReactivatedActivity | UserSuspendedActivity>;
  modifyActivityComment?: Maybe<ActivityComment>;
  modifyAddressDataField?: Maybe<AddressDataField>;
  modifyAppointment?: Maybe<Appointment>;
  modifyBooleanDataField?: Maybe<BooleanDataField>;
  modifyBrokerage?: Maybe<Brokerage>;
  modifyBuilding?: Maybe<Building>;
  /** Modify a Company. Available for ADMIN only */
  modifyCompany?: Maybe<FurnitureSupplierCompany | FurnitureVendorCompany>;
  /** Modify a Company Location. Available for ADMIN only */
  modifyCompanyLocation?: Maybe<CompanyLocation>;
  /** Modifies either a CONSUMER OR CONTACT user */
  modifyConsumerUser: AdminUser | AgentUser | ConsumerUser | ContactUser;
  /**
   *    createDataForm(input: CreateDataFormInput!): DataFormDefinition
   * Modifies a Data Form. Available to ADMIN only
   */
  modifyDataForm?: Maybe<PropertyListingDataFormDefinition>;
  /** Modifies a Data Form Input. Available to ADMIN only */
  modifyDataFormInput?: Maybe<DataFormInputDefinition>;
  /** Modifies a Data Form Section. Available to ADMIN only */
  modifyDataFormSection?: Maybe<DataFormSectionDefinition>;
  /** Modifies a Data Form Step. Available to ADMIN only */
  modifyDataFormStep?: Maybe<DataFormStepDefinition>;
  /** Modifies a Data Form Subsection. Available to ADMIN only */
  modifyDataFormSubsection?: Maybe<DataFormSubsectionDefinition>;
  /**
   *    createDataView(input: CreateDataViewField!): DataViewDefinition
   * Modifies a Data View. Available to ADMIN only
   */
  modifyDataView?: Maybe<PropertyListingDataViewDefinition>;
  /** Modifies a Data View Field. Available to ADMIN only */
  modifyDataViewField?: Maybe<DataViewFieldDefinition>;
  /** Modifies a Data View Section. Available to ADMIN only */
  modifyDataViewSection?: Maybe<DataViewSectionDefinition>;
  /** Modifies a Data View Subsection. Available to ADMIN only */
  modifyDataViewSubsection?: Maybe<DataViewSubsectionDefinition>;
  /** Modifies a Data View Table. Available to ADMIN only */
  modifyDataViewTable?: Maybe<DataViewTableDefinition>;
  modifyDateDataField?: Maybe<DateDataField>;
  modifyDistribution?: Maybe<Distribution>;
  modifyDropdownDataField?: Maybe<DropdownDataField>;
  modifyEmailDataField?: Maybe<EmailDataField>;
  modifyFileDataField?: Maybe<FileDataField>;
  modifyFurnitureProduct?: Maybe<FurnitureProduct>;
  modifyImageDataField?: Maybe<ImageDataField>;
  modifyNoteActivity?: Maybe<NoteActivity>;
  modifyNumericDataField?: Maybe<NumericDataField>;
  modifyOpenHouse?: Maybe<OpenHouse>;
  modifyPhoneDataField?: Maybe<PhoneDataField>;
  modifyPlace?: Maybe<Place>;
  modifyProductInventory?: Maybe<ProductInventory>;
  /** Modify the provided collaborator. Available to ADMIN user, or project collaborator with role ProjectRoleType.OWNER or ProjectRoleType.ADMIN */
  modifyProjectCollaborator?: Maybe<ProjectCollaborator>;
  modifyProperty?: Maybe<Property>;
  /** Modify a Property Floor Plan. Available for staff only */
  modifyPropertyFloorPlan?: Maybe<PropertyFloorPlan>;
  /** Modifies a Property Floor Plan Configuration. Available to staff only */
  modifyPropertyFloorPlanConfiguration?: Maybe<PropertyFloorPlanConfiguration>;
  /** Modify a Property Floor Plan Level. Available for staff only */
  modifyPropertyFloorPlanLevel?: Maybe<PropertyFloorPlanLevel>;
  modifyPropertyListing?: Maybe<PropertyListing>;
  modifyPropertyListingOpportunity?: Maybe<PropertyListingOpportunity>;
  modifyPropertyListingOpportunityMilestone?: Maybe<OpportunityMilestone>;
  /** Modify an existing rule. Only admins can access the API */
  modifyPropertyListingTagRule?: Maybe<PropertyListingTagDropdownValueRule | PropertyListingTagPrimitiveValueRule>;
  modifyResidentialPropertyListingLike?: Maybe<ResidentialPropertyListingLike>;
  modifyResidentialPropertyRoom?: Maybe<ResidentialPropertyRoom>;
  modifyRoomDataField?: Maybe<RoomDataField>;
  modifySavedSearch?: Maybe<SavedSearch>;
  modifyStaffUser: AdminUser | AgentUser | ConsumerUser | ContactUser;
  modifyStringDataField?: Maybe<StringDataField>;
  modifyTag?: Maybe<Tag>;
  modifyTagCollection?: Maybe<TagCollection>;
  /** Update task details */
  modifyTaskInstance?: Maybe<ApproveSubmissionToMlsTaskInstance | BasicTaskInstance | FileUploadTaskInstance | FormFillTaskInstance | SubmitListingToMlsTaskInstance>;
  modifyThread?: Maybe<InquiryCreatedActivity | NoteActivity | ProjectStageEventActivity | PropertyListingLikeActivity | PropertyListingViewActivity | RootProjectEventActivity | SubProjectEventActivity | TaskAssignActivity | TaskEventActivity | UserAssignedAgentAddedActivity | UserAssignedAgentRemovedActivity | UserReactivatedActivity | UserSuspendedActivity>;
  modifyTimeDataField?: Maybe<TimeDataField>;
  modifyUser: AdminUser | AgentUser | ConsumerUser | ContactUser;
  modifyUserDataField?: Maybe<UserDataField>;
  performCustomAction?: Maybe<PerformCustomActionResponse>;
  /** Rearrange media in a media collection with the provided order. Make sure all media ids from the collection are provided in the input(not a subset of media within the collection), because sequences would be distributed with that assumption */
  rearrangeMedia?: Maybe<Array<File | Image | Model3D>>;
  /** Only admins can access the API */
  removeDataField?: Maybe<Scalars['Boolean']>;
  /** Removes a Data Form Input. Available to ADMIN only */
  removeDataFormInput?: Maybe<Scalars['Boolean']>;
  /** Removes a Data Form Section. Available to ADMIN only */
  removeDataFormSection?: Maybe<Scalars['Boolean']>;
  /** Removes a Data Form Step. Available to ADMIN only */
  removeDataFormStep?: Maybe<Scalars['Boolean']>;
  /** Removes a Data Form Subsection. Available to ADMIN only */
  removeDataFormSubsection?: Maybe<Scalars['Boolean']>;
  /** Removes a Data View Field. Available to ADMIN only */
  removeDataViewField?: Maybe<Scalars['Boolean']>;
  /** Removes a Data View Section. Available to ADMIN only */
  removeDataViewSection?: Maybe<Scalars['Boolean']>;
  /** Removes a Data View Subsection. Available to ADMIN only */
  removeDataViewSubsection?: Maybe<Scalars['Boolean']>;
  /** Removes a Data View Table. Available to ADMIN only */
  removeDataViewTable?: Maybe<Scalars['Boolean']>;
  removeEntityTag?: Maybe<Scalars['Boolean']>;
  removeOpportunityAgent?: Maybe<PropertyListingOpportunity>;
  removeOpportunityCustomer?: Maybe<PropertyListingOpportunity>;
  /** Removes the provided collaborators from the project */
  removeProjectCollaborators?: Maybe<ProjectInstance>;
  /** Removes a Property Floor Plan Configuration. Can be removed by the configuration creator or staff */
  removePropertyFloorPlanConfiguration?: Maybe<Scalars['Boolean']>;
  /** Remove a Property Floor Plan Level. Available for staff only */
  removePropertyFloorPlanLevel?: Maybe<Scalars['Boolean']>;
  removePropertyListingIterationAgent?: Maybe<PropertyListingIteration>;
  removePropertyListingIterationSeller?: Maybe<PropertyListingIteration>;
  /** Remove an existing rule. Only admins can access the API */
  removePropertyListingTagRule?: Maybe<Scalars['Boolean']>;
  /** Remove a AGENT type user from a CONSUMER OR CONTACT type user. Available for staff only */
  removeUserAssignedAgent?: Maybe<AdminUser | AgentUser | ConsumerUser | ContactUser>;
  /** Reopen the task */
  reopenTaskInstance?: Maybe<ApproveSubmissionToMlsTaskInstance | BasicTaskInstance | FileUploadTaskInstance | FormFillTaskInstance | SubmitListingToMlsTaskInstance>;
  requestUserPasswordReset?: Maybe<Scalars['Boolean']>;
  /** Resends verification email to user with code */
  resendSignupEmail?: Maybe<Scalars['Boolean']>;
  resetUserPassword: AuthenticatedUser;
  /** Review the task */
  reviewTaskInstance?: Maybe<ApproveSubmissionToMlsTaskInstance | BasicTaskInstance | FileUploadTaskInstance | FormFillTaskInstance | SubmitListingToMlsTaskInstance>;
  /** Mutation to save property listing iteration `listingForm` */
  savePropertyListingIterationDynamicData?: Maybe<PropertyListingIteration>;
  sendPdfPackageWithData?: Maybe<Scalars['Boolean']>;
  /** Temp push sending notification. Available for admin only */
  sendPushNotification?: Maybe<Scalars['Boolean']>;
  setFollowActivityObject?: Maybe<Scalars['Boolean']>;
  setMedia: MediaWithUploadUrl;
  /** Sends verification email to user with token */
  signupUser: AdminUser | AgentUser | ConsumerUser | ContactUser;
  startProjectInstance?: Maybe<ProjectEventResponse>;
  /** Start the task */
  startTaskInstance?: Maybe<TaskEventResponse>;
  /**
   * Subscribe/unsubscribe device to push notifications. `fcmToken` must be provided for subscribe and for unsubscribing particular device.
   * If unsubscribing and no token provided, all registered user devices would be unsubscribed
   */
  subscribeToPushNotifications?: Maybe<Scalars['Boolean']>;
  /** Refreshes current Property Listing iteration dynamic and normalized data based on raw MLS listing data */
  synchronizePropertyListingsFromMls?: Maybe<Scalars['Boolean']>;
  undoPropertyListingOpportunityMilestone?: Maybe<PropertyListingOpportunity>;
  updateImage?: Maybe<Image>;
  updateMedia?: Maybe<File | Image | Model3D>;
  /** Get or create a Model3D entry to the space configuration. Allowed content type: "model/gltf-binary", "model/usd", "model/vnd.usdz+zip" */
  uploadSpaceConfigurationModel: MediaWithUploadUrl;
  /** @deprecated Use address autocomplete flow instead. See https://coda.io/d/Address-Autocomplete_dtSR2npplIP/Address-Autocomplete_su4Szu8y#_luuv9ctb */
  validateAddress?: Maybe<Address>;
};


/** The Root Mutation for the application */
export type MutationAcceptProjectCollaboratorInviteExistingUserArgs = {
  token: Scalars['ID'];
};


/** The Root Mutation for the application */
export type MutationAcceptProjectCollaboratorInviteNewUserArgs = {
  input: AcceptProjectCollaboratorInviteNewUserInput;
};


/** The Root Mutation for the application */
export type MutationAcceptTaskAssignExistingUserArgs = {
  token: Scalars['ID'];
};


/** The Root Mutation for the application */
export type MutationAcceptTaskAssignNewUserArgs = {
  token: Scalars['ID'];
};


/** The Root Mutation for the application */
export type MutationAcceptUserInviteArgs = {
  input?: InputMaybe<AcceptUserInviteInput>;
};


/** The Root Mutation for the application */
export type MutationAddDistributionHeaderLogoArgs = {
  input: AddDistributionMediaInput;
};


/** The Root Mutation for the application */
export type MutationAddDistributionLoadingLogoArgs = {
  input: AddDistributionMediaInput;
};


/** The Root Mutation for the application */
export type MutationAddEntityTagArgs = {
  input: AddEntityTagInput;
};


/** The Root Mutation for the application */
export type MutationAddImageArgs = {
  input: AddImageInput;
};


/** The Root Mutation for the application */
export type MutationAddMediaArgs = {
  input: AddMediaInput;
};


/** The Root Mutation for the application */
export type MutationAddOpportunityCustomersArgs = {
  input: AddOpportunityCustomersInput;
};


/** The Root Mutation for the application */
export type MutationAddProductModel3DArgs = {
  input: AddProductModel3DInput;
};


/** The Root Mutation for the application */
export type MutationAddProjectCollaboratorArgs = {
  input: AddProjectCollaboratorInput;
};


/** The Root Mutation for the application */
export type MutationAddProjectCollaboratorsArgs = {
  input: AddProjectCollaboratorsInput;
};


/** The Root Mutation for the application */
export type MutationAddPropertyListingIterationSellersArgs = {
  input: AddPropertyListingIterationSellersInput;
};


/** The Root Mutation for the application */
export type MutationAddSpaceMaterialAoMapArgs = {
  input?: InputMaybe<AddSpaceMaterialMapInput>;
};


/** The Root Mutation for the application */
export type MutationAddSpaceMaterialDiffuseMapArgs = {
  input?: InputMaybe<AddSpaceMaterialMapInput>;
};


/** The Root Mutation for the application */
export type MutationAddSpaceMaterialNormalMapArgs = {
  input?: InputMaybe<AddSpaceMaterialMapInput>;
};


/** The Root Mutation for the application */
export type MutationAddUserProfileImageArgs = {
  input: AddUserProfileImageInput;
};


/** The Root Mutation for the application */
export type MutationApproveMlsSubmissionArgs = {
  propertyListingId: Scalars['ID'];
};


/** The Root Mutation for the application */
export type MutationAssignOpportunityAgentsArgs = {
  input: AssignOpportunityAgentsInput;
};


/** The Root Mutation for the application */
export type MutationAssignPropertyListingIterationAgentsArgs = {
  input: AssignPropertyListingIterationAgentsInput;
};


/** The Root Mutation for the application */
export type MutationAssignTaskArgs = {
  input: AssignTaskInput;
};


/** The Root Mutation for the application */
export type MutationAssignUserAgentsArgs = {
  input: AddUserAssignedAgentsInput;
};


/** The Root Mutation for the application */
export type MutationAuthenticateUserArgs = {
  input: UserAuthenticationInput;
};


/** The Root Mutation for the application */
export type MutationCompletePropertyListingOpportunityMilestoneArgs = {
  input: CompletePropertyListingOpportunityMilestoneInput;
};


/** The Root Mutation for the application */
export type MutationCompleteTaskInstanceArgs = {
  input: CompleteTaskInstanceInput;
};


/** The Root Mutation for the application */
export type MutationCompleteUserSignUpArgs = {
  input: CompleteUserSignUpInput;
};


/** The Root Mutation for the application */
export type MutationCreateActivityCommentArgs = {
  input: CreateActivityCommentInput;
};


/** The Root Mutation for the application */
export type MutationCreateAddressDataFieldArgs = {
  input: CreateAddressDataFieldInput;
};


/** The Root Mutation for the application */
export type MutationCreateAppointmentArgs = {
  input: CreateAppointmentInput;
};


/** The Root Mutation for the application */
export type MutationCreateBooleanDataFieldArgs = {
  input: CreateBooleanDataFieldInput;
};


/** The Root Mutation for the application */
export type MutationCreateBoxRoomConfigurationArgs = {
  input: CreateBoxRoomConfigurationInput;
};


/** The Root Mutation for the application */
export type MutationCreateBrokerageArgs = {
  input: CreateBrokerageInput;
};


/** The Root Mutation for the application */
export type MutationCreateBuildingArgs = {
  input: CreateBuildingInput;
};


/** The Root Mutation for the application */
export type MutationCreateCompanyArgs = {
  input: CreateCompanyInput;
};


/** The Root Mutation for the application */
export type MutationCreateCompanyLocationArgs = {
  input: CreateCompanyLocationInput;
};


/** The Root Mutation for the application */
export type MutationCreateContactUserArgs = {
  input: CreateContactUserInput;
};


/** The Root Mutation for the application */
export type MutationCreateDataFormInputArgs = {
  input: CreateDataFormInputInput;
};


/** The Root Mutation for the application */
export type MutationCreateDataFormSectionArgs = {
  input: CreateDataFormSectionInput;
};


/** The Root Mutation for the application */
export type MutationCreateDataFormStepArgs = {
  input: CreateDataFormStepInput;
};


/** The Root Mutation for the application */
export type MutationCreateDataFormSubsectionArgs = {
  input: CreateDataFormSubsectionInput;
};


/** The Root Mutation for the application */
export type MutationCreateDataViewFieldArgs = {
  input: CreateDataViewFieldInput;
};


/** The Root Mutation for the application */
export type MutationCreateDataViewSectionArgs = {
  input: CreateDataViewSectionInput;
};


/** The Root Mutation for the application */
export type MutationCreateDataViewSubsectionArgs = {
  input: CreateDataViewSubsectionInput;
};


/** The Root Mutation for the application */
export type MutationCreateDataViewTableArgs = {
  input: CreateDataViewTableInput;
};


/** The Root Mutation for the application */
export type MutationCreateDateDataFieldArgs = {
  input: CreateDateDataFieldInput;
};


/** The Root Mutation for the application */
export type MutationCreateDistributionArgs = {
  input: CreateDistributionInput;
};


/** The Root Mutation for the application */
export type MutationCreateDropdownDataFieldArgs = {
  input: CreateDropdownDataFieldInput;
};


/** The Root Mutation for the application */
export type MutationCreateEmailDataFieldArgs = {
  input: CreateEmailDataFieldInput;
};


/** The Root Mutation for the application */
export type MutationCreateFileDataFieldArgs = {
  input: CreateFileDataFieldInput;
};


/** The Root Mutation for the application */
export type MutationCreateFurnitureGeneralInquiryArgs = {
  input: CreateFurnitureGeneralInquiryInput;
};


/** The Root Mutation for the application */
export type MutationCreateFurnitureProductArgs = {
  input: CreateFurnitureProductInput;
};


/** The Root Mutation for the application */
export type MutationCreateGeneralInquiryArgs = {
  input: CreateGeneralInquiryInput;
};


/** The Root Mutation for the application */
export type MutationCreateImageDataFieldArgs = {
  input: CreateImageDataFieldInput;
};


/** The Root Mutation for the application */
export type MutationCreateNoteActivityArgs = {
  input: CreateNoteActivityInput;
};


/** The Root Mutation for the application */
export type MutationCreateNumericDataFieldArgs = {
  input: CreateNumericDataFieldInput;
};


/** The Root Mutation for the application */
export type MutationCreateOpenHouseArgs = {
  input: CreateOpenHouseInput;
};


/** The Root Mutation for the application */
export type MutationCreatePhoneDataFieldArgs = {
  input: CreatePhoneDataFieldInput;
};


/** The Root Mutation for the application */
export type MutationCreatePlaceArgs = {
  input: CreatePlaceInput;
};


/** The Root Mutation for the application */
export type MutationCreateProductInventoryArgs = {
  input: CreateProductInventoryInput;
};


/** The Root Mutation for the application */
export type MutationCreateProjectDefinitionArgs = {
  input: CreateProjectDefinitionInput;
};


/** The Root Mutation for the application */
export type MutationCreateProjectInstanceArgs = {
  input: CreateProjectInstanceInput;
};


/** The Root Mutation for the application */
export type MutationCreatePropertyArgs = {
  input: CreatePropertyInput;
};


/** The Root Mutation for the application */
export type MutationCreatePropertyFloorPlanArgs = {
  input: CreatePropertyFloorPlanInput;
};


/** The Root Mutation for the application */
export type MutationCreatePropertyFloorPlanConfigurationArgs = {
  input: CreatePropertyFloorPlanConfigurationInput;
};


/** The Root Mutation for the application */
export type MutationCreatePropertyFloorPlanLevelArgs = {
  input: CreatePropertyFloorPlanLevelInput;
};


/** The Root Mutation for the application */
export type MutationCreatePropertyListingArgs = {
  input: CreatePropertyListingInput;
};


/** The Root Mutation for the application */
export type MutationCreatePropertyListingDataConfigArgs = {
  input: CreatePropertyListingDataConfigInput;
};


/** The Root Mutation for the application */
export type MutationCreatePropertyListingIterationArgs = {
  input: CreatePropertyListingIterationInput;
};


/** The Root Mutation for the application */
export type MutationCreatePropertyListingOpportunityFromDataArgs = {
  input: CreatePropertyListingOpportunityFromDataInput;
};


/** The Root Mutation for the application */
export type MutationCreatePropertyListingOpportunityInquiryArgs = {
  input: CreatePropertyListingOpportunityInquiryInput;
};


/** The Root Mutation for the application */
export type MutationCreatePropertyListingTagRuleArgs = {
  input: CreatePropertyListingTagRuleInput;
};


/** The Root Mutation for the application */
export type MutationCreateResidentialAppraisalInquiryArgs = {
  input: CreateResidentialAppraisalInquiryInput;
};


/** The Root Mutation for the application */
export type MutationCreateResidentialListingGeneralInquiryArgs = {
  input: CreateResidentialListingGeneralInquiryInput;
};


/** The Root Mutation for the application */
export type MutationCreateResidentialListingOpenHouseInquiryArgs = {
  input: CreateResidentialListingOpenHouseInquiryInput;
};


/** The Root Mutation for the application */
export type MutationCreateResidentialListingPrivateShowingInquiryArgs = {
  input: CreateResidentialListingPrivateShowingInquiryInput;
};


/** The Root Mutation for the application */
export type MutationCreateResidentialPropertyRoomArgs = {
  input: CreateResidentialPropertyRoomInput;
};


/** The Root Mutation for the application */
export type MutationCreateRoomDataFieldArgs = {
  input: CreateRoomDataFieldInput;
};


/** The Root Mutation for the application */
export type MutationCreateSavedSearchArgs = {
  input: CreateSavedSearchInput;
};


/** The Root Mutation for the application */
export type MutationCreateStringDataFieldArgs = {
  input: CreateStringDataFieldInput;
};


/** The Root Mutation for the application */
export type MutationCreateTagArgs = {
  input: CreateTagInput;
};


/** The Root Mutation for the application */
export type MutationCreateTagCollectionArgs = {
  input: CreateTagCollectionInput;
};


/** The Root Mutation for the application */
export type MutationCreateTaskDefinitionArgs = {
  input: CreateTaskDefinitionInput;
};


/** The Root Mutation for the application */
export type MutationCreateTaskInstanceArgs = {
  input: CreateTaskInstanceInput;
};


/** The Root Mutation for the application */
export type MutationCreateTimeDataFieldArgs = {
  input: CreateTimeDataFieldInput;
};


/** The Root Mutation for the application */
export type MutationCreateUserDataFieldArgs = {
  input: CreateUserDataFieldInput;
};


/** The Root Mutation for the application */
export type MutationEnsureAddressPlaceConsistencyArgs = {
  input: EnsureAddressPlaceConsistencyInput;
};


/** The Root Mutation for the application */
export type MutationFillFormArgs = {
  input: FillFormInput;
};


/** The Root Mutation for the application */
export type MutationGenerateMediaUploadUrlArgs = {
  mediaId: Scalars['ID'];
};


/** The Root Mutation for the application */
export type MutationInviteOpportunityCustomerArgs = {
  input: InviteOpportunityCustomerInput;
};


/** The Root Mutation for the application */
export type MutationInviteProjectCollaboratorArgs = {
  input: InviteProjectCollaboratorInput;
};


/** The Root Mutation for the application */
export type MutationInviteTaskAssigneeArgs = {
  input: InviteTaskAssigneeInput;
};


/** The Root Mutation for the application */
export type MutationInviteUserArgs = {
  input: InviteUserInput;
};


/** The Root Mutation for the application */
export type MutationJunkInquiryArgs = {
  id: Scalars['ID'];
};


/** The Root Mutation for the application */
export type MutationMarkThreadReadArgs = {
  id: Scalars['ID'];
};


/** The Root Mutation for the application */
export type MutationModifyActivityCommentArgs = {
  input: ModifyActivityCommentInput;
};


/** The Root Mutation for the application */
export type MutationModifyAddressDataFieldArgs = {
  input: ModifyAddressDataFieldInput;
};


/** The Root Mutation for the application */
export type MutationModifyAppointmentArgs = {
  input: ModifyAppointmentInput;
};


/** The Root Mutation for the application */
export type MutationModifyBooleanDataFieldArgs = {
  input: ModifyBooleanDataFieldInput;
};


/** The Root Mutation for the application */
export type MutationModifyBrokerageArgs = {
  input: ModifyBrokerageInput;
};


/** The Root Mutation for the application */
export type MutationModifyBuildingArgs = {
  input: ModifyBuildingInput;
};


/** The Root Mutation for the application */
export type MutationModifyCompanyArgs = {
  input: ModifyCompanyInput;
};


/** The Root Mutation for the application */
export type MutationModifyCompanyLocationArgs = {
  input: ModifyCompanyLocationInput;
};


/** The Root Mutation for the application */
export type MutationModifyConsumerUserArgs = {
  input: ModifyConsumerUserInput;
};


/** The Root Mutation for the application */
export type MutationModifyDataFormArgs = {
  input: ModifyDataFormInput;
};


/** The Root Mutation for the application */
export type MutationModifyDataFormInputArgs = {
  input: ModifyDataFormInputInput;
};


/** The Root Mutation for the application */
export type MutationModifyDataFormSectionArgs = {
  input: ModifyDataFormSectionInput;
};


/** The Root Mutation for the application */
export type MutationModifyDataFormStepArgs = {
  input: ModifyDataFormStepInput;
};


/** The Root Mutation for the application */
export type MutationModifyDataFormSubsectionArgs = {
  input: ModifyDataFormSubsectionInput;
};


/** The Root Mutation for the application */
export type MutationModifyDataViewArgs = {
  input: ModifyDataViewInput;
};


/** The Root Mutation for the application */
export type MutationModifyDataViewFieldArgs = {
  input: ModifyDataViewFieldInput;
};


/** The Root Mutation for the application */
export type MutationModifyDataViewSectionArgs = {
  input: ModifyDataViewSectionInput;
};


/** The Root Mutation for the application */
export type MutationModifyDataViewSubsectionArgs = {
  input: ModifyDataViewSubsectionInput;
};


/** The Root Mutation for the application */
export type MutationModifyDataViewTableArgs = {
  input: ModifyDataViewTableInput;
};


/** The Root Mutation for the application */
export type MutationModifyDateDataFieldArgs = {
  input: ModifyDateDataFieldInput;
};


/** The Root Mutation for the application */
export type MutationModifyDistributionArgs = {
  input: ModifyDistributionInput;
};


/** The Root Mutation for the application */
export type MutationModifyDropdownDataFieldArgs = {
  input: ModifyDropdownDataFieldInput;
};


/** The Root Mutation for the application */
export type MutationModifyEmailDataFieldArgs = {
  input: ModifyEmailDataFieldInput;
};


/** The Root Mutation for the application */
export type MutationModifyFileDataFieldArgs = {
  input: ModifyFileDataFieldInput;
};


/** The Root Mutation for the application */
export type MutationModifyFurnitureProductArgs = {
  input: ModifyFurnitureProductInput;
};


/** The Root Mutation for the application */
export type MutationModifyImageDataFieldArgs = {
  input: ModifyImageDataFieldInput;
};


/** The Root Mutation for the application */
export type MutationModifyNoteActivityArgs = {
  input: ModifyNoteActivityInput;
};


/** The Root Mutation for the application */
export type MutationModifyNumericDataFieldArgs = {
  input: ModifyNumericDataFieldInput;
};


/** The Root Mutation for the application */
export type MutationModifyOpenHouseArgs = {
  input: ModifyOpenHouseInput;
};


/** The Root Mutation for the application */
export type MutationModifyPhoneDataFieldArgs = {
  input: ModifyPhoneDataFieldInput;
};


/** The Root Mutation for the application */
export type MutationModifyPlaceArgs = {
  input: ModifyPlaceInput;
};


/** The Root Mutation for the application */
export type MutationModifyProductInventoryArgs = {
  input: ModifyProductInventoryInput;
};


/** The Root Mutation for the application */
export type MutationModifyProjectCollaboratorArgs = {
  input: ModifyProjectCollaboratorInput;
};


/** The Root Mutation for the application */
export type MutationModifyPropertyArgs = {
  input: ModifyPropertyInput;
};


/** The Root Mutation for the application */
export type MutationModifyPropertyFloorPlanArgs = {
  input: ModifyPropertyFloorPlanInput;
};


/** The Root Mutation for the application */
export type MutationModifyPropertyFloorPlanConfigurationArgs = {
  input: ModifyPropertyFloorPlanConfigurationInput;
};


/** The Root Mutation for the application */
export type MutationModifyPropertyFloorPlanLevelArgs = {
  input: ModifyPropertyFloorPlanLevelInput;
};


/** The Root Mutation for the application */
export type MutationModifyPropertyListingArgs = {
  input: ModifyPropertyListingInput;
};


/** The Root Mutation for the application */
export type MutationModifyPropertyListingOpportunityArgs = {
  input: ModifyPropertyListingOpportunityInput;
};


/** The Root Mutation for the application */
export type MutationModifyPropertyListingOpportunityMilestoneArgs = {
  input: ModifyPropertyListingOpportunityMilestoneInput;
};


/** The Root Mutation for the application */
export type MutationModifyPropertyListingTagRuleArgs = {
  input: ModifyPropertyListingTagRuleInput;
};


/** The Root Mutation for the application */
export type MutationModifyResidentialPropertyListingLikeArgs = {
  input: ModifyResidentialPropertyListingLikeInput;
};


/** The Root Mutation for the application */
export type MutationModifyResidentialPropertyRoomArgs = {
  input: ModifyResidentialPropertyRoomInput;
};


/** The Root Mutation for the application */
export type MutationModifyRoomDataFieldArgs = {
  input: ModifyRoomDataFieldInput;
};


/** The Root Mutation for the application */
export type MutationModifySavedSearchArgs = {
  input: ModifySavedSearchInput;
};


/** The Root Mutation for the application */
export type MutationModifyStaffUserArgs = {
  input: ModifyStaffUserInput;
};


/** The Root Mutation for the application */
export type MutationModifyStringDataFieldArgs = {
  input: ModifyStringDataFieldInput;
};


/** The Root Mutation for the application */
export type MutationModifyTagArgs = {
  input: ModifyTagInput;
};


/** The Root Mutation for the application */
export type MutationModifyTagCollectionArgs = {
  input: ModifyTagCollectionInput;
};


/** The Root Mutation for the application */
export type MutationModifyTaskInstanceArgs = {
  input: ModifyTaskInstanceInput;
};


/** The Root Mutation for the application */
export type MutationModifyThreadArgs = {
  input: ModifyThreadInput;
};


/** The Root Mutation for the application */
export type MutationModifyTimeDataFieldArgs = {
  input: ModifyTimeDataFieldInput;
};


/** The Root Mutation for the application */
export type MutationModifyUserArgs = {
  input: ModifyUserInput;
};


/** The Root Mutation for the application */
export type MutationModifyUserDataFieldArgs = {
  input: ModifyUserDataFieldInput;
};


/** The Root Mutation for the application */
export type MutationPerformCustomActionArgs = {
  input: PerformCustomActionInput;
};


/** The Root Mutation for the application */
export type MutationRearrangeMediaArgs = {
  input: RearrangeMediaInput;
};


/** The Root Mutation for the application */
export type MutationRemoveDataFieldArgs = {
  id: Scalars['ID'];
};


/** The Root Mutation for the application */
export type MutationRemoveDataFormInputArgs = {
  id: Scalars['ID'];
};


/** The Root Mutation for the application */
export type MutationRemoveDataFormSectionArgs = {
  id: Scalars['ID'];
};


/** The Root Mutation for the application */
export type MutationRemoveDataFormStepArgs = {
  id: Scalars['ID'];
};


/** The Root Mutation for the application */
export type MutationRemoveDataFormSubsectionArgs = {
  id: Scalars['ID'];
};


/** The Root Mutation for the application */
export type MutationRemoveDataViewFieldArgs = {
  id: Scalars['ID'];
};


/** The Root Mutation for the application */
export type MutationRemoveDataViewSectionArgs = {
  id: Scalars['ID'];
};


/** The Root Mutation for the application */
export type MutationRemoveDataViewSubsectionArgs = {
  id: Scalars['ID'];
};


/** The Root Mutation for the application */
export type MutationRemoveDataViewTableArgs = {
  id: Scalars['ID'];
};


/** The Root Mutation for the application */
export type MutationRemoveEntityTagArgs = {
  input: RemoveEntityTagInput;
};


/** The Root Mutation for the application */
export type MutationRemoveOpportunityAgentArgs = {
  id: Scalars['ID'];
};


/** The Root Mutation for the application */
export type MutationRemoveOpportunityCustomerArgs = {
  id: Scalars['ID'];
};


/** The Root Mutation for the application */
export type MutationRemoveProjectCollaboratorsArgs = {
  projectCollaboratorIds: Array<Scalars['ID']>;
};


/** The Root Mutation for the application */
export type MutationRemovePropertyFloorPlanConfigurationArgs = {
  id: Scalars['ID'];
};


/** The Root Mutation for the application */
export type MutationRemovePropertyFloorPlanLevelArgs = {
  id: Scalars['ID'];
};


/** The Root Mutation for the application */
export type MutationRemovePropertyListingIterationAgentArgs = {
  id: Scalars['ID'];
};


/** The Root Mutation for the application */
export type MutationRemovePropertyListingIterationSellerArgs = {
  id: Scalars['ID'];
};


/** The Root Mutation for the application */
export type MutationRemovePropertyListingTagRuleArgs = {
  id: Scalars['ID'];
};


/** The Root Mutation for the application */
export type MutationRemoveUserAssignedAgentArgs = {
  id: Scalars['ID'];
};


/** The Root Mutation for the application */
export type MutationReopenTaskInstanceArgs = {
  input: ReopenTaskInstanceInput;
};


/** The Root Mutation for the application */
export type MutationRequestUserPasswordResetArgs = {
  email: Scalars['String'];
};


/** The Root Mutation for the application */
export type MutationResendSignupEmailArgs = {
  email: Scalars['String'];
};


/** The Root Mutation for the application */
export type MutationResetUserPasswordArgs = {
  input: ResetUserPasswordInput;
};


/** The Root Mutation for the application */
export type MutationReviewTaskInstanceArgs = {
  input: ReviewTaskInstanceInput;
};


/** The Root Mutation for the application */
export type MutationSavePropertyListingIterationDynamicDataArgs = {
  input: SavePropertyListingIterationDynamicDataInput;
};


/** The Root Mutation for the application */
export type MutationSendPdfPackageWithDataArgs = {
  input: SendPdfPackageWithDataInput;
};


/** The Root Mutation for the application */
export type MutationSendPushNotificationArgs = {
  body: Scalars['String'];
  fcmToken: Scalars['ID'];
  title: Scalars['String'];
};


/** The Root Mutation for the application */
export type MutationSetFollowActivityObjectArgs = {
  input: SetFollowActivityObjectInput;
};


/** The Root Mutation for the application */
export type MutationSetMediaArgs = {
  input: SetMediaInput;
};


/** The Root Mutation for the application */
export type MutationSignupUserArgs = {
  email: Scalars['String'];
};


/** The Root Mutation for the application */
export type MutationStartProjectInstanceArgs = {
  input: StartProjectInstanceInput;
};


/** The Root Mutation for the application */
export type MutationStartTaskInstanceArgs = {
  input: StartTaskInstanceInput;
};


/** The Root Mutation for the application */
export type MutationSubscribeToPushNotificationsArgs = {
  fcmToken?: InputMaybe<Scalars['ID']>;
  subscribe: Scalars['Boolean'];
};


/** The Root Mutation for the application */
export type MutationSynchronizePropertyListingsFromMlsArgs = {
  ids: Array<Scalars['ID']>;
};


/** The Root Mutation for the application */
export type MutationUndoPropertyListingOpportunityMilestoneArgs = {
  id: Scalars['ID'];
};


/** The Root Mutation for the application */
export type MutationUpdateImageArgs = {
  input: UpdateImageInput;
};


/** The Root Mutation for the application */
export type MutationUpdateMediaArgs = {
  input: UpdateMediaInput;
};


/** The Root Mutation for the application */
export type MutationUploadSpaceConfigurationModelArgs = {
  input: UploadSpaceConfigurationModelInput;
};


/** The Root Mutation for the application */
export type MutationValidateAddressArgs = {
  input: ValidateAddressInput;
};

export type NearByPropertySearchInput = {
  distance: DistanceInput;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type NearbyProperty = {
  __typename?: 'NearbyProperty';
  distance: Distance;
  formattedDistance: Scalars['String'];
  property: Property;
};

export type NoteActivity = Activity & {
  __typename?: 'NoteActivity';
  actor?: Maybe<AdminUser | AgentUser | ConsumerUser | ContactUser>;
  comments?: Maybe<Array<ActivityComment>>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** Whether the thread id fully read or there are any unread comments */
  isRead: Scalars['Boolean'];
  /** Whether the thread is resolved */
  isResolved?: Maybe<Scalars['Boolean']>;
  media?: Maybe<Array<File | Image | Model3D>>;
  message: Scalars['String'];
  object?: Maybe<AdminUser | AgentUser | ApproveSubmissionToMlsTaskInstance | BasicTaskInstance | ConsumerUser | ContactUser | FileUploadTaskInstance | FormFillTaskInstance | ProjectInstance | PropertyListing | PropertyListingOpportunity | SubmitListingToMlsTaskInstance>;
  /** Timestamp of the last time the thread was read by the current user. False if the current user is not a thread participant */
  readAt?: Maybe<Scalars['DateTime']>;
  relatedEntity?: Maybe<Address | AdminUser | AgentUser | ConsumerUser | ContactUser | DataFormInstance | DataFormStepInstance | Image | PropertyComparativeMarketAnalysis | PropertyListing | PropertyListingIteration | ResidentialPropertyRoom>;
  status: ActivityStatus;
  subType?: Maybe<ActivitySubType>;
  subTypeName?: Maybe<Scalars['String']>;
  type: ActivityType;
  typeName: Scalars['String'];
  verb: Scalars['String'];
  visibility: ActivityVisibility;
  visibilityName: Scalars['String'];
};


export type NoteActivityCommentsArgs = {
  latest?: InputMaybe<Scalars['Int']>;
  statuses?: InputMaybe<Array<ActivityCommentStatus>>;
};


export type NoteActivityMediaArgs = {
  first?: InputMaybe<Scalars['Int']>;
  statuses?: InputMaybe<Array<MediaStatus>>;
};

export type NumericDataField = DataField & {
  __typename?: 'NumericDataField';
  decimalPlaces?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  increment?: Maybe<Scalars['Float']>;
  key: Scalars['String'];
  logicalName?: Maybe<DataFieldLogicalName>;
  maxValue?: Maybe<Scalars['Float']>;
  minValue?: Maybe<Scalars['Float']>;
  mlsField?: Maybe<MlsField>;
  name: Scalars['String'];
  processed?: Maybe<Scalars['Boolean']>;
  type: DataFieldType;
  typeName: Scalars['String'];
};

export type NumericDataFormInputInstance = DataFormInputInstance & {
  __typename?: 'NumericDataFormInputInstance';
  currentValue?: Maybe<Scalars['String']>;
  definition: DataFormInputDefinition;
  editable: Scalars['Boolean'];
  id: Scalars['ID'];
};

export type NumericDataViewFieldInstance = DataViewFieldInstance & {
  __typename?: 'NumericDataViewFieldInstance';
  definition: DataViewFieldDefinition;
  value?: Maybe<Scalars['String']>;
};

export const enum OccupancyType {
  Owner = 'OWNER',
  Partial = 'PARTIAL',
  Tenant = 'TENANT',
  Vacant = 'VACANT'
};

export type OpenHouse = {
  __typename?: 'OpenHouse';
  arrivalInstructions?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  eventEndTime?: Maybe<Scalars['DateTime']>;
  eventStartTime?: Maybe<Scalars['DateTime']>;
  format?: Maybe<OpenHouseFormat>;
  formatName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  listing?: Maybe<ResidentialPropertyForSaleListing>;
  status?: Maybe<OpenHouseStatus>;
  statusName?: Maybe<Scalars['String']>;
  targetAudience?: Maybe<TargetAudience>;
  targetAudienceName?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type OpenHouseConnection = {
  __typename?: 'OpenHouseConnection';
  edges?: Maybe<Array<Maybe<OpenHouseConnectionEdge>>>;
  facetGroups?: Maybe<Array<Maybe<FacetGroup>>>;
  filters?: Maybe<Array<Maybe<AggregatedFacetFilter | BooleanFilter | DateRangeFilter | FloatRangeFilter | IntegerRangeFilter | SimpleFacetFilter | TagCollectionFilter>>>;
  histogramGroups?: Maybe<Array<Maybe<HistogramGroup>>>;
  metricGroups?: Maybe<Array<Maybe<MetricGroup>>>;
  pageInfo?: Maybe<PageInfo>;
  savedSearches?: Maybe<Array<Maybe<SavedSearch>>>;
  sortOptions?: Maybe<Array<Maybe<SortOption>>>;
};


export type OpenHouseConnectionFacetGroupsArgs = {
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type OpenHouseConnectionFiltersArgs = {
  aggregatedFacetKeywordSearch?: InputMaybe<AggregatedFacetKeywordSearchInput>;
  fetchAggregatedFacetsFilterIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  includeTagFilters?: InputMaybe<Scalars['Boolean']>;
  selected?: InputMaybe<Scalars['Boolean']>;
};


export type OpenHouseConnectionHistogramGroupsArgs = {
  histogramGroupInput?: InputMaybe<HistogramGroupInput>;
};


export type OpenHouseConnectionMetricGroupsArgs = {
  metricInput?: InputMaybe<Array<InputMaybe<MetricGroupInput>>>;
};

export type OpenHouseConnectionEdge = {
  __typename?: 'OpenHouseConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<OpenHouse>;
};

export type OpenHouseConnectionInput = {
  eventEndsAfter?: InputMaybe<Scalars['DateTime']>;
  eventEndsBefore?: InputMaybe<Scalars['DateTime']>;
  eventStartsAfter?: InputMaybe<Scalars['DateTime']>;
  eventStartsBefore?: InputMaybe<Scalars['DateTime']>;
  /** can search hosts by firstName/lastName/Email, as well as listing property address */
  filters?: InputMaybe<Array<FilterInput>>;
  first: Scalars['Int'];
  formats?: InputMaybe<Array<OpenHouseFormat>>;
  keyword?: InputMaybe<Scalars['String']>;
  listingIds?: InputMaybe<Array<Scalars['ID']>>;
  offset: Scalars['Int'];
  placeIds?: InputMaybe<Array<Scalars['ID']>>;
  placeSlugs?: InputMaybe<Array<Scalars['String']>>;
  savedSearchId?: InputMaybe<Scalars['ID']>;
  statuses?: InputMaybe<Array<OpenHouseStatus>>;
  tagIds?: InputMaybe<Array<Scalars['ID']>>;
  targetAudiences?: InputMaybe<Array<TargetAudience>>;
};

export const enum OpenHouseFormat {
  InPerson = 'IN_PERSON',
  Virtual = 'VIRTUAL'
};

export const enum OpenHouseStatus {
  Cancelled = 'CANCELLED',
  Draft = 'DRAFT',
  Published = 'PUBLISHED'
};

export type Opportunity = {
  activityFeed?: Maybe<ActivityFeed>;
  assignedAgents?: Maybe<Array<OpportunityAssignedAgent>>;
  commission: Scalars['BigDecimal'];
  commissionType: CommissionType;
  commissionTypeName: Scalars['String'];
  completedMilestones: Array<Maybe<OpportunityMilestone>>;
  createdAt: Scalars['DateTime'];
  customerTwilioPhoneNumber?: Maybe<TwilioPhoneNumber>;
  customers: Array<OpportunityCustomer>;
  following: Scalars['Boolean'];
  id: Scalars['ID'];
  objectType: ActivityObjectType;
  type: OpportunityType;
  typeName: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type OpportunityActivityFeedArgs = {
  containingActivityId?: InputMaybe<Scalars['ID']>;
  first?: Scalars['Int'];
  offset?: Scalars['Int'];
};

export type OpportunityAssignedAgent = {
  __typename?: 'OpportunityAssignedAgent';
  agent: AdminUser | AgentUser | ConsumerUser | ContactUser;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  opportunity: PropertyListingOpportunity;
  sequence: Scalars['Int'];
};

export type OpportunityConnection = {
  __typename?: 'OpportunityConnection';
  edges?: Maybe<Array<Maybe<OpportunityConnectionEdge>>>;
  facetGroups?: Maybe<Array<Maybe<FacetGroup>>>;
  filters?: Maybe<Array<Maybe<AggregatedFacetFilter | BooleanFilter | DateRangeFilter | FloatRangeFilter | IntegerRangeFilter | SimpleFacetFilter | TagCollectionFilter>>>;
  histogramGroups?: Maybe<Array<Maybe<HistogramGroup>>>;
  metricGroups?: Maybe<Array<Maybe<MetricGroup>>>;
  pageInfo?: Maybe<PageInfo>;
  savedSearches?: Maybe<Array<Maybe<SavedSearch>>>;
  sortOptions?: Maybe<Array<Maybe<SortOption>>>;
};


export type OpportunityConnectionFacetGroupsArgs = {
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type OpportunityConnectionFiltersArgs = {
  aggregatedFacetKeywordSearch?: InputMaybe<AggregatedFacetKeywordSearchInput>;
  fetchAggregatedFacetsFilterIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  includeTagFilters?: InputMaybe<Scalars['Boolean']>;
  selected?: InputMaybe<Scalars['Boolean']>;
};


export type OpportunityConnectionHistogramGroupsArgs = {
  histogramGroupInput?: InputMaybe<HistogramGroupInput>;
};


export type OpportunityConnectionMetricGroupsArgs = {
  metricInput?: InputMaybe<Array<InputMaybe<MetricGroupInput>>>;
};

export type OpportunityConnectionEdge = {
  __typename?: 'OpportunityConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<PropertyListingOpportunity>;
};

export type OpportunityConnectionInput = {
  assignedAgentIds?: InputMaybe<Array<Scalars['ID']>>;
  customerUserIds?: InputMaybe<Array<Scalars['ID']>>;
  filters?: InputMaybe<Array<FilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  keyword?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  savedSearchId?: InputMaybe<Scalars['ID']>;
  sortKey?: InputMaybe<OpportunitySortKey>;
  sortOptionId?: InputMaybe<Scalars['ID']>;
  sortOrder?: InputMaybe<SortOrder>;
  tagIds?: InputMaybe<Array<Scalars['ID']>>;
  userIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type OpportunityCustomer = {
  __typename?: 'OpportunityCustomer';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  opportunity: PropertyListingOpportunity;
  user: AdminUser | AgentUser | ConsumerUser | ContactUser;
};

export type OpportunityMilestone = {
  __typename?: 'OpportunityMilestone';
  completedAt: Scalars['DateTime'];
  completedBy?: Maybe<AdminUser | AgentUser | ConsumerUser | ContactUser>;
  id: Scalars['ID'];
  milestone: Scalars['String'];
  milestoneName: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  opportunity: PropertyListingOpportunity;
};

export type OpportunityMilestoneMetadata = {
  __typename?: 'OpportunityMilestoneMetadata';
  propertyListingOpportunityMilestone: Array<EnumValueMetadata>;
};

export const enum OpportunitySortKey {
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT'
};

export const enum OpportunityType {
  Listing = 'LISTING'
};

export const enum OwnershipType {
  Condo = 'CONDO',
  Freehold = 'FREEHOLD'
};

export type PageInfo = {
  __typename?: 'PageInfo';
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  totalEdges: Scalars['Int'];
};

export const enum ParkingType {
  Covered = 'COVERED',
  Garage = 'GARAGE',
  Indoor = 'INDOOR',
  None = 'NONE',
  Underground = 'UNDERGROUND'
};

export type PerformCustomActionInput = {
  actionDefinitionId: Scalars['ID'];
  payload?: InputMaybe<Scalars['JSON']>;
  projectInstanceId: Scalars['ID'];
};

export type PerformCustomActionResponse = {
  __typename?: 'PerformCustomActionResponse';
  payload?: Maybe<Scalars['JSON']>;
  success: Scalars['Boolean'];
};

export const enum PetType {
  Cat = 'CAT',
  Dog = 'DOG'
};

export type PhoneDataField = DataField & {
  __typename?: 'PhoneDataField';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  key: Scalars['String'];
  logicalName?: Maybe<DataFieldLogicalName>;
  mlsField?: Maybe<MlsField>;
  name: Scalars['String'];
  processed?: Maybe<Scalars['Boolean']>;
  type: DataFieldType;
  typeName: Scalars['String'];
};

export type PhoneDataFormInputInstance = DataFormInputInstance & {
  __typename?: 'PhoneDataFormInputInstance';
  currentValue?: Maybe<Scalars['String']>;
  definition: DataFormInputDefinition;
  editable: Scalars['Boolean'];
  id: Scalars['ID'];
};

export type PhoneDataViewFieldInstance = DataViewFieldInstance & {
  __typename?: 'PhoneDataViewFieldInstance';
  definition: DataViewFieldDefinition;
  value?: Maybe<Scalars['String']>;
};

export type Place = {
  __typename?: 'Place';
  city?: Maybe<Place>;
  country?: Maybe<Place>;
  createdAt: Scalars['DateTime'];
  geoJson?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  metroArea?: Maybe<Place>;
  nameAbbreviated?: Maybe<Scalars['String']>;
  nameLong: Scalars['String'];
  nameShort: Scalars['String'];
  region?: Maybe<Place>;
  slug: Scalars['String'];
  type: PlaceType;
  typeName: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export const enum PlaceAggregationValueField {
  ListingPrice = 'LISTING_PRICE'
};

export type PlaceAggregationsInput = {
  limit?: InputMaybe<Scalars['Int']>;
  parentPlaceSlug?: InputMaybe<Scalars['String']>;
  placeTypes: Array<PlaceType>;
  valueField: PlaceAggregationValueField;
};

export type PlaceAggregationsResponse = {
  __typename?: 'PlaceAggregationsResponse';
  placeTypeAggregations: Array<PlaceTypeAggregation>;
};

export type PlaceAutoCompleteSuggestion = {
  __typename?: 'PlaceAutoCompleteSuggestion';
  cities: Array<PlaceAutoCompleteSuggestionInfo>;
  metroAreas: Array<PlaceAutoCompleteSuggestionInfo>;
  neighbourhoods: Array<PlaceAutoCompleteSuggestionInfo>;
  regions: Array<PlaceAutoCompleteSuggestionInfo>;
  streets: Array<PlaceAutoCompleteSuggestionInfo>;
};

export type PlaceAutoCompleteSuggestionInfo = {
  __typename?: 'PlaceAutoCompleteSuggestionInfo';
  id: Scalars['ID'];
  nameLong: Scalars['String'];
  nameShort: Scalars['String'];
  slug: Scalars['String'];
};

export type PlaceAutocompleteInput = {
  input: Scalars['String'];
  locationBias?: InputMaybe<CoordinateInput>;
};

export type PlaceConnection = {
  __typename?: 'PlaceConnection';
  edges?: Maybe<Array<Maybe<PlaceConnectionEdge>>>;
  facetGroups?: Maybe<Array<Maybe<FacetGroup>>>;
  filters?: Maybe<Array<Maybe<AggregatedFacetFilter | BooleanFilter | DateRangeFilter | FloatRangeFilter | IntegerRangeFilter | SimpleFacetFilter | TagCollectionFilter>>>;
  histogramGroups?: Maybe<Array<Maybe<HistogramGroup>>>;
  metricGroups?: Maybe<Array<Maybe<MetricGroup>>>;
  pageInfo?: Maybe<PageInfo>;
  savedSearches?: Maybe<Array<Maybe<SavedSearch>>>;
  sortOptions?: Maybe<Array<Maybe<SortOption>>>;
};


export type PlaceConnectionFacetGroupsArgs = {
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type PlaceConnectionFiltersArgs = {
  aggregatedFacetKeywordSearch?: InputMaybe<AggregatedFacetKeywordSearchInput>;
  fetchAggregatedFacetsFilterIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  includeTagFilters?: InputMaybe<Scalars['Boolean']>;
  selected?: InputMaybe<Scalars['Boolean']>;
};


export type PlaceConnectionHistogramGroupsArgs = {
  histogramGroupInput?: InputMaybe<HistogramGroupInput>;
};


export type PlaceConnectionMetricGroupsArgs = {
  metricInput?: InputMaybe<Array<InputMaybe<MetricGroupInput>>>;
};

export type PlaceConnectionEdge = {
  __typename?: 'PlaceConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Place>;
};

export type PlaceConnectionInput = {
  filters?: InputMaybe<Array<FilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  geoShape?: InputMaybe<GeoShape>;
  keyword?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  placeIds?: InputMaybe<Array<Scalars['ID']>>;
  savedSearchId?: InputMaybe<Scalars['ID']>;
  sortKey?: InputMaybe<PlaceSortKey>;
  sortOptionId?: InputMaybe<Scalars['ID']>;
  sortOrder?: InputMaybe<SortOrder>;
  tagIds?: InputMaybe<Array<Scalars['ID']>>;
  types?: InputMaybe<Array<PlaceType>>;
};

export type PlaceInput = {
  id?: InputMaybe<Scalars['ID']>;
  slug?: InputMaybe<Scalars['String']>;
};

export const enum PlaceSortKey {
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT'
};

export const enum PlaceType {
  City = 'CITY',
  Country = 'COUNTRY',
  MacroNeighbourhood = 'MACRO_NEIGHBOURHOOD',
  MetroArea = 'METRO_AREA',
  Neighbourhood = 'NEIGHBOURHOOD',
  Region = 'REGION',
  Street = 'STREET',
  SubNeighbourhood = 'SUB_NEIGHBOURHOOD'
};

export type PlaceTypeAggregation = {
  __typename?: 'PlaceTypeAggregation';
  aggregations?: Maybe<Array<Aggregation>>;
  placeType: PlaceType;
  placeTypeName: Scalars['String'];
};

export type PlainEntityDataConfig = DataConfig & {
  __typename?: 'PlainEntityDataConfig';
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  entityType: EntityType;
  entityTypeName: Scalars['String'];
  fields: Array<AddressDataField | BooleanDataField | DateDataField | DropdownDataField | EmailDataField | FileDataField | ImageDataField | NumericDataField | PhoneDataField | PrimaryImageSelectDataField | RoomDataField | StringDataField | TimeDataField | UserDataField>;
  forms: Array<PropertyListingDataFormDefinition>;
  id: Scalars['ID'];
  logicalName?: Maybe<DataConfigLogicalName>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  views: Array<PropertyListingDataViewDefinition>;
};

export const enum PoolFeatureType {
  /** <https://ddwiki.reso.org/display/DDW17/PoolFeatures+Lookups> */
  AboveGround = 'ABOVE_GROUND',
  Association = 'ASSOCIATION',
  BlackBottom = 'BLACK_BOTTOM',
  Cabana = 'CABANA',
  Community = 'COMMUNITY',
  DivingBoard = 'DIVING_BOARD',
  ElectricHeat = 'ELECTRIC_HEAT',
  EnergyStarQualifiedPoolPump = 'ENERGY_STAR_QUALIFIED_POOL_PUMP',
  Fenced = 'FENCED',
  Fiberglass = 'FIBERGLASS',
  Filtered = 'FILTERED',
  GasHeat = 'GAS_HEAT',
  Gunite = 'GUNITE',
  Heated = 'HEATED',
  Indoor = 'INDOOR',
  Infinity = 'INFINITY',
  InGround = 'IN_GROUND',
  Lap = 'LAP',
  Liner = 'LINER',
  None = 'NONE',
  OutdoorPool = 'OUTDOOR_POOL',
  PoolCover = 'POOL_COVER',
  PoolSpaCombo = 'POOL_SPA_COMBO',
  PoolSweep = 'POOL_SWEEP',
  Private = 'PRIVATE',
  SaltWater = 'SALT_WATER',
  ScreenEnclosure = 'SCREEN_ENCLOSURE',
  SeeRemarks = 'SEE_REMARKS',
  SolarCover = 'SOLAR_COVER',
  SolarHeat = 'SOLAR_HEAT',
  Sport = 'SPORT',
  Tile = 'TILE',
  Vinyl = 'VINYL',
  Waterfall = 'WATERFALL'
};

export type PrimaryImageDataFormInputInstance = DataFormInputInstance & {
  __typename?: 'PrimaryImageDataFormInputInstance';
  currentValue?: Maybe<Scalars['String']>;
  definition: DataFormInputDefinition;
  editable: Scalars['Boolean'];
  id: Scalars['ID'];
};

export type PrimaryImageDataViewFieldInstance = DataViewFieldInstance & {
  __typename?: 'PrimaryImageDataViewFieldInstance';
  definition: DataViewFieldDefinition;
  value?: Maybe<Scalars['String']>;
};

export type PrimaryImageSelectDataField = DataField & {
  __typename?: 'PrimaryImageSelectDataField';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  key: Scalars['String'];
  logicalName?: Maybe<DataFieldLogicalName>;
  mlsField?: Maybe<MlsField>;
  name: Scalars['String'];
  processed?: Maybe<Scalars['Boolean']>;
  type: DataFieldType;
  typeName: Scalars['String'];
};

export type PrimitiveCollectionPropertyListingIterationChange = PropertyListingIterationChange & {
  __typename?: 'PrimitiveCollectionPropertyListingIterationChange';
  addedEntries?: Maybe<Array<Scalars['String']>>;
  field: Scalars['String'];
  removedEntries?: Maybe<Array<Scalars['String']>>;
  value?: Maybe<Array<Scalars['String']>>;
};

export type PrimitivePropertyListingIterationChange = PropertyListingIterationChange & {
  __typename?: 'PrimitivePropertyListingIterationChange';
  afterValue?: Maybe<Scalars['String']>;
  beforeValue?: Maybe<Scalars['String']>;
  field: Scalars['String'];
};

export type Product = {
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  ecommerceUrl?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  inventories: Array<ProductInventory>;
  length?: Maybe<Scalars['Float']>;
  media?: Maybe<Array<File | Image | Model3D>>;
  model?: Maybe<Model3D>;
  modelARIos?: Maybe<Model3D>;
  modelLowRes?: Maybe<Model3D>;
  /** Ultra High Definition (UHD) 3D model */
  modelUhd?: Maybe<Model3D>;
  /** Unbaked UHD 3D Model */
  modelUhdUnbaked?: Maybe<Model3D>;
  positioning?: Maybe<ProductPositioning>;
  positioningName?: Maybe<Scalars['String']>;
  /**
   * Deprecated in favour of ProductInventory.price
   * @deprecated No longer supported
   */
  price?: Maybe<MonetaryAmount>;
  primaryImage?: Maybe<Image>;
  sku?: Maybe<Scalars['String']>;
  status: ProductStatus;
  statusName: Scalars['String'];
  /**
   * Deprecated in favour of 'vendorCompany'
   * @deprecated No longer supported
   */
  storeName?: Maybe<Scalars['String']>;
  supplier?: Maybe<FurnitureSupplierCompany | FurnitureVendorCompany>;
  tagEntityType: TagEntityType;
  tags: Array<Tag>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  useCubemap: Scalars['Boolean'];
  vendor?: Maybe<FurnitureSupplierCompany | FurnitureVendorCompany>;
  width?: Maybe<Scalars['Float']>;
};


export type ProductHeightArgs = {
  unit?: InputMaybe<DimensionUnit>;
};


export type ProductLengthArgs = {
  unit?: InputMaybe<DimensionUnit>;
};


export type ProductMediaArgs = {
  first?: InputMaybe<Scalars['Int']>;
  statuses?: InputMaybe<Array<MediaStatus>>;
};


export type ProductWidthArgs = {
  unit?: InputMaybe<DimensionUnit>;
};

export type ProductConnection = {
  __typename?: 'ProductConnection';
  edges?: Maybe<Array<Maybe<ProductConnectionEdge>>>;
  facetGroups?: Maybe<Array<Maybe<FacetGroup>>>;
  filters?: Maybe<Array<Maybe<AggregatedFacetFilter | BooleanFilter | DateRangeFilter | FloatRangeFilter | IntegerRangeFilter | SimpleFacetFilter | TagCollectionFilter>>>;
  histogramGroups?: Maybe<Array<Maybe<HistogramGroup>>>;
  metricGroups?: Maybe<Array<Maybe<MetricGroup>>>;
  pageInfo?: Maybe<PageInfo>;
  savedSearches?: Maybe<Array<Maybe<SavedSearch>>>;
  sortOptions?: Maybe<Array<Maybe<SortOption>>>;
};


export type ProductConnectionFacetGroupsArgs = {
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type ProductConnectionFiltersArgs = {
  aggregatedFacetKeywordSearch?: InputMaybe<AggregatedFacetKeywordSearchInput>;
  fetchAggregatedFacetsFilterIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  includeTagFilters?: InputMaybe<Scalars['Boolean']>;
  selected?: InputMaybe<Scalars['Boolean']>;
};


export type ProductConnectionHistogramGroupsArgs = {
  histogramGroupInput?: InputMaybe<HistogramGroupInput>;
};


export type ProductConnectionMetricGroupsArgs = {
  metricInput?: InputMaybe<Array<InputMaybe<MetricGroupInput>>>;
};

export type ProductConnectionEdge = {
  __typename?: 'ProductConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<FurnitureProduct>;
};

export type ProductConnectionInput = {
  filters?: InputMaybe<Array<FilterInput>>;
  first: Scalars['Int'];
  furnitureCategories?: InputMaybe<Array<FurnitureCategory>>;
  /** Deprecated - will not filter anything */
  hasPrice?: InputMaybe<Scalars['Boolean']>;
  keyword?: InputMaybe<Scalars['String']>;
  offset: Scalars['Int'];
  /** Deprecated - will not filter anything */
  priceGTE?: InputMaybe<Scalars['BigDecimal']>;
  /** Deprecated - will not filter anything */
  priceLTE?: InputMaybe<Scalars['BigDecimal']>;
  savedSearchId?: InputMaybe<Scalars['ID']>;
  sortKey?: InputMaybe<ProductSortKey>;
  sortOptionId?: InputMaybe<Scalars['ID']>;
  sortOrder?: InputMaybe<SortOrder>;
  statuses?: InputMaybe<Array<ProductStatus>>;
  tagIds?: InputMaybe<Array<Scalars['ID']>>;
  types?: InputMaybe<Array<ProductType>>;
};

export type ProductInventory = {
  __typename?: 'ProductInventory';
  id: Scalars['ID'];
  price?: Maybe<MonetaryAmount>;
  product: FurnitureProduct;
  quantity?: Maybe<Scalars['Int']>;
  status: ProductInventoryStatus;
  statusName: Scalars['String'];
  vendorLocation: CompanyLocation;
};

export type ProductInventoryConnection = {
  __typename?: 'ProductInventoryConnection';
  edges?: Maybe<Array<Maybe<ProductInventoryConnectionEdge>>>;
  facetGroups?: Maybe<Array<Maybe<FacetGroup>>>;
  filters?: Maybe<Array<Maybe<AggregatedFacetFilter | BooleanFilter | DateRangeFilter | FloatRangeFilter | IntegerRangeFilter | SimpleFacetFilter | TagCollectionFilter>>>;
  histogramGroups?: Maybe<Array<Maybe<HistogramGroup>>>;
  metricGroups?: Maybe<Array<Maybe<MetricGroup>>>;
  pageInfo?: Maybe<PageInfo>;
  savedSearches?: Maybe<Array<Maybe<SavedSearch>>>;
  sortOptions?: Maybe<Array<Maybe<SortOption>>>;
};


export type ProductInventoryConnectionFacetGroupsArgs = {
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type ProductInventoryConnectionFiltersArgs = {
  aggregatedFacetKeywordSearch?: InputMaybe<AggregatedFacetKeywordSearchInput>;
  fetchAggregatedFacetsFilterIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  includeTagFilters?: InputMaybe<Scalars['Boolean']>;
  selected?: InputMaybe<Scalars['Boolean']>;
};


export type ProductInventoryConnectionHistogramGroupsArgs = {
  histogramGroupInput?: InputMaybe<HistogramGroupInput>;
};


export type ProductInventoryConnectionMetricGroupsArgs = {
  metricInput?: InputMaybe<Array<InputMaybe<MetricGroupInput>>>;
};

export type ProductInventoryConnectionEdge = {
  __typename?: 'ProductInventoryConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<ProductInventory>;
};

export type ProductInventoryConnectionInput = {
  distributionId?: InputMaybe<Scalars['ID']>;
  filters?: InputMaybe<Array<FilterInput>>;
  first: Scalars['Int'];
  keyword?: InputMaybe<Scalars['String']>;
  offset: Scalars['Int'];
  savedSearchId?: InputMaybe<Scalars['ID']>;
  sortKey?: InputMaybe<ProductSortKey>;
  sortOptionId?: InputMaybe<Scalars['ID']>;
  sortOrder?: InputMaybe<SortOrder>;
  statuses?: InputMaybe<Array<ProductStatus>>;
  tagIds?: InputMaybe<Array<Scalars['ID']>>;
  types?: InputMaybe<Array<ProductType>>;
};

export type ProductInventoryMetadata = {
  __typename?: 'ProductInventoryMetadata';
  status: Array<EnumValueMetadata>;
};

export const enum ProductInventoryStatus {
  Available = 'AVAILABLE',
  BackOrder = 'BACK_ORDER',
  ComingSoon = 'COMING_SOON',
  Unavailable = 'UNAVAILABLE'
};

export const enum ProductPositioning {
  Ceiling = 'CEILING',
  Floor = 'FLOOR',
  Surface = 'SURFACE',
  Wall = 'WALL',
  WallFloorLocked = 'WALL_FLOOR_LOCKED'
};

export const enum ProductSortKey {
  CreatedAt = 'CREATED_AT',
  /** Deprecated - not sortable by price */
  Price = 'PRICE',
  Title = 'TITLE',
  UpdatedAt = 'UPDATED_AT'
};

export const enum ProductStatus {
  Active = 'ACTIVE',
  Disabled = 'DISABLED',
  Draft = 'DRAFT'
};

export const enum ProductType {
  Furniture = 'FURNITURE'
};

export type ProjectCollaborator = {
  __typename?: 'ProjectCollaborator';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  role: ProjectRole;
  status: ProjectCollaboratorStatus;
  statusName: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  user: AdminUser | AgentUser | ConsumerUser | ContactUser;
};

export type ProjectCollaboratorInput = {
  message?: InputMaybe<Scalars['String']>;
  permissionIds?: InputMaybe<Array<Scalars['ID']>>;
  roleId?: InputMaybe<Scalars['ID']>;
  userId: Scalars['ID'];
};

export const enum ProjectCollaboratorStatus {
  Active = 'ACTIVE',
  PendingInvite = 'PENDING_INVITE',
  Removed = 'REMOVED'
};

export type ProjectDefinition = {
  __typename?: 'ProjectDefinition';
  actions?: Maybe<Array<CreateProjectActionDefinition | CreateTaskActionDefinition | CustomActionDefinition | StartProjectActionDefinition>>;
  /** Warning: this involves real time script execution. Only fetch this field if you really need it */
  assignments?: Maybe<Array<WorkflowAssignment>>;
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Icon>;
  id: Scalars['ID'];
  logicalName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  parentProjectDefinition?: Maybe<ProjectDefinition>;
  roles?: Maybe<Array<ProjectRole>>;
  scriptOnCancel?: Maybe<Scalars['String']>;
  scriptOnCreate?: Maybe<Scalars['String']>;
  scriptOnFinish?: Maybe<Scalars['String']>;
  scriptOnStart?: Maybe<Scalars['String']>;
  scriptValidateBeforeCancel?: Maybe<Scalars['String']>;
  scriptValidateBeforeCreate?: Maybe<Scalars['String']>;
  scriptValidateBeforeFinish?: Maybe<Scalars['String']>;
  scriptValidateBeforeStart?: Maybe<Scalars['String']>;
  stages?: Maybe<Array<ProjectStageDefinition>>;
  subProjects?: Maybe<Array<ProjectDefinition>>;
  tasks?: Maybe<Array<ApproveSubmissionToMlsTaskDefinition | AttachFileStorageTaskDefinition | BasicTaskDefinition | FileUploadTaskDefinition | FormAssignTaskDefinition | FormFillTaskDefinition | FormReviewTaskDefinition | SubmitListingToMlsTaskDefinition | UploadToFileStorageTaskDefinition>>;
  type: ProjectType;
  typeName: Scalars['String'];
  uiOnCancel?: Maybe<Array<Scalars['String']>>;
  uiOnCreate?: Maybe<Array<Scalars['String']>>;
  uiOnFinish?: Maybe<Array<Scalars['String']>>;
  uiOnStart?: Maybe<Array<Scalars['String']>>;
  workspace: Workspace;
};


export type ProjectDefinitionAssignmentsArgs = {
  projectInstanceId?: InputMaybe<Scalars['ID']>;
};

export type ProjectDefinitionConnection = {
  __typename?: 'ProjectDefinitionConnection';
  edges?: Maybe<Array<Maybe<ProjectDefinitionConnectionEdge>>>;
  facetGroups?: Maybe<Array<Maybe<FacetGroup>>>;
  filters?: Maybe<Array<Maybe<AggregatedFacetFilter | BooleanFilter | DateRangeFilter | FloatRangeFilter | IntegerRangeFilter | SimpleFacetFilter | TagCollectionFilter>>>;
  histogramGroups?: Maybe<Array<Maybe<HistogramGroup>>>;
  metricGroups?: Maybe<Array<Maybe<MetricGroup>>>;
  pageInfo?: Maybe<PageInfo>;
  savedSearches?: Maybe<Array<Maybe<SavedSearch>>>;
  sortOptions?: Maybe<Array<Maybe<SortOption>>>;
};


export type ProjectDefinitionConnectionFacetGroupsArgs = {
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type ProjectDefinitionConnectionFiltersArgs = {
  aggregatedFacetKeywordSearch?: InputMaybe<AggregatedFacetKeywordSearchInput>;
  fetchAggregatedFacetsFilterIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  includeTagFilters?: InputMaybe<Scalars['Boolean']>;
  selected?: InputMaybe<Scalars['Boolean']>;
};


export type ProjectDefinitionConnectionHistogramGroupsArgs = {
  histogramGroupInput?: InputMaybe<HistogramGroupInput>;
};


export type ProjectDefinitionConnectionMetricGroupsArgs = {
  metricInput?: InputMaybe<Array<InputMaybe<MetricGroupInput>>>;
};

export type ProjectDefinitionConnectionEdge = {
  __typename?: 'ProjectDefinitionConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<ProjectDefinition>;
};

export type ProjectDefinitionConnectionInput = {
  filters?: InputMaybe<Array<FilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  keyword?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  savedSearchId?: InputMaybe<Scalars['ID']>;
  sortOptionId?: InputMaybe<Scalars['ID']>;
  tagIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type ProjectEventResponse = {
  __typename?: 'ProjectEventResponse';
  payload?: Maybe<Scalars['JSON']>;
  project: ProjectInstance;
};

export type ProjectInstance = ActivityObject & {
  __typename?: 'ProjectInstance';
  activityFeed?: Maybe<ActivityFeed>;
  availableActions?: Maybe<Array<CreateProjectActionDefinition | CreateTaskActionDefinition | CustomActionDefinition | StartProjectActionDefinition>>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  definition: ProjectDefinition;
  finishedAt?: Maybe<Scalars['DateTime']>;
  following: Scalars['Boolean'];
  id: Scalars['ID'];
  milestones?: Maybe<Array<ProjectInstanceMilestone>>;
  myTasks?: Maybe<Array<ApproveSubmissionToMlsTaskInstance | BasicTaskInstance | FileUploadTaskInstance | FormFillTaskInstance | SubmitListingToMlsTaskInstance>>;
  myThreads?: Maybe<Array<InquiryCreatedActivity | NoteActivity | ProjectStageEventActivity | PropertyListingLikeActivity | PropertyListingViewActivity | RootProjectEventActivity | SubProjectEventActivity | TaskAssignActivity | TaskEventActivity | UserAssignedAgentAddedActivity | UserAssignedAgentRemovedActivity | UserReactivatedActivity | UserSuspendedActivity>>;
  name: Scalars['String'];
  objectType: ActivityObjectType;
  parentProjectInstance?: Maybe<ProjectInstance>;
  projectCollaborators?: Maybe<Array<ProjectCollaborator>>;
  projectLead?: Maybe<AdminUser | AgentUser | ConsumerUser | ContactUser>;
  relatedEntities?: Maybe<Array<ProjectInstanceRelatedEntity>>;
  stages?: Maybe<Array<ProjectStageInstance>>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status: ProjectInstanceStatus;
  statusName: Scalars['String'];
  subProjects?: Maybe<Array<ProjectInstance>>;
  tasks?: Maybe<Array<ApproveSubmissionToMlsTaskInstance | BasicTaskInstance | FileUploadTaskInstance | FormFillTaskInstance | SubmitListingToMlsTaskInstance>>;
  type: ProjectType;
  typeName: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  workspace: Workspace;
};


export type ProjectInstanceActivityFeedArgs = {
  containingActivityId?: InputMaybe<Scalars['ID']>;
  first?: Scalars['Int'];
  offset?: Scalars['Int'];
};


export type ProjectInstanceMyTasksArgs = {
  first?: InputMaybe<Scalars['Int']>;
};


export type ProjectInstanceMyThreadsArgs = {
  first?: InputMaybe<Scalars['Int']>;
};

export type ProjectInstanceConnection = {
  __typename?: 'ProjectInstanceConnection';
  edges?: Maybe<Array<Maybe<ProjectInstanceConnectionEdge>>>;
  facetGroups?: Maybe<Array<Maybe<FacetGroup>>>;
  filters?: Maybe<Array<Maybe<AggregatedFacetFilter | BooleanFilter | DateRangeFilter | FloatRangeFilter | IntegerRangeFilter | SimpleFacetFilter | TagCollectionFilter>>>;
  histogramGroups?: Maybe<Array<Maybe<HistogramGroup>>>;
  metricGroups?: Maybe<Array<Maybe<MetricGroup>>>;
  pageInfo?: Maybe<PageInfo>;
  savedSearches?: Maybe<Array<Maybe<SavedSearch>>>;
  sortOptions?: Maybe<Array<Maybe<SortOption>>>;
};


export type ProjectInstanceConnectionFacetGroupsArgs = {
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type ProjectInstanceConnectionFiltersArgs = {
  aggregatedFacetKeywordSearch?: InputMaybe<AggregatedFacetKeywordSearchInput>;
  fetchAggregatedFacetsFilterIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  includeTagFilters?: InputMaybe<Scalars['Boolean']>;
  selected?: InputMaybe<Scalars['Boolean']>;
};


export type ProjectInstanceConnectionHistogramGroupsArgs = {
  histogramGroupInput?: InputMaybe<HistogramGroupInput>;
};


export type ProjectInstanceConnectionMetricGroupsArgs = {
  metricInput?: InputMaybe<Array<InputMaybe<MetricGroupInput>>>;
};

export type ProjectInstanceConnectionEdge = {
  __typename?: 'ProjectInstanceConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<ProjectInstance>;
};

export type ProjectInstanceConnectionInput = {
  filters?: InputMaybe<Array<FilterInput>>;
  first: Scalars['Int'];
  keyword?: InputMaybe<Scalars['String']>;
  offset: Scalars['Int'];
  /** Filter projects that are related to a certain entity. */
  relatedEntity?: InputMaybe<RelatedEntityKeyInput>;
  savedSearchId?: InputMaybe<Scalars['ID']>;
  sortOptionId?: InputMaybe<Scalars['ID']>;
  tagIds?: InputMaybe<Array<Scalars['ID']>>;
  workspaceId?: InputMaybe<Scalars['ID']>;
};

export type ProjectInstanceMilestone = {
  __typename?: 'ProjectInstanceMilestone';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ProjectInstanceRelatedEntity = {
  __typename?: 'ProjectInstanceRelatedEntity';
  entity: Address | AdminUser | AgentUser | ConsumerUser | ContactUser | DataFormInstance | DataFormStepInstance | Image | PropertyComparativeMarketAnalysis | PropertyListing | PropertyListingIteration | ResidentialPropertyRoom;
  id: Scalars['ID'];
  isPublic?: Maybe<Scalars['Boolean']>;
  key: Scalars['String'];
};

export const enum ProjectInstanceStatus {
  Cancelled = 'CANCELLED',
  Finished = 'FINISHED',
  InProgress = 'IN_PROGRESS',
  NotStarted = 'NOT_STARTED'
};

export type ProjectRole = {
  __typename?: 'ProjectRole';
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  logicalName?: Maybe<Scalars['String']>;
  maxPerProject?: Maybe<Scalars['Int']>;
  minPerProject?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  type: ProjectRoleType;
  typeName: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export const enum ProjectRoleType {
  Admin = 'ADMIN',
  Member = 'MEMBER',
  OutsideCollaborator = 'OUTSIDE_COLLABORATOR',
  Owner = 'OWNER'
};

export type ProjectStageDefinition = {
  __typename?: 'ProjectStageDefinition';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  initialProjectDefinition?: Maybe<ProjectDefinition>;
  logicalName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  sequence?: Maybe<Scalars['Int']>;
};

export type ProjectStageEventActivity = Activity & {
  __typename?: 'ProjectStageEventActivity';
  actor?: Maybe<AdminUser | AgentUser | ConsumerUser | ContactUser>;
  comments?: Maybe<Array<ActivityComment>>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** Whether the thread id fully read or there are any unread comments */
  isRead: Scalars['Boolean'];
  /** Whether the thread is resolved */
  isResolved?: Maybe<Scalars['Boolean']>;
  media?: Maybe<Array<File | Image | Model3D>>;
  message?: Maybe<Scalars['String']>;
  object?: Maybe<AdminUser | AgentUser | ApproveSubmissionToMlsTaskInstance | BasicTaskInstance | ConsumerUser | ContactUser | FileUploadTaskInstance | FormFillTaskInstance | ProjectInstance | PropertyListing | PropertyListingOpportunity | SubmitListingToMlsTaskInstance>;
  /** Timestamp of the last time the thread was read by the current user. False if the current user is not a thread participant */
  readAt?: Maybe<Scalars['DateTime']>;
  relatedEntity?: Maybe<Address | AdminUser | AgentUser | ConsumerUser | ContactUser | DataFormInstance | DataFormStepInstance | Image | PropertyComparativeMarketAnalysis | PropertyListing | PropertyListingIteration | ResidentialPropertyRoom>;
  stage: ProjectStageDefinition;
  status: ActivityStatus;
  subType?: Maybe<ActivitySubType>;
  subTypeName?: Maybe<Scalars['String']>;
  type: ActivityType;
  typeName: Scalars['String'];
  verb: Scalars['String'];
  visibility: ActivityVisibility;
  visibilityName: Scalars['String'];
};


export type ProjectStageEventActivityCommentsArgs = {
  latest?: InputMaybe<Scalars['Int']>;
  statuses?: InputMaybe<Array<ActivityCommentStatus>>;
};


export type ProjectStageEventActivityMediaArgs = {
  first?: InputMaybe<Scalars['Int']>;
  statuses?: InputMaybe<Array<MediaStatus>>;
};

export type ProjectStageInstance = {
  __typename?: 'ProjectStageInstance';
  definition: ProjectStageDefinition;
  progressPercent: Scalars['Int'];
  projects?: Maybe<Array<ProjectInstance>>;
  status: ProjectStageStatus;
  statusLine: Scalars['String'];
  statusName: Scalars['String'];
  totalFinished: Scalars['Int'];
  totalInProgress: Scalars['Int'];
  totalNotStarted: Scalars['Int'];
  totalProjects: Scalars['Int'];
};

export const enum ProjectStageStatus {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  NotStarted = 'NOT_STARTED'
};

export const enum ProjectType {
  Basic = 'BASIC',
  Form = 'FORM'
};

export type Property = {
  __typename?: 'Property';
  address: Address;
  createdAt: Scalars['DateTime'];
  distance?: Maybe<Distance>;
  id: Scalars['ID'];
  listings?: Maybe<Array<ResidentialPropertyForSaleListing>>;
  status: PropertyStatus;
  statusName: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type PropertyComparativeMarketAnalysis = Entity & {
  __typename?: 'PropertyComparativeMarketAnalysis';
  address: Address;
  attachments?: Maybe<Array<File | Image | Model3D>>;
  comparables?: Maybe<Array<PropertyComparativeMarketAnalysisComparable>>;
  completedAt?: Maybe<Scalars['DateTime']>;
  completedBy?: Maybe<AdminUser | AgentUser | ConsumerUser | ContactUser>;
  createdAt: Scalars['DateTime'];
  estimatedTransactionPriceMax?: Maybe<MonetaryAmount>;
  estimatedTransactionPriceMin?: Maybe<MonetaryAmount>;
  formInstance: DataFormInstance;
  id: Scalars['ID'];
  propertyListingType: PropertyListingType;
  suggestedListPriceMax?: Maybe<MonetaryAmount>;
  suggestedListPriceMin?: Maybe<MonetaryAmount>;
  thread?: Maybe<InquiryCreatedActivity | NoteActivity | ProjectStageEventActivity | PropertyListingLikeActivity | PropertyListingViewActivity | RootProjectEventActivity | SubProjectEventActivity | TaskAssignActivity | TaskEventActivity | UserAssignedAgentAddedActivity | UserAssignedAgentRemovedActivity | UserReactivatedActivity | UserSuspendedActivity>;
  valuationNotes?: Maybe<Scalars['String']>;
  workspace: Workspace;
};


export type PropertyComparativeMarketAnalysisAttachmentsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  statuses?: InputMaybe<Array<MediaStatus>>;
};

export type PropertyComparativeMarketAnalysisComparable = {
  __typename?: 'PropertyComparativeMarketAnalysisComparable';
  dataFields?: Maybe<Array<PropertyComparativeMarketAnalysisComparableDataField>>;
  propertyListing: PropertyListing;
};


export type PropertyComparativeMarketAnalysisComparableDataFieldsArgs = {
  dataFieldIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type PropertyComparativeMarketAnalysisComparableDataField = {
  __typename?: 'PropertyComparativeMarketAnalysisComparableDataField';
  id: Scalars['ID'];
  name: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type PropertyComparativeMarketAnalysisConnection = {
  __typename?: 'PropertyComparativeMarketAnalysisConnection';
  edges?: Maybe<Array<Maybe<PropertyComparativeMarketAnalysisConnectionEdge>>>;
  facetGroups?: Maybe<Array<Maybe<FacetGroup>>>;
  filters?: Maybe<Array<Maybe<AggregatedFacetFilter | BooleanFilter | DateRangeFilter | FloatRangeFilter | IntegerRangeFilter | SimpleFacetFilter | TagCollectionFilter>>>;
  histogramGroups?: Maybe<Array<Maybe<HistogramGroup>>>;
  metricGroups?: Maybe<Array<Maybe<MetricGroup>>>;
  pageInfo?: Maybe<PageInfo>;
  savedSearches?: Maybe<Array<Maybe<SavedSearch>>>;
  sortOptions?: Maybe<Array<Maybe<SortOption>>>;
};


export type PropertyComparativeMarketAnalysisConnectionFacetGroupsArgs = {
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type PropertyComparativeMarketAnalysisConnectionFiltersArgs = {
  aggregatedFacetKeywordSearch?: InputMaybe<AggregatedFacetKeywordSearchInput>;
  fetchAggregatedFacetsFilterIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  includeTagFilters?: InputMaybe<Scalars['Boolean']>;
  selected?: InputMaybe<Scalars['Boolean']>;
};


export type PropertyComparativeMarketAnalysisConnectionHistogramGroupsArgs = {
  histogramGroupInput?: InputMaybe<HistogramGroupInput>;
};


export type PropertyComparativeMarketAnalysisConnectionMetricGroupsArgs = {
  metricInput?: InputMaybe<Array<InputMaybe<MetricGroupInput>>>;
};

export type PropertyComparativeMarketAnalysisConnectionEdge = {
  __typename?: 'PropertyComparativeMarketAnalysisConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<PropertyComparativeMarketAnalysis>;
};

export type PropertyComparativeMarketAnalysisConnectionInput = {
  filters?: InputMaybe<Array<FilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  keyword?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  savedSearchId?: InputMaybe<Scalars['ID']>;
  sortOptionId?: InputMaybe<Scalars['ID']>;
  tagIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type PropertyConnection = {
  __typename?: 'PropertyConnection';
  edges?: Maybe<Array<Maybe<PropertyConnectionEdge>>>;
  facetGroups?: Maybe<Array<Maybe<FacetGroup>>>;
  filters?: Maybe<Array<Maybe<AggregatedFacetFilter | BooleanFilter | DateRangeFilter | FloatRangeFilter | IntegerRangeFilter | SimpleFacetFilter | TagCollectionFilter>>>;
  histogramGroups?: Maybe<Array<Maybe<HistogramGroup>>>;
  metricGroups?: Maybe<Array<Maybe<MetricGroup>>>;
  pageInfo?: Maybe<PageInfo>;
  places?: Maybe<Array<Maybe<Place>>>;
  savedSearches?: Maybe<Array<Maybe<SavedSearch>>>;
  sortOptions?: Maybe<Array<Maybe<SortOption>>>;
};


export type PropertyConnectionFacetGroupsArgs = {
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type PropertyConnectionFiltersArgs = {
  aggregatedFacetKeywordSearch?: InputMaybe<AggregatedFacetKeywordSearchInput>;
  fetchAggregatedFacetsFilterIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  includeTagFilters?: InputMaybe<Scalars['Boolean']>;
  selected?: InputMaybe<Scalars['Boolean']>;
};


export type PropertyConnectionHistogramGroupsArgs = {
  histogramGroupInput?: InputMaybe<HistogramGroupInput>;
};


export type PropertyConnectionMetricGroupsArgs = {
  metricInput?: InputMaybe<Array<InputMaybe<MetricGroupInput>>>;
};

export type PropertyConnectionEdge = {
  __typename?: 'PropertyConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Property>;
};

export type PropertyConnectionInput = {
  filters?: InputMaybe<Array<FilterInput>>;
  first: Scalars['Int'];
  geo?: InputMaybe<GeoInput>;
  keyword?: InputMaybe<Scalars['String']>;
  offset: Scalars['Int'];
  placeIds?: InputMaybe<Array<Scalars['ID']>>;
  placeSlugs?: InputMaybe<Array<Scalars['String']>>;
  savedSearchId?: InputMaybe<Scalars['ID']>;
  sortKey?: InputMaybe<PropertySortKey>;
  sortOptionId?: InputMaybe<Scalars['ID']>;
  sortOrder?: InputMaybe<SortOrder>;
  status?: InputMaybe<PropertyStatus>;
  tagIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type PropertyFloorPlan = {
  __typename?: 'PropertyFloorPlan';
  address?: Maybe<Address>;
  configurations?: Maybe<Array<PropertyFloorPlanConfiguration>>;
  createdAt: Scalars['DateTime'];
  defaultLevel?: Maybe<PropertyFloorPlanLevel>;
  id: Scalars['ID'];
  levels?: Maybe<Array<PropertyFloorPlanLevel>>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type PropertyFloorPlanConfigurationsArgs = {
  published?: InputMaybe<Scalars['Boolean']>;
};

export type PropertyFloorPlanComponentMaterial = {
  __typename?: 'PropertyFloorPlanComponentMaterial';
  componentId: Scalars['ID'];
  material: SpaceMaterial;
};

export type PropertyFloorPlanComponentMaterialInput = {
  componentId: Scalars['ID'];
  materialId: Scalars['ID'];
};

export type PropertyFloorPlanConfiguration = SpaceConfiguration & {
  __typename?: 'PropertyFloorPlanConfiguration';
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  floorPlan: PropertyFloorPlan;
  id: Scalars['ID'];
  levels?: Maybe<Array<PropertyFloorPlanLevelConfiguration>>;
  name: Scalars['String'];
  publiclyAvailable: Scalars['Boolean'];
  published: Scalars['Boolean'];
  user?: Maybe<AdminUser | AgentUser | ConsumerUser | ContactUser>;
};

export type PropertyFloorPlanConnection = {
  __typename?: 'PropertyFloorPlanConnection';
  edges?: Maybe<Array<Maybe<PropertyFloorPlanConnectionEdge>>>;
  facetGroups?: Maybe<Array<Maybe<FacetGroup>>>;
  filters?: Maybe<Array<Maybe<AggregatedFacetFilter | BooleanFilter | DateRangeFilter | FloatRangeFilter | IntegerRangeFilter | SimpleFacetFilter | TagCollectionFilter>>>;
  histogramGroups?: Maybe<Array<Maybe<HistogramGroup>>>;
  metricGroups?: Maybe<Array<Maybe<MetricGroup>>>;
  pageInfo?: Maybe<PageInfo>;
  savedSearches?: Maybe<Array<Maybe<SavedSearch>>>;
  sortOptions?: Maybe<Array<Maybe<SortOption>>>;
};


export type PropertyFloorPlanConnectionFacetGroupsArgs = {
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type PropertyFloorPlanConnectionFiltersArgs = {
  aggregatedFacetKeywordSearch?: InputMaybe<AggregatedFacetKeywordSearchInput>;
  fetchAggregatedFacetsFilterIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  includeTagFilters?: InputMaybe<Scalars['Boolean']>;
  selected?: InputMaybe<Scalars['Boolean']>;
};


export type PropertyFloorPlanConnectionHistogramGroupsArgs = {
  histogramGroupInput?: InputMaybe<HistogramGroupInput>;
};


export type PropertyFloorPlanConnectionMetricGroupsArgs = {
  metricInput?: InputMaybe<Array<InputMaybe<MetricGroupInput>>>;
};

export type PropertyFloorPlanConnectionEdge = {
  __typename?: 'PropertyFloorPlanConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<PropertyFloorPlan>;
};

export type PropertyFloorPlanConnectionInput = {
  filters?: InputMaybe<Array<FilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  keyword?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  savedSearchId?: InputMaybe<Scalars['ID']>;
  sortKey?: InputMaybe<PropertyFloorPlanSortKey>;
  sortOptionId?: InputMaybe<Scalars['ID']>;
  sortOrder?: InputMaybe<SortOrder>;
  tagIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type PropertyFloorPlanLevel = {
  __typename?: 'PropertyFloorPlanLevel';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  level?: Maybe<Level>;
  levelName?: Maybe<Scalars['String']>;
  model?: Maybe<Model3D>;
  squareFootage?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PropertyFloorPlanLevelConfiguration = {
  __typename?: 'PropertyFloorPlanLevelConfiguration';
  floorPlanLevel?: Maybe<PropertyFloorPlanLevel>;
  floors?: Maybe<Array<PropertyFloorPlanComponentMaterial>>;
  id: Scalars['ID'];
  items: Array<SpaceConfigurationItem>;
  itemsModel?: Maybe<Model3D>;
  model?: Maybe<Model3D>;
  walls?: Maybe<Array<PropertyFloorPlanComponentMaterial>>;
};

export type PropertyFloorPlanLevelConfigurationInput = {
  floorPlanLevelId: Scalars['ID'];
  floors?: InputMaybe<Array<PropertyFloorPlanComponentMaterialInput>>;
  items: Array<SpaceConfigurationItemInput>;
  walls?: InputMaybe<Array<PropertyFloorPlanComponentMaterialInput>>;
};

export const enum PropertyFloorPlanSortKey {
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT'
};

export type PropertyListing = ActivityObject & Entity & {
  __typename?: 'PropertyListing';
  activityFeed?: Maybe<ActivityFeed>;
  createdAt: Scalars['DateTime'];
  currentIteration: PropertyListingIteration;
  dataOrigin: PropertyListingDataOrigin;
  dataOriginName: Scalars['String'];
  distance?: Maybe<Distance>;
  following: Scalars['Boolean'];
  id: Scalars['ID'];
  iterations: Array<PropertyListingIteration>;
  latestIteration: PropertyListingIteration;
  listedAt?: Maybe<Scalars['DateTime']>;
  listingType: PropertyListingType;
  listingTypeName: Scalars['String'];
  mlsBoard: MlsBoard;
  mlsNumber?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  objectType: ActivityObjectType;
  opportunity?: Maybe<PropertyListingOpportunity>;
  primarySeller?: Maybe<AdminUser | AgentUser | ConsumerUser | ContactUser>;
  slug?: Maybe<Scalars['String']>;
  status: PropertyListingStatus;
  statusName: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  visibility: ListingVisibility;
  workspace?: Maybe<Workspace>;
};


export type PropertyListingActivityFeedArgs = {
  containingActivityId?: InputMaybe<Scalars['ID']>;
  first?: Scalars['Int'];
  offset?: Scalars['Int'];
};

export type PropertyListingClustersInput = {
  /** Geo shape within the results should be returned */
  geoShape: GeoShape;
  /** The resolution(1 to 12 where 12 is the most detailed) */
  resolution: Scalars['Int'];
};

export type PropertyListingComparablesInput = {
  /** Controls how to aggregate listings, by number of bedrooms / property style etc */
  aggregationMode?: InputMaybe<ListingAggregationMode>;
  /** Controls how to calculate prices in the response - as average or mean(median) */
  aggregationValueMode?: InputMaybe<AggregationValueMode>;
  /** Deprecated, values calculated if the 'value' field is requested by the client */
  includeAggregationValue: Scalars['Boolean'];
  useCache?: InputMaybe<Scalars['Boolean']>;
};

export type PropertyListingConnection = {
  __typename?: 'PropertyListingConnection';
  edges?: Maybe<Array<Maybe<PropertyListingConnectionEdge>>>;
  facetGroups?: Maybe<Array<Maybe<FacetGroup>>>;
  filters?: Maybe<Array<Maybe<AggregatedFacetFilter | BooleanFilter | DateRangeFilter | FloatRangeFilter | IntegerRangeFilter | SimpleFacetFilter | TagCollectionFilter>>>;
  histogramGroups?: Maybe<Array<Maybe<HistogramGroup>>>;
  metricGroups?: Maybe<Array<Maybe<MetricGroup>>>;
  pageInfo?: Maybe<PageInfo>;
  places?: Maybe<Array<Maybe<Place>>>;
  savedSearches?: Maybe<Array<Maybe<SavedSearch>>>;
  sortOptions?: Maybe<Array<Maybe<SortOption>>>;
};


export type PropertyListingConnectionFacetGroupsArgs = {
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type PropertyListingConnectionFiltersArgs = {
  aggregatedFacetKeywordSearch?: InputMaybe<AggregatedFacetKeywordSearchInput>;
  fetchAggregatedFacetsFilterIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  includeTagFilters?: InputMaybe<Scalars['Boolean']>;
  selected?: InputMaybe<Scalars['Boolean']>;
};


export type PropertyListingConnectionHistogramGroupsArgs = {
  histogramGroupInput?: InputMaybe<HistogramGroupInput>;
};


export type PropertyListingConnectionMetricGroupsArgs = {
  metricInput?: InputMaybe<Array<InputMaybe<MetricGroupInput>>>;
};

export type PropertyListingConnectionEdge = {
  __typename?: 'PropertyListingConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<PropertyListing>;
};

export type PropertyListingConnectionInput = {
  accessibilityFeatures?: InputMaybe<Array<AccessibilityFeature>>;
  ageGTE?: InputMaybe<Scalars['Int']>;
  ageLTE?: InputMaybe<Scalars['Int']>;
  apartmentStyles?: InputMaybe<Array<ApartmentStyle>>;
  buildingId?: InputMaybe<Scalars['ID']>;
  dateListedGTE?: InputMaybe<Scalars['Date']>;
  dateListedLTE?: InputMaybe<Scalars['Date']>;
  dateSoldGTE?: InputMaybe<Scalars['Date']>;
  dateSoldLTE?: InputMaybe<Scalars['Date']>;
  filters?: InputMaybe<Array<FilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  geo?: InputMaybe<GeoInput>;
  geoShape?: InputMaybe<GeoShape>;
  hasListingPrice?: InputMaybe<Scalars['Boolean']>;
  hasMedia?: InputMaybe<Scalars['Boolean']>;
  hasSalePrice?: InputMaybe<Scalars['Boolean']>;
  hasSquareFootage?: InputMaybe<Scalars['Boolean']>;
  hasVirtualTour?: InputMaybe<Scalars['Boolean']>;
  houseStyles?: InputMaybe<Array<HouseStyle>>;
  inquired?: InputMaybe<Scalars['Boolean']>;
  inquiredByUserIds?: InputMaybe<Array<Scalars['ID']>>;
  keyword?: InputMaybe<Scalars['String']>;
  liked?: InputMaybe<Scalars['Boolean']>;
  likedByUserIds?: InputMaybe<Array<Scalars['ID']>>;
  listingPriceGTE?: InputMaybe<Scalars['BigDecimal']>;
  listingPriceLTE?: InputMaybe<Scalars['BigDecimal']>;
  listingStatuses?: InputMaybe<Array<PropertyListingStatus>>;
  listingTypes?: InputMaybe<Array<ListingType>>;
  lotSizeSqftGTE?: InputMaybe<Scalars['Float']>;
  lotSizeSqftLTE?: InputMaybe<Scalars['Float']>;
  mapClusterIds?: InputMaybe<Array<Scalars['ID']>>;
  mapClusterIdsNot?: InputMaybe<Array<Scalars['ID']>>;
  monthlyMaintenanceExpenseGTE?: InputMaybe<Scalars['BigDecimal']>;
  monthlyMaintenanceExpenseLTE?: InputMaybe<Scalars['BigDecimal']>;
  offset?: InputMaybe<Scalars['Int']>;
  openHouseDates?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  ownershipTypes?: InputMaybe<Array<OwnershipType>>;
  parkingTypes?: InputMaybe<Array<ParkingType>>;
  placeIds?: InputMaybe<Array<Scalars['ID']>>;
  placeSlugs?: InputMaybe<Array<Scalars['String']>>;
  propertyId?: InputMaybe<Scalars['ID']>;
  residentialSubTypes?: InputMaybe<Array<ResidentialSubType>>;
  residentialTypes?: InputMaybe<Array<ResidentialType>>;
  salePriceGTE?: InputMaybe<Scalars['BigDecimal']>;
  salePriceLTE?: InputMaybe<Scalars['BigDecimal']>;
  savedSearchId?: InputMaybe<Scalars['ID']>;
  sortKey?: InputMaybe<PropertyListingSortKey>;
  sortOptionId?: InputMaybe<Scalars['ID']>;
  sortOrder?: InputMaybe<SortOrder>;
  squareFootageGTE?: InputMaybe<Scalars['Long']>;
  squareFootageLTE?: InputMaybe<Scalars['Long']>;
  tagIds?: InputMaybe<Array<Scalars['ID']>>;
  tags?: InputMaybe<Array<Scalars['String']>>;
  totalBathroomsGTE?: InputMaybe<Scalars['Float']>;
  totalBathroomsLTE?: InputMaybe<Scalars['Float']>;
  totalBedroomsGTE?: InputMaybe<Scalars['Int']>;
  totalBedroomsLTE?: InputMaybe<Scalars['Int']>;
  totalParkingGTE?: InputMaybe<Scalars['Int']>;
  totalParkingLTE?: InputMaybe<Scalars['Int']>;
};

export type PropertyListingDataConfig = DataConfig & {
  __typename?: 'PropertyListingDataConfig';
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  fields: Array<AddressDataField | BooleanDataField | DateDataField | DropdownDataField | EmailDataField | FileDataField | ImageDataField | NumericDataField | PhoneDataField | PrimaryImageSelectDataField | RoomDataField | StringDataField | TimeDataField | UserDataField>;
  formViewSet?: Maybe<PropertyListingDataFormViewSet>;
  forms: Array<PropertyListingDataFormDefinition>;
  id: Scalars['ID'];
  logicalName?: Maybe<DataConfigLogicalName>;
  mlsBoard: MlsBoard;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  views: Array<PropertyListingDataViewDefinition>;
};


export type PropertyListingDataConfigFormViewSetArgs = {
  propertyType: PropertyType;
  transactionType: TransactionType;
};

export type PropertyListingDataFormDefinition = DataFormDefinition & {
  __typename?: 'PropertyListingDataFormDefinition';
  dataConfig: PlainEntityDataConfig | PropertyListingDataConfig;
  description?: Maybe<Scalars['String']>;
  formType?: Maybe<PropertyListingDataFormType>;
  formTypeName?: Maybe<Scalars['String']>;
  hasReviewStep: Scalars['Boolean'];
  icon?: Maybe<Icon>;
  id: Scalars['ID'];
  instancePreview: DataFormInstance;
  isFinalizable: Scalars['Boolean'];
  linear: Scalars['Boolean'];
  listingType: PropertyListingType;
  listingTypeName: Scalars['String'];
  logicalName?: Maybe<DataFormLogicalName>;
  name: Scalars['String'];
  status: DataFormStatus;
  steps: Array<DataFormStepDefinition>;
};

export const enum PropertyListingDataFormType {
  Assessment = 'ASSESSMENT',
  Listing = 'LISTING'
};

export type PropertyListingDataFormViewSet = {
  __typename?: 'PropertyListingDataFormViewSet';
  forms: Array<DataConfigFormDescriptor>;
  views: Array<DataConfigViewDescriptor>;
};

export const enum PropertyListingDataOrigin {
  Mls = 'MLS',
  Unreserved = 'UNRESERVED'
};

export type PropertyListingDataViewDefinition = DataViewDefinition & {
  __typename?: 'PropertyListingDataViewDefinition';
  dataConfig: PlainEntityDataConfig | PropertyListingDataConfig;
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Icon>;
  id: Scalars['ID'];
  instancePreview: DataViewInstance;
  listingType: PropertyListingType;
  listingTypeName: Scalars['String'];
  logicalName?: Maybe<DataViewLogicalName>;
  name: Scalars['String'];
  status: DataViewStatus;
  tables?: Maybe<Array<DataViewTableDefinition>>;
  viewType?: Maybe<PropertyListingDataViewType>;
  viewTypeName?: Maybe<Scalars['String']>;
};

export const enum PropertyListingDataViewType {
  Assessment = 'ASSESSMENT',
  ListingInternal = 'LISTING_INTERNAL',
  ListingMlsSubmission = 'LISTING_MLS_SUBMISSION',
  ListingPublic = 'LISTING_PUBLIC'
};

export type PropertyListingHistoricalDataEntry = {
  __typename?: 'PropertyListingHistoricalDataEntry';
  createdAt: Scalars['DateTime'];
  inputs?: Maybe<Array<AddressDataFormInputInstance | BooleanDataFormInputInstance | DateDataFormInputInstance | DropdownDataFormInputInstance | EmailDataFormInputInstance | FileDataFormInputInstance | ImageDataFormInputInstance | NumericDataFormInputInstance | PhoneDataFormInputInstance | PrimaryImageDataFormInputInstance | RoomDataFormInputInstance | StringDataFormInputInstance | TimeDataFormInputInstance | UserDataFormInputInstance>>;
  mlsNumber: Scalars['String'];
};

export type PropertyListingHistoricalDataQueryInput = {
  /** List fo data fields for which historical values should be returned */
  dataFieldIds: Array<Scalars['ID']>;
  /** Data form instance for which historical data should be pulled */
  dataFormInstanceId: Scalars['ID'];
  /** Number of entries to return */
  first: Scalars['Int'];
  /** Number of entries to skip */
  offset: Scalars['Int'];
};

export type PropertyListingHistoricalDataQueryResponse = {
  __typename?: 'PropertyListingHistoricalDataQueryResponse';
  entries?: Maybe<Array<PropertyListingHistoricalDataEntry>>;
};

export type PropertyListingIteration = Entity & TagsOwner & {
  __typename?: 'PropertyListingIteration';
  address: Address;
  annualPropertyTax?: Maybe<MonetaryAmount>;
  assignedAgents?: Maybe<Array<PropertyListingIterationAssignedAgent>>;
  bathroomCount?: Maybe<Scalars['Float']>;
  bedroomCount?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<AdminUser | AgentUser | ConsumerUser | ContactUser>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  internalListingView?: Maybe<DataViewInstance>;
  iteration: Scalars['Int'];
  iterationChangesSummary?: Maybe<Scalars['String']>;
  iterationStatus: PropertyListingIterationStatus;
  iterationStatusName: Scalars['String'];
  leaseTermMonths?: Maybe<Scalars['Int']>;
  listedAt?: Maybe<Scalars['DateTime']>;
  listedBy?: Maybe<AdminUser | AgentUser | ConsumerUser | ContactUser>;
  listingForm?: Maybe<DataFormInstance>;
  listingPrice?: Maybe<MonetaryAmount>;
  /** @deprecated listingView is being replaced. Use listingForm.derivedView instead */
  listingView?: Maybe<DataViewInstance>;
  maintenanceFeePerMonth?: Maybe<MonetaryAmount>;
  media?: Maybe<Array<File | Image | Model3D>>;
  mlsModificationUrl?: Maybe<Scalars['String']>;
  mlsSubmissionApprovedBySellerAt?: Maybe<Scalars['DateTime']>;
  mlsSubmissionApprovedByStaffAt?: Maybe<Scalars['DateTime']>;
  mlsSubmissionView?: Maybe<DataViewInstance>;
  offMarketAt?: Maybe<Scalars['DateTime']>;
  possessionAt?: Maybe<Scalars['DateTime']>;
  propertyListing: PropertyListing;
  propertyStyles?: Maybe<Array<PropertyStyle>>;
  propertyStylesNames?: Maybe<Array<Scalars['String']>>;
  publicListingView?: Maybe<DataViewInstance>;
  readyForSubmission?: Maybe<Scalars['Boolean']>;
  rooms?: Maybe<Array<ResidentialPropertyRoom>>;
  sellers?: Maybe<Array<PropertyListingIterationSeller>>;
  squareFootage?: Maybe<Scalars['String']>;
  squareFootageMax?: Maybe<Scalars['Int']>;
  squareFootageMin?: Maybe<Scalars['Int']>;
  squareFootageSource?: Maybe<SquareFootageSource>;
  stories?: Maybe<Scalars['Int']>;
  tagEntityType: TagEntityType;
  tags: Array<Tag>;
  totalParking?: Maybe<Scalars['Int']>;
  transactionPrice?: Maybe<MonetaryAmount>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  yearBuiltMax?: Maybe<Scalars['Int']>;
  yearBuiltMin?: Maybe<Scalars['Int']>;
};


export type PropertyListingIterationListingFormArgs = {
  input?: InputMaybe<DataFormInstanceFieldInput>;
  keyword?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<DataFormFilteringMode>;
};


export type PropertyListingIterationMediaArgs = {
  first?: InputMaybe<Scalars['Int']>;
  statuses?: InputMaybe<Array<MediaStatus>>;
};


export type PropertyListingIterationRoomsArgs = {
  statuses?: InputMaybe<Array<ResidentialRoomStatus>>;
};

export type PropertyListingIterationAssignedAgent = {
  __typename?: 'PropertyListingIterationAssignedAgent';
  agent: AdminUser | AgentUser | ConsumerUser | ContactUser;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  iteration: PropertyListingIteration;
  sequence: Scalars['Int'];
};

export type PropertyListingIterationChange = {
  field: Scalars['String'];
};

export type PropertyListingIterationChanges = {
  __typename?: 'PropertyListingIterationChanges';
  changes?: Maybe<Array<EntityCollectionPropertyListingIterationChange | EntityPropertyListingIterationChange | PrimitiveCollectionPropertyListingIterationChange | PrimitivePropertyListingIterationChange>>;
};

export type PropertyListingIterationSeller = {
  __typename?: 'PropertyListingIterationSeller';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  iteration: PropertyListingIteration;
  seller: AdminUser | AgentUser | ConsumerUser | ContactUser;
};

export const enum PropertyListingIterationStatus {
  AwaitingMlsSubmission = 'AWAITING_MLS_SUBMISSION',
  Draft = 'DRAFT',
  Stale = 'STALE',
  SubmittedToMls = 'SUBMITTED_TO_MLS'
};

export type PropertyListingLikeActivity = Activity & {
  __typename?: 'PropertyListingLikeActivity';
  actor?: Maybe<AdminUser | AgentUser | ConsumerUser | ContactUser>;
  comments?: Maybe<Array<ActivityComment>>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** Whether the thread id fully read or there are any unread comments */
  isRead: Scalars['Boolean'];
  /** Whether the thread is resolved */
  isResolved?: Maybe<Scalars['Boolean']>;
  legacyListing?: Maybe<ResidentialPropertyForSaleListing>;
  listing?: Maybe<PropertyListing>;
  media?: Maybe<Array<File | Image | Model3D>>;
  message?: Maybe<Scalars['String']>;
  object?: Maybe<AdminUser | AgentUser | ApproveSubmissionToMlsTaskInstance | BasicTaskInstance | ConsumerUser | ContactUser | FileUploadTaskInstance | FormFillTaskInstance | ProjectInstance | PropertyListing | PropertyListingOpportunity | SubmitListingToMlsTaskInstance>;
  /** Timestamp of the last time the thread was read by the current user. False if the current user is not a thread participant */
  readAt?: Maybe<Scalars['DateTime']>;
  relatedEntity?: Maybe<Address | AdminUser | AgentUser | ConsumerUser | ContactUser | DataFormInstance | DataFormStepInstance | Image | PropertyComparativeMarketAnalysis | PropertyListing | PropertyListingIteration | ResidentialPropertyRoom>;
  status: ActivityStatus;
  subType?: Maybe<ActivitySubType>;
  subTypeName?: Maybe<Scalars['String']>;
  type: ActivityType;
  typeName: Scalars['String'];
  verb: Scalars['String'];
  visibility: ActivityVisibility;
  visibilityName: Scalars['String'];
};


export type PropertyListingLikeActivityCommentsArgs = {
  latest?: InputMaybe<Scalars['Int']>;
  statuses?: InputMaybe<Array<ActivityCommentStatus>>;
};


export type PropertyListingLikeActivityMediaArgs = {
  first?: InputMaybe<Scalars['Int']>;
  statuses?: InputMaybe<Array<MediaStatus>>;
};

export type PropertyListingLikeConnectionInput = {
  filters?: InputMaybe<Array<FilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  listingIds?: InputMaybe<Array<Scalars['ID']>>;
  offset?: InputMaybe<Scalars['Int']>;
  savedSearchId?: InputMaybe<Scalars['ID']>;
  sortKey?: InputMaybe<PropertyListingLikeSortKey>;
  sortOptionId?: InputMaybe<Scalars['ID']>;
  sortOrder?: InputMaybe<SortOrder>;
  tagIds?: InputMaybe<Array<Scalars['ID']>>;
};

export const enum PropertyListingLikeSortKey {
  CreatedAt = 'CREATED_AT'
};

export type PropertyListingOpportunity = ActivityObject & AppointmentOwner & Opportunity & {
  __typename?: 'PropertyListingOpportunity';
  activityFeed?: Maybe<ActivityFeed>;
  address: Address;
  appointments?: Maybe<Array<Appointment>>;
  assignedAgents?: Maybe<Array<OpportunityAssignedAgent>>;
  /** Dynamically derived */
  bathroomCount?: Maybe<Scalars['Float']>;
  /** Dynamically derived */
  bedroomCount?: Maybe<Scalars['Int']>;
  commission: Scalars['BigDecimal'];
  commissionType: CommissionType;
  commissionTypeName: Scalars['String'];
  completedMilestones: Array<OpportunityMilestone>;
  createdAt: Scalars['DateTime'];
  currentStage: PropertyListingOpportunityStage;
  currentStageName: Scalars['String'];
  customerTwilioPhoneNumber?: Maybe<TwilioPhoneNumber>;
  customers: Array<OpportunityCustomer>;
  /** Dynamically derived */
  denCount?: Maybe<Scalars['Int']>;
  following: Scalars['Boolean'];
  id: Scalars['ID'];
  lastCompletedMilestone?: Maybe<PropertyListingOpportunityMilestone>;
  lastCompletedMilestoneName?: Maybe<Scalars['String']>;
  listing?: Maybe<ResidentialPropertyForSaleListing>;
  listings?: Maybe<Array<PropertyListing>>;
  name: Scalars['String'];
  objectType: ActivityObjectType;
  outcome?: Maybe<PropertyListingOpportunityOutcome>;
  outcomeName?: Maybe<Scalars['String']>;
  /** Dynamically derived */
  price?: Maybe<MonetaryAmount>;
  /** Dynamically derived */
  primaryImage?: Maybe<Image>;
  /** Dynamically derived */
  squareFootage?: Maybe<Scalars['String']>;
  type: OpportunityType;
  typeName: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type PropertyListingOpportunityActivityFeedArgs = {
  containingActivityId?: InputMaybe<Scalars['ID']>;
  first?: Scalars['Int'];
  offset?: Scalars['Int'];
};


export type PropertyListingOpportunityAppointmentsArgs = {
  first?: Scalars['Int'];
};


export type PropertyListingOpportunityListingsArgs = {
  statuses?: InputMaybe<Array<PropertyListingStatus>>;
};

export type PropertyListingOpportunityInquiry = Inquiry & {
  __typename?: 'PropertyListingOpportunityInquiry';
  /** Address of the property for listing */
  address: Address;
  /** Whether the customer is in the market to buy a new home */
  buyingHome: YesNoNotSure;
  /** Human readable representation of buyingHome */
  buyingHomeName: Scalars['String'];
  chimeExportedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  fubExportedAt?: Maybe<Scalars['DateTime']>;
  /**
   * Whether there is an agent currently representing the customer
   * @deprecated No longer supported
   */
  haveAgent?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  lastName: Scalars['String'];
  /** Where lead came from */
  leadSource?: Maybe<LeadSource>;
  /** Human readable representation of leadSource */
  leadSourceName?: Maybe<Scalars['String']>;
  /** Planned time to list customer's home for sale */
  listingTime: PropertyListingOpportunityInquiryListingTime;
  /** Human readable representation of listingTime */
  listingTimeName: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  /** @deprecated Please use 'firstName' and 'lastName' */
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  /** Type of the property for listing */
  propertyType: PropertyListingOpportunityInquiryPropertyType;
  /** Human readable representation of propertyType */
  propertyTypeName: Scalars['String'];
  status: InquiryStatus;
  statusName: Scalars['String'];
  /** Type of the transaction. Can be one of: FOR_SALE | FOR_LEASE */
  transactionType: ListingType;
  /** Human readable representation of transactionType */
  transactionTypeName: Scalars['String'];
  type: InquiryType;
  typeName: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<AdminUser | AgentUser | ConsumerUser | ContactUser>;
};

export const enum PropertyListingOpportunityInquiryListingTime {
  AsSoonAsFindNewHome = 'AS_SOON_AS_FIND_NEW_HOME',
  Next_3Months = 'NEXT_3_MONTHS',
  Next_6Months = 'NEXT_6_MONTHS',
  NotSure = 'NOT_SURE',
  Over_6Months = 'OVER_6_MONTHS',
  RightAway = 'RIGHT_AWAY'
};

export type PropertyListingOpportunityInquiryMetadata = {
  __typename?: 'PropertyListingOpportunityInquiryMetadata';
  buyingHome: Array<EnumValueMetadata>;
  listingTime: Array<EnumValueMetadata>;
  propertyType: Array<EnumValueMetadata>;
  transactionType: Array<EnumValueMetadata>;
};

export const enum PropertyListingOpportunityInquiryPropertyType {
  Condo = 'CONDO',
  MultiFamily = 'MULTI_FAMILY',
  SemiDetached = 'SEMI_DETACHED',
  SingleHouse = 'SINGLE_HOUSE',
  Townhouse = 'TOWNHOUSE'
};

export type PropertyListingOpportunityMetadata = {
  __typename?: 'PropertyListingOpportunityMetadata';
  commissionType: Array<EnumValueMetadata>;
  propertyListingOpportunityOutcome: Array<EnumValueMetadata>;
  propertyListingOpportunityStage: Array<EnumValueMetadata>;
};

export const enum PropertyListingOpportunityMilestone {
  AcceptedOffer = 'ACCEPTED_OFFER',
  CapturedPhotos = 'CAPTURED_PHOTOS',
  CommunicatedWithLawyers = 'COMMUNICATED_WITH_LAWYERS',
  CompletedHandover = 'COMPLETED_HANDOVER',
  CompletedHomeDetails = 'COMPLETED_HOME_DETAILS',
  FulfilledConditions = 'FULFILLED_CONDITIONS',
  HeldOpenHouse = 'HELD_OPEN_HOUSE',
  ListedToMls = 'LISTED_TO_MLS',
  MadeContact = 'MADE_CONTACT',
  MetInPerson = 'MET_IN_PERSON',
  ReceivedDeposit = 'RECEIVED_DEPOSIT',
  ReceivedOffer = 'RECEIVED_OFFER',
  RemovedLockBox = 'REMOVED_LOCK_BOX',
  RemovedSign = 'REMOVED_SIGN',
  SetAppointment = 'SET_APPOINTMENT',
  ShowedHome = 'SHOWED_HOME',
  SignedListingAgreement = 'SIGNED_LISTING_AGREEMENT',
  SignedSellingAgreement = 'SIGNED_SELLING_AGREEMENT'
};

export const enum PropertyListingOpportunityOutcome {
  ListingExpired = 'LISTING_EXPIRED',
  NotInterested = 'NOT_INTERESTED',
  NotQualified = 'NOT_QUALIFIED',
  Transacted = 'TRANSACTED',
  Unresponsive = 'UNRESPONSIVE'
};

export const enum PropertyListingOpportunityStage {
  Draft = 'DRAFT',
  Lost = 'LOST',
  PointOfSale = 'POINT_OF_SALE',
  PostSale = 'POST_SALE',
  PreSale = 'PRE_SALE',
  Qualify = 'QUALIFY',
  Transacted = 'TRANSACTED'
};

export const enum PropertyListingSortKey {
  CreatedAt = 'CREATED_AT',
  DateListed = 'DATE_LISTED',
  DateSold = 'DATE_SOLD',
  Geo = 'GEO',
  ListingPrice = 'LISTING_PRICE',
  SalePrice = 'SALE_PRICE',
  UpdatedAt = 'UPDATED_AT'
};

export const enum PropertyListingStatus {
  Active = 'ACTIVE',
  ActiveUnderContract = 'ACTIVE_UNDER_CONTRACT',
  Cancelled = 'CANCELLED',
  Closed = 'CLOSED',
  ComingSoon = 'COMING_SOON',
  ConditionallySold = 'CONDITIONALLY_SOLD',
  Delete = 'DELETE',
  /** Legacy */
  Draft = 'DRAFT',
  Duplicate = 'DUPLICATE',
  Expired = 'EXPIRED',
  Hold = 'HOLD',
  Inactive = 'INACTIVE',
  /** RESO based */
  Incomplete = 'INCOMPLETE',
  NoSale = 'NO_SALE',
  Pending = 'PENDING',
  SalePending = 'SALE_PENDING',
  Sold = 'SOLD',
  Suspended = 'SUSPENDED',
  Withdrawn = 'WITHDRAWN'
};

export type PropertyListingTagDropdownValueRule = PropertyListingTagRule & {
  __typename?: 'PropertyListingTagDropdownValueRule';
  createdAt: Scalars['DateTime'];
  dataField: AddressDataField | BooleanDataField | DateDataField | DropdownDataField | EmailDataField | FileDataField | ImageDataField | NumericDataField | PhoneDataField | PrimaryImageSelectDataField | RoomDataField | StringDataField | TimeDataField | UserDataField;
  dataFieldValues: Array<DropdownFieldOption>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  status: PropertyListingTagRuleStatus;
  statusName: Scalars['String'];
  tag: Tag;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PropertyListingTagPrimitiveValueRule = PropertyListingTagRule & {
  __typename?: 'PropertyListingTagPrimitiveValueRule';
  createdAt: Scalars['DateTime'];
  dataField: AddressDataField | BooleanDataField | DateDataField | DropdownDataField | EmailDataField | FileDataField | ImageDataField | NumericDataField | PhoneDataField | PrimaryImageSelectDataField | RoomDataField | StringDataField | TimeDataField | UserDataField;
  dataFieldValues: Array<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  status: PropertyListingTagRuleStatus;
  statusName: Scalars['String'];
  tag: Tag;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PropertyListingTagRule = {
  createdAt: Scalars['DateTime'];
  dataField: AddressDataField | BooleanDataField | DateDataField | DropdownDataField | EmailDataField | FileDataField | ImageDataField | NumericDataField | PhoneDataField | PrimaryImageSelectDataField | RoomDataField | StringDataField | TimeDataField | UserDataField;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  status: PropertyListingTagRuleStatus;
  statusName: Scalars['String'];
  tag: Tag;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PropertyListingTagRuleConnection = {
  __typename?: 'PropertyListingTagRuleConnection';
  edges?: Maybe<Array<Maybe<PropertyListingTagRuleConnectionEdge>>>;
  facetGroups?: Maybe<Array<Maybe<FacetGroup>>>;
  filters?: Maybe<Array<Maybe<AggregatedFacetFilter | BooleanFilter | DateRangeFilter | FloatRangeFilter | IntegerRangeFilter | SimpleFacetFilter | TagCollectionFilter>>>;
  histogramGroups?: Maybe<Array<Maybe<HistogramGroup>>>;
  metricGroups?: Maybe<Array<Maybe<MetricGroup>>>;
  pageInfo?: Maybe<PageInfo>;
  savedSearches?: Maybe<Array<Maybe<SavedSearch>>>;
  sortOptions?: Maybe<Array<Maybe<SortOption>>>;
};


export type PropertyListingTagRuleConnectionFacetGroupsArgs = {
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type PropertyListingTagRuleConnectionFiltersArgs = {
  aggregatedFacetKeywordSearch?: InputMaybe<AggregatedFacetKeywordSearchInput>;
  fetchAggregatedFacetsFilterIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  includeTagFilters?: InputMaybe<Scalars['Boolean']>;
  selected?: InputMaybe<Scalars['Boolean']>;
};


export type PropertyListingTagRuleConnectionHistogramGroupsArgs = {
  histogramGroupInput?: InputMaybe<HistogramGroupInput>;
};


export type PropertyListingTagRuleConnectionMetricGroupsArgs = {
  metricInput?: InputMaybe<Array<InputMaybe<MetricGroupInput>>>;
};

export type PropertyListingTagRuleConnectionEdge = {
  __typename?: 'PropertyListingTagRuleConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<PropertyListingTagDropdownValueRule | PropertyListingTagPrimitiveValueRule>;
};

export type PropertyListingTagRuleConnectionInput = {
  filters?: InputMaybe<Array<FilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  keyword?: InputMaybe<Scalars['String']>;
  mlsBoardIds?: InputMaybe<Array<Scalars['ID']>>;
  offset?: InputMaybe<Scalars['Int']>;
  savedSearchId?: InputMaybe<Scalars['ID']>;
  sortOptionId?: InputMaybe<Scalars['ID']>;
};

export const enum PropertyListingTagRuleStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
};

export const enum PropertyListingType {
  FarmForLease = 'FARM_FOR_LEASE',
  FarmForSale = 'FARM_FOR_SALE',
  LandForLease = 'LAND_FOR_LEASE',
  LandForSale = 'LAND_FOR_SALE',
  OtherForLease = 'OTHER_FOR_LEASE',
  OtherForSale = 'OTHER_FOR_SALE',
  ParkingForLease = 'PARKING_FOR_LEASE',
  ParkingForSale = 'PARKING_FOR_SALE',
  RecreationalForLease = 'RECREATIONAL_FOR_LEASE',
  RecreationalForSale = 'RECREATIONAL_FOR_SALE',
  ResidentialCondoForLease = 'RESIDENTIAL_CONDO_FOR_LEASE',
  ResidentialCondoForSale = 'RESIDENTIAL_CONDO_FOR_SALE',
  ResidentialHouseForLease = 'RESIDENTIAL_HOUSE_FOR_LEASE',
  ResidentialHouseForSale = 'RESIDENTIAL_HOUSE_FOR_SALE',
  ResidentialMultiFamilyForLease = 'RESIDENTIAL_MULTI_FAMILY_FOR_LEASE',
  ResidentialMultiFamilyForSale = 'RESIDENTIAL_MULTI_FAMILY_FOR_SALE'
};

export type PropertyListingViewActivity = Activity & {
  __typename?: 'PropertyListingViewActivity';
  actor?: Maybe<AdminUser | AgentUser | ConsumerUser | ContactUser>;
  comments?: Maybe<Array<ActivityComment>>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** Whether the thread id fully read or there are any unread comments */
  isRead: Scalars['Boolean'];
  /** Whether the thread is resolved */
  isResolved?: Maybe<Scalars['Boolean']>;
  legacyListing?: Maybe<ResidentialPropertyForSaleListing>;
  listing?: Maybe<PropertyListing>;
  media?: Maybe<Array<File | Image | Model3D>>;
  message?: Maybe<Scalars['String']>;
  object?: Maybe<AdminUser | AgentUser | ApproveSubmissionToMlsTaskInstance | BasicTaskInstance | ConsumerUser | ContactUser | FileUploadTaskInstance | FormFillTaskInstance | ProjectInstance | PropertyListing | PropertyListingOpportunity | SubmitListingToMlsTaskInstance>;
  /** Timestamp of the last time the thread was read by the current user. False if the current user is not a thread participant */
  readAt?: Maybe<Scalars['DateTime']>;
  relatedEntity?: Maybe<Address | AdminUser | AgentUser | ConsumerUser | ContactUser | DataFormInstance | DataFormStepInstance | Image | PropertyComparativeMarketAnalysis | PropertyListing | PropertyListingIteration | ResidentialPropertyRoom>;
  status: ActivityStatus;
  subType?: Maybe<ActivitySubType>;
  subTypeName?: Maybe<Scalars['String']>;
  type: ActivityType;
  typeName: Scalars['String'];
  verb: Scalars['String'];
  visibility: ActivityVisibility;
  visibilityName: Scalars['String'];
};


export type PropertyListingViewActivityCommentsArgs = {
  latest?: InputMaybe<Scalars['Int']>;
  statuses?: InputMaybe<Array<ActivityCommentStatus>>;
};


export type PropertyListingViewActivityMediaArgs = {
  first?: InputMaybe<Scalars['Int']>;
  statuses?: InputMaybe<Array<MediaStatus>>;
};

export const enum PropertySortKey {
  Address = 'ADDRESS',
  CreatedAt = 'CREATED_AT',
  Geo = 'GEO',
  UpdatedAt = 'UPDATED_AT'
};

export const enum PropertyStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED'
};

export const enum PropertyStyle {
  Bungalow = 'BUNGALOW',
  Cabin = 'CABIN',
  Chalet = 'CHALET',
  Cottage = 'COTTAGE',
  Detached = 'DETACHED',
  Duplex = 'DUPLEX',
  Fourplex = 'FOURPLEX',
  Loft = 'LOFT',
  Penthouse = 'PENTHOUSE',
  SemiDetached = 'SEMI_DETACHED',
  SingleHouse = 'SINGLE_HOUSE',
  SplitLevel = 'SPLIT_LEVEL',
  Studio = 'STUDIO',
  Townhome = 'TOWNHOME',
  Triplex = 'TRIPLEX',
  Villa = 'VILLA'
};

export type PropertyTax = {
  __typename?: 'PropertyTax';
  amount?: Maybe<MonetaryAmount>;
  year?: Maybe<Scalars['Date']>;
};

export const enum PropertyType {
  Condo = 'CONDO',
  Farm = 'FARM',
  House = 'HOUSE',
  Land = 'LAND',
  MultiFamily = 'MULTI_FAMILY',
  Other = 'OTHER',
  Parking = 'PARKING',
  Recreational = 'RECREATIONAL'
};

/** The Root Query for the application */
export type Query = {
  __typename?: 'Query';
  address?: Maybe<Address>;
  addressAutocomplete?: Maybe<Array<Maybe<AddressSuggestion>>>;
  addressFromAutocomplete?: Maybe<Address>;
  brokerage?: Maybe<Brokerage>;
  brokerageConnection?: Maybe<BrokerageConnection>;
  building?: Maybe<Building>;
  /** Available facet groups: amenities, builderName, street, macroNeighbourhood, neighbourhood, subNeighbourhood, metroArea, city, region, country. */
  buildingConnection?: Maybe<BuildingConnection>;
  company?: Maybe<FurnitureSupplierCompany | FurnitureVendorCompany>;
  companyConnection?: Maybe<CompanyConnection>;
  /** Available to ADMIN users only and workspace members */
  contact?: Maybe<Contact>;
  coordinate?: Maybe<Coordinate>;
  dataConfig?: Maybe<PlainEntityDataConfig | PropertyListingDataConfig>;
  dataConfigConnection?: Maybe<DataConfigConnection>;
  dataField?: Maybe<AddressDataField | BooleanDataField | DateDataField | DropdownDataField | EmailDataField | FileDataField | ImageDataField | NumericDataField | PhoneDataField | PrimaryImageSelectDataField | RoomDataField | StringDataField | TimeDataField | UserDataField>;
  dataFieldConnection?: Maybe<DataFieldConnection>;
  dataFormDefinition?: Maybe<PropertyListingDataFormDefinition>;
  /** If logicalName provided, generates a brand new data form instance for a new entity based on data form definition logical name. Otherwise returns existing instance by ID */
  dataFormInstance?: Maybe<DataFormInstance>;
  /** Only admins can access the API */
  dataViewDefinition?: Maybe<PropertyListingDataViewDefinition>;
  distribution?: Maybe<Distribution>;
  /** Fake API to expose GraphQLErrorType in schema */
  error?: Maybe<GraphQlErrorType>;
  geo: Array<GeocodedAddress>;
  inquiry?: Maybe<FurnitureGeneralInquiry | GeneralInquiry | PropertyListingOpportunityInquiry | ResidentialAppraisalInquiry | ResidentialListingGeneralInquiry | ResidentialListingOpenHouseInquiry | ResidentialListingPrivateShowingInquiry>;
  /** Available facet groups: status, type. */
  inquiryConnection?: Maybe<InquiryConnection>;
  /** Any auxiliary data required for client side rendering */
  metadata?: Maybe<MetadataResponse>;
  /** Available for ADMIN only */
  mlsBoard?: Maybe<MlsBoard>;
  mlsBoardConnection?: Maybe<MlsBoardConnection>;
  mlsFieldConnection?: Maybe<MlsFieldConnection>;
  nearbyPropertiesSearch?: Maybe<Array<NearbyProperty>>;
  openHouse?: Maybe<OpenHouse>;
  /** Available facet groups: status, format, targetAudience. */
  openHouseConnection?: Maybe<OpenHouseConnection>;
  opportunity?: Maybe<PropertyListingOpportunity>;
  opportunityConnection?: Maybe<OpportunityConnection>;
  place?: Maybe<Place>;
  placeAggregations: PlaceAggregationsResponse;
  placeAutocomplete: PlaceAutoCompleteSuggestion;
  /** Available facet groups: type. */
  placeConnection?: Maybe<PlaceConnection>;
  /** Returns product if found. Otherwise, returns error indicating product not found. */
  product?: Maybe<FurnitureProduct>;
  /**
   * Returns a list of products matching search criteria. Keyword search on product title and sku.
   * Available facet groups: category.
   */
  productConnection?: Maybe<ProductConnection>;
  productInventory?: Maybe<ProductInventory>;
  productInventoryConnection?: Maybe<ProductInventoryConnection>;
  projectAssignments?: Maybe<Array<WorkflowAssignment>>;
  projectDefinition?: Maybe<ProjectDefinition>;
  projectDefinitionConnection?: Maybe<ProjectDefinitionConnection>;
  projectInstance?: Maybe<ProjectInstance>;
  projectInstanceConnection?: Maybe<ProjectInstanceConnection>;
  property?: Maybe<Property>;
  /** Available to STAFF only for now */
  propertyComparativeMarketAnalysis?: Maybe<PropertyComparativeMarketAnalysis>;
  propertyComparativeMarketAnalysisConnection?: Maybe<PropertyComparativeMarketAnalysisConnection>;
  /** Available facet groups: street, macroNeighbourhood, neighbourhood, subNeighbourhood, metroArea, city, region, country, status. */
  propertyConnection?: Maybe<PropertyConnection>;
  propertyFloorPlan?: Maybe<PropertyFloorPlan>;
  propertyFloorPlanConnection?: Maybe<PropertyFloorPlanConnection>;
  propertyListing?: Maybe<PropertyListing>;
  propertyListingClusters: Array<MapCluster>;
  /**
   * Returns keyword searchable property listings with filter facet support
   * Available facet groups: street, macroNeighbourhood, neighbourhood, subNeighbourhood, metroArea, city, region, country
   */
  propertyListingConnection?: Maybe<PropertyListingConnection>;
  /** Get historical data from previous property listing */
  propertyListingHistoricalData?: Maybe<PropertyListingHistoricalDataQueryResponse>;
  propertyListingIteration?: Maybe<PropertyListingIteration>;
  /** Returns a list of changes between two iterations of the same listing. This API returns a list of detailed changes for each field changed between iterations. Brief summary changes are stored directly in iteration in `iterationChangesSummary` field. Only authenticated users can fetch the API. User must be either one of the sellers in opportunity, or a staff member. */
  propertyListingIterationChanges?: Maybe<PropertyListingIterationChanges>;
  /** Only admins can access the API */
  propertyListingTagRule?: Maybe<PropertyListingTagDropdownValueRule | PropertyListingTagPrimitiveValueRule>;
  /** Only admins can access the API */
  propertyListingTagRuleConnection?: Maybe<PropertyListingTagRuleConnection>;
  residentialPropertyListing?: Maybe<ResidentialPropertyForSaleListing>;
  residentialPropertyListingClusters: Array<MapCluster>;
  residentialPropertyListingComparables?: Maybe<ResidentialPropertyListingComparablesResponse>;
  /**
   * Available facet groups: street, macroNeighbourhood, neighbourhood, subNeighbourhood, metroArea, city, region, country, ownershipType, listingStatus, listingType, residentialType, residentialSubType, userListingSection, , tags, openHouseDates.
   * Available metric groups: listingPrice, totalBathrooms, totalBedrooms, salePrice, pricePerSqFtSold, pricePerSqFtActive, daysOnMarketSold, daysOnMarketActive.
   * Available histogram groups: dateSold, dateListed.
   */
  residentialPropertyListingConnection?: Maybe<ResidentialPropertyListingConnection>;
  residentialPropertyListingLikeConnection?: Maybe<ResidentialPropertyListingLikeConnection>;
  residentialPropertyListingSemanticSearch: Array<ResidentialPropertyListingWithScore>;
  residentialPropertyRoom?: Maybe<ResidentialPropertyRoom>;
  spaceConfiguration?: Maybe<BoxRoomConfiguration | PropertyFloorPlanConfiguration>;
  spaceMaterial?: Maybe<SpaceMaterial>;
  spaceMaterialConnection?: Maybe<SpaceMaterialConnection>;
  suggestWorkflowScript?: Maybe<Scalars['String']>;
  supportedPropertyListingTypes?: Maybe<Array<SupportedPropertyListingType>>;
  tagCollection?: Maybe<TagCollection>;
  tagCollectionConnection?: Maybe<TagCollectionConnection>;
  tagConnection?: Maybe<TagConnection>;
  taskInstance?: Maybe<ApproveSubmissionToMlsTaskInstance | BasicTaskInstance | FileUploadTaskInstance | FormFillTaskInstance | SubmitListingToMlsTaskInstance>;
  taskInstanceConnection?: Maybe<TaskInstanceConnection>;
  thread?: Maybe<InquiryCreatedActivity | NoteActivity | ProjectStageEventActivity | PropertyListingLikeActivity | PropertyListingViewActivity | RootProjectEventActivity | SubProjectEventActivity | TaskAssignActivity | TaskEventActivity | UserAssignedAgentAddedActivity | UserAssignedAgentRemovedActivity | UserReactivatedActivity | UserSuspendedActivity>;
  threadConnection?: Maybe<ThreadConnection>;
  user: AdminUser | AgentUser | ConsumerUser | ContactUser;
  /**
   * Facets on status and role. Keyword search on firstName, lastName, nickname, email & phone
   * Available facet groups: status, type.
   */
  userConnection?: Maybe<UserConnection>;
  userLookup: UserLookup;
  userMention?: Maybe<UserMention>;
  userMentionConnection?: Maybe<UserMentionConnection>;
  userMentionLookup?: Maybe<Array<Maybe<AdminUser | AgentUser | ConsumerUser | ContactUser>>>;
  /** Available to ADMIN users only */
  workspace?: Maybe<Workspace>;
  /** Available to ADMIN users only */
  workspaceConnection?: Maybe<WorkspaceConnection>;
  /** Available to ADMIN and workspace users */
  workspaceMember?: Maybe<WorkspaceMember>;
  /** Available to ADMIN and workspace users */
  workspaceMemberConnection?: Maybe<WorkspaceMemberConnection>;
};


/** The Root Query for the application */
export type QueryAddressArgs = {
  id: Scalars['ID'];
};


/** The Root Query for the application */
export type QueryAddressAutocompleteArgs = {
  input: AddressAutocompleteInput;
};


/** The Root Query for the application */
export type QueryAddressFromAutocompleteArgs = {
  input: AddressAutocompleteResultInput;
};


/** The Root Query for the application */
export type QueryBrokerageArgs = {
  id: Scalars['ID'];
};


/** The Root Query for the application */
export type QueryBrokerageConnectionArgs = {
  input: BrokerageConnectionInput;
};


/** The Root Query for the application */
export type QueryBuildingArgs = {
  id: Scalars['ID'];
};


/** The Root Query for the application */
export type QueryBuildingConnectionArgs = {
  input: BuildingConnectionInput;
};


/** The Root Query for the application */
export type QueryCompanyArgs = {
  id: Scalars['ID'];
};


/** The Root Query for the application */
export type QueryCompanyConnectionArgs = {
  input: CompanyConnectionInput;
};


/** The Root Query for the application */
export type QueryContactArgs = {
  id: Scalars['ID'];
};


/** The Root Query for the application */
export type QueryDataConfigArgs = {
  id: Scalars['ID'];
};


/** The Root Query for the application */
export type QueryDataConfigConnectionArgs = {
  input: DataConfigConnectionInput;
};


/** The Root Query for the application */
export type QueryDataFieldArgs = {
  id: Scalars['ID'];
};


/** The Root Query for the application */
export type QueryDataFieldConnectionArgs = {
  input: DataFieldConnectionInput;
};


/** The Root Query for the application */
export type QueryDataFormDefinitionArgs = {
  id: Scalars['ID'];
};


/** The Root Query for the application */
export type QueryDataFormInstanceArgs = {
  id?: InputMaybe<Scalars['ID']>;
  logicalName?: InputMaybe<DataFormLogicalName>;
};


/** The Root Query for the application */
export type QueryDataViewDefinitionArgs = {
  id: Scalars['ID'];
};


/** The Root Query for the application */
export type QueryDistributionArgs = {
  id: Scalars['ID'];
};


/** The Root Query for the application */
export type QueryGeoArgs = {
  input?: InputMaybe<GeoSearchInput>;
};


/** The Root Query for the application */
export type QueryInquiryArgs = {
  id: Scalars['ID'];
};


/** The Root Query for the application */
export type QueryInquiryConnectionArgs = {
  input: InquiryConnectionInput;
};


/** The Root Query for the application */
export type QueryMlsBoardArgs = {
  id: Scalars['ID'];
};


/** The Root Query for the application */
export type QueryMlsBoardConnectionArgs = {
  input: MlsBoardConnectionInput;
};


/** The Root Query for the application */
export type QueryMlsFieldConnectionArgs = {
  input: MlsFieldConnectionInput;
};


/** The Root Query for the application */
export type QueryNearbyPropertiesSearchArgs = {
  input: NearByPropertySearchInput;
};


/** The Root Query for the application */
export type QueryOpenHouseArgs = {
  id: Scalars['ID'];
};


/** The Root Query for the application */
export type QueryOpenHouseConnectionArgs = {
  input: OpenHouseConnectionInput;
};


/** The Root Query for the application */
export type QueryOpportunityArgs = {
  id: Scalars['ID'];
};


/** The Root Query for the application */
export type QueryOpportunityConnectionArgs = {
  input: OpportunityConnectionInput;
};


/** The Root Query for the application */
export type QueryPlaceArgs = {
  input: PlaceInput;
};


/** The Root Query for the application */
export type QueryPlaceAggregationsArgs = {
  input: PlaceAggregationsInput;
};


/** The Root Query for the application */
export type QueryPlaceAutocompleteArgs = {
  input: PlaceAutocompleteInput;
};


/** The Root Query for the application */
export type QueryPlaceConnectionArgs = {
  input: PlaceConnectionInput;
};


/** The Root Query for the application */
export type QueryProductArgs = {
  id: Scalars['ID'];
};


/** The Root Query for the application */
export type QueryProductConnectionArgs = {
  input: ProductConnectionInput;
};


/** The Root Query for the application */
export type QueryProductInventoryArgs = {
  id: Scalars['ID'];
};


/** The Root Query for the application */
export type QueryProductInventoryConnectionArgs = {
  input: ProductInventoryConnectionInput;
};


/** The Root Query for the application */
export type QueryProjectAssignmentsArgs = {
  projectDefinitionId: Scalars['ID'];
  projectInstanceId?: InputMaybe<Scalars['ID']>;
};


/** The Root Query for the application */
export type QueryProjectDefinitionArgs = {
  id: Scalars['ID'];
};


/** The Root Query for the application */
export type QueryProjectDefinitionConnectionArgs = {
  input: ProjectDefinitionConnectionInput;
};


/** The Root Query for the application */
export type QueryProjectInstanceArgs = {
  id: Scalars['ID'];
};


/** The Root Query for the application */
export type QueryProjectInstanceConnectionArgs = {
  input: ProjectInstanceConnectionInput;
};


/** The Root Query for the application */
export type QueryPropertyArgs = {
  id: Scalars['ID'];
};


/** The Root Query for the application */
export type QueryPropertyComparativeMarketAnalysisArgs = {
  id: Scalars['ID'];
};


/** The Root Query for the application */
export type QueryPropertyComparativeMarketAnalysisConnectionArgs = {
  input: PropertyComparativeMarketAnalysisConnectionInput;
};


/** The Root Query for the application */
export type QueryPropertyConnectionArgs = {
  input: PropertyConnectionInput;
};


/** The Root Query for the application */
export type QueryPropertyFloorPlanArgs = {
  id: Scalars['ID'];
};


/** The Root Query for the application */
export type QueryPropertyFloorPlanConnectionArgs = {
  input: PropertyFloorPlanConnectionInput;
};


/** The Root Query for the application */
export type QueryPropertyListingArgs = {
  id: Scalars['ID'];
};


/** The Root Query for the application */
export type QueryPropertyListingClustersArgs = {
  clustersInput: PropertyListingClustersInput;
  connectionInput: PropertyListingConnectionInput;
};


/** The Root Query for the application */
export type QueryPropertyListingConnectionArgs = {
  input: PropertyListingConnectionInput;
};


/** The Root Query for the application */
export type QueryPropertyListingHistoricalDataArgs = {
  input: PropertyListingHistoricalDataQueryInput;
};


/** The Root Query for the application */
export type QueryPropertyListingIterationArgs = {
  id: Scalars['ID'];
};


/** The Root Query for the application */
export type QueryPropertyListingIterationChangesArgs = {
  listingIterationId: Scalars['ID'];
  previousListingIterationId?: InputMaybe<Scalars['ID']>;
};


/** The Root Query for the application */
export type QueryPropertyListingTagRuleArgs = {
  id: Scalars['ID'];
};


/** The Root Query for the application */
export type QueryPropertyListingTagRuleConnectionArgs = {
  input: PropertyListingTagRuleConnectionInput;
};


/** The Root Query for the application */
export type QueryResidentialPropertyListingArgs = {
  id: Scalars['ID'];
};


/** The Root Query for the application */
export type QueryResidentialPropertyListingClustersArgs = {
  clustersInput: PropertyListingClustersInput;
  connectionInput: PropertyListingConnectionInput;
};


/** The Root Query for the application */
export type QueryResidentialPropertyListingComparablesArgs = {
  comparablesInput: PropertyListingComparablesInput;
  connectionInput: PropertyListingConnectionInput;
};


/** The Root Query for the application */
export type QueryResidentialPropertyListingConnectionArgs = {
  input: PropertyListingConnectionInput;
};


/** The Root Query for the application */
export type QueryResidentialPropertyListingLikeConnectionArgs = {
  input: PropertyListingLikeConnectionInput;
};


/** The Root Query for the application */
export type QueryResidentialPropertyListingSemanticSearchArgs = {
  input: ResidentialPropertyListingSemanticSearchInput;
};


/** The Root Query for the application */
export type QueryResidentialPropertyRoomArgs = {
  id: Scalars['ID'];
};


/** The Root Query for the application */
export type QuerySpaceConfigurationArgs = {
  id: Scalars['ID'];
};


/** The Root Query for the application */
export type QuerySpaceMaterialArgs = {
  id: Scalars['ID'];
};


/** The Root Query for the application */
export type QuerySpaceMaterialConnectionArgs = {
  input: SpaceMaterialConnectionInput;
};


/** The Root Query for the application */
export type QuerySuggestWorkflowScriptArgs = {
  input: SuggestWorkflowScriptInput;
};


/** The Root Query for the application */
export type QueryTagCollectionArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


/** The Root Query for the application */
export type QueryTagCollectionConnectionArgs = {
  input: TagCollectionConnectionInput;
};


/** The Root Query for the application */
export type QueryTagConnectionArgs = {
  input: TagConnectionInput;
};


/** The Root Query for the application */
export type QueryTaskInstanceArgs = {
  id: Scalars['ID'];
};


/** The Root Query for the application */
export type QueryTaskInstanceConnectionArgs = {
  input: TaskInstanceConnectionInput;
};


/** The Root Query for the application */
export type QueryThreadArgs = {
  id?: InputMaybe<Scalars['ID']>;
  relatedEntity?: InputMaybe<RelatedEntityKeyInput>;
};


/** The Root Query for the application */
export type QueryThreadConnectionArgs = {
  input: ThreadConnectionInput;
};


/** The Root Query for the application */
export type QueryUserArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


/** The Root Query for the application */
export type QueryUserConnectionArgs = {
  input: UserConnectionInput;
};


/** The Root Query for the application */
export type QueryUserLookupArgs = {
  email: Scalars['String'];
};


/** The Root Query for the application */
export type QueryUserMentionArgs = {
  id: Scalars['ID'];
};


/** The Root Query for the application */
export type QueryUserMentionConnectionArgs = {
  input: UserMentionConnectionInput;
};


/** The Root Query for the application */
export type QueryUserMentionLookupArgs = {
  input: UserMentionLookupInput;
};


/** The Root Query for the application */
export type QueryWorkspaceArgs = {
  id: Scalars['ID'];
};


/** The Root Query for the application */
export type QueryWorkspaceConnectionArgs = {
  input: WorkspaceConnectionInput;
};


/** The Root Query for the application */
export type QueryWorkspaceMemberArgs = {
  id: Scalars['ID'];
};


/** The Root Query for the application */
export type QueryWorkspaceMemberConnectionArgs = {
  input: WorkspaceMemberConnectionInput;
};

export type RearrangeMediaInput = {
  /** Used to rearrange whole collection. Avoid unless necessary */
  mediaIds?: InputMaybe<Array<Scalars['ID']>>;
  /** Used to move a single media in collection to a new position by providing the id of the media it should be after */
  rearrangeSingleEntry?: InputMaybe<RearrangeSingleMediaInput>;
};

export type RearrangeSingleMediaInput = {
  /** ID of the entity owning the collection */
  entityId: Scalars['ID'];
  /** Type of the entity owning the collection */
  entityType: MediaOwner;
  /** ID of the media that needs to be moved */
  mediaId: Scalars['ID'];
  /** ID of a media the entry should be put after. If the media should be placed to the beginning of the collection, set NULL value */
  previousMediaId?: InputMaybe<Scalars['ID']>;
};

export type RelatedEntityKeyInput = {
  id: Scalars['ID'];
  type: EntityType;
};

export type RemoveEntityTagInput = {
  entity: TagEntityInput;
  tagId: Scalars['ID'];
};

export type ReopenTaskInstanceInput = {
  assignedUserId?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  payload?: InputMaybe<Scalars['JSON']>;
  requestedChanges?: InputMaybe<Scalars['String']>;
};

export type ResetUserPasswordInput = {
  /** will be embedded in link within email sent out from requestUserPasswordReset */
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
  token: Scalars['String'];
};

/** type CoOpOwnershipDetails implements OwnershipDetails { } */
export type ResidentialApartmentPropertyDetails = ResidentialPropertyDetails & {
  __typename?: 'ResidentialApartmentPropertyDetails';
  /** @deprecated No longer supported */
  accessibilityFeatures?: Maybe<Array<AccessibilityFeature>>;
  amenities?: Maybe<Array<BuildingAmenity>>;
  amenitiesName?: Maybe<Array<Scalars['String']>>;
  amenityRaw?: Maybe<Scalars['String']>;
  /**
   * Will be removed soon. Replaced by beds
   * @deprecated No longer supported
   */
  apartmentBedrooms?: Maybe<Scalars['Int']>;
  apartmentStyles?: Maybe<Array<ApartmentStyle>>;
  apartmentStylesName?: Maybe<Array<Scalars['String']>>;
  balcony?: Maybe<Array<BalconyType>>;
  balconyName?: Maybe<Array<Scalars['String']>>;
  balconyRaw?: Maybe<Scalars['String']>;
  /**
   * Will be removed soon. Fields were flattened to baths, bathsFull, bathsPartial, bathsEnsuite
   * @deprecated No longer supported
   */
  bathrooms?: Maybe<BathroomCount>;
  baths?: Maybe<Scalars['Float']>;
  bathsEnsuite?: Maybe<Scalars['Int']>;
  bathsFull?: Maybe<Scalars['Int']>;
  bathsPartial?: Maybe<Scalars['Int']>;
  beds?: Maybe<Scalars['Int']>;
  buildYear?: Maybe<Scalars['String']>;
  buildYearMax?: Maybe<Scalars['Int']>;
  buildYearMin?: Maybe<Scalars['Int']>;
  builderModel?: Maybe<Scalars['String']>;
  builderName?: Maybe<Scalars['String']>;
  building?: Maybe<Building>;
  /**
   * Will be removed soon. Fields were flattened to builderName, builderModel, buildYear, buildYearMin, buildYearMax
   * @deprecated No longer supported
   */
  construction?: Maybe<ResidentialBuildingConstruction>;
  dens?: Maybe<Scalars['Int']>;
  exposure?: Maybe<Exposure>;
  exterior?: Maybe<Array<ExteriorType>>;
  exteriorName?: Maybe<Array<Scalars['String']>>;
  exteriorRaw?: Maybe<Scalars['String']>;
  flooring?: Maybe<Array<FlooringType>>;
  flooringName?: Maybe<Array<Scalars['String']>>;
  flooringRaw?: Maybe<Scalars['String']>;
  foundation?: Maybe<Array<FoundationType>>;
  foundationName?: Maybe<Array<Scalars['String']>>;
  foundationRaw?: Maybe<Scalars['String']>;
  hasInSuiteLaundry?: Maybe<Scalars['Boolean']>;
  /** @deprecated No longer supported */
  interiorFinishes?: Maybe<Array<InteriorFinish>>;
  levels?: Maybe<Scalars['Int']>;
  lockerOwned?: Maybe<Scalars['Boolean']>;
  parkingRaw?: Maybe<Scalars['String']>;
  parkingTypes?: Maybe<Array<ParkingType>>;
  parkingTypesName?: Maybe<Array<Scalars['String']>>;
  residentialSubType?: Maybe<ResidentialSubType>;
  residentialSubTypeName?: Maybe<Scalars['String']>;
  residentialType?: Maybe<ResidentialType>;
  residentialTypeName?: Maybe<Scalars['String']>;
  roof?: Maybe<Array<RoofType>>;
  roofName?: Maybe<Array<Scalars['String']>>;
  roofRaw?: Maybe<Scalars['String']>;
  squareFootage?: Maybe<SquareFootage>;
  /**
   * Will be removed soon without replacement
   * @deprecated No longer supported
   */
  storage?: Maybe<StorageType>;
  stories?: Maybe<Scalars['Int']>;
  totalParkingSpots?: Maybe<Scalars['Int']>;
  utilities?: Maybe<ResidentialPropertyUtilities>;
  virtualTourUrl?: Maybe<Scalars['String']>;
  waterfrontAccess?: Maybe<WaterfrontAccess>;
  waterfrontType?: Maybe<WaterfrontType>;
};

export type ResidentialAppraisalInquiry = Inquiry & {
  __typename?: 'ResidentialAppraisalInquiry';
  chimeExportedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  fubExportedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  lastName: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  /** @deprecated Please use 'firstName' and 'lastName' */
  name?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  status: InquiryStatus;
  statusName: Scalars['String'];
  type: InquiryType;
  typeName: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<AdminUser | AgentUser | ConsumerUser | ContactUser>;
};

export type ResidentialBuildingConstruction = {
  __typename?: 'ResidentialBuildingConstruction';
  buildYearMax?: Maybe<Scalars['Int']>;
  buildYearMin?: Maybe<Scalars['Int']>;
  builderName?: Maybe<Scalars['String']>;
  buildingAge?: Maybe<Scalars['String']>;
};

export type ResidentialCondoOwnershipDetails = ResidentialOwnershipDetails & {
  __typename?: 'ResidentialCondoOwnershipDetails';
  appliancesIncluded?: Maybe<Array<ApplianceType>>;
  appliancesIncludedName?: Maybe<Array<Scalars['String']>>;
  associationFee?: Maybe<AssociationFee>;
  condoAdministration?: Maybe<CondoAdministration>;
  condoMaintenanceFee?: Maybe<CondoMaintenanceFee>;
  condoPetPolicy?: Maybe<CondoPetPolicy>;
  estimatedMonthlyGarbageExpense?: Maybe<MonetaryAmount>;
  estimatedMonthlyHeatingExpense?: Maybe<MonetaryAmount>;
  estimatedMonthlyHydroExpense?: Maybe<MonetaryAmount>;
  /** @deprecated No longer supported */
  estimatedMonthlyHydroUsageExpense?: Maybe<MonetaryAmount>;
  estimatedMonthlyInsuranceExpense?: Maybe<MonetaryAmount>;
  estimatedMonthlyLawnExpense?: Maybe<MonetaryAmount>;
  estimatedMonthlyMaintenanceExpense?: Maybe<MonetaryAmount>;
  estimatedMonthlyManagementExpenses?: Maybe<MonetaryAmount>;
  /** @deprecated No longer supported */
  estimatedMonthlyNaturalGasUsageExpense?: Maybe<MonetaryAmount>;
  estimatedMonthlyParkingExpense?: Maybe<MonetaryAmount>;
  estimatedMonthlyRentalEquipmentExpense?: Maybe<MonetaryAmount>;
  estimatedMonthlyRentalIncome?: Maybe<MonetaryAmount>;
  estimatedMonthlySecurityExpense?: Maybe<MonetaryAmount>;
  estimatedMonthlyStorageExpense?: Maybe<MonetaryAmount>;
  estimatedMonthlyWaterSewerExpense?: Maybe<MonetaryAmount>;
  /** @deprecated No longer supported */
  estimatedMonthlyWaterUsageExpense?: Maybe<MonetaryAmount>;
  ownershipType?: Maybe<OwnershipType>;
  ownershipTypeName?: Maybe<Scalars['String']>;
  petsAllowed?: Maybe<Scalars['Boolean']>;
  presentOccupancy?: Maybe<OccupancyType>;
  presentOccupancyName?: Maybe<Scalars['String']>;
  propertyTax?: Maybe<PropertyTax>;
  shortTermRentalsAllowed?: Maybe<Scalars['Boolean']>;
  zoning?: Maybe<Zoning>;
  zoningName?: Maybe<Scalars['String']>;
};

export type ResidentialFreeholdOwnershipDetails = ResidentialOwnershipDetails & {
  __typename?: 'ResidentialFreeholdOwnershipDetails';
  associationFee?: Maybe<AssociationFee>;
  estimatedMonthlyGarbageExpense?: Maybe<MonetaryAmount>;
  estimatedMonthlyHeatingExpense?: Maybe<MonetaryAmount>;
  estimatedMonthlyHydroExpense?: Maybe<MonetaryAmount>;
  /** @deprecated No longer supported */
  estimatedMonthlyHydroUsageExpense?: Maybe<MonetaryAmount>;
  estimatedMonthlyInsuranceExpense?: Maybe<MonetaryAmount>;
  estimatedMonthlyLawnExpense?: Maybe<MonetaryAmount>;
  estimatedMonthlyMaintenanceExpense?: Maybe<MonetaryAmount>;
  estimatedMonthlyManagementExpenses?: Maybe<MonetaryAmount>;
  /** @deprecated No longer supported */
  estimatedMonthlyNaturalGasUsageExpense?: Maybe<MonetaryAmount>;
  estimatedMonthlyParkingExpense?: Maybe<MonetaryAmount>;
  estimatedMonthlyRentalEquipmentExpense?: Maybe<MonetaryAmount>;
  estimatedMonthlyRentalIncome?: Maybe<MonetaryAmount>;
  estimatedMonthlySecurityExpense?: Maybe<MonetaryAmount>;
  estimatedMonthlyStorageExpense?: Maybe<MonetaryAmount>;
  estimatedMonthlyWaterSewerExpense?: Maybe<MonetaryAmount>;
  /** @deprecated No longer supported */
  estimatedMonthlyWaterUsageExpense?: Maybe<MonetaryAmount>;
  ownershipType?: Maybe<OwnershipType>;
  ownershipTypeName?: Maybe<Scalars['String']>;
  presentOccupancy?: Maybe<OccupancyType>;
  presentOccupancyName?: Maybe<Scalars['String']>;
  propertyTax?: Maybe<PropertyTax>;
  zoning?: Maybe<Zoning>;
  zoningName?: Maybe<Scalars['String']>;
};

export type ResidentialHouseConstruction = {
  __typename?: 'ResidentialHouseConstruction';
  buildYearMax?: Maybe<Scalars['Int']>;
  buildYearMin?: Maybe<Scalars['Int']>;
  builderModel?: Maybe<Scalars['String']>;
  builderName?: Maybe<Scalars['String']>;
  buildingAge?: Maybe<Scalars['String']>;
};

export type ResidentialHousePropertyDetails = ResidentialPropertyDetails & {
  __typename?: 'ResidentialHousePropertyDetails';
  /** @deprecated No longer supported */
  accessibilityFeatures?: Maybe<Array<AccessibilityFeature>>;
  /**
   * Will be removed soon without replacement
   * @deprecated No longer supported
   */
  basementFeatures?: Maybe<Array<BasementFeature>>;
  basementFinish?: Maybe<BasementFinish>;
  basementFinishRaw?: Maybe<Scalars['String']>;
  /**
   * Will be removed soon. Replaced by basementFinish
   * @deprecated No longer supported
   */
  basementType?: Maybe<BasementType>;
  /**
   * Will be removed soon. Fields were flattened to baths, bathsFull, bathsPartial, bathsEnsuite
   * @deprecated No longer supported
   */
  bathrooms?: Maybe<BathroomCount>;
  baths?: Maybe<Scalars['Float']>;
  bathsEnsuite?: Maybe<Scalars['Int']>;
  bathsFull?: Maybe<Scalars['Int']>;
  bathsPartial?: Maybe<Scalars['Int']>;
  /**
   * Will be removed soon. Replaced by beds, bedsAboveGrade, bedsBelowGrade
   * @deprecated No longer supported
   */
  bedrooms?: Maybe<HouseRoomCount>;
  beds?: Maybe<Scalars['Int']>;
  bedsAboveGrade?: Maybe<Scalars['Int']>;
  bedsBelowGrade?: Maybe<Scalars['Int']>;
  buildYear?: Maybe<Scalars['String']>;
  buildYearMax?: Maybe<Scalars['Int']>;
  buildYearMin?: Maybe<Scalars['Int']>;
  builderModel?: Maybe<Scalars['String']>;
  builderName?: Maybe<Scalars['String']>;
  /**
   * Will be removed soon. Fields were flattened to builderName, builderModel, buildYear, buildYearMin, buildYearMax
   * @deprecated No longer supported
   */
  construction?: Maybe<ResidentialHouseConstruction>;
  exterior?: Maybe<Array<ExteriorType>>;
  /** @deprecated No longer supported */
  exteriorFeatures?: Maybe<Array<ExteriorFeature>>;
  /** @deprecated No longer supported */
  exteriorFinishes?: Maybe<Array<ExteriorFinish>>;
  exteriorName?: Maybe<Array<Scalars['String']>>;
  exteriorRaw?: Maybe<Scalars['String']>;
  /** @deprecated No longer supported */
  fireplaces?: Maybe<Fireplaces>;
  flooring?: Maybe<Array<FlooringType>>;
  flooringName?: Maybe<Array<Scalars['String']>>;
  flooringRaw?: Maybe<Scalars['String']>;
  foundation?: Maybe<Array<FoundationType>>;
  foundationName?: Maybe<Array<Scalars['String']>>;
  foundationRaw?: Maybe<Scalars['String']>;
  houseStyles?: Maybe<Array<HouseStyle>>;
  houseStylesName?: Maybe<Array<Scalars['String']>>;
  interiorFeatures?: Maybe<Array<InteriorHouseFeature>>;
  interiorFeaturesName?: Maybe<Array<Scalars['String']>>;
  /** @deprecated No longer supported */
  interiorFinishes?: Maybe<Array<InteriorFinish>>;
  /**
   * Will be removed soon without replacement
   * @deprecated No longer supported
   */
  laundry?: Maybe<HouseRoomCount>;
  levels?: Maybe<Scalars['Int']>;
  lot?: Maybe<HouseLot>;
  /**
   * Will be removed soon without replacement
   * @deprecated No longer supported
   */
  lotFeatures?: Maybe<Array<LotFeature>>;
  parkingRaw?: Maybe<Scalars['String']>;
  parkingTypes?: Maybe<Array<ParkingType>>;
  parkingTypesName?: Maybe<Array<Scalars['String']>>;
  /**
   * Will be removed soon without replacement
   * @deprecated No longer supported
   */
  poolFeatures?: Maybe<Array<PoolFeatureType>>;
  residentialSubType?: Maybe<ResidentialSubType>;
  residentialSubTypeName?: Maybe<Scalars['String']>;
  residentialType?: Maybe<ResidentialType>;
  residentialTypeName?: Maybe<Scalars['String']>;
  roof?: Maybe<Array<RoofType>>;
  roofName?: Maybe<Array<Scalars['String']>>;
  roofRaw?: Maybe<Scalars['String']>;
  squareFootage?: Maybe<SquareFootage>;
  stories?: Maybe<Scalars['Int']>;
  totalParkingSpots?: Maybe<Scalars['Int']>;
  utilities?: Maybe<ResidentialPropertyUtilities>;
  virtualTourUrl?: Maybe<Scalars['String']>;
  waterfrontAccess?: Maybe<WaterfrontAccess>;
  waterfrontType?: Maybe<WaterfrontType>;
};

export type ResidentialListingGeneralInquiry = Inquiry & {
  __typename?: 'ResidentialListingGeneralInquiry';
  chimeExportedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  fubExportedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  lastName: Scalars['String'];
  listing: ResidentialPropertyForSaleListing;
  message?: Maybe<Scalars['String']>;
  /** @deprecated Please use 'firstName' and 'lastName' */
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  status: InquiryStatus;
  statusName: Scalars['String'];
  type: InquiryType;
  typeName: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<AdminUser | AgentUser | ConsumerUser | ContactUser>;
};

export type ResidentialListingOpenHouseInquiry = Inquiry & {
  __typename?: 'ResidentialListingOpenHouseInquiry';
  chimeExportedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  fubExportedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  lastName: Scalars['String'];
  listing: ResidentialPropertyForSaleListing;
  message?: Maybe<Scalars['String']>;
  /** @deprecated Please use 'firstName' and 'lastName' */
  name?: Maybe<Scalars['String']>;
  openHouse: OpenHouse;
  phoneNumber?: Maybe<Scalars['String']>;
  status: InquiryStatus;
  statusName: Scalars['String'];
  type: InquiryType;
  typeName: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<AdminUser | AgentUser | ConsumerUser | ContactUser>;
};

export type ResidentialListingPrivateShowingInquiry = Inquiry & {
  __typename?: 'ResidentialListingPrivateShowingInquiry';
  chimeExportedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  fubExportedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  lastName: Scalars['String'];
  listing: ResidentialPropertyForSaleListing;
  message?: Maybe<Scalars['String']>;
  /** @deprecated Please use 'firstName' and 'lastName' */
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  requestedShowingDate: Scalars['Date'];
  requestedShowingTime: DayTime;
  requestedShowingTimeName: Scalars['String'];
  status: InquiryStatus;
  statusName: Scalars['String'];
  type: InquiryType;
  typeName: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<AdminUser | AgentUser | ConsumerUser | ContactUser>;
};

export type ResidentialListingTag = {
  __typename?: 'ResidentialListingTag';
  name: Scalars['String'];
  tag: Scalars['String'];
  weight: Scalars['Int'];
};

export type ResidentialListingTagFacet = Facet & {
  __typename?: 'ResidentialListingTagFacet';
  categoryName: Scalars['String'];
  count: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  selected: Scalars['Boolean'];
};

/** Details around whats included in the property ownership, policies, common elements, and expenses */
export type ResidentialOwnershipDetails = {
  associationFee?: Maybe<AssociationFee>;
  /** Ownership Assets i.e. owned appliances vs rented appliances */
  estimatedMonthlyGarbageExpense?: Maybe<MonetaryAmount>;
  estimatedMonthlyHeatingExpense?: Maybe<MonetaryAmount>;
  estimatedMonthlyHydroExpense?: Maybe<MonetaryAmount>;
  /**
   * Will be removed soon. Replaced with estimatedMonthlyHydroExpense
   * @deprecated No longer supported
   */
  estimatedMonthlyHydroUsageExpense?: Maybe<MonetaryAmount>;
  estimatedMonthlyInsuranceExpense?: Maybe<MonetaryAmount>;
  estimatedMonthlyLawnExpense?: Maybe<MonetaryAmount>;
  estimatedMonthlyMaintenanceExpense?: Maybe<MonetaryAmount>;
  estimatedMonthlyManagementExpenses?: Maybe<MonetaryAmount>;
  /**
   * Will be removed soon without replacement
   * @deprecated No longer supported
   */
  estimatedMonthlyNaturalGasUsageExpense?: Maybe<MonetaryAmount>;
  estimatedMonthlyParkingExpense?: Maybe<MonetaryAmount>;
  estimatedMonthlyRentalEquipmentExpense?: Maybe<MonetaryAmount>;
  estimatedMonthlyRentalIncome?: Maybe<MonetaryAmount>;
  estimatedMonthlySecurityExpense?: Maybe<MonetaryAmount>;
  estimatedMonthlyStorageExpense?: Maybe<MonetaryAmount>;
  estimatedMonthlyWaterSewerExpense?: Maybe<MonetaryAmount>;
  /**
   * Will be removed soon without replacement
   * @deprecated No longer supported
   */
  estimatedMonthlyWaterUsageExpense?: Maybe<MonetaryAmount>;
  ownershipType?: Maybe<OwnershipType>;
  ownershipTypeName?: Maybe<Scalars['String']>;
  presentOccupancy?: Maybe<OccupancyType>;
  presentOccupancyName?: Maybe<Scalars['String']>;
  propertyTax?: Maybe<PropertyTax>;
  zoning?: Maybe<Zoning>;
  zoningName?: Maybe<Scalars['String']>;
};

/** Details about the property characteristics */
export type ResidentialPropertyDetails = {
  /** @deprecated No longer supported */
  accessibilityFeatures?: Maybe<Array<AccessibilityFeature>>;
  /** @deprecated No longer supported */
  bathrooms?: Maybe<BathroomCount>;
  baths?: Maybe<Scalars['Float']>;
  bathsEnsuite?: Maybe<Scalars['Int']>;
  bathsFull?: Maybe<Scalars['Int']>;
  bathsPartial?: Maybe<Scalars['Int']>;
  beds?: Maybe<Scalars['Int']>;
  buildYear?: Maybe<Scalars['String']>;
  buildYearMax?: Maybe<Scalars['Int']>;
  buildYearMin?: Maybe<Scalars['Int']>;
  builderModel?: Maybe<Scalars['String']>;
  builderName?: Maybe<Scalars['String']>;
  exterior?: Maybe<Array<ExteriorType>>;
  exteriorName?: Maybe<Array<Scalars['String']>>;
  exteriorRaw?: Maybe<Scalars['String']>;
  flooring?: Maybe<Array<FlooringType>>;
  flooringName?: Maybe<Array<Scalars['String']>>;
  flooringRaw?: Maybe<Scalars['String']>;
  foundation?: Maybe<Array<FoundationType>>;
  foundationName?: Maybe<Array<Scalars['String']>>;
  foundationRaw?: Maybe<Scalars['String']>;
  /**
   * Will be removed soon without replacement
   * @deprecated No longer supported
   */
  interiorFinishes?: Maybe<Array<InteriorFinish>>;
  levels?: Maybe<Scalars['Int']>;
  parkingRaw?: Maybe<Scalars['String']>;
  parkingTypes?: Maybe<Array<ParkingType>>;
  parkingTypesName?: Maybe<Array<Scalars['String']>>;
  residentialSubType?: Maybe<ResidentialSubType>;
  residentialSubTypeName?: Maybe<Scalars['String']>;
  residentialType?: Maybe<ResidentialType>;
  residentialTypeName?: Maybe<Scalars['String']>;
  roof?: Maybe<Array<RoofType>>;
  roofName?: Maybe<Array<Scalars['String']>>;
  roofRaw?: Maybe<Scalars['String']>;
  squareFootage?: Maybe<SquareFootage>;
  stories?: Maybe<Scalars['Int']>;
  totalParkingSpots?: Maybe<Scalars['Int']>;
  utilities?: Maybe<ResidentialPropertyUtilities>;
  virtualTourUrl?: Maybe<Scalars['String']>;
  /**
   * Will be removed soon without replacement
   * @deprecated No longer supported
   */
  waterfrontAccess?: Maybe<WaterfrontAccess>;
  /**
   * Will be removed soon without replacement
   * @deprecated No longer supported
   */
  waterfrontType?: Maybe<WaterfrontType>;
};

export type ResidentialPropertyForSaleListing = ResidentialPropertyListing & {
  __typename?: 'ResidentialPropertyForSaleListing';
  accessInstructions?: Maybe<Scalars['String']>;
  buyerCashback?: Maybe<MonetaryAmount>;
  createdAt: Scalars['DateTime'];
  dateAvailable?: Maybe<Scalars['Date']>;
  dateListed?: Maybe<Scalars['Date']>;
  dateSold?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  descriptionSummary?: Maybe<Array<Scalars['String']>>;
  distance?: Maybe<Distance>;
  exclusions?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  inclusions?: Maybe<Scalars['String']>;
  inquired?: Maybe<Scalars['Boolean']>;
  inquiries?: Maybe<Array<FurnitureGeneralInquiry | GeneralInquiry | PropertyListingOpportunityInquiry | ResidentialAppraisalInquiry | ResidentialListingGeneralInquiry | ResidentialListingOpenHouseInquiry | ResidentialListingPrivateShowingInquiry>>;
  liked?: Maybe<Scalars['Boolean']>;
  likes?: Maybe<Array<AdminUser | AgentUser | ConsumerUser | ContactUser>>;
  listingContract?: Maybe<ResidentialPropertyListingContract>;
  listingPrice?: Maybe<MonetaryAmount>;
  listingStatus: PropertyListingStatus;
  listingStatusName: Scalars['String'];
  listingType: ListingType;
  listingTypeName: Scalars['String'];
  media?: Maybe<Array<File | Image | Model3D>>;
  mlsDetails?: Maybe<ResidentialPropertyListingMlsDetails>;
  nearbyListings?: Maybe<Array<ResidentialPropertyForSaleListing>>;
  ownershipDetails?: Maybe<ResidentialCondoOwnershipDetails | ResidentialFreeholdOwnershipDetails>;
  /**
   * Will be removed soon. dateAvailable replaces earliestPossession within possession
   * @deprecated No longer supported
   */
  possession?: Maybe<ResidentialPropertyPossession>;
  property: Property;
  propertyDetails?: Maybe<ResidentialApartmentPropertyDetails | ResidentialHousePropertyDetails>;
  rooms?: Maybe<Array<ResidentialPropertyRoom>>;
  salePrice?: Maybe<MonetaryAmount>;
  similarListings?: Maybe<Array<ResidentialPropertyListingWithScore>>;
  slug: Scalars['String'];
  source: DataSource;
  sourceName: Scalars['String'];
  tags: Array<ResidentialListingTag>;
  upcomingOpenHouses?: Maybe<Array<OpenHouse>>;
  updatedAt: Scalars['DateTime'];
  upgrades?: Maybe<Scalars['String']>;
  visibility: ListingVisibility;
};


export type ResidentialPropertyForSaleListingMediaArgs = {
  first?: InputMaybe<Scalars['Int']>;
  statuses?: InputMaybe<Array<MediaStatus>>;
};


export type ResidentialPropertyForSaleListingNearbyListingsArgs = {
  distance?: InputMaybe<DistanceInput>;
  first?: InputMaybe<Scalars['Int']>;
};


export type ResidentialPropertyForSaleListingRoomsArgs = {
  statuses?: InputMaybe<Array<ResidentialRoomStatus>>;
};


export type ResidentialPropertyForSaleListingSimilarListingsArgs = {
  first?: InputMaybe<Scalars['Int']>;
};

export type ResidentialPropertyListing = {
  accessInstructions?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  /** Will be removed soon without replacement */
  dateAvailable?: Maybe<Scalars['Date']>;
  dateListed?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  descriptionSummary?: Maybe<Array<Scalars['String']>>;
  distance?: Maybe<Distance>;
  exclusions?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  inclusions?: Maybe<Scalars['String']>;
  inquired?: Maybe<Scalars['Boolean']>;
  inquiries?: Maybe<Array<FurnitureGeneralInquiry | GeneralInquiry | PropertyListingOpportunityInquiry | ResidentialAppraisalInquiry | ResidentialListingGeneralInquiry | ResidentialListingOpenHouseInquiry | ResidentialListingPrivateShowingInquiry>>;
  liked?: Maybe<Scalars['Boolean']>;
  likes?: Maybe<Array<AdminUser | AgentUser | ConsumerUser | ContactUser>>;
  listingContract?: Maybe<ResidentialPropertyListingContract>;
  listingStatus: PropertyListingStatus;
  listingStatusName: Scalars['String'];
  listingType: ListingType;
  listingTypeName: Scalars['String'];
  media?: Maybe<Array<File | Image | Model3D>>;
  mlsDetails?: Maybe<ResidentialPropertyListingMlsDetails>;
  nearbyListings?: Maybe<Array<ResidentialPropertyForSaleListing>>;
  property: Property;
  propertyDetails?: Maybe<ResidentialApartmentPropertyDetails | ResidentialHousePropertyDetails>;
  rooms?: Maybe<Array<ResidentialPropertyRoom>>;
  similarListings?: Maybe<Array<ResidentialPropertyListingWithScore>>;
  slug: Scalars['String'];
  source: DataSource;
  sourceName: Scalars['String'];
  tags: Array<ResidentialListingTag>;
  upcomingOpenHouses?: Maybe<Array<OpenHouse>>;
  updatedAt: Scalars['DateTime'];
  upgrades?: Maybe<Scalars['String']>;
  visibility: ListingVisibility;
};


export type ResidentialPropertyListingMediaArgs = {
  first?: InputMaybe<Scalars['Int']>;
  statuses?: InputMaybe<Array<MediaStatus>>;
};


export type ResidentialPropertyListingNearbyListingsArgs = {
  distance?: InputMaybe<DistanceInput>;
  first?: InputMaybe<Scalars['Int']>;
};


export type ResidentialPropertyListingRoomsArgs = {
  statuses?: InputMaybe<Array<ResidentialRoomStatus>>;
};


export type ResidentialPropertyListingSimilarListingsArgs = {
  first?: InputMaybe<Scalars['Int']>;
};

export type ResidentialPropertyListingComparablesResponse = {
  __typename?: 'ResidentialPropertyListingComparablesResponse';
  aggregations?: Maybe<Array<Aggregation>>;
  listingPrice?: Maybe<Scalars['BigDecimal']>;
  listingPriceYearAgo?: Maybe<Scalars['BigDecimal']>;
  newListingsCount?: Maybe<Scalars['Long']>;
  salePrice?: Maybe<Scalars['BigDecimal']>;
  soldListingsCount?: Maybe<Scalars['Long']>;
  totalListingsCount?: Maybe<Scalars['Long']>;
};

export type ResidentialPropertyListingConnection = {
  __typename?: 'ResidentialPropertyListingConnection';
  edges?: Maybe<Array<Maybe<ResidentialPropertyListingConnectionEdge>>>;
  facetGroups?: Maybe<Array<Maybe<FacetGroup>>>;
  filters?: Maybe<Array<Maybe<AggregatedFacetFilter | BooleanFilter | DateRangeFilter | FloatRangeFilter | IntegerRangeFilter | SimpleFacetFilter | TagCollectionFilter>>>;
  histogramGroups?: Maybe<Array<Maybe<HistogramGroup>>>;
  metricGroups?: Maybe<Array<Maybe<MetricGroup>>>;
  pageInfo?: Maybe<PageInfo>;
  places?: Maybe<Array<Maybe<Place>>>;
  savedSearches?: Maybe<Array<Maybe<SavedSearch>>>;
  sortOptions?: Maybe<Array<Maybe<SortOption>>>;
};


export type ResidentialPropertyListingConnectionFacetGroupsArgs = {
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type ResidentialPropertyListingConnectionFiltersArgs = {
  aggregatedFacetKeywordSearch?: InputMaybe<AggregatedFacetKeywordSearchInput>;
  fetchAggregatedFacetsFilterIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  includeTagFilters?: InputMaybe<Scalars['Boolean']>;
  selected?: InputMaybe<Scalars['Boolean']>;
};


export type ResidentialPropertyListingConnectionHistogramGroupsArgs = {
  histogramGroupInput?: InputMaybe<HistogramGroupInput>;
};


export type ResidentialPropertyListingConnectionMetricGroupsArgs = {
  metricInput?: InputMaybe<Array<InputMaybe<MetricGroupInput>>>;
};

export type ResidentialPropertyListingConnectionEdge = {
  __typename?: 'ResidentialPropertyListingConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<ResidentialPropertyForSaleListing>;
};

/** Property Listing Types */
export type ResidentialPropertyListingContract = {
  __typename?: 'ResidentialPropertyListingContract';
  buyersAgentCommissionPercentage?: Maybe<Scalars['Float']>;
  contractCancellationDate?: Maybe<Scalars['DateTime']>;
  contractCancellationFee?: Maybe<MonetaryAmount>;
  contractExpiryDate?: Maybe<Scalars['Date']>;
  contractStartDate?: Maybe<Scalars['Date']>;
  holdoverPeriodInDays?: Maybe<Scalars['Int']>;
  sellersAgentCommissionPercentage?: Maybe<Scalars['Float']>;
};

export type ResidentialPropertyListingLike = {
  __typename?: 'ResidentialPropertyListingLike';
  createdAt: Scalars['DateTime'];
  listing: ResidentialPropertyForSaleListing;
  user: AdminUser | AgentUser | ConsumerUser | ContactUser;
};

export type ResidentialPropertyListingLikeConnection = {
  __typename?: 'ResidentialPropertyListingLikeConnection';
  edges?: Maybe<Array<Maybe<ResidentialPropertyListingLikeConnectionEdge>>>;
  facetGroups?: Maybe<Array<Maybe<FacetGroup>>>;
  filters?: Maybe<Array<Maybe<AggregatedFacetFilter | BooleanFilter | DateRangeFilter | FloatRangeFilter | IntegerRangeFilter | SimpleFacetFilter | TagCollectionFilter>>>;
  histogramGroups?: Maybe<Array<Maybe<HistogramGroup>>>;
  metricGroups?: Maybe<Array<Maybe<MetricGroup>>>;
  pageInfo?: Maybe<PageInfo>;
  savedSearches?: Maybe<Array<Maybe<SavedSearch>>>;
  sortOptions?: Maybe<Array<Maybe<SortOption>>>;
};


export type ResidentialPropertyListingLikeConnectionFacetGroupsArgs = {
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type ResidentialPropertyListingLikeConnectionFiltersArgs = {
  aggregatedFacetKeywordSearch?: InputMaybe<AggregatedFacetKeywordSearchInput>;
  fetchAggregatedFacetsFilterIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  includeTagFilters?: InputMaybe<Scalars['Boolean']>;
  selected?: InputMaybe<Scalars['Boolean']>;
};


export type ResidentialPropertyListingLikeConnectionHistogramGroupsArgs = {
  histogramGroupInput?: InputMaybe<HistogramGroupInput>;
};


export type ResidentialPropertyListingLikeConnectionMetricGroupsArgs = {
  metricInput?: InputMaybe<Array<InputMaybe<MetricGroupInput>>>;
};

export type ResidentialPropertyListingLikeConnectionEdge = {
  __typename?: 'ResidentialPropertyListingLikeConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<ResidentialPropertyListingLike>;
};

export type ResidentialPropertyListingMlsDetails = {
  __typename?: 'ResidentialPropertyListingMlsDetails';
  brokerageName?: Maybe<Scalars['String']>;
  /** @deprecated All listings considered listed on mls */
  isListedOnMls?: Maybe<Scalars['Boolean']>;
  mlsBoard?: Maybe<Scalars['String']>;
  mlsListingUrl?: Maybe<Scalars['String']>;
  /** make this enum? */
  mlsNumber?: Maybe<Scalars['String']>;
  requiresCREATermsAgreement: Scalars['Boolean'];
  /** @deprecated 'requiresRegistration' will be replaced with 'visibility: ListingVisibility!' */
  requiresRegistration: Scalars['Boolean'];
};

export type ResidentialPropertyListingSemanticSearchInput = {
  first: Scalars['Int'];
  listingStatuses: Array<PropertyListingStatus>;
  query: Scalars['String'];
};

export type ResidentialPropertyListingWithScore = {
  __typename?: 'ResidentialPropertyListingWithScore';
  listing: ResidentialPropertyForSaleListing;
  score: Scalars['Float'];
};

/** Transaction Details Types */
export type ResidentialPropertyPossession = {
  __typename?: 'ResidentialPropertyPossession';
  earliestPossession?: Maybe<Scalars['Date']>;
  latestPossession?: Maybe<Scalars['Date']>;
  possessionRemarks?: Maybe<Scalars['String']>;
};

export type ResidentialPropertyRoom = Entity & {
  __typename?: 'ResidentialPropertyRoom';
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  height?: Maybe<Dimension>;
  id: Scalars['ID'];
  isHighlight?: Maybe<Scalars['Boolean']>;
  length?: Maybe<Dimension>;
  level?: Maybe<Level>;
  levelName?: Maybe<Scalars['String']>;
  listing?: Maybe<ResidentialPropertyForSaleListing>;
  media?: Maybe<Array<File | Image | Model3D>>;
  roomName: Scalars['String'];
  roomType: RoomType;
  roomTypeName: Scalars['String'];
  sequence: Scalars['Int'];
  status?: Maybe<ResidentialRoomStatus>;
  statusName?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  width?: Maybe<Dimension>;
};


export type ResidentialPropertyRoomMediaArgs = {
  first?: InputMaybe<Scalars['Int']>;
  statuses?: InputMaybe<Array<MediaStatus>>;
};

export type ResidentialPropertyRoomMetadata = {
  __typename?: 'ResidentialPropertyRoomMetadata';
  level: Array<EnumValueMetadata>;
  roomType: Array<EnumValueMetadata>;
  status: Array<EnumValueMetadata>;
};

export type ResidentialPropertyUtilities = {
  __typename?: 'ResidentialPropertyUtilities';
  coolingRaw?: Maybe<Scalars['String']>;
  coolingTypes?: Maybe<Array<CoolingType>>;
  coolingTypesName?: Maybe<Array<Scalars['String']>>;
  heatingRaw?: Maybe<Scalars['String']>;
  heatingTypes?: Maybe<Array<HeatingType>>;
  heatingTypesName?: Maybe<Array<Scalars['String']>>;
  sewerRaw?: Maybe<Scalars['String']>;
  sewerTypes?: Maybe<Array<SewerType>>;
  sewerTypesName?: Maybe<Array<Scalars['String']>>;
  waterSourceRaw?: Maybe<Scalars['String']>;
  waterSources?: Maybe<Array<WaterSourceType>>;
  waterSourcesName?: Maybe<Array<Scalars['String']>>;
};

export const enum ResidentialRoomStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
};

export const enum ResidentialSubType {
  Detached = 'DETACHED',
  HighRise = 'HIGH_RISE',
  LowRise = 'LOW_RISE',
  MultiFamilyHouse = 'MULTI_FAMILY_HOUSE',
  SemiDetached = 'SEMI_DETACHED',
  Townhouse = 'TOWNHOUSE',
  Unknown = 'UNKNOWN'
};

export const enum ResidentialType {
  Apartment = 'APARTMENT',
  House = 'HOUSE'
};

export type ReviewTaskInstanceInput = {
  id: Scalars['ID'];
  outcome: TaskReviewOutcome;
  payload?: InputMaybe<Scalars['JSON']>;
  reviewMessage?: InputMaybe<Scalars['String']>;
};

export type Rgb = {
  __typename?: 'Rgb';
  blue: Scalars['Int'];
  green: Scalars['Int'];
  red: Scalars['Int'];
};

export const enum RoofType {
  /** <https://ddwiki.reso.org/display/DDW17/Roof+Lookups> */
  Aluminum = 'ALUMINUM',
  AsbestosShingle = 'ASBESTOS_SHINGLE',
  Asphalt = 'ASPHALT',
  Bahama = 'BAHAMA',
  Barrel = 'BARREL',
  Bituthene = 'BITUTHENE',
  BuiltUp = 'BUILT_UP',
  Clay = 'CLAY',
  Composition = 'COMPOSITION',
  Concrete = 'CONCRETE',
  Copper = 'COPPER',
  Elastomeric = 'ELASTOMERIC',
  Fiberglass = 'FIBERGLASS',
  Flat = 'FLAT',
  FlatTile = 'FLAT_TILE',
  Foam = 'FOAM',
  GreenRoof = 'GREEN_ROOF',
  Mansard = 'MANSARD',
  Membrane = 'MEMBRANE',
  Metal = 'METAL',
  Mixed = 'MIXED',
  None = 'NONE',
  Other = 'OTHER',
  RolledOrHotMop = 'ROLLED_OR_HOT_MOP',
  Rubber = 'RUBBER',
  SeeRemarks = 'SEE_REMARKS',
  Shake = 'SHAKE',
  Shingle = 'SHINGLE',
  Slate = 'SLATE',
  SpanishTile = 'SPANISH_TILE',
  Stone = 'STONE',
  Synthetic = 'SYNTHETIC',
  TarAndGravel = 'TAR_AND_GRAVEL',
  TarOrGravel = 'TAR_OR_GRAVEL',
  Tile = 'TILE',
  Wood = 'WOOD'
};

export type RoomDataField = DataField & {
  __typename?: 'RoomDataField';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  key: Scalars['String'];
  logicalName?: Maybe<DataFieldLogicalName>;
  minimumEntriesRequired: Scalars['Int'];
  mlsField?: Maybe<MlsField>;
  multiSelect: Scalars['Boolean'];
  name: Scalars['String'];
  processed?: Maybe<Scalars['Boolean']>;
  type: DataFieldType;
  typeName: Scalars['String'];
};

export type RoomDataFormInputInstance = DataFormInputInstance & {
  __typename?: 'RoomDataFormInputInstance';
  /** Not used for this type. please use `rooms` field */
  currentValue?: Maybe<Scalars['String']>;
  definition: DataFormInputDefinition;
  editable: Scalars['Boolean'];
  id: Scalars['ID'];
  rooms?: Maybe<Array<ResidentialPropertyRoom>>;
};

export type RoomDataViewFieldInstance = DataViewFieldInstance & {
  __typename?: 'RoomDataViewFieldInstance';
  definition: DataViewFieldDefinition;
  rooms?: Maybe<Array<ResidentialPropertyRoom>>;
  /** Not used for this type. Please use `rooms` field */
  value?: Maybe<Scalars['String']>;
};

export const enum RoomType {
  Backyard = 'BACKYARD',
  Basement = 'BASEMENT',
  Bathroom = 'BATHROOM',
  Bedroom = 'BEDROOM',
  BonusRoom = 'BONUS_ROOM',
  Den = 'DEN',
  DiningRoom = 'DINING_ROOM',
  Ensuite = 'ENSUITE',
  Entrance = 'ENTRANCE',
  ExerciseRoom = 'EXERCISE_ROOM',
  FamilyRoom = 'FAMILY_ROOM',
  Foyer = 'FOYER',
  GameRoom = 'GAME_ROOM',
  Garage = 'GARAGE',
  GreatRoom = 'GREAT_ROOM',
  Gym = 'GYM',
  Kitchen = 'KITCHEN',
  Laundry = 'LAUNDRY',
  Library = 'LIBRARY',
  LivingRoom = 'LIVING_ROOM',
  Loft = 'LOFT',
  MediaRoom = 'MEDIA_ROOM',
  Mudroom = 'MUDROOM',
  Office = 'OFFICE',
  Other = 'OTHER',
  PowderRoom = 'POWDER_ROOM',
  PrimaryBedroom = 'PRIMARY_BEDROOM',
  Suana = 'SUANA',
  Sunroom = 'SUNROOM',
  UtilityRoom = 'UTILITY_ROOM',
  Workshop = 'WORKSHOP'
};

export type RootProjectEventActivity = Activity & {
  __typename?: 'RootProjectEventActivity';
  actor?: Maybe<AdminUser | AgentUser | ConsumerUser | ContactUser>;
  comments?: Maybe<Array<ActivityComment>>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** Whether the thread id fully read or there are any unread comments */
  isRead: Scalars['Boolean'];
  /** Whether the thread is resolved */
  isResolved?: Maybe<Scalars['Boolean']>;
  media?: Maybe<Array<File | Image | Model3D>>;
  message?: Maybe<Scalars['String']>;
  object?: Maybe<AdminUser | AgentUser | ApproveSubmissionToMlsTaskInstance | BasicTaskInstance | ConsumerUser | ContactUser | FileUploadTaskInstance | FormFillTaskInstance | ProjectInstance | PropertyListing | PropertyListingOpportunity | SubmitListingToMlsTaskInstance>;
  /** Timestamp of the last time the thread was read by the current user. False if the current user is not a thread participant */
  readAt?: Maybe<Scalars['DateTime']>;
  relatedEntity?: Maybe<Address | AdminUser | AgentUser | ConsumerUser | ContactUser | DataFormInstance | DataFormStepInstance | Image | PropertyComparativeMarketAnalysis | PropertyListing | PropertyListingIteration | ResidentialPropertyRoom>;
  status: ActivityStatus;
  subType?: Maybe<ActivitySubType>;
  subTypeName?: Maybe<Scalars['String']>;
  type: ActivityType;
  typeName: Scalars['String'];
  verb: Scalars['String'];
  visibility: ActivityVisibility;
  visibilityName: Scalars['String'];
};


export type RootProjectEventActivityCommentsArgs = {
  latest?: InputMaybe<Scalars['Int']>;
  statuses?: InputMaybe<Array<ActivityCommentStatus>>;
};


export type RootProjectEventActivityMediaArgs = {
  first?: InputMaybe<Scalars['Int']>;
  statuses?: InputMaybe<Array<MediaStatus>>;
};

export type SavePropertyListingIterationDynamicDataInput = {
  inputs: Array<InputMaybe<DataFormSaveInput>>;
  listingIterationId: Scalars['ID'];
};

export type SavedSearch = {
  __typename?: 'SavedSearch';
  connection: SavedSearchSupportedConnection;
  connectionName: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  filters: Array<SavedSearchFilter>;
  id: Scalars['ID'];
  name: Scalars['String'];
  selected: Scalars['Boolean'];
  status?: Maybe<SavedSearchStatus>;
  statusName?: Maybe<Scalars['String']>;
  user: AdminUser | AgentUser | ConsumerUser | ContactUser;
};

export type SavedSearchFilter = {
  __typename?: 'SavedSearchFilter';
  filterId: Scalars['String'];
  filterValue: Scalars['String'];
  id: Scalars['ID'];
  savedSearch: SavedSearch;
};

export const enum SavedSearchStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED'
};

export const enum SavedSearchSupportedConnection {
  Building = 'BUILDING',
  Company = 'COMPANY',
  Inquiry = 'INQUIRY',
  OpenHouse = 'OPEN_HOUSE',
  Opportunity = 'OPPORTUNITY',
  Place = 'PLACE',
  Product = 'PRODUCT',
  ProductInventory = 'PRODUCT_INVENTORY',
  ProjectDefinition = 'PROJECT_DEFINITION',
  ProjectInstance = 'PROJECT_INSTANCE',
  Property = 'PROPERTY',
  PropertyFloorPlan = 'PROPERTY_FLOOR_PLAN',
  PropertyListing = 'PROPERTY_LISTING',
  ResidentialListing = 'RESIDENTIAL_LISTING',
  TagCollection = 'TAG_COLLECTION',
  User = 'USER',
  UserMention = 'USER_MENTION'
};

export const enum ScriptOwnerEntityType {
  Outcome = 'OUTCOME',
  Project = 'PROJECT',
  Task = 'TASK'
};

export type ScriptOwnerInput = {
  entityId: Scalars['ID'];
  entityType: ScriptOwnerEntityType;
};

export type SendPdfPackageWithDataInput = {
  documents: Array<SignPdfDocumentInput>;
  isDraft: Scalars['Boolean'];
  isTest: Scalars['Boolean'];
  packageName: Scalars['String'];
};

export type SetFollowActivityObjectInput = {
  follow: Scalars['Boolean'];
  objectId: Scalars['ID'];
  objectType: ActivityObjectType;
};

export type SetMediaInput = {
  contentType: Scalars['String'];
  entityId: Scalars['ID'];
  originalFileName?: InputMaybe<Scalars['String']>;
  sizeBytes: Scalars['Long'];
  useCase: SetMediaUseCase;
};

export const enum SetMediaUseCase {
  BoxRoomConfigurationItemsModel3D = 'BOX_ROOM_CONFIGURATION_ITEMS_MODEL3D',
  BoxRoomConfigurationModel3D = 'BOX_ROOM_CONFIGURATION_MODEL3D',
  ProductIosArModel3D = 'PRODUCT_IOS_AR_MODEL3D',
  ProductModel3D = 'PRODUCT_MODEL3D',
  ProductModel3DLowRes = 'PRODUCT_MODEL3D_LOW_RES',
  ProductUhdModel3D = 'PRODUCT_UHD_MODEL3D',
  ProductUhdUnbakedModel3D = 'PRODUCT_UHD_UNBAKED_MODEL3D',
  PropertyFloorPlanLevelConfigurationItemsModel3D = 'PROPERTY_FLOOR_PLAN_LEVEL_CONFIGURATION_ITEMS_MODEL3D',
  PropertyFloorPlanLevelConfigurationModel3D = 'PROPERTY_FLOOR_PLAN_LEVEL_CONFIGURATION_MODEL3D',
  PropertyFloorPlanLevelModel3D = 'PROPERTY_FLOOR_PLAN_LEVEL_MODEL3D',
  SpaceMaterialAoMap = 'SPACE_MATERIAL_AO_MAP',
  SpaceMaterialDiffuseMap = 'SPACE_MATERIAL_DIFFUSE_MAP',
  SpaceMaterialMetalnessMap = 'SPACE_MATERIAL_METALNESS_MAP',
  SpaceMaterialNormalMap = 'SPACE_MATERIAL_NORMAL_MAP',
  SpaceMaterialRoughnessMap = 'SPACE_MATERIAL_ROUGHNESS_MAP',
  SpaceMaterialSpecularMap = 'SPACE_MATERIAL_SPECULAR_MAP',
  UserProfileImage = 'USER_PROFILE_IMAGE'
};

export const enum SewerType {
  /** <https://ddwiki.reso.org/display/DDW17/Sewer+Lookups> */
  HoldingTank = 'HOLDING_TANK',
  MunicipalSewage = 'MUNICIPAL_SEWAGE',
  NotConnected = 'NOT_CONNECTED',
  NoSewageSystem = 'NO_SEWAGE_SYSTEM',
  Other = 'OTHER',
  PrivateSewer = 'PRIVATE_SEWER',
  Septic = 'SEPTIC',
  Sewer = 'SEWER'
};

export const enum ShortTermRentalPolicy {
  NoRestrictions = 'NO_RESTRICTIONS',
  OneMonth = 'ONE_MONTH',
  OneYear = 'ONE_YEAR',
  SixMonths = 'SIX_MONTHS',
  StrictlyProhibited = 'STRICTLY_PROHIBITED',
  ThreeMonths = 'THREE_MONTHS'
};

export type SignPdfDocumentFieldInput = {
  id: Scalars['ID'];
  value: Scalars['String'];
};

export type SignPdfDocumentInput = {
  filledFields?: InputMaybe<Array<SignPdfDocumentFieldInput>>;
  signers: Array<SignPdfDocumentSignerInput>;
  templateId: Scalars['ID'];
};

export type SignPdfDocumentSignerInput = {
  email: Scalars['String'];
  fullName: Scalars['String'];
  signatureFieldIds: Array<Scalars['ID']>;
};

export type SimpleFacet = {
  __typename?: 'SimpleFacet';
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  selected: Scalars['Boolean'];
};

export type SimpleFacetFilter = ConnectionFilter & {
  __typename?: 'SimpleFacetFilter';
  allowMultiSelect?: Maybe<Scalars['Boolean']>;
  currentValue?: Maybe<Array<FacetCurrentValue>>;
  facets?: Maybe<Array<SimpleFacet>>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<ConnectionFilterType>;
  typeName?: Maybe<Scalars['String']>;
};

export type SortOption = {
  __typename?: 'SortOption';
  id: Scalars['ID'];
  name: Scalars['String'];
  selected: Scalars['Boolean'];
};

export const enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
};

export type SpaceConfiguration = {
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  user?: Maybe<AdminUser | AgentUser | ConsumerUser | ContactUser>;
};

export type SpaceConfigurationItem = {
  __typename?: 'SpaceConfigurationItem';
  position: Vector3;
  product: FurnitureProduct;
  rotation: Vector3;
};

export type SpaceConfigurationItemInput = {
  position: Vector3Input;
  productId: Scalars['ID'];
  rotation: Vector3Input;
};

export type SpaceMaterial = {
  __typename?: 'SpaceMaterial';
  ambient: Rgb;
  aoMap?: Maybe<Image>;
  applications: Array<SpaceMaterialApplication>;
  applicationsName: Array<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  diffuse: Rgb;
  diffuseMap?: Maybe<Image>;
  id: Scalars['ID'];
  metalnessMap?: Maybe<Image>;
  name: Scalars['String'];
  normalMap?: Maybe<Image>;
  order: Scalars['Int'];
  roughnessMap?: Maybe<Image>;
  shininess: Scalars['Int'];
  specularMap?: Maybe<Image>;
  status: SpaceMaterialStatus;
  statusName: Scalars['String'];
};

export const enum SpaceMaterialApplication {
  Ceiling = 'CEILING',
  Floor = 'FLOOR',
  Product = 'PRODUCT',
  Wall = 'WALL'
};

export type SpaceMaterialConnection = {
  __typename?: 'SpaceMaterialConnection';
  edges?: Maybe<Array<Maybe<SpaceMaterialConnectionEdge>>>;
  facetGroups?: Maybe<Array<Maybe<FacetGroup>>>;
  filters?: Maybe<Array<Maybe<AggregatedFacetFilter | BooleanFilter | DateRangeFilter | FloatRangeFilter | IntegerRangeFilter | SimpleFacetFilter | TagCollectionFilter>>>;
  histogramGroups?: Maybe<Array<Maybe<HistogramGroup>>>;
  metricGroups?: Maybe<Array<Maybe<MetricGroup>>>;
  pageInfo?: Maybe<PageInfo>;
  savedSearches?: Maybe<Array<Maybe<SavedSearch>>>;
  sortOptions?: Maybe<Array<Maybe<SortOption>>>;
};


export type SpaceMaterialConnectionFacetGroupsArgs = {
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type SpaceMaterialConnectionFiltersArgs = {
  aggregatedFacetKeywordSearch?: InputMaybe<AggregatedFacetKeywordSearchInput>;
  fetchAggregatedFacetsFilterIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  includeTagFilters?: InputMaybe<Scalars['Boolean']>;
  selected?: InputMaybe<Scalars['Boolean']>;
};


export type SpaceMaterialConnectionHistogramGroupsArgs = {
  histogramGroupInput?: InputMaybe<HistogramGroupInput>;
};


export type SpaceMaterialConnectionMetricGroupsArgs = {
  metricInput?: InputMaybe<Array<InputMaybe<MetricGroupInput>>>;
};

export type SpaceMaterialConnectionEdge = {
  __typename?: 'SpaceMaterialConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<SpaceMaterial>;
};

export type SpaceMaterialConnectionInput = {
  applications?: InputMaybe<Array<SpaceMaterialApplication>>;
  first: Scalars['Int'];
  offset: Scalars['Int'];
  sortKey?: InputMaybe<SpaceMaterialSortKey>;
  sortOptionId?: InputMaybe<Scalars['ID']>;
  sortOrder?: InputMaybe<SortOrder>;
  statuses?: InputMaybe<Array<SpaceMaterialStatus>>;
  tagIds?: InputMaybe<Array<Scalars['ID']>>;
};

export const enum SpaceMaterialSortKey {
  CreatedAt = 'CREATED_AT',
  Name = 'NAME',
  Order = 'ORDER'
};

export const enum SpaceMaterialStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  Draft = 'DRAFT'
};

export type SquareFootage = {
  __typename?: 'SquareFootage';
  approximateSquareFootage?: Maybe<Scalars['String']>;
  approximateSquareFootageMax?: Maybe<Scalars['Long']>;
  approximateSquareFootageMin?: Maybe<Scalars['Long']>;
  derivedSquareFootage?: Maybe<Scalars['Long']>;
  derivedSquareFootageSource?: Maybe<DerivedSquareFootageSource>;
  derivedSquareFootageSourceName?: Maybe<Scalars['String']>;
};

export const enum SquareFootageSource {
  Description = 'DESCRIPTION',
  Mls = 'MLS',
  Mpac = 'MPAC',
  RoomDimensions = 'ROOM_DIMENSIONS',
  Unreserved = 'UNRESERVED'
};

export type StartProjectActionDefinition = ActionDefinition & {
  __typename?: 'StartProjectActionDefinition';
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Icon>;
  id: Scalars['ID'];
  kind: ActionKind;
  logicalName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  sequence?: Maybe<Scalars['Int']>;
  targetProjectDefinition: ProjectDefinition;
};

export type StartProjectInstanceInput = {
  assignments?: InputMaybe<Array<WorkflowAssignmentInput>>;
  collaborators?: InputMaybe<Array<ProjectCollaboratorInput>>;
  id: Scalars['ID'];
  payload?: InputMaybe<Scalars['JSON']>;
  projectLeadId?: InputMaybe<Scalars['ID']>;
};

export type StartTaskInstanceInput = {
  /** Mandatory if task have no assigned user yet */
  assignedUserId?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  payload?: InputMaybe<Scalars['JSON']>;
};

export const enum StorageType {
  Common = 'COMMON',
  EnSuite = 'EN_SUITE',
  Exclusive = 'EXCLUSIVE',
  Locker = 'LOCKER'
};

export type StringDataField = DataField & {
  __typename?: 'StringDataField';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  key: Scalars['String'];
  logicalName?: Maybe<DataFieldLogicalName>;
  maxLength?: Maybe<Scalars['Int']>;
  minLength?: Maybe<Scalars['Int']>;
  mlsField?: Maybe<MlsField>;
  name: Scalars['String'];
  processed?: Maybe<Scalars['Boolean']>;
  type: DataFieldType;
  typeName: Scalars['String'];
};

export type StringDataFormInputInstance = DataFormInputInstance & {
  __typename?: 'StringDataFormInputInstance';
  currentValue?: Maybe<Scalars['String']>;
  definition: DataFormInputDefinition;
  editable: Scalars['Boolean'];
  id: Scalars['ID'];
};

export type StringDataViewFieldInstance = DataViewFieldInstance & {
  __typename?: 'StringDataViewFieldInstance';
  definition: DataViewFieldDefinition;
  value?: Maybe<Scalars['String']>;
};

export type SubProjectEventActivity = Activity & {
  __typename?: 'SubProjectEventActivity';
  actor?: Maybe<AdminUser | AgentUser | ConsumerUser | ContactUser>;
  comments?: Maybe<Array<ActivityComment>>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** Whether the thread id fully read or there are any unread comments */
  isRead: Scalars['Boolean'];
  /** Whether the thread is resolved */
  isResolved?: Maybe<Scalars['Boolean']>;
  media?: Maybe<Array<File | Image | Model3D>>;
  message?: Maybe<Scalars['String']>;
  object?: Maybe<AdminUser | AgentUser | ApproveSubmissionToMlsTaskInstance | BasicTaskInstance | ConsumerUser | ContactUser | FileUploadTaskInstance | FormFillTaskInstance | ProjectInstance | PropertyListing | PropertyListingOpportunity | SubmitListingToMlsTaskInstance>;
  /** Timestamp of the last time the thread was read by the current user. False if the current user is not a thread participant */
  readAt?: Maybe<Scalars['DateTime']>;
  relatedEntity?: Maybe<Address | AdminUser | AgentUser | ConsumerUser | ContactUser | DataFormInstance | DataFormStepInstance | Image | PropertyComparativeMarketAnalysis | PropertyListing | PropertyListingIteration | ResidentialPropertyRoom>;
  status: ActivityStatus;
  subProjectInstance: ProjectInstance;
  subType?: Maybe<ActivitySubType>;
  subTypeName?: Maybe<Scalars['String']>;
  type: ActivityType;
  typeName: Scalars['String'];
  verb: Scalars['String'];
  visibility: ActivityVisibility;
  visibilityName: Scalars['String'];
};


export type SubProjectEventActivityCommentsArgs = {
  latest?: InputMaybe<Scalars['Int']>;
  statuses?: InputMaybe<Array<ActivityCommentStatus>>;
};


export type SubProjectEventActivityMediaArgs = {
  first?: InputMaybe<Scalars['Int']>;
  statuses?: InputMaybe<Array<MediaStatus>>;
};

export type SubmitListingToMlsTaskDefinition = TaskDefinition & {
  __typename?: 'SubmitListingToMlsTaskDefinition';
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Icon>;
  id: Scalars['ID'];
  logicalName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  projectDefinition: ProjectDefinition;
  scriptOnComplete?: Maybe<Scalars['String']>;
  scriptOnCreate?: Maybe<Scalars['String']>;
  scriptOnStart?: Maybe<Scalars['String']>;
  scriptValidateBeforeComplete?: Maybe<Scalars['String']>;
  scriptValidateBeforeCreate?: Maybe<Scalars['String']>;
  scriptValidateBeforeStart?: Maybe<Scalars['String']>;
  type: TaskType;
  typeName: Scalars['String'];
  uiOnComplete?: Maybe<Array<Scalars['String']>>;
  uiOnCreate?: Maybe<Array<Scalars['String']>>;
  uiOnStart?: Maybe<Array<Scalars['String']>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SubmitListingToMlsTaskInstance = AbstractTaskInstance & ActivityObject & {
  __typename?: 'SubmitListingToMlsTaskInstance';
  activityFeed?: Maybe<ActivityFeed>;
  assignedBy?: Maybe<AdminUser | AgentUser | ConsumerUser | ContactUser>;
  assignedUser?: Maybe<AdminUser | AgentUser | ConsumerUser | ContactUser>;
  assignedUserStatus: TaskAssigneeStatus;
  assignedUserStatusName: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  definition: ApproveSubmissionToMlsTaskDefinition | AttachFileStorageTaskDefinition | BasicTaskDefinition | FileUploadTaskDefinition | FormAssignTaskDefinition | FormFillTaskDefinition | FormReviewTaskDefinition | SubmitListingToMlsTaskDefinition | UploadToFileStorageTaskDefinition;
  description?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  following: Scalars['Boolean'];
  id: Scalars['ID'];
  listing?: Maybe<PropertyListing>;
  mlsNumber?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  objectType: ActivityObjectType;
  project: ProjectInstance;
  requestedChanges?: Maybe<Scalars['String']>;
  reviewer?: Maybe<AdminUser | AgentUser | ConsumerUser | ContactUser>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status: TaskInstanceStatus;
  statusName: Scalars['String'];
  type: TaskType;
  typeName: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type SubmitListingToMlsTaskInstanceActivityFeedArgs = {
  containingActivityId?: InputMaybe<Scalars['ID']>;
  first?: Scalars['Int'];
  offset?: Scalars['Int'];
};

export type SuggestWorkflowScriptInput = {
  entity: ScriptOwnerInput;
  prompt: Scalars['String'];
};

export type SupportedPropertyListingType = {
  __typename?: 'SupportedPropertyListingType';
  dataConfig?: Maybe<PropertyListingDataConfig>;
  id: Scalars['ID'];
  mlsSubmissionUrl?: Maybe<Scalars['String']>;
  propertyListingType: PropertyListingType;
  propertyListingTypeName: Scalars['String'];
  propertyType: PropertyType;
  propertyTypeName: Scalars['String'];
  transactionType: TransactionType;
  transactionTypeName: Scalars['String'];
};

export type SupportedPropertyListingTypeMetadata = {
  __typename?: 'SupportedPropertyListingTypeMetadata';
  propertyListingType: Array<EnumValueMetadata>;
  propertyType: Array<EnumValueMetadata>;
  transactionType: Array<EnumValueMetadata>;
};

export type Tag = {
  __typename?: 'Tag';
  collection: TagCollection;
  createdAt: Scalars['DateTime'];
  dependencyTags?: Maybe<Array<Tag>>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  status: TagStatus;
  statusName: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TagCollection = {
  __typename?: 'TagCollection';
  createdAt: Scalars['DateTime'];
  dependencyTags?: Maybe<Array<Tag>>;
  description?: Maybe<Scalars['String']>;
  entityType: TagEntityType;
  entityTypeName: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  owner?: Maybe<FurnitureSupplierCompany | FurnitureVendorCompany>;
  status: TagCollectionStatus;
  statusName: Scalars['String'];
  tags?: Maybe<Array<Tag>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight: Scalars['Int'];
};

export type TagCollectionConnection = {
  __typename?: 'TagCollectionConnection';
  edges?: Maybe<Array<Maybe<TagCollectionConnectionEdge>>>;
  facetGroups?: Maybe<Array<Maybe<FacetGroup>>>;
  filters?: Maybe<Array<Maybe<AggregatedFacetFilter | BooleanFilter | DateRangeFilter | FloatRangeFilter | IntegerRangeFilter | SimpleFacetFilter | TagCollectionFilter>>>;
  histogramGroups?: Maybe<Array<Maybe<HistogramGroup>>>;
  metricGroups?: Maybe<Array<Maybe<MetricGroup>>>;
  pageInfo?: Maybe<PageInfo>;
  savedSearches?: Maybe<Array<Maybe<SavedSearch>>>;
  sortOptions?: Maybe<Array<Maybe<SortOption>>>;
};


export type TagCollectionConnectionFacetGroupsArgs = {
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type TagCollectionConnectionFiltersArgs = {
  aggregatedFacetKeywordSearch?: InputMaybe<AggregatedFacetKeywordSearchInput>;
  fetchAggregatedFacetsFilterIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  includeTagFilters?: InputMaybe<Scalars['Boolean']>;
  selected?: InputMaybe<Scalars['Boolean']>;
};


export type TagCollectionConnectionHistogramGroupsArgs = {
  histogramGroupInput?: InputMaybe<HistogramGroupInput>;
};


export type TagCollectionConnectionMetricGroupsArgs = {
  metricInput?: InputMaybe<Array<InputMaybe<MetricGroupInput>>>;
};

export type TagCollectionConnectionEdge = {
  __typename?: 'TagCollectionConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<TagCollection>;
};

export type TagCollectionConnectionInput = {
  filters?: InputMaybe<Array<FilterInput>>;
  first: Scalars['Int'];
  keyword?: InputMaybe<Scalars['String']>;
  offset: Scalars['Int'];
  savedSearchId?: InputMaybe<Scalars['ID']>;
  sortKey?: InputMaybe<Scalars['String']>;
  sortOptionId?: InputMaybe<Scalars['ID']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  statuses?: InputMaybe<Array<TagCollectionStatus>>;
  tagEntity?: InputMaybe<TagEntityInput>;
  tagIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type TagCollectionFilter = ConnectionFilter & {
  __typename?: 'TagCollectionFilter';
  allowMultiSelect?: Maybe<Scalars['Boolean']>;
  currentValue?: Maybe<Array<FacetCurrentValue>>;
  facets?: Maybe<Array<BasicCountFacet | ResidentialListingTagFacet>>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<ConnectionFilterType>;
  typeName?: Maybe<Scalars['String']>;
};

export type TagCollectionMetadata = {
  __typename?: 'TagCollectionMetadata';
  entityType: Array<EnumValueMetadata>;
  status: Array<EnumValueMetadata>;
};

export const enum TagCollectionStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  Draft = 'DRAFT'
};

export type TagConnection = {
  __typename?: 'TagConnection';
  edges?: Maybe<Array<Maybe<TagConnectionEdge>>>;
  facetGroups?: Maybe<Array<Maybe<FacetGroup>>>;
  filters?: Maybe<Array<Maybe<AggregatedFacetFilter | BooleanFilter | DateRangeFilter | FloatRangeFilter | IntegerRangeFilter | SimpleFacetFilter | TagCollectionFilter>>>;
  histogramGroups?: Maybe<Array<Maybe<HistogramGroup>>>;
  metricGroups?: Maybe<Array<Maybe<MetricGroup>>>;
  pageInfo?: Maybe<PageInfo>;
  savedSearches?: Maybe<Array<Maybe<SavedSearch>>>;
  sortOptions?: Maybe<Array<Maybe<SortOption>>>;
};


export type TagConnectionFacetGroupsArgs = {
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type TagConnectionFiltersArgs = {
  aggregatedFacetKeywordSearch?: InputMaybe<AggregatedFacetKeywordSearchInput>;
  fetchAggregatedFacetsFilterIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  includeTagFilters?: InputMaybe<Scalars['Boolean']>;
  selected?: InputMaybe<Scalars['Boolean']>;
};


export type TagConnectionHistogramGroupsArgs = {
  histogramGroupInput?: InputMaybe<HistogramGroupInput>;
};


export type TagConnectionMetricGroupsArgs = {
  metricInput?: InputMaybe<Array<InputMaybe<MetricGroupInput>>>;
};

export type TagConnectionEdge = {
  __typename?: 'TagConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Tag>;
};

export type TagConnectionInput = {
  filters?: InputMaybe<Array<FilterInput>>;
  first: Scalars['Int'];
  keyword?: InputMaybe<Scalars['String']>;
  offset: Scalars['Int'];
  savedSearchId?: InputMaybe<Scalars['ID']>;
  sortKey?: InputMaybe<Scalars['String']>;
  sortOptionId?: InputMaybe<Scalars['ID']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  tagEntity?: InputMaybe<TagEntityInput>;
  tagIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type TagEntityInput = {
  id: Scalars['ID'];
  type: TagEntityType;
};

export const enum TagEntityType {
  FurnitureProduct = 'FURNITURE_PRODUCT',
  PropertyListing = 'PROPERTY_LISTING'
};

export type TagMetadata = {
  __typename?: 'TagMetadata';
  status: Array<EnumValueMetadata>;
};

export const enum TagStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED'
};

export type TagsOwner = {
  id: Scalars['ID'];
  tagEntityType: TagEntityType;
  tags: Array<Tag>;
};

export const enum TargetAudience {
  Public = 'PUBLIC'
};

export type TaskAssignActivity = Activity & {
  __typename?: 'TaskAssignActivity';
  actor?: Maybe<AdminUser | AgentUser | ConsumerUser | ContactUser>;
  affectedUser?: Maybe<AdminUser | AgentUser | ConsumerUser | ContactUser>;
  comments?: Maybe<Array<ActivityComment>>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** Whether the thread id fully read or there are any unread comments */
  isRead: Scalars['Boolean'];
  /** Whether the thread is resolved */
  isResolved?: Maybe<Scalars['Boolean']>;
  media?: Maybe<Array<File | Image | Model3D>>;
  message?: Maybe<Scalars['String']>;
  object?: Maybe<AdminUser | AgentUser | ApproveSubmissionToMlsTaskInstance | BasicTaskInstance | ConsumerUser | ContactUser | FileUploadTaskInstance | FormFillTaskInstance | ProjectInstance | PropertyListing | PropertyListingOpportunity | SubmitListingToMlsTaskInstance>;
  /** Timestamp of the last time the thread was read by the current user. False if the current user is not a thread participant */
  readAt?: Maybe<Scalars['DateTime']>;
  relatedEntity?: Maybe<Address | AdminUser | AgentUser | ConsumerUser | ContactUser | DataFormInstance | DataFormStepInstance | Image | PropertyComparativeMarketAnalysis | PropertyListing | PropertyListingIteration | ResidentialPropertyRoom>;
  status: ActivityStatus;
  subType?: Maybe<ActivitySubType>;
  subTypeName?: Maybe<Scalars['String']>;
  taskInstance: ApproveSubmissionToMlsTaskInstance | BasicTaskInstance | FileUploadTaskInstance | FormFillTaskInstance | SubmitListingToMlsTaskInstance;
  type: ActivityType;
  typeName: Scalars['String'];
  verb: Scalars['String'];
  visibility: ActivityVisibility;
  visibilityName: Scalars['String'];
};


export type TaskAssignActivityCommentsArgs = {
  latest?: InputMaybe<Scalars['Int']>;
  statuses?: InputMaybe<Array<ActivityCommentStatus>>;
};


export type TaskAssignActivityMediaArgs = {
  first?: InputMaybe<Scalars['Int']>;
  statuses?: InputMaybe<Array<MediaStatus>>;
};

export const enum TaskAssigneeStatus {
  Assigned = 'ASSIGNED',
  PendingInvite = 'PENDING_INVITE'
};

export type TaskDefinition = {
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Icon>;
  id: Scalars['ID'];
  logicalName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  projectDefinition: ProjectDefinition;
  scriptOnComplete?: Maybe<Scalars['String']>;
  scriptOnCreate?: Maybe<Scalars['String']>;
  scriptOnStart?: Maybe<Scalars['String']>;
  scriptValidateBeforeComplete?: Maybe<Scalars['String']>;
  scriptValidateBeforeCreate?: Maybe<Scalars['String']>;
  scriptValidateBeforeStart?: Maybe<Scalars['String']>;
  type: TaskType;
  typeName: Scalars['String'];
  uiOnComplete?: Maybe<Array<Scalars['String']>>;
  uiOnCreate?: Maybe<Array<Scalars['String']>>;
  uiOnStart?: Maybe<Array<Scalars['String']>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TaskEventActivity = Activity & {
  __typename?: 'TaskEventActivity';
  actor?: Maybe<AdminUser | AgentUser | ConsumerUser | ContactUser>;
  comments?: Maybe<Array<ActivityComment>>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** Whether the thread id fully read or there are any unread comments */
  isRead: Scalars['Boolean'];
  /** Whether the thread is resolved */
  isResolved?: Maybe<Scalars['Boolean']>;
  media?: Maybe<Array<File | Image | Model3D>>;
  message?: Maybe<Scalars['String']>;
  object?: Maybe<AdminUser | AgentUser | ApproveSubmissionToMlsTaskInstance | BasicTaskInstance | ConsumerUser | ContactUser | FileUploadTaskInstance | FormFillTaskInstance | ProjectInstance | PropertyListing | PropertyListingOpportunity | SubmitListingToMlsTaskInstance>;
  /** Timestamp of the last time the thread was read by the current user. False if the current user is not a thread participant */
  readAt?: Maybe<Scalars['DateTime']>;
  relatedEntity?: Maybe<Address | AdminUser | AgentUser | ConsumerUser | ContactUser | DataFormInstance | DataFormStepInstance | Image | PropertyComparativeMarketAnalysis | PropertyListing | PropertyListingIteration | ResidentialPropertyRoom>;
  status: ActivityStatus;
  subType?: Maybe<ActivitySubType>;
  subTypeName?: Maybe<Scalars['String']>;
  taskInstance: ApproveSubmissionToMlsTaskInstance | BasicTaskInstance | FileUploadTaskInstance | FormFillTaskInstance | SubmitListingToMlsTaskInstance;
  type: ActivityType;
  typeName: Scalars['String'];
  verb: Scalars['String'];
  visibility: ActivityVisibility;
  visibilityName: Scalars['String'];
};


export type TaskEventActivityCommentsArgs = {
  latest?: InputMaybe<Scalars['Int']>;
  statuses?: InputMaybe<Array<ActivityCommentStatus>>;
};


export type TaskEventActivityMediaArgs = {
  first?: InputMaybe<Scalars['Int']>;
  statuses?: InputMaybe<Array<MediaStatus>>;
};

export type TaskEventResponse = {
  __typename?: 'TaskEventResponse';
  payload?: Maybe<Scalars['JSON']>;
  task: ApproveSubmissionToMlsTaskInstance | BasicTaskInstance | FileUploadTaskInstance | FormFillTaskInstance | SubmitListingToMlsTaskInstance;
};

export type TaskInstanceConnection = {
  __typename?: 'TaskInstanceConnection';
  edges?: Maybe<Array<Maybe<TaskInstanceConnectionEdge>>>;
  facetGroups?: Maybe<Array<Maybe<FacetGroup>>>;
  filters?: Maybe<Array<Maybe<AggregatedFacetFilter | BooleanFilter | DateRangeFilter | FloatRangeFilter | IntegerRangeFilter | SimpleFacetFilter | TagCollectionFilter>>>;
  histogramGroups?: Maybe<Array<Maybe<HistogramGroup>>>;
  metricGroups?: Maybe<Array<Maybe<MetricGroup>>>;
  pageInfo?: Maybe<PageInfo>;
  savedSearches?: Maybe<Array<Maybe<SavedSearch>>>;
  sortOptions?: Maybe<Array<Maybe<SortOption>>>;
};


export type TaskInstanceConnectionFacetGroupsArgs = {
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type TaskInstanceConnectionFiltersArgs = {
  aggregatedFacetKeywordSearch?: InputMaybe<AggregatedFacetKeywordSearchInput>;
  fetchAggregatedFacetsFilterIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  includeTagFilters?: InputMaybe<Scalars['Boolean']>;
  selected?: InputMaybe<Scalars['Boolean']>;
};


export type TaskInstanceConnectionHistogramGroupsArgs = {
  histogramGroupInput?: InputMaybe<HistogramGroupInput>;
};


export type TaskInstanceConnectionMetricGroupsArgs = {
  metricInput?: InputMaybe<Array<InputMaybe<MetricGroupInput>>>;
};

export type TaskInstanceConnectionEdge = {
  __typename?: 'TaskInstanceConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<ApproveSubmissionToMlsTaskInstance | BasicTaskInstance | FileUploadTaskInstance | FormFillTaskInstance | SubmitListingToMlsTaskInstance>;
};

export type TaskInstanceConnectionInput = {
  filters?: InputMaybe<Array<FilterInput>>;
  first: Scalars['Int'];
  keyword?: InputMaybe<Scalars['String']>;
  offset: Scalars['Int'];
  parentProjectIds?: InputMaybe<Array<Scalars['ID']>>;
  projectIds?: InputMaybe<Array<Scalars['ID']>>;
  rootProjectIds?: InputMaybe<Array<Scalars['ID']>>;
  savedSearchId?: InputMaybe<Scalars['ID']>;
  sortOptionId?: InputMaybe<Scalars['ID']>;
  tagIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type TaskInstanceMetadata = {
  __typename?: 'TaskInstanceMetadata';
  status: Array<EnumValueMetadata>;
  type: Array<EnumValueMetadata>;
};

export const enum TaskInstanceStatus {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  InReview = 'IN_REVIEW',
  NotStarted = 'NOT_STARTED'
};

export const enum TaskReviewOutcome {
  Approve = 'APPROVE',
  RequestChanges = 'REQUEST_CHANGES'
};

export const enum TaskType {
  ApproveSubmissionToMls = 'APPROVE_SUBMISSION_TO_MLS',
  Basic = 'BASIC',
  FileUpload = 'FILE_UPLOAD',
  FormFill = 'FORM_FILL',
  SubmitListingToMls = 'SUBMIT_LISTING_TO_MLS'
};

export type ThreadConnection = {
  __typename?: 'ThreadConnection';
  edges?: Maybe<Array<Maybe<ThreadConnectionEdge>>>;
  facetGroups?: Maybe<Array<Maybe<FacetGroup>>>;
  filters?: Maybe<Array<Maybe<AggregatedFacetFilter | BooleanFilter | DateRangeFilter | FloatRangeFilter | IntegerRangeFilter | SimpleFacetFilter | TagCollectionFilter>>>;
  histogramGroups?: Maybe<Array<Maybe<HistogramGroup>>>;
  metricGroups?: Maybe<Array<Maybe<MetricGroup>>>;
  pageInfo?: Maybe<PageInfo>;
  savedSearches?: Maybe<Array<Maybe<SavedSearch>>>;
  sortOptions?: Maybe<Array<Maybe<SortOption>>>;
};


export type ThreadConnectionFacetGroupsArgs = {
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type ThreadConnectionFiltersArgs = {
  aggregatedFacetKeywordSearch?: InputMaybe<AggregatedFacetKeywordSearchInput>;
  fetchAggregatedFacetsFilterIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  includeTagFilters?: InputMaybe<Scalars['Boolean']>;
  selected?: InputMaybe<Scalars['Boolean']>;
};


export type ThreadConnectionHistogramGroupsArgs = {
  histogramGroupInput?: InputMaybe<HistogramGroupInput>;
};


export type ThreadConnectionMetricGroupsArgs = {
  metricInput?: InputMaybe<Array<InputMaybe<MetricGroupInput>>>;
};

export type ThreadConnectionEdge = {
  __typename?: 'ThreadConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<InquiryCreatedActivity | NoteActivity | ProjectStageEventActivity | PropertyListingLikeActivity | PropertyListingViewActivity | RootProjectEventActivity | SubProjectEventActivity | TaskAssignActivity | TaskEventActivity | UserAssignedAgentAddedActivity | UserAssignedAgentRemovedActivity | UserReactivatedActivity | UserSuspendedActivity>;
};

export type ThreadConnectionInput = {
  filters?: InputMaybe<Array<FilterInput>>;
  first: Scalars['Int'];
  keyword?: InputMaybe<Scalars['String']>;
  offset: Scalars['Int'];
  savedSearchId?: InputMaybe<Scalars['ID']>;
  sortOptionId?: InputMaybe<Scalars['ID']>;
};

export type TimeDataField = DataField & {
  __typename?: 'TimeDataField';
  description?: Maybe<Scalars['String']>;
  /** Must be absolute from time */
  from?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  key: Scalars['String'];
  logicalName?: Maybe<DataFieldLogicalName>;
  mlsField?: Maybe<MlsField>;
  multiSelect: Scalars['Boolean'];
  name: Scalars['String'];
  processed?: Maybe<Scalars['Boolean']>;
  /** Must be absolute to time */
  to?: Maybe<Scalars['DateTime']>;
  type: DataFieldType;
  typeName: Scalars['String'];
};

export type TimeDataFormInputInstance = DataFormInputInstance & {
  __typename?: 'TimeDataFormInputInstance';
  currentValue?: Maybe<Scalars['String']>;
  definition: DataFormInputDefinition;
  editable: Scalars['Boolean'];
  id: Scalars['ID'];
};

export type TimeDataViewFieldInstance = DataViewFieldInstance & {
  __typename?: 'TimeDataViewFieldInstance';
  definition: DataViewFieldDefinition;
  value?: Maybe<Scalars['String']>;
};

export const enum TransactionType {
  ForLease = 'FOR_LEASE',
  ForSale = 'FOR_SALE'
};

export type TwilioPhoneNumber = {
  __typename?: 'TwilioPhoneNumber';
  id: Scalars['ID'];
  phoneNumber?: Maybe<Scalars['String']>;
};

export type UpdateImageInput = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  sequence?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<MediaStatus>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateMediaInput = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  sequence?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<MediaStatus>;
  title?: InputMaybe<Scalars['String']>;
};

export type UploadSpaceConfigurationModelInput = {
  contentType: Scalars['String'];
  sizeBytes: Scalars['Long'];
  spaceConfigurationId: Scalars['ID'];
};

export type UploadToFileStorageTaskDefinition = TaskDefinition & {
  __typename?: 'UploadToFileStorageTaskDefinition';
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Icon>;
  id: Scalars['ID'];
  logicalName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  projectDefinition: ProjectDefinition;
  scriptOnComplete?: Maybe<Scalars['String']>;
  scriptOnCreate?: Maybe<Scalars['String']>;
  scriptOnStart?: Maybe<Scalars['String']>;
  scriptValidateBeforeComplete?: Maybe<Scalars['String']>;
  scriptValidateBeforeCreate?: Maybe<Scalars['String']>;
  scriptValidateBeforeStart?: Maybe<Scalars['String']>;
  type: TaskType;
  typeName: Scalars['String'];
  uiOnComplete?: Maybe<Array<Scalars['String']>>;
  uiOnCreate?: Maybe<Array<Scalars['String']>>;
  uiOnStart?: Maybe<Array<Scalars['String']>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type User = {
  activityFeed?: Maybe<ActivityFeed>;
  address?: Maybe<Address>;
  birthDate?: Maybe<Scalars['Date']>;
  chimeId?: Maybe<Scalars['Long']>;
  chimeLead?: Maybe<LeadInfo>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  following: Scalars['Boolean'];
  fubId?: Maybe<Scalars['Int']>;
  fubLead?: Maybe<LeadInfo>;
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastActiveAt?: Maybe<Scalars['DateTime']>;
  lastName?: Maybe<Scalars['String']>;
  listingInquiredCount?: Maybe<Scalars['Int']>;
  listingLikedCount?: Maybe<Scalars['Int']>;
  locale?: Maybe<Scalars['Locale']>;
  name: Scalars['String'];
  objectType: ActivityObjectType;
  personalFeed?: Maybe<ActivityFeed>;
  primaryPhone?: Maybe<Scalars['String']>;
  profileImage?: Maybe<Image>;
  receiveMarketingEmails?: Maybe<Scalars['Boolean']>;
  screenName?: Maybe<Scalars['String']>;
  status?: Maybe<UserStatus>;
  statusName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userType?: Maybe<UserType>;
  userTypeName?: Maybe<Scalars['String']>;
  workspaceMemberships?: Maybe<Array<WorkspaceMember>>;
};


export type UserActivityFeedArgs = {
  containingActivityId?: InputMaybe<Scalars['ID']>;
  first?: Scalars['Int'];
  offset?: Scalars['Int'];
};


export type UserPersonalFeedArgs = {
  containingActivityId?: InputMaybe<Scalars['ID']>;
  first?: Scalars['Int'];
  offset?: Scalars['Int'];
};

export type UserAssignedAgent = {
  __typename?: 'UserAssignedAgent';
  agent: AdminUser | AgentUser | ConsumerUser | ContactUser;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  sequence: Scalars['Int'];
  user: AdminUser | AgentUser | ConsumerUser | ContactUser;
};

export type UserAssignedAgentAddedActivity = Activity & {
  __typename?: 'UserAssignedAgentAddedActivity';
  actor?: Maybe<AdminUser | AgentUser | ConsumerUser | ContactUser>;
  affectedUser?: Maybe<AdminUser | AgentUser | ConsumerUser | ContactUser>;
  comments?: Maybe<Array<ActivityComment>>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** Whether the thread id fully read or there are any unread comments */
  isRead: Scalars['Boolean'];
  /** Whether the thread is resolved */
  isResolved?: Maybe<Scalars['Boolean']>;
  media?: Maybe<Array<File | Image | Model3D>>;
  message?: Maybe<Scalars['String']>;
  object?: Maybe<AdminUser | AgentUser | ApproveSubmissionToMlsTaskInstance | BasicTaskInstance | ConsumerUser | ContactUser | FileUploadTaskInstance | FormFillTaskInstance | ProjectInstance | PropertyListing | PropertyListingOpportunity | SubmitListingToMlsTaskInstance>;
  /** Timestamp of the last time the thread was read by the current user. False if the current user is not a thread participant */
  readAt?: Maybe<Scalars['DateTime']>;
  relatedEntity?: Maybe<Address | AdminUser | AgentUser | ConsumerUser | ContactUser | DataFormInstance | DataFormStepInstance | Image | PropertyComparativeMarketAnalysis | PropertyListing | PropertyListingIteration | ResidentialPropertyRoom>;
  status: ActivityStatus;
  subType?: Maybe<ActivitySubType>;
  subTypeName?: Maybe<Scalars['String']>;
  type: ActivityType;
  typeName: Scalars['String'];
  verb: Scalars['String'];
  visibility: ActivityVisibility;
  visibilityName: Scalars['String'];
};


export type UserAssignedAgentAddedActivityCommentsArgs = {
  latest?: InputMaybe<Scalars['Int']>;
  statuses?: InputMaybe<Array<ActivityCommentStatus>>;
};


export type UserAssignedAgentAddedActivityMediaArgs = {
  first?: InputMaybe<Scalars['Int']>;
  statuses?: InputMaybe<Array<MediaStatus>>;
};

export type UserAssignedAgentRemovedActivity = Activity & {
  __typename?: 'UserAssignedAgentRemovedActivity';
  actor?: Maybe<AdminUser | AgentUser | ConsumerUser | ContactUser>;
  affectedUser?: Maybe<AdminUser | AgentUser | ConsumerUser | ContactUser>;
  comments?: Maybe<Array<ActivityComment>>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** Whether the thread id fully read or there are any unread comments */
  isRead: Scalars['Boolean'];
  /** Whether the thread is resolved */
  isResolved?: Maybe<Scalars['Boolean']>;
  media?: Maybe<Array<File | Image | Model3D>>;
  message?: Maybe<Scalars['String']>;
  object?: Maybe<AdminUser | AgentUser | ApproveSubmissionToMlsTaskInstance | BasicTaskInstance | ConsumerUser | ContactUser | FileUploadTaskInstance | FormFillTaskInstance | ProjectInstance | PropertyListing | PropertyListingOpportunity | SubmitListingToMlsTaskInstance>;
  /** Timestamp of the last time the thread was read by the current user. False if the current user is not a thread participant */
  readAt?: Maybe<Scalars['DateTime']>;
  relatedEntity?: Maybe<Address | AdminUser | AgentUser | ConsumerUser | ContactUser | DataFormInstance | DataFormStepInstance | Image | PropertyComparativeMarketAnalysis | PropertyListing | PropertyListingIteration | ResidentialPropertyRoom>;
  status: ActivityStatus;
  subType?: Maybe<ActivitySubType>;
  subTypeName?: Maybe<Scalars['String']>;
  type: ActivityType;
  typeName: Scalars['String'];
  verb: Scalars['String'];
  visibility: ActivityVisibility;
  visibilityName: Scalars['String'];
};


export type UserAssignedAgentRemovedActivityCommentsArgs = {
  latest?: InputMaybe<Scalars['Int']>;
  statuses?: InputMaybe<Array<ActivityCommentStatus>>;
};


export type UserAssignedAgentRemovedActivityMediaArgs = {
  first?: InputMaybe<Scalars['Int']>;
  statuses?: InputMaybe<Array<MediaStatus>>;
};

export type UserAuthenticationInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type UserConnection = {
  __typename?: 'UserConnection';
  edges?: Maybe<Array<Maybe<UserConnectionEdge>>>;
  facetGroups?: Maybe<Array<Maybe<FacetGroup>>>;
  filters?: Maybe<Array<Maybe<AggregatedFacetFilter | BooleanFilter | DateRangeFilter | FloatRangeFilter | IntegerRangeFilter | SimpleFacetFilter | TagCollectionFilter>>>;
  histogramGroups?: Maybe<Array<Maybe<HistogramGroup>>>;
  metricGroups?: Maybe<Array<Maybe<MetricGroup>>>;
  pageInfo?: Maybe<PageInfo>;
  savedSearches?: Maybe<Array<Maybe<SavedSearch>>>;
  sortOptions?: Maybe<Array<Maybe<SortOption>>>;
};


export type UserConnectionFacetGroupsArgs = {
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type UserConnectionFiltersArgs = {
  aggregatedFacetKeywordSearch?: InputMaybe<AggregatedFacetKeywordSearchInput>;
  fetchAggregatedFacetsFilterIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  includeTagFilters?: InputMaybe<Scalars['Boolean']>;
  selected?: InputMaybe<Scalars['Boolean']>;
};


export type UserConnectionHistogramGroupsArgs = {
  histogramGroupInput?: InputMaybe<HistogramGroupInput>;
};


export type UserConnectionMetricGroupsArgs = {
  metricInput?: InputMaybe<Array<InputMaybe<MetricGroupInput>>>;
};

export type UserConnectionEdge = {
  __typename?: 'UserConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<AdminUser | AgentUser | ConsumerUser | ContactUser>;
};

export type UserConnectionInput = {
  /** search on firstName, lastName & email */
  filters?: InputMaybe<Array<FilterInput>>;
  first: Scalars['Int'];
  keyword?: InputMaybe<Scalars['String']>;
  offset: Scalars['Int'];
  savedSearchId?: InputMaybe<Scalars['ID']>;
  sortKey?: InputMaybe<UserSortKey>;
  sortOptionId?: InputMaybe<Scalars['ID']>;
  sortOrder?: InputMaybe<SortOrder>;
  statuses?: InputMaybe<Array<UserStatus>>;
  tagIds?: InputMaybe<Array<Scalars['ID']>>;
  userTypes?: InputMaybe<Array<UserType>>;
};

export type UserDataField = DataField & {
  __typename?: 'UserDataField';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  key: Scalars['String'];
  logicalName?: Maybe<DataFieldLogicalName>;
  minimumEntriesRequired: Scalars['Int'];
  mlsField?: Maybe<MlsField>;
  multiSelect: Scalars['Boolean'];
  name: Scalars['String'];
  processed?: Maybe<Scalars['Boolean']>;
  type: DataFieldType;
  typeName: Scalars['String'];
};

export type UserDataFormInputInstance = DataFormInputInstance & {
  __typename?: 'UserDataFormInputInstance';
  /** Not used for this type. please use `users` field */
  currentValue?: Maybe<Scalars['String']>;
  definition: DataFormInputDefinition;
  editable: Scalars['Boolean'];
  id: Scalars['ID'];
  users?: Maybe<Array<AdminUser | AgentUser | ConsumerUser | ContactUser>>;
};

export type UserDataViewFieldInstance = DataViewFieldInstance & {
  __typename?: 'UserDataViewFieldInstance';
  definition: DataViewFieldDefinition;
  users?: Maybe<Array<AdminUser | AgentUser | ConsumerUser | ContactUser>>;
  /** Not used for this type. Please use `users` field */
  value?: Maybe<Scalars['String']>;
};

export const enum UserLookup {
  Found = 'FOUND',
  FoundNotConfirmed = 'FOUND_NOT_CONFIRMED',
  NotFound = 'NOT_FOUND'
};

/** Type representing a fact that a user is mentioned in some object's activity feed */
export type UserMention = {
  __typename?: 'UserMention';
  /** Activity in which the user was mentioned. Can be either mentioned directly in the activity, or in one of the activity comments. The activity may be used to navigate to it's `object` and to the object's activity feed with scrolling to the activity by providing `containingActivityId` argument */
  activity: InquiryCreatedActivity | NoteActivity | ProjectStageEventActivity | PropertyListingLikeActivity | PropertyListingViewActivity | RootProjectEventActivity | SubProjectEventActivity | TaskAssignActivity | TaskEventActivity | UserAssignedAgentAddedActivity | UserAssignedAgentRemovedActivity | UserReactivatedActivity | UserSuspendedActivity;
  /** Timestamp at which the user was mentioned */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** User who mentioned another user. Optional since the System can mention someone too */
  mentionedBy?: Maybe<AdminUser | AgentUser | ConsumerUser | ContactUser>;
  /** Message in which the user was mentioned. Can be either direct message of the activity, or message from an activity comment where user was mentioned */
  message: Scalars['String'];
  /** Whether the user seen the mention */
  seen: Scalars['Boolean'];
  /** Timestamp at which the user seen the mention */
  seenAt?: Maybe<Scalars['DateTime']>;
  /** User who is mentioned */
  user: AdminUser | AgentUser | ConsumerUser | ContactUser;
};

export type UserMentionConnection = {
  __typename?: 'UserMentionConnection';
  edges?: Maybe<Array<Maybe<UserMentionConnectionEdge>>>;
  facetGroups?: Maybe<Array<Maybe<FacetGroup>>>;
  filters?: Maybe<Array<Maybe<AggregatedFacetFilter | BooleanFilter | DateRangeFilter | FloatRangeFilter | IntegerRangeFilter | SimpleFacetFilter | TagCollectionFilter>>>;
  histogramGroups?: Maybe<Array<Maybe<HistogramGroup>>>;
  metricGroups?: Maybe<Array<Maybe<MetricGroup>>>;
  pageInfo?: Maybe<PageInfo>;
  savedSearches?: Maybe<Array<Maybe<SavedSearch>>>;
  sortOptions?: Maybe<Array<Maybe<SortOption>>>;
};


export type UserMentionConnectionFacetGroupsArgs = {
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type UserMentionConnectionFiltersArgs = {
  aggregatedFacetKeywordSearch?: InputMaybe<AggregatedFacetKeywordSearchInput>;
  fetchAggregatedFacetsFilterIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  includeTagFilters?: InputMaybe<Scalars['Boolean']>;
  selected?: InputMaybe<Scalars['Boolean']>;
};


export type UserMentionConnectionHistogramGroupsArgs = {
  histogramGroupInput?: InputMaybe<HistogramGroupInput>;
};


export type UserMentionConnectionMetricGroupsArgs = {
  metricInput?: InputMaybe<Array<InputMaybe<MetricGroupInput>>>;
};

export type UserMentionConnectionEdge = {
  __typename?: 'UserMentionConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<UserMention>;
};

export type UserMentionConnectionInput = {
  filters?: InputMaybe<Array<FilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  keyword?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  savedSearchId?: InputMaybe<Scalars['ID']>;
  sortOptionId?: InputMaybe<Scalars['ID']>;
};

export type UserMentionLookupInput = {
  first?: Scalars['Int'];
  keyword: Scalars['String'];
  objectId: Scalars['ID'];
  objectType: ActivityObjectType;
};

export type UserMetadata = {
  __typename?: 'UserMetadata';
  leadSource: Array<EnumValueMetadata>;
  userStatus: Array<EnumValueMetadata>;
  userType: Array<EnumValueMetadata>;
};

export type UserReactivatedActivity = Activity & {
  __typename?: 'UserReactivatedActivity';
  actor?: Maybe<AdminUser | AgentUser | ConsumerUser | ContactUser>;
  affectedUser?: Maybe<AdminUser | AgentUser | ConsumerUser | ContactUser>;
  comments?: Maybe<Array<ActivityComment>>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** Whether the thread id fully read or there are any unread comments */
  isRead: Scalars['Boolean'];
  /** Whether the thread is resolved */
  isResolved?: Maybe<Scalars['Boolean']>;
  media?: Maybe<Array<File | Image | Model3D>>;
  message?: Maybe<Scalars['String']>;
  object?: Maybe<AdminUser | AgentUser | ApproveSubmissionToMlsTaskInstance | BasicTaskInstance | ConsumerUser | ContactUser | FileUploadTaskInstance | FormFillTaskInstance | ProjectInstance | PropertyListing | PropertyListingOpportunity | SubmitListingToMlsTaskInstance>;
  /** Timestamp of the last time the thread was read by the current user. False if the current user is not a thread participant */
  readAt?: Maybe<Scalars['DateTime']>;
  relatedEntity?: Maybe<Address | AdminUser | AgentUser | ConsumerUser | ContactUser | DataFormInstance | DataFormStepInstance | Image | PropertyComparativeMarketAnalysis | PropertyListing | PropertyListingIteration | ResidentialPropertyRoom>;
  status: ActivityStatus;
  subType?: Maybe<ActivitySubType>;
  subTypeName?: Maybe<Scalars['String']>;
  type: ActivityType;
  typeName: Scalars['String'];
  verb: Scalars['String'];
  visibility: ActivityVisibility;
  visibilityName: Scalars['String'];
};


export type UserReactivatedActivityCommentsArgs = {
  latest?: InputMaybe<Scalars['Int']>;
  statuses?: InputMaybe<Array<ActivityCommentStatus>>;
};


export type UserReactivatedActivityMediaArgs = {
  first?: InputMaybe<Scalars['Int']>;
  statuses?: InputMaybe<Array<MediaStatus>>;
};

export const enum UserSortKey {
  CreatedAt = 'CREATED_AT',
  LastName = 'LAST_NAME',
  UpdatedAt = 'UPDATED_AT'
};

export const enum UserStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  Suspended = 'SUSPENDED'
};

export type UserSuspendedActivity = Activity & {
  __typename?: 'UserSuspendedActivity';
  actor?: Maybe<AdminUser | AgentUser | ConsumerUser | ContactUser>;
  affectedUser?: Maybe<AdminUser | AgentUser | ConsumerUser | ContactUser>;
  comments?: Maybe<Array<ActivityComment>>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** Whether the thread id fully read or there are any unread comments */
  isRead: Scalars['Boolean'];
  /** Whether the thread is resolved */
  isResolved?: Maybe<Scalars['Boolean']>;
  media?: Maybe<Array<File | Image | Model3D>>;
  message?: Maybe<Scalars['String']>;
  object?: Maybe<AdminUser | AgentUser | ApproveSubmissionToMlsTaskInstance | BasicTaskInstance | ConsumerUser | ContactUser | FileUploadTaskInstance | FormFillTaskInstance | ProjectInstance | PropertyListing | PropertyListingOpportunity | SubmitListingToMlsTaskInstance>;
  /** Timestamp of the last time the thread was read by the current user. False if the current user is not a thread participant */
  readAt?: Maybe<Scalars['DateTime']>;
  relatedEntity?: Maybe<Address | AdminUser | AgentUser | ConsumerUser | ContactUser | DataFormInstance | DataFormStepInstance | Image | PropertyComparativeMarketAnalysis | PropertyListing | PropertyListingIteration | ResidentialPropertyRoom>;
  status: ActivityStatus;
  subType?: Maybe<ActivitySubType>;
  subTypeName?: Maybe<Scalars['String']>;
  type: ActivityType;
  typeName: Scalars['String'];
  verb: Scalars['String'];
  visibility: ActivityVisibility;
  visibilityName: Scalars['String'];
};


export type UserSuspendedActivityCommentsArgs = {
  latest?: InputMaybe<Scalars['Int']>;
  statuses?: InputMaybe<Array<ActivityCommentStatus>>;
};


export type UserSuspendedActivityMediaArgs = {
  first?: InputMaybe<Scalars['Int']>;
  statuses?: InputMaybe<Array<MediaStatus>>;
};

export const enum UserType {
  Admin = 'ADMIN',
  Agent = 'AGENT',
  Consumer = 'CONSUMER',
  Contact = 'CONTACT'
};

export type ValidateAddressInput = {
  addressAutocompleteResult?: InputMaybe<AddressAutocompleteResultInput>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  forceCreate?: InputMaybe<Scalars['Boolean']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  region?: InputMaybe<Scalars['String']>;
  streetName?: InputMaybe<Scalars['String']>;
  streetNumber?: InputMaybe<Scalars['String']>;
  unitNumber?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type Vector3 = {
  __typename?: 'Vector3';
  x: Scalars['Float'];
  y: Scalars['Float'];
  z: Scalars['Float'];
};

export type Vector3Input = {
  x: Scalars['Float'];
  y: Scalars['Float'];
  z: Scalars['Float'];
};

export const enum WaterSourceType {
  /** <https://ddwiki.reso.org/display/DDW17/WaterSource+Lookups> */
  Lake = 'LAKE',
  LakeRiver = 'LAKE_RIVER',
  Municipal = 'MUNICIPAL',
  MunicipalWater = 'MUNICIPAL_WATER',
  NotConnected = 'NOT_CONNECTED',
  Other = 'OTHER',
  Spring = 'SPRING',
  Well = 'WELL'
};

export const enum WaterfrontAccess {
  Direct = 'DIRECT',
  Indirect = 'INDIRECT',
  None = 'NONE'
};

export const enum WaterfrontType {
  Beach = 'BEACH',
  Canal = 'CANAL',
  Lake = 'LAKE',
  Ocean = 'OCEAN',
  River = 'RIVER',
  Sea = 'SEA'
};

export type WorkflowAssignment = {
  __typename?: 'WorkflowAssignment';
  description?: Maybe<Scalars['String']>;
  iconId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  suggestedUser?: Maybe<AdminUser | AgentUser | ConsumerUser | ContactUser>;
};

export type WorkflowAssignmentInput = {
  assignedUserId: Scalars['ID'];
  id: Scalars['ID'];
};

export type Workspace = {
  __typename?: 'Workspace';
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  status: WorkspaceStatus;
  statusName: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type WorkspaceConnection = {
  __typename?: 'WorkspaceConnection';
  edges?: Maybe<Array<Maybe<WorkspaceConnectionEdge>>>;
  facetGroups?: Maybe<Array<Maybe<FacetGroup>>>;
  filters?: Maybe<Array<Maybe<AggregatedFacetFilter | BooleanFilter | DateRangeFilter | FloatRangeFilter | IntegerRangeFilter | SimpleFacetFilter | TagCollectionFilter>>>;
  histogramGroups?: Maybe<Array<Maybe<HistogramGroup>>>;
  metricGroups?: Maybe<Array<Maybe<MetricGroup>>>;
  pageInfo?: Maybe<PageInfo>;
  savedSearches?: Maybe<Array<Maybe<SavedSearch>>>;
  sortOptions?: Maybe<Array<Maybe<SortOption>>>;
};


export type WorkspaceConnectionFacetGroupsArgs = {
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type WorkspaceConnectionFiltersArgs = {
  aggregatedFacetKeywordSearch?: InputMaybe<AggregatedFacetKeywordSearchInput>;
  fetchAggregatedFacetsFilterIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  includeTagFilters?: InputMaybe<Scalars['Boolean']>;
  selected?: InputMaybe<Scalars['Boolean']>;
};


export type WorkspaceConnectionHistogramGroupsArgs = {
  histogramGroupInput?: InputMaybe<HistogramGroupInput>;
};


export type WorkspaceConnectionMetricGroupsArgs = {
  metricInput?: InputMaybe<Array<InputMaybe<MetricGroupInput>>>;
};

export type WorkspaceConnectionEdge = {
  __typename?: 'WorkspaceConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Workspace>;
};

export type WorkspaceConnectionInput = {
  first: Scalars['Int'];
  keyword?: InputMaybe<Scalars['String']>;
  offset: Scalars['Int'];
  sortOptionId?: InputMaybe<Scalars['ID']>;
};

export type WorkspaceMember = {
  __typename?: 'WorkspaceMember';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  role?: Maybe<Scalars['String']>;
  status: WorkspaceMemberStatus;
  statusName: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: AdminUser | AgentUser | ConsumerUser | ContactUser;
  workspace: Workspace;
};

export type WorkspaceMemberConnection = {
  __typename?: 'WorkspaceMemberConnection';
  edges?: Maybe<Array<Maybe<WorkspaceMemberConnectionEdge>>>;
  facetGroups?: Maybe<Array<Maybe<FacetGroup>>>;
  filters?: Maybe<Array<Maybe<AggregatedFacetFilter | BooleanFilter | DateRangeFilter | FloatRangeFilter | IntegerRangeFilter | SimpleFacetFilter | TagCollectionFilter>>>;
  histogramGroups?: Maybe<Array<Maybe<HistogramGroup>>>;
  metricGroups?: Maybe<Array<Maybe<MetricGroup>>>;
  pageInfo?: Maybe<PageInfo>;
  savedSearches?: Maybe<Array<Maybe<SavedSearch>>>;
  sortOptions?: Maybe<Array<Maybe<SortOption>>>;
};


export type WorkspaceMemberConnectionFacetGroupsArgs = {
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type WorkspaceMemberConnectionFiltersArgs = {
  aggregatedFacetKeywordSearch?: InputMaybe<AggregatedFacetKeywordSearchInput>;
  fetchAggregatedFacetsFilterIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  includeTagFilters?: InputMaybe<Scalars['Boolean']>;
  selected?: InputMaybe<Scalars['Boolean']>;
};


export type WorkspaceMemberConnectionHistogramGroupsArgs = {
  histogramGroupInput?: InputMaybe<HistogramGroupInput>;
};


export type WorkspaceMemberConnectionMetricGroupsArgs = {
  metricInput?: InputMaybe<Array<InputMaybe<MetricGroupInput>>>;
};

export type WorkspaceMemberConnectionEdge = {
  __typename?: 'WorkspaceMemberConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<WorkspaceMember>;
};

export type WorkspaceMemberConnectionInput = {
  filters?: InputMaybe<Array<FilterInput>>;
  first: Scalars['Int'];
  keyword?: InputMaybe<Scalars['String']>;
  offset: Scalars['Int'];
  savedSearchId?: InputMaybe<Scalars['ID']>;
  sortOptionId?: InputMaybe<Scalars['ID']>;
  tagIds?: InputMaybe<Array<Scalars['ID']>>;
};

export const enum WorkspaceMemberStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  PendingInvite = 'PENDING_INVITE',
  Suspended = 'SUSPENDED'
};

export const enum WorkspaceStatus {
  Active = 'ACTIVE',
  Suspended = 'SUSPENDED'
};

export const enum YesNoNotSure {
  No = 'NO',
  NotSure = 'NOT_SURE',
  Yes = 'YES'
};

export const enum Zoning {
  Commercial = 'COMMERCIAL',
  Industrial = 'INDUSTRIAL',
  Residential = 'RESIDENTIAL',
  Rural = 'RURAL'
};
